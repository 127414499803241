import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import ModalClipboard from "../../../common/Share/ModalClipboard";
import { newInvitationClose } from "../../Framework/redux/InvitationsAction";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";

export default function Invitations(params) {
  const dispatch = useDispatch();

  const showModalNewInvitation = useSelector((state) => state.invitationData.invitationNewModal);
  const showModalCopyInvitation = useSelector((state) => state.invitationData.invitationCopy);

  const onCloseModal = () => {
    dispatch(newInvitationClose());
  };

  return (
    <>
      <div className="container--padding bg-white">
        <div className=" position-relative">
          <ModalClipboard
            bottom={0}
            show={showModalNewInvitation}
            close={onCloseModal}
            title={multiLangJSONParse()["invitation.pop.up.create.title.msg"]}
            text={multiLangJSONParse()["invitation.pop.up.create.body.msg"]}
          />
          <ModalClipboard
            bottom={0}
            show={showModalCopyInvitation}
            close={onCloseModal}
            title={multiLangJSONParse()["buttons.copied"] || "Copiado"}
            text={"Dirección de la invitación copiada con éxito"}
          />
        </div>
        <div className=" container--padding--card-holder ">
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
