import React from 'react'

import '../pages/Configuration.css'

export default function DataField({fieldLabel, fieldContent, clickFunction, whereTo}) {

  return (
    <div /*className='hover-pointer' onClick={() => clickFunction(whereTo)}*/>
        <p className='fw-bold mb-2 museo-sans-700 s12 blackColor'>{fieldLabel}</p>
        <div className='d-flex justify-content-between border-bottom mb-3'>
            <p className='mb-1 museo-sans-500 s16 blackColor opacity-50'>{fieldContent}</p>
            {/*<i className="bi bi-pencil-fill"></i>*/}
        </div>
    </div>
  )
}
