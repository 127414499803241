import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ReactComponent as SVGCheckGray } from "../../../common/svgs/ic-check-circle-gray.svg";
import { ReactComponent as SVGCheckGreen } from "../../../common/svgs/ic-check-circle-sm.svg";
import { ReactComponent as SVGNotifcationStar } from "../../../common/svgs/business/ic-star-outline-lg.svg";

import { formatAmount } from "../../../common/Share/FormatCurrency.js";

import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget.jsx";
import ProgressBarDashboard from "../../../common/Share/ProgressBarDashboard.jsx";

export default function BusinessPlanCardQualification({
  businessPlan,
  newToggle,
  loading,
  groups,
  data,
  level,
}) {
  return (
    <div className="cards-shadow position-relative f9GrayBorder p-4 w-100 rounded-3">
      <div style={{ height: "19px" }} className="d-flex align-items-center ">
        <h2 className=" s16 museo-sans-500 m-0 ">
          {" "}
          <FormattedMessage id="dashboard.modal.group.details.template.qualification" />{" "}
        </h2>
        <i
          data-bs-toggle="modal"
          data-bs-target="#modalInfoQualification"
          className="px-2 pointer d-flex"
        >
          <HelpIconNoTarget size={"sm"} />
        </i>
      </div>
      <hr></hr>
      {!loading ? (
        <SwitchPerLevel
          businessPlan={businessPlan}
          groups={groups}
          newToggle={newToggle}
          data={data}
          level={parseInt(level)}
        />
      ) : (
          <Skeleton />
      )}
    </div>
  );
}

export function SwitchPerLevel({
  businessPlan,
  groups,
  newToggle,
  data,
  level,
}) {

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const addProgressBar = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(<ProgressBarDashboard completed={100} incompleted={false} status={status} />);
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(<ProgressBarDashboard completed={0} incompleted={true} status={status} />);
      } else {
        aux.push(<ProgressBarDashboard completed={100} incompleted={true} status={status} />);
      }
    }

    return aux;
  };

  const onCoveringAll = () => {
    if (
      data?.personalSales?.status == "completed" &&
      data?.incorporatedSales?.status == "completed" &&
      data?.groupSales?.status == "completed"
    ) {
      return true;
    }
    return false;
  };

  const checkStatusSVG = (actual, total) => {
    if (actual >= total) {
      return <SVGCheckGreen />;
    } else {
      return <SVGCheckGray />;
    }
  };

  switch (level) {
    case 1:
      return (
        <div className="h-100 s14  d-flex flex-column">
          <p className=" d-flex pb-4 gap-1 museo-sans-500">
            <span
              style={{ minWidth: "fit-content" }}
              className=" dm-sans-bold "
            >
              {formatAmount(
                data?.triperiodicSales?.totalAmount !== 0
                  ? data?.triperiodicSales?.totalAmount
                  : 2500,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </span>
            <FormattedMessage id="business.plan.qualification.level.one" />
          </p>
        </div>
      );

    default:
      return (
        <>
          {newToggle ? (
            <>
              {level === 2 && (
                <>
                  <div className="d-flex gap-1 pb-4 ">
                    <div>
                      {
                        checkStatusSVG(data?.personalSales?.actualAmount, data?.personalSales?.totalAmount)
                      }
                    </div>
                    <div className="d-flex flex-column w-100 s14 ">
                      <p className=" museo-sans-500">
                        <FormattedMessage id="business.plan.have" />{" "}
                        <span className=" dm-sans-medium">
                          {formatAmount(
                            data?.personalSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </span>{" "}
                        <FormattedMessage id="business.plan.qualification.level.two.personal.sale" />
                      </p>
                      <div>
                        <ProgressBarDashboard
                          completed={
                            (data?.personalSales?.actualAmount * 100) /
                            data?.personalSales?.totalAmount
                          }
                          status={data?.personalSales?.status}
                          incompleted={
                            data?.personalSales?.actualAmount <
                            data?.personalSales?.totalAmount
                          }
                        />
                      </div>
                      
                      <div className="d-flex s14 dm-sans-medium justify-content-between">
                        <p>
                          {formatAmount(
                            data?.personalSales?.actualAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        <p className=" grayColor">
                          /{" "}
                          {formatAmount(
                            data?.personalSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-1 pb-4 ">
                    <div>
                      {
                        checkStatusSVG(data?.incorporatedSales?.actualActives, data?.incorporatedSales?.totalNeed)
                      }
                    </div>
                    <div className="d-flex flex-column w-100 s14 ">
                      <p className=" museo-sans-500">
                        <FormattedMessage id="business.plan.have" />{" "}
                        <span className=" dm-sans-medium">4</span>{" "}
                        <FormattedMessage id="business.plan.qualification.level.two.personal.incorporated" />
                      </p>
                      <div className=" d-flex gap-2">
                        {addProgressBar(
                          data?.incorporatedSales?.actualActives,
                          data?.incorporatedSales?.totalNeed,
                          2,
                          data?.incorporatedSales?.status
                        )}
                      </div>
                      <div className="d-flex s14 dm-sans-medium justify-content-between">
                        <p>
                          {data?.incorporatedSales?.actualActives.toLocaleString()}
                        </p>
                        <p className=" grayColor">
                          / {data?.incorporatedSales?.totalNeed}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-1 pb-4 ">
                    <div>
                      {
                         checkStatusSVG(data?.groupSales?.actualAmount, data?.groupSales?.totalAmount)
                      }
                    </div>
                    <div className="d-flex flex-column w-100 s14 ">
                      <p className=" museo-sans-500">
                        <FormattedMessage id="business.plan.have" />{" "}
                        <span className=" dm-sans-medium">
                          {formatAmount(
                            data?.groupSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </span>{" "}
                        <FormattedMessage id="business.plan.qualification.level.two.personal.group" />
                      </p>
                      <div>
                        <ProgressBarDashboard
                          completed={
                            (data?.groupSales?.actualAmount * 100) /
                            data?.groupSales?.totalAmount
                          }
                          status={data?.groupSales?.status}
                          incompleted={
                            data?.groupSales?.actualAmount <
                            data?.groupSales?.totalAmount
                          }
                        />
                      </div>
                      <div className="d-flex s14 dm-sans-medium justify-content-between">
                        <p>
                          {formatAmount(
                            data?.groupSales?.actualAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        <p className=" grayColor">
                          /{" "}
                          {formatAmount(
                            data?.groupSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {level > 2 ? (
                <>
                  <div className="d-flex gap-1 pb-4 align-items-center ">
                    <div>
                      {onCoveringAll() ? <SVGCheckGreen /> : <SVGCheckGray />}
                    </div>
                    <div className="d-flex flex-column w-100 s14 ">
                      <p className=" museo-sans-500">
                        <FormattedMessage
                          id="business.plan.qualification.leader.qualified"
                          defaultMessage="Ser Líder de Grupo Calificado."
                        />{" "}
                        <span className=" dm-sans-medium"></span>
                      </p>
                    </div>
                  </div>

                  {level > 5 && (
                    <div className="d-flex gap-1 pb-4 ">
                      <div>
                        {data?.descentSales?.actualAmount >=
                        data?.descentSales?.totalAmount ? (
                          <SVGCheckGreen />
                        ) : (
                          <SVGCheckGray />
                        )}
                      </div>
                      <div className="d-flex flex-column w-100 s14 ">
                        <p className=" museo-sans-500">
                          <FormattedMessage id="business.plan.have" />{" "}
                          <span className=" dm-sans-medium">
                            {formatAmount(
                              data?.descentSales?.totalAmount,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </span>{" "}
                          <FormattedMessage id="business.plan.qualification.level.two.personal.descendencia" />
                        </p>
                        <div>
                          <ProgressBarDashboard
                            completed={
                              (data?.descentSales?.actualAmount * 100) /
                              data?.descentSales?.totalAmount
                            }
                            incompleted={
                              data?.descentSales?.actualAmount < data?.descentSales?.totalAmount
                            }
                            status={data?.descentSales?.status}
                          />
                        </div>
                        <div className="d-flex s14 dm-sans-medium justify-content-between">
                          <p>
                            {formatAmount(
                              data?.descentSales?.actualAmount,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </p>
                          <p className=" grayColor">
                            /{" "}
                            {formatAmount(
                              data?.descentSales?.totalAmount,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex gap-1 pb-4  ">
                    <div className="d-flex align-baseline">
                      {
                        checkStatusSVG(groups?.actualQualified, groups?.totalNeed)
                      }
                    </div>
                    <div className="d-flex flex-column w-100 s14 ">
                      <p className=" museo-sans-500">
                        <FormattedMessage id="business.plan.have" />{" "}
                        {groups?.totalNeed}{" "}
                        <FormattedMessage id="business.plan.qualification.not.new.prequalification" />{" "}
                        <span className=" dm-sans-medium"></span>
                      </p>
                      <div className=" dm-sans-bold s40 d-flex ">
                        {groups?.actualQualified >= groups?.totalNeed ? (
                          <p className=" lightGreenColor ">
                            {groups?.actualQualified}
                          </p>
                        ) : (
                          <p className="grayColor">{groups?.actualQualified}</p>
                        )}
                        <p className=" grayColor">/{groups?.totalNeed}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ background: "#ECF0FA" }}
                    className=" gap-3 d-flex p-3 w-100 rounded-1"
                  >
                    <div className=" d-flex align-items-center">
                      <SVGNotifcationStar />
                    </div>
                    <div className=" s14 museo-sans-500 blueColor">
                      <FormattedMessage id="business.plan.gain" />{" "}
                      {groups?.totalNeed}{" "}
                      <FormattedMessage id="business.plan.qualification.group.leader" />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="h-100 s14  d-flex flex-column">
              <p className=" d-flex pb-4 gap-1 museo-sans-500">
                <span
                  style={{ minWidth: "fit-content" }}
                  className=" dm-sans-bold "
                >
                  {formatAmount(
                    data?.personalSales?.totalAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </span>
                <FormattedMessage id="business.plan.qualification.not.new.personal" />
              </p>
              <p className=" d-flex pb-4 gap-1 museo-sans-500">
                <span
                  style={{ minWidth: "fit-content" }}
                  className=" dm-sans-bold "
                >
                  {formatAmount(
                    businessPlan?.qualifications[1]?.value,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </span>
                <FormattedMessage id="business.plan.qualification.not.new.group" />
              </p>
              <p className=" d-flex pb-4 gap-1 museo-sans-500">
                <span
                  style={{ minWidth: "fit-content" }}
                  className=" dm-sans-bold "
                >
                  {businessPlan?.qualifications[2]?.value.toLocaleString()}
                </span>
                <FormattedMessage id="business.plan.qualification.not.new.incorporated" />
              </p>
              {level > 2 && (
                <p className=" d-flex pb-4 gap-1 museo-sans-500">
                  <span
                    style={{ minWidth: "fit-content" }}
                    className=" dm-sans-bold "
                  >
                    {groups?.totalNeed}
                  </span>
                  <FormattedMessage id="business.plan.qualification.not.new.prequalification" />
                </p>
              )}
              {level > 5 && (
                <p className=" d-flex pb-4 gap-1 museo-sans-500">
                  <span
                    style={{ minWidth: "fit-content" }}
                    className=" dm-sans-bold "
                  >
                    {formatAmount(
                      data?.descentSales?.totalAmount,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </span>
                  <FormattedMessage id="business.plan.qualification.not.new.desc" />
                </p>
              )}
            </div>
          )}
        </>
      );
  }
}

export function Skeleton() {
  return (
    <div className=" d-flex  flex-column gap-4 ">
      <SkeletonRow />
      <SkeletonRow type={true} />
      <SkeletonRow />
      <SkeletonRow line={true} />
      <SkeletonRow button={true} />
    </div>
  );
}

export function SkeletonRow({ type, line, button }) {
  return (
    <div className="d-flex gap-2 ">
      {button ? (
        <>
          <div className="invisible">
            <SVGCheckGray />
          </div>
          <div className=" w-100 ">
            <div
              style={{ height: "26px" }}
              className=" rounded-4 w-25 brightGrayBg lightui1-shimmer"
            ></div>
          </div>
        </>
      ) : (
        <>
          <SVGCheckGray />
          {!line && (
              <div className=" mt-1 d-flex flex-column gap-3  w-100  ">
                <div
                  style={{ height: "6px" }}
                  className=" rounded-3 w-75 brightGrayBg lightui1-shimmer "
                ></div>
                <div>
                  <SkeletonRowCenter type={type} />
                </div>
                <div className="d-flex justify-content-between w-100 ">
                  <div className=" w-50">
                    <div
                      style={{ height: "6px" }}
                      className=" rounded-3 w-25 d-flex justify-content-between brightGrayBg lightui1-shimmer"
                    ></div>
                    <div className=" w-75 d-flex justify-content-between  "></div>
                  </div>
                  <div className=" w-50 d-flex justify-content-between ">
                    <div className=" w-50 d-flex justify-content-between lightui1-shimmer"></div>
                    <div
                      style={{ height: "6px" }}
                      className=" rounded-3 w-50 d-flex justify-content-between brightGrayBg lightui1-shimmer"
                    ></div>
                  </div>
                </div>
              </div>
          )}
          {line && (
            <div
              style={{ height: "6px" }}
              className=" rounded-3 mt-1 w-75 brightGrayBg lightui1-shimmer"
            ></div>
          )}
        </>
      )}
    </div>
  );
}

export function SkeletonRowCenter({ type }) {
  return (
    <>
      {type ? (
          <div className=" d-flex gap-2 ">
            <div
              style={{ height: "12px" }}
              className=" brightGrayBg w-100 rounded-3 lightui1-shimmer" 
            ></div>
            <div
              style={{ height: "12px" }}
              className=" brightGrayBg w-100 rounded-3 lightui1-shimmer"
            ></div>
            <div
              style={{ height: "12px" }}
              className=" brightGrayBg w-100 rounded-3 lightui1-shimmer"
            ></div>
            <div
              style={{ height: "12px" }}
              className=" brightGrayBg w-100 rounded-3 lightui1-shimmer"
            ></div>
          </div>
      ) : (
          <div
            style={{ height: "12px" }}
            className=" brightGrayBg w-100 rounded-3 lightui1-shimmer"
          ></div>
      )}
    </>
  );
}
