import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openVimeo } from "../../../common/redux/commonActions";
import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as SVGVideo } from "../../../common/svgs/ic-video-sm.svg";

export default function GoodPracticesImportantCard({ cardData }) {
  const history = useNavigate();
  const sortColors = (color) => {
    if (color === "completed") {
      return { color: "#16BE7D" };
    }
    if (color === "warning") {
      return { color: "#DCB632" };
    }
    if (color === "risk") {
      return { color: "#F06E69" };
    }
    if (color === "normal") {
      return { color: "#DDDDDD" };
    }
  };

  const dispatch = useDispatch();

  const onClick = () => {

    if (cardData.links[0]?.type === "deep_link") {
      switch (cardData.links[0]?.id) {
        case "EDBRealizadosAll":
          history("/dashboard/edbs/list/dated")
          return;

        case "Descendants":
          history("/dashboard/descendants/dashboard")
          return;
      
        default:
          return;
      }
    }
    let values = cardData.links[0];
    dispatch(openVimeo({id: values.id, key: values.key}))
  };

  return (
    <div
      style={{
        borderRadius: "1rem",
      }}
      className=" gg-cards--importance f9GrayBorder bg-white desc-cards-shadow d-flex flex-column justify-content-between p-3"
    >
      <div className=" d-flex flex-column gap-2" >
        <p style={{ paddingBottom: "14px"}} className=" pt-2 grayColor museo-sans-500 s12 text-uppercase ">{cardData.header}</p>
        <div style={{ alignItems: "center", height: "31px" }} className="d-flex">
          {cardData?.title?.type === "ratio" ? (
            <>
              <h4
                style={sortColors(cardData.title.items[0].status)}
                className="m-0 s26 dm-sans-bold  "
              >
                {cardData.title.items[0].value}
              </h4>
              <span
                style={sortColors(cardData.title.items[1].status)}
                className="m-0 s26 dm-sans-bold "
              >
                /{cardData.title.items[1].value}
              </span>
            </>
          ) : (
            <h4
              style={sortColors(cardData.title.items[0].status)}
              className="m-0 s26 dm-sans-bold "
            >
              {cardData.title.items[0].value}
            </h4>
          )}
        </div>
        <p className=" s14 museo-sans-500 ">{cardData.description}</p>
      </div>

      <div
        onClick={onClick}
        className="skyColor d-flex  museo-sans-700 s14"
      >
        <p className=" pointer">
          {cardData.links[0].title}
          <i className="px-2 pointer">
            {cardData.links[0]?.type === "deep_link" ? (
              <SVGRightArrow />
            ) : (
              <SVGVideo />
            )}
          </i>
        </p>
      </div>
    </div>
  );
}
