import { ReactComponent as SVGBloqued } from "../SVGs/Bloqueado/ic-item-bloq-sm-stroke-grey.svg";
import { ReactComponent as SVGUnlockedGraph } from "../SVGs/Bloqueado/ic-item-unlocked-yellow-bgwhite.svg";
import { ReactComponent as SVGReached } from "../SVGs/ReachedCompletedGreen/ic-check-circle-sm-inverse.svg";
import { ReactComponent as SVGFailed } from "../SVGs/NoAlcanzado/ic-x-circle-sm-inverse.svg";
import { ReactComponent as SVGCompleted } from "../SVGs/Completado/ic-item-gift-sm.svg";
import { ReactComponent as SVGpending } from "../SVGs/Pending/ic-item-pending-sm-stroke-yellow.svg";
import moment from "moment-timezone";

export default function DashboardFirstStepsModalDetailGraph({ infoStep, isModal }) {
  let toggler = true;

  return (
    <div className=" w-100 d-flex gap-2 py-4    ">
      {toggler && (
        <>
          <Column isWeek={false} infoStep={infoStep}  isModal={isModal} />
        </>
      )}
      <Column isWeek={true} week={1} infoStep={infoStep} isModal={isModal} />
      <Column isWeek={true} week={2} infoStep={infoStep} isModal={isModal} />
      <Column isWeek={true} week={3} infoStep={infoStep} isModal={isModal} />
      <Column isWeek={true} week={4} infoStep={infoStep} isModal={isModal} />
    </div>
  );
}

export function Column({ isWeek, week, infoStep, isModal  }) {

  const constructIncompletedProgress = (index) => {
    const progressMap = {
      1: "80%",
      2: "90%",
      3: "100%",
      4: "10%",
      5: "25%",
      6: "45%",
      7: "65%"
    };
  
    return progressMap[index] || "100%";
  };
  

  const today = moment().isoWeekday()

  const titleColumn = () => {
    if (isWeek) {
      return `S${week}`;
    } else {
      return "ALTA";
    }
  };

  return (
    <div className=" w-100 d-flex align-items-center flex-column museo-sans-500 s12 skyColor ">
      {
        !isWeek &&
        <p className="mb-1 blueColor" >{titleColumn()}</p>
      }
      {
        infoStep?.currentWeek >= week &&
        <p className="mb-1 blueColor" >{titleColumn()}</p>
      }
      {
        infoStep?.currentWeek < week &&
        <p className=" mb-1 brightGrayColor " >{titleColumn()}</p>
      }
      <div className=" w-100 position-relative">
        <ProgressBarOfertas
          isWeek={isWeek}
          dataWeek={infoStep}
          completed={infoStep?.currentWeek >= week || !isWeek ? 100 : 0}
          height={4}
          today={constructIncompletedProgress(today)}
          currentWeek={infoStep?.currentWeek}
          deliveredWeek={week}
        />
        {isWeek && infoStep?.currentWeek >= week && (
          <>
            <div
              style={{ height: "96px", bottom: -96, opacity: 0.4, width: infoStep?.currentWeek == week ? constructIncompletedProgress(today) : "100%" }}
              className=" blueBg20 position-absolute"
            ></div>
          </>
        )}
        {!isWeek && (
          <>
            <div
              style={{ height: "96px", bottom: -96, left: "50%", opacity: 0.4 }}
              className=" blueBg20 w-50 position-absolute"
            ></div>
          </>
        )}
      </div>
      <div
        style={{ height: "88px" }}
        className=" pt-2 w-100 d-flex gap-2 flex-column position-relative"
      >
        {infoStep?.steps && (
          <>
            <ProgressBarOfertasBody
              actualWeek={3}
              isWeek={isWeek}
              index={infoStep?.steps[0]?.stepDetail?.limitWeek  }
              continues={false}
              week={week}
              data={infoStep?.steps[0]}
              achievementIndex={1}
               isModal={isModal}
            />
            <div>
              <ProgressBarOfertasBody
                actualWeek={infoStep?.currentWeek}
                isWeek={isWeek}
                index={infoStep?.steps[1]?.stepDetail?.limitWeek  }
                continues={false}
                week={week}
                data={infoStep?.steps[1]}
                achievementIndex={2}
                 isModal={isModal}
              />
              <ProgressBarOfertasBody
                actualWeek={infoStep?.currentWeek}
                isWeek={isWeek}
                index={infoStep?.steps[2]?.stepDetail?.limitWeek  }
                continues={false}
                week={week}
                data={infoStep?.steps[2]}
                achievementIndex={3}
                 isModal={isModal}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function ProgressBarOfertasBody({
  isWeek,
  week,
  data,
  index,
  achievementIndex,
  isModal
}) {


  const checkStepStatus = (status, tipoLogro, index, position, isModal) => {
    switch (status) {
      case "FAILED":
        return (
          <p
            className=" position-absolute"
            style={{ top: 3, minWidth: "180px", color: "#FFFFFF" }}
          >
            {tipoLogro} {index != 3 ? data.stepDescription : isModal && position < 2  ? "Logro..." : "Logro EDB"} -{" "}
            {readuceElement("No alcanzado", position, isModal)}
          </p>
        );
      case "PENDING":
        return (
          <p
            className=" position-absolute"
            style={{ top: 3, minWidth: "180px", color: "#171A22" }}
          >
            {tipoLogro} {index != 3 ? data.stepDescription : isModal && position < 2  ? "Logro..." : "Logro EDB"} -{" "}
            {readuceElement("Pendiente", position, isModal)}
          </p>
        );
        case "UNLOCKED":
        return (
          <p
            className=" position-absolute"
            style={{ top: 3, minWidth: "180px", color: "#171A22" }}
          >
            {tipoLogro} {index != 3 ? data.stepDescription : isModal && position < 2  ? "Logro..." : "Logro EDB"} -{" "}
            {readuceElement("Desbloqueado", position, isModal)}
          </p>
        );
      case "BLOCKED":
        return (
          <p
            className=" position-absolute"
            style={{ top: 3, minWidth: "180px", color: "#171A22" }}
          >
            {tipoLogro} {index != 3 ? data.stepDescription : isModal && position < 2  ? "Logro..." : "Logro EDB"} -{" "}
            {readuceElement("Bloqueado", position, isModal)}
          </p>
        );
      case "COMPLETED":
        return (
          <p
            className=" position-absolute"
            style={{ top: 3, minWidth: "180px", color: "#FFFFFF" }}
          >
            {tipoLogro} {index != 3 ? data.stepDescription : isModal && position < 2  ? "Logro..." : "Logro EDB"} -{" "}
            {readuceElement("Completado", position, isModal)}
          </p>
        );
      case "REACHED":
        return (
          <p
            className=" position-absolute"
            style={{ top: 3, minWidth: "180px", color: "#FFFFFF" }}
          >
            {tipoLogro} {index != 3 ? data.stepDescription : isModal && position < 2  ? "Logro..." : "Logro EDB"} -{" "}
            {readuceElement("Alcanzado", position, isModal)}
          </p>
        );
    }
  };

  const readuceElement = (string, index, isModal) => {
    if (index == 1) {
      let result = string;
      if (isModal) {
        result = ""
      } else { // .slice(0, 7) + "...";
        result = result.slice(0, 7) + "...";
      }
      return result;
    } else {
      if (isModal && index == 2) {
        let result = string;
        result = result.slice(0, 7) + "...";
        return result;
      }
      return string;
    }
  };

  const checkColor = (status) => {
    const colors = {
      FAILED: "#F06E69",
      PENDING: "#F2D149",
      UNLOCKED: "#F2D149",
      BLOCKED: "#DDDDDD",
      COMPLETED: "#F2D149",
      REACHED: "#16BE7D"
    };
  
    return colors[status];
  };
  
  const checkSvg = (status) => {
    const svgs = {
      FAILED: <SVGFailed />,
      PENDING: <SVGpending />,
      BLOCKED: <SVGBloqued />,
      COMPLETED: <SVGCompleted />,
      REACHED: <SVGReached />,
      UNLOCKED: <SVGUnlockedGraph />
    };
  
    return (
      <div style={{ width: "16px", height: "16px", top: -2 }} className="position-relative svg-force-size">
        {svgs[status] || <SVGCompleted />}
      </div>
    );
  };

  const adjustProgressDistance = (index, isModal) => {
    const distances = {
      1: isModal ? "90px" : "100px",
      2: isModal ? "150px" : "200px",
      3: isModal ? "180px" : "300px",
      4: isModal ? "280px" : "400px"
    };
  
    return distances[index] || (isModal ? "280px" : "400px");
  };

  return (
    <>
      {isWeek ? (
        <>
          {
            // Ubicacion dada igual o menor dentro del limite. reachedWeek limitWeek
            index >= week ? (
              <>
                <div
                  style={{
                    height: "24px",
                    background: checkColor(data?.stepDetail?.state),
                  }}
                  className=" w-100 position-relative "
                >
                  {
                    // Ubicacion dada igual a ultimo lugar.
                    (index > 4 ? 4 : index) == week && achievementIndex == 1 && (
                      <>
                        <div
                          id={`limit-by-${index}`}
                          style={{
                            height: "24px",
                            top: -12,
                            width: "2px",
                            right: 0,
                            background: checkColor(data?.stepDetail?.state),
                          }}
                          className=" position-absolute"
                        ></div>
                      </>
                    )
                  }
                  {(index > 4 ? 4 : index) == week && achievementIndex == 2 && (
                    <>
                      <div
                        id={`limit-by-${index}`}
                        style={{
                          height: "44px",
                          top: -44,
                          width: "2px",
                          right: 0,
                          background: checkColor(data?.stepDetail?.state),
                          
                        }}
                        className=" position-absolute"
                      ></div>
                    </>
                  )}
                  {(index > 4 ? 4 : index) == week && achievementIndex == 3 && (
                    <>
                      <div
                        id={`limit-by-${index}`}
                        style={{
                          height: "64px",
                          top: -64,
                          width: "2px",
                          right: 0,
                          background: checkColor(data?.stepDetail?.state),
                        }}
                        className=" position-absolute"
                      ></div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ height: "24px" }}
                  className=" w-100 position-relative "
                ></div>
              </>
            )
          }
        </>
      ) : (
        <>
          <div
            style={{
              height: "24px",
              borderRadius: "16px 0px 0px 16px",
              right: "-50%",
              background: checkColor(data?.stepDetail?.state),
            }}
            className=" w-100 position-relative "
          >
            <div
              style={{
                width: adjustProgressDistance(index, isModal),
                borderRadius: "16px 0px 0px 16px",
                zIndex: 5,
                background: checkColor(data?.stepDetail?.state),
              }}
              className=" d-flex px-2 pe-0 h-100 position-absolute align-items-center gap-2 text-white "
            >
              <div className=" position-relative rounded-circle bg-white" style={{ width: "auto" }}>
                {checkSvg(data?.stepDetail?.state)}
              </div>
              <div className=" text-uppercase museo-sans-500 s12">
                {checkStepStatus(
                  data?.stepDetail?.state,
                  null,
                  data?.stepId,
                  index,
                  isModal
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export function ProgressBarOfertas({ completed, height, isWeek, dataWeek, today, currentWeek, deliveredWeek  }) {
  const containerStyles = {
    height: height ? height : 8,
    width: "100%",
    backgroundColor: "#DDDDDD",
    borderRadius: 0,
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div className="progress-bar position-relative" style={containerStyles}>
      <div className=" d-flex h-100 w-100">
        {!isWeek && (
          <>
            <div
              className=" d-flex justify-content-end position-relative "
              style={{
                height: "100%",
                right: 1,
                borderRadius: "inherit",
                width: `${completed ? 50 : 0}%`,
              }}
            >
              <span
                className=" position-absolute mx-1"
                style={labelStyles}
              ></span>
            </div>
          </>
        )}

        <div
          className=" d-flex justify-content-end position-relative blueBg"
          style={
            isWeek
              ? {
                  height: "100%",
                  borderRadius: "inherit",
                  width: `${currentWeek == deliveredWeek ? today : currentWeek > deliveredWeek ? "100%" : "0%"}`,
                }
              : {
                  height: "100%",
                  borderRadius: "inherit",
                  width: `${completed ? 50 : 0}%`,
                }
          }
        >
          <span className=" position-absolute mx-1" style={labelStyles}></span>
        </div>
      </div>
    </div>
  );
}
