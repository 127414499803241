
import { FormattedMessage } from "react-intl";
import InvitationsAddFormInput from "./InvitationsAddFormInput";
import InvitationsAddFormTwoInput from "./InvitationsAddFormTwoInput";

export default function InvitationsAddForm({ form, formErrorToggle, changeReference, changeFirstName, changeLastName, changePhoneArea, changePhoneBody, changeEmail, onSubmit, placeHoldeCountry }) {
 
  return (
    <>
      <div style={{ marginTop: "147px" }} className="w-100">
        <p className="s16 grayColor museo-sans-500">
          <FormattedMessage id="invitation.create.title.form" />
        </p>
        <div style={{ marginTop: "60px" }}>
          <div style={{ gap: "37px" }} className="d-flex flex-column">
            <div style={{ gap: "37px" }} className="d-flex w-100 gap-4">
              <div className=" pe-3 w-100 ">
                <InvitationsAddFormInput
                  label={"Nombre"}
                  requiered={true}
                  placeholder={"Ej.: María"}
                  onChange={changeFirstName}
                  errorControl={formErrorToggle && form?.firstName?.error}
                />
              </div>
              <div className=" px-3 w-100 ">
                <InvitationsAddFormInput
                  label={"Apellido"}
                  requiered={false}
                  placeholder={"Ej.: Suarez"}
                  onChange={changeLastName}
                  errorControl={formErrorToggle && form?.lastName?.error}
                />
              </div>
            </div>
            <div style={{ gap: "37px" }} className="d-flex w-100 gap-4">
              <div className=" pe-3 w-100 ">
                <InvitationsAddFormTwoInput
                  label={"Teléfono celular"}
                  requiered={false}
                  placeholderOne={"Ej.: +54"}
                  placeholderTwo={placeHoldeCountry}
                  onChangeOne={changePhoneArea}
                  onChangeTwo={changePhoneBody}
                  errorControl={formErrorToggle && form?.phone?.error}
                />
              </div>
              <div className=" px-3 w-100 ">
                <InvitationsAddFormInput
                  label={"Email"}
                  requiered={false}
                  placeholder={"Ej.: maria@email.com"}
                  onChange={changeEmail}
                  errorControl={formErrorToggle && form?.email?.error}
                />
              </div>
            </div>
            <div style={{ gap: "37px" }} className="d-flex w-100 gap-4">
              <div className=" pe-3 w-100 ">
              <InvitationsAddFormInput
                  label={"Referencia"}
                  requiered={false}
                  placeholder={"Ej.: amiga del gym"}
                  onChange={changeReference}
                  errorControl={formErrorToggle && form?.reference?.error}
                />
              </div>
              <div className=" invisible px-3 w-100 ">
                <InvitationsAddFormInput
                  label={"Email"}
                  requiered={false}
                  placeholder={"Ej.: maria@email.com"}
                  onChange={changeEmail}
                  errorControl={formErrorToggle && form?.email?.error}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <button
              onClick={onSubmit}
              style={{ width: "434px", height: "55px" }}
              className=" s16 border-0 rounded-16 s16  museo-sans-700 skyBg text-white "
            >
              <FormattedMessage id="invitation.create.button.text" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
