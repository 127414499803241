import EDBCard from "../components/EDBCard";

import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { OpenEDBMeetings } from "../../Framework/redux/EDBsSideEffects";
import { DatedEDBMeetings } from "../../Framework/redux/EDBsSideEffects";
import { ClosedEDBMeetings } from "../../Framework/redux/EDBsSideEffects";
import {
  clearArrayVimeo,
  openVimeo,
  setArrayVimeo,
} from "../../../common/redux/commonActions";

import EDBFourStepsCard from "../components/EDBFourStepsCard";

import firstStepImage from "../../../common/img/fourStepsImages/4-pasos_paso-1.jpeg";
import secondStepImage from "../../../common/img/fourStepsImages/4-pasos_paso-2.jpeg";
import thirdStepImage from "../../../common/img/fourStepsImages/4-pasos_paso-3.jpeg";
import fourthStepImage from "../../../common/img/fourStepsImages/4-pasos_paso-4.jpeg";

import { ReactComponent as CameraIconSVG } from "../../../common/svgs/ic-video-sm.svg";

import EDBModal from "../components/EDBModal";
import EDBEmpty from "../components/EDBEmpty";
import LastUpdate from "../../../common/Share/LastUpdate";
import SkeletonEDBDashboard from "../../../common/Share/SkeletonEDBDashboard";
import EDBDashboardSlider from "../components/EDBSliders/EDBDashboardSlider";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

import { ReactComponent as SVGPresence } from "../../../common/svgs/ic-edb-presencial-blue-sm.svg";
import { ReactComponent as SVGVirtual } from "../../../common/svgs/ic-edb-virtual-blue-sm.svg";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import "../pages/EDBs.css";
import { clearClientDetailsForm } from "../../Framework/redux/EDBsActions";

export default function EDBsDashboard() {
  const history = useNavigate();

  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const dispatch = useDispatch();

  const arrayVimeo = [
    { id: "660660137", key: "905525bd8c" },
    { id: "658654704", key: "8f84a90f0e" },
    { id: "660673321", key: "eaa93263cd" },
    { id: "659321858", key: "8de1c2d8a4" },
    { id: "660661792", key: "6a1fb9a5f9" },
    { id: "658738576", key: "13c72be8ed" },
    { id: "660676388", key: "e45e961b7d" },
    { id: "659323470", key: "007e5c529a" },
    { id: "658655231", key: "db06ebc68b" },
    { id: "660663242", key: "7bea6dd12e" },
  ];

  useEffect(() => {
    dispatch(ClosedEDBMeetings(10));
    dispatch(OpenEDBMeetings(10));
    dispatch(DatedEDBMeetings(10));
    dispatch(clearArrayVimeo());
    dispatch(setArrayVimeo(arrayVimeo));
  }, []);

  function howManyCards() {
    if (biggerThan1280) {
      return 4;
    } else {
      return 3;
    }
  }

  const openEDBs = useSelector((state) => state.edbData.openEDBs);
  const loadingOpenEDBs = useSelector((state) => state.edbData.loadingOpenEDBs);

  const datedEDBs = useSelector((state) => state.edbData.datedEDBs);
  const loadingDatedEDBs = useSelector(
    (state) => state.edbData.loadingDatedEDBs
  );

  function carouselCards(array, from, to) {
    return array.map((element, index) => {
      if (index >= from && index < to) {
        return (
          <EDBCard
            key={ShareKeyGenerator()}
            id={element.id}
            edbState={element.edbState}
            daysToClose={element.daysToClose}
            daysToOpen={element.daysToOpen}
            edbStatus={element.edbStatus}
            accumulatedAmount={element.accumulatedAmount}
            amountStatus={element.amountStatus}
            date={element.date}
            title={element.title}
            hostName={element.hostName}
            totalRegistered={element.totalRegistered}
            registeredStatus={element.registeredStatus}
          />
        );
      }
    });
  }

  function carouselStepCards(array, from, to) {
    return array.map((element, index) => {
      if (index >= from && index < to) {
        return element.card;
      }
    });
  }

  const stepsCardArray = [
    {
      card: (
        <EDBFourStepsCard image={firstStepImage}>
          <p className="museo-sans-500 s12 grayColor mb-2">
            <FormattedMessage id="edb.step.card.1" defaultMessage="Paso 1" />
          </p>
          <p className="museo-sans-700 s16 blackColor mb-3">
            <FormattedMessage
              id="edb.step.card.1.title"
              defaultMessage="Título"
            />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "660660137", key: "905525bd8c" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.1.link.1"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "658654704", key: "8f84a90f0e" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.1.link.2"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
        </EDBFourStepsCard>
      ),
    },
    {
      card: (
        <EDBFourStepsCard image={secondStepImage}>
          <p className="museo-sans-500 s12 grayColor mb-2">
            <FormattedMessage id="edb.step.card.2" defaultMessage="Paso 2" />
          </p>
          <p className="museo-sans-700 s16 blackColor mb-3">
            <FormattedMessage
              id="edb.step.card.2.title"
              defaultMessage="Título"
            />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "660673321", key: "eaa93263cd" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.2.link.1"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "659321858", key: "8de1c2d8a4" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.2.link.2"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "660661792", key: "6a1fb9a5f9" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.2.link.3"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
        </EDBFourStepsCard>
      ),
    },
    {
      card: (
        <EDBFourStepsCard image={thirdStepImage}>
          <p className="museo-sans-500 s12 grayColor mb-2">
            <FormattedMessage id="edb.step.card.3" defaultMessage="Paso 3" />
          </p>
          <p className="museo-sans-700 s16 blackColor mb-3">
            <FormattedMessage
              id="edb.step.card.3.title"
              defaultMessage="Título"
            />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "658738576", key: "13c72be8ed" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.3.link.1"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "660676388", key: "e45e961b7d" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.3.link.2"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "659323470", key: "007e5c529a" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.3.link.3"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
        </EDBFourStepsCard>
      ),
    },
    {
      card: (
        <EDBFourStepsCard image={fourthStepImage}>
          <p className="museo-sans-500 s12 grayColor mb-2">
            <FormattedMessage id="edb.step.card.4" defaultMessage="Paso 4" />
          </p>
          <p className="museo-sans-700 s16 blackColor mb-3">
            <FormattedMessage
              id="edb.step.card.4.title"
              defaultMessage="Título"
            />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "658655231", key: "db06ebc68b" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.4.link.1"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
          <p
            onClick={() =>
              dispatch(openVimeo({ id: "660663242", key: "7bea6dd12e" }))
            }
            className="hover-pointer museo-sans-700 s14 skyColor mb-3 button-hover-light-text button-hover-svg-light-fill"
          >
            <FormattedMessage
              id="edb.step.card.4.link.2"
              defaultMessage="Enlace"
            />{" "}
            <CameraIconSVG />
          </p>
        </EDBFourStepsCard>
      ),
    },
  ];

  return (
    <div className="container--padding container--padding--card-holder edb-background">
      <div className=" pt-5 pb-5 edb-background ">
        <ShareModalStructure id={"modalInfo"} maxWidth={"600px"} >
          <EDBModal />
        </ShareModalStructure>
        
        <div className=" w-100 d-flex justify-content-between">
          <p className=" invisible museo-sans-500 s20">
            <FormattedMessage id="edb.title" defaultMessage="Encuentros" />
          </p>
          <LastUpdate />
        </div>

        {
          ShareCheckEnvKey("CREATE_EDB") &&
          <div className="w-100 mb-5">
            <p className="s20 museo-sans-500">
              <FormattedMessage id="header.button.new.one" />
            </p>
            <div
              style={{ width: "600px", height: "108px" }}
              className="mt-2 s14 bg-white account--card-shadow rounded-16 "
            >
              <div className="d-flex museo-sans-700 justify-content-between h-100">
                <div onClick={() => history("form/type/present")} className="d-flex w-100 h-100 justify-content-center align-items-center gap-2 pointer">
                  <div style={{width: "24px", fill: "#456ECE", color: "#456ECE", top: -1}} className=" position-relative svg-force-size svg-force-color ">
                    <SVGPresence />
                  </div>
                  <p>
                    <FormattedMessage id="edb.filter.label.button.one" />
                  </p>
                </div>
                <div className="d-flex justify-content-center align-items-center h-100" >
                  <div style={{width: "2px", height: "50%"}} className=" brightGrayBgOpacity20" >
                    <p className=" invisible" >
                      .
                    </p>

                  </div>
                </div>
                <div onClick={() => history("form/type/virtual")} className="d-flex w-100 h-100 justify-content-center align-items-center gap-2 pointer">
                <div style={{width: "24px", fill: "#8889DB", color: "#8889DB"}} className=" svg-force-size svg-force-color ">
                    <SVGVirtual />
                  </div>
                  <p>
                  <FormattedMessage id="edb.filter.label.button.two" />
                  </p>
                </div>
              </div>
            </div>
          </div>

        }

        <div className=" w-100">
          <div className="d-flex justify-content-start align-items-center mb-4">
            <p className="museo-sans-500 s20">
              <FormattedMessage
                id="edb.open.section.title"
                defaultMessage="Ultima actualización"
              />
            </p>
            <p
              onClick={() => history(`/dashboard/edbs/list/open`)}
              className="pointer museo-sans-700 s14 ms-3 button-hover-light-text button-hover-svg-light-fill skyColor"
              href=""
            >
              <FormattedMessage
                id="edb.section.link"
                defaultMessage="Enlace de la sección"
              />
            </p>
          </div>
          <div
            className="d-flex position-relative pb-5"
            style={{ gap: "15px" }}
          >
            {
              // loadingOpenEDBs - Cambiar "False" por esta variable. Eliminar Loader
            }
            {loadingOpenEDBs ? (
              <>
                <div className=" w-100 position-absolute"></div>
                <div
                  style={{ overflowX: "hidden", scrollBehavior: "smooth" }}
                  className=" edb-scroll-slider d-flex position-relative"
                >
                  <div
                    style={{ flexWrap: "nowrap", flexShrink: 0 }}
                    className=" d-flex gap-4"
                  >
                    <SkeletonEDBDashboard />
                    <SkeletonEDBDashboard />
                    <SkeletonEDBDashboard />
                    <SkeletonEDBDashboard />
                  </div>
                </div>
              </>
            ) : openEDBs.length === 0 ? (
              <div className=" position-relative w-100 d-flex justify-content-center align-items-center">
                <EDBEmpty
                  title={
                    <FormattedMessage
                      id="edb.no.open.edbs"
                      defaultMessage="No hay encuentros Abiertos"
                    />
                  }
                  description={
                    <FormattedMessage
                      id="edb.no.open.edbs.description"
                      defaultMessage="No hay encuentros Abiertos"
                    />
                  }
                ></EDBEmpty>
              </div>
            ) : (
              <>
                {
                  // <Loader loading={loadingOpenEDBs} />
                }
                <EDBDashboardSlider
                  length={howManyCards()}
                  data={openEDBs}
                  carouselCards={carouselCards}
                />
              </>
            )}
          </div>
        </div>

        <div className=" w-100">
          <div className="d-flex justify-content-start align-items-center mb-3">
            <p className="museo-sans-500 s20">
              <FormattedMessage
                id="edb.dated.section.title"
                defaultMessage="Título de la sección"
              />
            </p>
            <p
              onClick={() => history(`/dashboard/edbs/list/dated`)}
              className="pointer museo-sans-700 s14 ms-3 button-hover-light-text button-hover-svg-light-fill skyColor"
              href=""
            >
              <FormattedMessage
                id="edb.section.link"
                defaultMessage="Enlace de la sección"
              />
            </p>
          </div>
          <div
            className="d-flex position-relative pb-5  "
            style={{ gap: "15px" }}
          >
            {
              // loadingDatedEDBs. Remove False and change it for this variable
            }
            {loadingDatedEDBs ? (
              <>
                <div className=" w-100 position-absolute"></div>
                <div
                  style={{ overflowX: "hidden", scrollBehavior: "smooth" }}
                  className=" edb-scroll-slider d-flex position-relative"
                >
                  <div
                    style={{ flexWrap: "nowrap", flexShrink: 0 }}
                    className=" d-flex gap-4"
                  >
                    <SkeletonEDBDashboard type={"dated"} />
                    <SkeletonEDBDashboard type={"dated"} />
                    <SkeletonEDBDashboard type={"dated"} />
                    <SkeletonEDBDashboard type={"dated"} />
                  </div>
                </div>
              </>
            ) : datedEDBs.length === 0 ? (
              <div className="  w-100 d-flex justify-content-center align-items-center">
                <EDBEmpty
                  title={
                    <FormattedMessage
                      id="edb.no.dated.edbs"
                      defaultMessage="No hay encuentros Fechados"
                    />
                  }
                  description={
                    <FormattedMessage
                      id="edb.no.dated.edbs.description"
                      defaultMessage="No hay encuentros Fechados"
                    />
                  }
                ></EDBEmpty>
              </div>
            ) : (
              <>
                {
                  // <Loader loading={loadingDatedEDBs} />
                }
                <EDBDashboardSlider
                  length={howManyCards()}
                  data={datedEDBs}
                  carouselCards={carouselCards}
                />
              </>
            )}
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-start mb-3">
            <p className="museo-sans-500 s20">
              <FormattedMessage
                id="edb.step.section.title"
                defaultMessage="Título de la sección"
              />
            </p>
          </div>
          <div
            className="d-flex flex-wrap position-relative"
            style={{ gap: "16px" }}
          >
            {
              // loadingOpenEDBs - Same here.
            }
            {loadingOpenEDBs ? (
              <>
                <div className=" w-100 position-absolute"></div>
                <div
                  style={{ overflowX: "hidden", scrollBehavior: "smooth" }}
                  className=" edb-scroll-slider d-flex position-relative"
                >
                  <div
                    style={{ flexWrap: "nowrap", flexShrink: 0 }}
                    className=" d-flex gap-4"
                  >
                    <SkeletonEDBDashboard type={"steps"} />
                    <SkeletonEDBDashboard type={"steps"} />
                    <SkeletonEDBDashboard type={"steps"} />
                    <SkeletonEDBDashboard type={"steps"} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <EDBDashboardSlider
                  solid={4}
                  length={howManyCards()}
                  data={stepsCardArray}
                  carouselCards={carouselStepCards}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
