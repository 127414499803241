import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import Loader from "../../../common/Share/Loader";
import StyledMultiLang from "../../../common/Share/StyledMultiLang";
import { fetchDesProfile, fetchDesScore } from "../../Framework/redux/DescendantsSideEffect";
import DescendantAscendentProfileHeader from "../components/DescendantAscendentProfileHeader";



export default function DescendantAscendentProfile() {

    const dispatch = useDispatch();
    const root = useParams();

    const profileDescData = useSelector((state) => state.descendantData.descendantProfilaData);
    const scoreDescData = useSelector((state) => state.descendantData.descendantScoreData);

    const [loader, setLoader] = useState(true);

    const onStartUp = async () => {
        setLoader(true);
        await dispatch(fetchDesProfile({root: root.id }));
        await dispatch(fetchDesScore({root: root.id}));
        setLoader(false);
    };

    useEffect(() => {
        onStartUp();
    }, [root]);
    
    return <div className=" position-relative" >
        {!loader ? (
        <>
          {profileDescData?.level < 2 && (
            <div
              style={{ background: "#8889DB", height: "124px", opacity: "65%" }}
              className=""
            ></div>
          )}
          {profileDescData?.level > 1 && profileDescData.level < 6 && (
            <div
              style={{ background: "#D18DEF", height: "124px", opacity: "65%" }}
              className=""
            ></div>
          )}
          {profileDescData?.level > 5 && (
            <div
              style={{ background: "#CED2FF", height: "124px", opacity: "65%" }}
              className=""
            ></div>
          )}
        </>
      ) : (
        <>
          <div
            className=" w-100 brightGrayBg "
            style={{
              height: "124px",
              maxWidth: "inherit",
              overflow: "none",
              background:
                "linear-gradient(180deg, #DDDDDD 0%, #85848E 100%) 0% 0% no-repeat padding-box",
            }}
          ></div>
        </>
      )}
        <div  className=" container--padding">
        {profileDescData && <DescendantAscendentProfileHeader loader={loader} data={profileDescData} score={scoreDescData} root={root.id} />}
        </div>
    </div>
}

export function SkeletonDashboardQualification({
    descendant,
    level,
    status,
    reduced,
    toggler,
    borderCompletedStyle,
    borderNotCompletedStyle,
    loading,
  }) {
    return (
      <>
        <div>
          <div className=" museo-sans-500 d-flex gap-2 align-items-center mb-4 ">
            <h3 className=" m-0 s20">
              {descendant ? (
                "Calificaciones"
              ) : (
                <>
                  {level == 1 ? (
                    <>
                      <StyledMultiLang
                        loading={loading}
                        id="dashboard.qualifications.title.one.b"
                        def={"Tus Calificaciones"}
                      />
                    </>
                  ) : (
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.title.one"
                      def={"Tus Calificaciones"}
                    />
                  )}
                </>
              )}
            </h3>
            {!descendant && (
              <i
                data-bs-toggle="modal"
                data-bs-target="#modalQualificationn"
                className="pointer"
              >
                <HelpIconNoTarget />
              </i>
            )}
            {!descendant && (
              <Link
                className="text-decoration-none"
                to="/dashboard/performance/personalSales"
              >
                <p className=" px-3 s14 skyColor museo-sans-700">
                  <>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.show.more"
                      def={"Ver todas tus calificaciones"}
                    />
                  </>
                </p>
              </Link>
            )}
          </div>
          {!descendant && (
            <>
              {reduced && (
                <div
                  style={{ background: "#FCEFFD" }}
                  className=" p-3 rounded-2 align-items-center cards-shadow mb-4 d-flex gap-3 w-100"
                >
                  <div style={{ width: "32px" }}>
                    <div />
                  </div>
                  <p style={{ color: "#D18DEF" }} className="s14 museo-sans-500">
                  <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.notice.label"
                      def={"Ver todas tus calificaciones"}
                    />
                    "
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.notice.label.two"
                      def={"Ver todas tus calificaciones"}
                    />
                    " {" "}
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.notice.label.three"
                      def={"Ver todas tus calificaciones"}
                    />
                  </p>
                </div>
              )}
            </>
          )}
          {level < 2 && (
            <div
              className=" position-relative "
              style={{ width: "200px", height: "30px" }}
            >
              <div
                style={{
                  width: "184px",
                  height: "38px",
                  top: -9,
                  borderRadius: "16px 16px 0px 0px ",
                  zIndex: 0,
                }}
                className={`${
                  toggler ? "lightWhiteBg" : "bg-white"
                } pointer d-flex justify-content-center align-items-center py-2   position-absolute`}
              >
                <p className=" museo-sans-500 s14 ">
                  <FormattedMessage id="level.two" defaultMessage={"Líder de Grupo"} />
                </p>
              </div>
              <div
                style={{
                  width: "184px",
                  height: "38px",
                  top: -9,
                  borderRadius: "16px 16px 0px 0px ",
                  left: 184,
                  zIndex: 0,
                }}
                className={` ${
                  toggler ? "bg-white" : "lightWhiteBg"
                } pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
              >
                <p className=" museo-sans-500 s14 ">
                  <FormattedMessage id="level.one.not.inclusive" defaultMessage={"Consultora"} />
                </p>
              </div>
            </div>
          )}
          <div
            style={
              level < 2 && status == "completed"
                ? borderCompletedStyle
                : borderNotCompletedStyle
            }
            className=" position-relative w-100 bg-white f9GrayBorder cards-shadow p-4"
          >
            <div
              style={{ height: "32px" }}
              className="w-100 d-flex justify-content-center lightGreyBg rounded-3 align-items-center   "
            >
              <div
                style={{ height: "12px", width: "440px" }}
                className=" rounded-3 brightGrayBg   "
              ></div>
            </div>
            <div style={{ gap: "32px" }} className=" mt-4 pt-2 d-flex ">
              <div style={{height: "341px"}} className=" d-flex justify-content-between flex-column w-100 ">
                <div
                  style={{ height: "16px", width: "129px" }}
                  className=" brightGrayBg rounded-3 "
                ></div>
                <div className="d-flex gap-4 flex-column" >
                  <SkeletonDashboardQualificationLeftRow />
                  <SkeletonDashboardQualificationLeftRow />
                  <SkeletonDashboardQualificationLeftRow />
                  <SkeletonDashboardQualificationLeftRow />
                </div>
                <div style={{width: "130px", height: "8px"}} className=" brightGrayBg rounded-3"  >
  
                </div>
              </div>
              <div style={{ width: "1px" }} className="h-100 brightGrayBg  ">
                <p className=" invisible">.</p>
              </div>
              <div className=" d-flex flex-column justify-content-between w-100 ">
                <div>
                  <div className="  d-flex justify-content-between ">
                    <div
                      style={{ height: "16px" }}
                      className=" w-75 brightGrayBg rounded-3 "
                    ></div>
                    <div
                      style={{ height: "16px" }}
                      className=" invisible w-25 brightGrayBg rounded-3 "
                    ></div>
                    <div
                      style={{ height: "16px" }}
                      className=" invisible w-25 brightGrayBg rounded-3 "
                    ></div>
                    <div
                      style={{ height: "16px" }}
                      className=" w-25 brightGrayBg rounded-3 "
                    ></div>
                  </div>
                  <div style={{gap: "43px"}} className="d-flex flex-wrap mt-4 pt-2 " >
                    <SkeletonDashboardQualificationRight />
                    <SkeletonDashboardQualificationRight />
                    <SkeletonDashboardQualificationRight />
                    <SkeletonDashboardQualificationRight />
                    <SkeletonDashboardQualificationRight />
                    <SkeletonDashboardQualificationRight />
                    <SkeletonDashboardQualificationRight />
                  </div>
                </div>
                <div style={{width: "130px", height: "8px"}} className=" brightGrayBg rounded-3"  >
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export function SkeletonDashboardQualificationLeftRow(params) {
    return (
      <>
        <div className=" gap-3 d-flex flex-column">
          <div className=" d-flex  justify-content-between">
            <div style={{height: "12px"}} className="lightui1-shimmer rounded-3 w-100 brightGrayBg "></div>
            <div style={{height: "12px"}} className=" rounded-3 w-100  "></div>
            <div className=" w-100 d-flex gap-1 " >
              <div style={{height: "8px"}} className="lightui1-shimmer rounded-3 w-100 brightGrayBg "></div>
              <div style={{height: "8px"}} className="lightui1-shimmer rounded-3 w-100 brightGrayBg "></div>
            </div>
            
          </div>
          <div style={{height: "12px"}} className="lightui1-shimmer mt-1 w-100 rounded-3 brightGrayBg ">
  
          </div>
        </div>
      </>
    );
  }
  
  export function SkeletonDashboardQualificationRight(params) {
    return <>
      <div style={{width: "62px"}}  className=" d-flex flex-column align-items-center
       gap-2 position-relative" >
        <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >
  
        </div>
        <div style={{gap: "2px"}} className=" w-100 d-flex " >
        <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >
  
  </div>
  <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >
  
        </div>
        <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >
  
        </div>
        <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >
  
        </div>
        </div>
        <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >
  
        </div>
        <div style={{height: "6px", width: "21px"}} className="lightui1-shimmer rounded-3 brightGrayBg  " >
  
        </div>
        <div style={{height: "6px", width: "31px"}} className="lightui1-shimmer rounded-3 brightGrayBg  " >
  
        </div>
      </div>
    </>
  }
  