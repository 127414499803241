import moment from "moment";
import ShareNameMonthLong from "./ShareNameMonthLong";

export default function ShareGetDate(string) {

  if (!string) {
    return;
  }

  const momentDateTime = moment(string);
  const momentTime = momentDateTime.format("HH:mm");
  const momentDay = momentDateTime.format("DD");
  const momentMonth = momentDateTime.format("MM");

  let resolve = momentDay + " " + ShareNameMonthLong(momentMonth) + " - " + momentTime + "hs";
  return resolve;
};
