import { useState } from "react";
import { useRef } from "react";

import { ReactComponent as SVGFilledRightGrayChev } from "../../../../common/svgs/ic-slide-right-gray.svg";
import { ReactComponent as SVGFilledLeftGrayChev } from "../../../../common/svgs/ic-slide-left-gray.svg";
import { ReactComponent as SVGFilledRightChev } from "../../../../common/svgs/ic-slide-right.svg";
import { ReactComponent as SVGFilledLeftChev } from "../../../../common/svgs/ic-slide-left.svg";

export default function EDBDashboardSlider({
  carouselCards,
  data,
  length,
  solid,
}) {
  const scrollBarRef = useRef(null);
  const [togglerLeftOne, setTogglerLeftOne] = useState(true);
  const [togglerRightOne, setTogglerRightOne] = useState(false);

  const scroll = (scrollOffset, ref) => {
    let maxScrollLeft =
      scrollBarRef.current.scrollWidth - scrollBarRef.current.clientWidth;

    scrollBarRef.current.scrollLeft += scrollOffset;

    let currentValue = (scrollBarRef.current.scrollLeft += scrollOffset);

    if (currentValue >= maxScrollLeft) {
      setTogglerRightOne(true);
    } else {
      setTogglerRightOne(false);
    }

    if (0 >= currentValue) {
      setTogglerLeftOne(true);
    } else {
      setTogglerLeftOne(false);
    }
  };

  const control = (length, solid, data) => {
    if (solid) {
      if (length == solid) {
        return false;
      } else {
        return true;
      }
    } else {
      if (data.length > length) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {control(length, solid, data) && (
        <div className=" w-100 position-absolute">
          <div
            style={{ top: 172 }}
            className=" d-flex justify-content-between align-items-center w-100 position-absolute"
          >
            <div
              style={{ width: "24px", zIndex: 5, left: -30 }}
              className=" svg-force-size position-relative pointer"
              onClick={() => scroll(-150)}
            >
              {togglerLeftOne ? (
                <SVGFilledLeftGrayChev />
              ) : (
                <SVGFilledLeftChev />
              )}
            </div>
            <div
              style={{ width: "24px", zIndex: 5, right: -30 }}
              className=" svg-force-size position-relative pointer"
              onClick={() => scroll(+150)}
            >
              {togglerRightOne ? (
                <SVGFilledRightGrayChev />
              ) : (
                <SVGFilledRightChev />
              )}
            </div>
          </div>
        </div>
      )}
      <div
        style={ data.length > length ? {overflowX: "hidden", scrollBehavior: "smooth" }: {scrollBehavior: "smooth" }}
        ref={scrollBarRef}
        className=" edb-scroll-slider d-flex position-relative"
      >
        <div
          style={{ flexWrap: "nowrap", flexShrink: 0 }}
          className=" edb-dashboard--slider-gap d-flex py-4"
        >
          {carouselCards(data, 0, 50)}
          <div className="invisible" >
            S
          </div>
        </div>
      </div>
    </>
  );
}
