import React from 'react'

import '../Config/Presentation/pages/Configuration.css'

import { useMediaPredicate } from 'react-media-hook';

import ReturnTo from './ReturnTo';

import { FormattedMessage } from 'react-intl';

export default function ReturnToAndTitle({children, travelFunction, returnTo, title}) {

  const biggerThan992 = useMediaPredicate("(min-width: 992px)");
  
  return (
    <>
      {
        returnTo
        ?
          <ReturnTo travelFunction={travelFunction} returnTo={returnTo}/>
        :
        null
      }
      {
        title ? 
        <div className={`${biggerThan992 && "w-50"}`}>
            <p className='fs-5 font-weight-semibold'>
              <FormattedMessage
                id={title}
                defaultMessage="Titulo de la sección"
              />
            </p>
            {children}
        </div>
        :
        null
      }
        
    
    </>
    
  )
}

