import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import Radius from "../../../common/Share/Radius";
import ShareComponentInput from "../../../common/Share/ShareComponentInput";
import { ErrorMessage } from "../pages/EDBsForm";

export default function EDBsFormVirtual() {

    const [isPrivate, setIsPrivate] = useState(false);
    const [virtual, setVirtual] = useState({
        url: "",
        password: "",
        streaming: "",
    });
    const errorToggler = useSelector((state) => state.edbFormData.errorTogger.value);

    const onVirtualChange = () => {
        const auxFormBody = JSON.parse(localStorage.getItem("formBody"));

        const updatedConference = { url: virtual.url, password: virtual.password };
        const updatedStreamingURL = { url: virtual.streaming }
        const updatedIsPublic = !isPrivate;

        const updatedAuxFormBody = { ...auxFormBody, conference: updatedConference, streaming: updatedStreamingURL, public: updatedIsPublic };

        localStorage.setItem("formBody", JSON.stringify(updatedAuxFormBody));
    };

    const onPrivateChange = (value) => {
        const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
        setIsPrivate(value);
        const updatedIsPublic = !value;
        const updatedAuxFormBody = { ...auxFormBody, public: updatedIsPublic };

        localStorage.setItem("formBody", JSON.stringify(updatedAuxFormBody));
    };

    const onChangeByName = (name, any, value) => {
        const updatedVirtual = { ...virtual, [name]: value };
        setVirtual(updatedVirtual);
    };

    useEffect(() => {
        const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
        if (!auxFormBody) {
          return;
        }
        const { conference } = auxFormBody;
        const { streaming } = auxFormBody;

        if (!streaming || !conference) {
            return;
        }

        const updatedStreamingURL = streaming;
        const updatedConference = { ...conference};

        setVirtual({ url: updatedConference?.url, password: updatedConference?.password, streaming: updatedStreamingURL?.url });
        setIsPrivate(!auxFormBody.public);
    
    }, [])

    useEffect(() => {
        onVirtualChange();
    },[virtual])

    
    return(
        <div className="d-flex flex-column gap-3 s14" >
            <div className="d-flex gap-3" >
                    <div className=" w-50 d-flex flex-column gap-2 " >
                        <ShareComponentInput errorToggler={errorToggler && !virtual.url}  name={"url"} onChange={onChangeByName} inputDate={false} value={virtual.url} helpIcon={""} label={<FormattedMessage id="edb.form.virtual.input.one" />} placeholder={multiLangJSONParse()["edb.form.virtual.input.placeholder.one"]} />
                        <ErrorMessage errorToggler={errorToggler && !virtual.url} />
                    </div>
                    <div className=" w-50 " >
                        <ShareComponentInput name={"password"} onChange={onChangeByName} inputDate={false} value={virtual.password} helpIcon={""} label={<FormattedMessage id="edb.form.virtual.input.two" />} placeholder={multiLangJSONParse()["edb.form.virtual.input.placeholder.two"]} />
                    </div>
            </div>
            <div className="d-flex gap-3" >
                    <div className=" w-50 " >
                        <ShareComponentInput name={"streaming"} onChange={onChangeByName} inputDate={false} value={virtual.streaming} helpIcon={""} label={<FormattedMessage id="edb.form.virtual.input.three" />} placeholder={multiLangJSONParse()["edb.form.virtual.input.placeholder.three"]} />
                    </div>
                    <div className=" w-50 invisible " >
                        <ShareComponentInput name={"form.date.name"} onChange={"handleFormChange"} inputDate={false} value={""} helpIcon={""} label={"form.date.label"} />
                    </div>
            </div>
            <div>
                <div className="my-2">
                    <p className=" museo-sans-700 s14" >
                        <FormattedMessage id="edb.form.virtual.privacy.text" />
                    </p>
                    <div className="d-flex" >
                        <div
                        onClick={() => onPrivateChange(false)}
                        className=" pe-3 py-2 my-1 d-flex pointer gap-2"
                        >
                        <Radius status={!isPrivate} />
                        <span className={`${!isPrivate ? " s14" : "grayColor s14"}`}>
                            <FormattedMessage id="edb.form.virtual.privacy.public" />
                        </span>
                        </div>
                        <div
                        onClick={() => onPrivateChange(true) }
                        className=" px-3 py-2 my-1 d-flex pointer gap-2"
                        >
                        <Radius status={isPrivate} />
                        <span className={`${isPrivate ? " s14" : "grayColor s14"}`}>
                        <FormattedMessage id="edb.form.virtual.privacy.private" />
                        </span>
                        </div>

                    </div>
                    <p className=" s14 museo-sans-500 grayColor" >
                        <FormattedMessage id="edb.form.virtual.privacy.help.text" />
                    </p>
                  </div>
                </div>
        </div>
    )
}