import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGMail } from "../../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGCross } from "../../../../common/svgs/ic-x-lg.svg";
import { useState, useEffect } from "react";

export default function InvitationsModalShareLink({
  email,
  phone,
  firstName,
  lastName,
  data,
  reInvite,
  id,
}) {
  const [copyValue, setCopyValue] = useState(false);

  function resolveLink(link, failsafe) {
    let auxURL = "";

    failsafe
      ? (auxURL =
          "http://swissjust-auto-incorporacion-int.s3-website-us-east-1.amazonaws.com/")
      : (auxURL = link);

    if (failsafe && link) {
      let splitted = link.split(".com/");
      let targetSplittedOne = splitted[1]; // /?country=ar&sponsorId=5726&personalId=C322F6242CE2409C8863B94358A3C79B

      auxURL = auxURL + targetSplittedOne;
    }

    if (link) {
      let auxName = `${firstName.trim()}${
        lastName ? `_${lastName.trim()}` : ""
      }`;
      let splittedName = auxName.split(" ");
      let jointSplittedName = splittedName.join("_");

      auxURL = auxURL + `&sponsorName=${jointSplittedName}`;
    }

    return auxURL;
  }

  const invitationListTest = resolveLink(
    data,
    process.env.REACT_APP_API_URL_FAILSAFE
  );
  
  const shareMessageTest = `¡Hola! ¡que bueno que te interesa empezar tu Negocio Just y ser parte de mi grupo personal de Consultores Independientes Just!\nHaciendo click en este link que te comparto, vas a poder incorporarte en línea hoy mismo:\n${invitationListTest}\nCualquier pregunta que tengas, no dudes en escribirme, estoy para acompañarte y que tengas un exitoso comienzo.`;

  const shareMessageTestPhone = `¡Hola! ¡que bueno que te interesa empezar tu Negocio Just y ser parte de mi grupo personal de Consultores Independientes Just!\nHaciendo click en este link que te comparto, vas a poder incorporarte en línea hoy mismo:\n${invitationListTest}\nCualquier pregunta que tengas, no dudes en escribirme, estoy para acompañarte y que tengas un exitoso comienzo.`;

  useEffect(() => {
    setTimeout(() => {
      copyValue && setCopyValue(false);
    }, 1500);
  }, [copyValue]);

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(invitationListTest);
      setCopyValue(true);
    } catch (error) {
      unsecuredCopyToClipboard(invitationListTest);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      setCopyValue(true);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <>
      <div
        className="modal fade"
        id={`modalShare${reInvite ? `-${id}` : ""}`}
        aria-hidden="true"
        aria-labelledby={`modalShare`}
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "556px" }}
        >
          <div className="modal-content modal-radius">
            <div className="modal-body p-0">
              <div>
                <div className="d-flex justify-content-between m-3 p-4">
                  <div className="d-flex">
                    <h5 className="museo-sans-500 s20 m-0">
                      {reInvite ? (
                        <FormattedMessage id="invitation.add.modal.title.two" />
                      ) : (
                        <FormattedMessage id="invitation.add.modal.title" />
                      )}
                    </h5>
                  </div>
                  <div
                    className="pointer"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <SVGCross />
                  </div>
                </div>
                <hr className="m-0"></hr>
                <div className=" p-4 d-flex  mt-0 gap-3">
                  <a
                    href={`mailto:${
                      email || ""
                    }?&subject=Te Comparto?&body=${encodeURIComponent(
                      shareMessageTest
                    )}`}
                    target="_blank"
                    className="ms-2 bronceStroke grayColor cancelBootstrap"
                  >
                    <div
                      style={{ alignItems: "center" }}
                      className=" pointer d-flex flex-column justify-content-center"
                    >
                      <div
                        style={{
                          width: "64px",
                          height: "64px",
                          alignItems: "center",
                        }}
                        className=" bubble-social rounded-circle d-flex justify-content-center"
                      >
                        <SVGMail />
                      </div>
                      <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                        <FormattedMessage id="social.sharedata.link.mail" />
                      </p>
                    </div>
                  </a>
                  <a
                    href={`https://wa.me/${
                      phone || ""
                    }?text=${encodeURIComponent(shareMessageTestPhone)}`}
                    target="_blank"
                    className="ms-2 bronceStroke grayColor cancelBootstrap"
                  >
                    <div
                      style={{ alignItems: "center" }}
                      className=" pointer d-flex flex-column justify-content-center"
                    >
                      <div
                        style={{
                          width: "64px",
                          height: "64px",
                          alignItems: "center",
                        }}
                        className=" bubble-social rounded-circle d-flex justify-content-center"
                      >
                        <SVGWhatsap />
                      </div>
                      <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                        <FormattedMessage id="social.sharedata.link.wap" />
                      </p>
                    </div>
                  </a>
                </div>
                <div className="p-3 px-4">
                  <div className=" museo-sans-500 pb-4 px-4  w-100">
                    <p className=" s14">
                      <FormattedMessage id="invitation.add.modal.link.text" />
                    </p>
                    <div
                      style={{ overflow: "hidden", height: "56px" }}
                      className=" px-2 s16 d-flex position-relative justify-content-between   align-items-center rounded-16 input-border gap-2 w-100 mt-1"
                    >
                      <p
                        style={{ height: "22px", overflow: "hidden" }}
                        className=""
                      >
                        {resolveLink(
                          data,
                          process.env.REACT_APP_API_URL_FAILSAFE
                        )}
                      </p>
                      <p
                        onClick={doThis}
                        className=" pointer pe-2 museo-sans-700 skyColor"
                      >
                        <FormattedMessage id="copy" />
                      </p>
                      {copyValue && (
                        <div
                          style={{
                            height: "38px",
                            right: 6,
                            bottom: 6,
                            width: "120px",
                          }}
                          className=" justify-content-center greenModalBg s16 d-flex align-items-center gap-2 position-absolute"
                        >
                          <i
                            style={{ justifySelf: "center" }}
                            className=" greenColor bi bi-check-circle"
                          ></i>
                          <p className="greenColor">
                            <FormattedMessage id="buttons.copied" />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
