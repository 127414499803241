import axios from "axios";

const NetSrvRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 50000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

NetSrvRefresh.interceptors.request.use(
  async (config) => {
    const refresh = localStorage.getItem("refresh");
    config.headers.Authorization = refresh;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);



// Add a response interceptor
NetSrvRefresh.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.info) {
      response = undefined;
    } else {
      response = response.data;
    }
    return response;
  },
  function (error) {

    if (error.isAxiosError) {
    }
    return Promise.reject(error)
  }
);

export default NetSrvRefresh;
