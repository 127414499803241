import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ShareFlagIcons from "../../../../../common/Share/ShareFlagIcons";
import { ReactComponent as SVGRedCross } from "../../../../../EDBs/Presentation/img/redcross.svg";

export default function InputPhoneNumber({
  label,
  placeholderTwo,
  register,
  name2,
  isValidateError,
  onChangeValidate,
}) {
  const country = localStorage.getItem("country");
  const [data, setData] = useState();

  const handleChangeNumber = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
    setData(event.target.value);
    onChangeValidate();
  };

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52",
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const countryList = Object.keys(numbers).reduce((acc, key) => {
    acc[numbers[key]] = key;
    return acc;
  }, {});

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <p className=" museo-sans-500 s14">{label}</p>
      </div>
      <div
        className="d-flex justify-content-between position-relative"
        style={{ width: "360px", height: "40px" }}
      >
        
        <div
          style={{ width: "360px" }}
          className={`museo-sans-500 position-relative s14 d-flex justify-content-center align-items-center rounded-2 input-border ${
            (!data && isValidateError) ? "redBorder" : ""
          }  h-100  p-2`}
        >
          <div className=" position-relative d-flex gap-2 s14 pe-2" >
          <div style={{ top: 8, left: 1 }}  className=" position-relative">
            <ShareFlagIcons code={countryList[numbers[country]]} height={"22px"} />
          </div>
          <p style={{top: 10}} className=" dm-sans-500 grayColor position-relative" >
            {numbers[country]}
          </p>
          <div style={{
            backgroundColor: (!data && isValidateError) ? "#f06e69" : "#B0D4FF", 
            width: "1px",
            height:"40px"
          }} >

          </div>
        </div>
          <input
            type="text"
            placeholder={placeholderTwo}
            className=" no-outline-input border-0 m-1 h-100 w-100"
            {...register(name2)}
            onChange={handleChangeNumber}
            maxLength={40}
          />
        </div>
        {(!data && isValidateError) && (
          <div
            style={{ top: 42 }}
            className="position-absolute bottom-0 start-0"
          >
            <p className="museo-sans-500 d-flex gap-1 pt-1 museo-sans-500 s12 text-danger">
              <SVGRedCross />
              <FormattedMessage id="input.error.required" />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
