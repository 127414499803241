import { FormattedMessage } from "react-intl";

import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGWarn } from "../../../../common/svgs/ic-x-circle-swade.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteClient } from "../../../Framework/redux/ClientListSideEffect";
import { openNotificationDeletePopUp } from "../../../Framework/redux/ClientListAction";

export default function ClientListDetailsModalDelete({contactId}) {

  const dispatch = useDispatch();
  const history = useNavigate();

  const onActivateSwalOnDelete = async () => {
    await dispatch(deleteClient(contactId));
    dispatch(openNotificationDeletePopUp());
    const clickMe = document.getElementById("close-details-delete-modal");
    clickMe.click();
    history("/dashboard/clientlist");
};

  return (
    <div
      className="modal fade"
      id="modalDelete"
      aria-hidden="true"
      aria-labelledby="modalDelete"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "600px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex justify-content-between pt-4 mt-3 px-4 mx-3 ">
                <div className="d-flex invisible align-items-center">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0">
                    <FormattedMessage
                      id="account.modal.info.title"
                      defaultMessage="Buenas Prácticas"
                    />
                  </h5>
                </div>
                <div
                  className="pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <SVGClose />
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className=" mb-2 w-75 d-flex justify-content-center flex-column text-center museo-sans-500 px-4 mx-3">
                  <div>
                    <SVGWarn />
                  </div>
                  <p className=" s24 museo-sans-700">Eliminar cliente</p>
                  <p className=" s16 museo-sans-500 grayColor  ">
                    ¿Estás seguro de que quieres eliminar este cliente de tu
                    agenda Just?
                  </p>
                </div>
                <div className=" mt-4 mb-4 pb-3 d-flex flex-column justify-content-center gap-3 align-items-center">
                  <button
                    type="button"
                    className=" btnLoginWidth  museo-sans-700 s14 skyBg whiteColor border-0 lightRedBg button-bootstrap-radius button-padding"
                    onClick={onActivateSwalOnDelete}
                  >
                    Eliminar cliente
                  </button>
                  <p
                    id="close-details-delete-modal"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className=" pointer s14 skyColor museo-sans-700"
                  >
                    Cancelar
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ¿Estás seguro de que quieres eliminar este cliente de tu agenda Just?
// Eliminar cliente
// Cancelar
