import { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";


import {
  PersonalData,
  ContactData,
  addressCountrySelector,
  ClientListFormTextArea,
} from "../components/formsections";
import WarningModal from "../components/modals/WarningModal";
import ButtonSubmit from "../components/ButtonSubmit";

import {
  getClientDetails,
  getTerritoryByLevel,
  postClient,
  putClient,
} from "../../Framework/redux/ClientListSideEffect";
import { defaultState, bodyAdapter, schemas } from "../helpers";
import FormSkeleton from "../components/skeletons/FormDetailSkeleton";
import { openPostUpdate } from "../../Framework/redux/ClientListAction";
import { clientDetailsForm } from "../../../EDBs/Framework/redux/EDBsActions";

export default function NewClientListForm() {
  const country = localStorage.getItem("country");
  const methods = useForm({ resolver: yupResolver(schemas[country]),
    defaultValues: {
      province: '',
      locality: '',
      postalCode:'',
      state:'',
      municipality:'',
      region:'',
      comune:'',
      department:'',
      city:'',
      signs:'',
      canton:'',
      district:'',
      township:'',
    },
  })
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors}
  } = methods

  const location = useLocation();
  const {
    data: clientDetails,
    loader,
    success,
  } = useSelector((state) => state.clientListData.clientDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidateError, setIsValidateError] = useState(false);
  const [formulationError, setFormulationError] = useState({
    firstName: false,
    lastName: false,
  });
  const [firstRun, setFirstRun] = useState(true);

  const params = useParams();

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52",
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const onSubmit = handleSubmit(async (data) => {
    const body = bodyAdapter(data, country);
    console.log("Body: ", body);
    debugger;
    const inBeforePhoneNumberArray = body.phoneNumbers;
    const auxiliarPhoneNumberArray = [];
    if (Array.isArray(inBeforePhoneNumberArray) && inBeforePhoneNumberArray.length > 0){
      
      inBeforePhoneNumberArray.forEach(element => {
        debugger
        let auxiliarPhoneNumber = numbers[country];
        auxiliarPhoneNumber = auxiliarPhoneNumber.concat(element?.number);
        element.number = auxiliarPhoneNumber;
        auxiliarPhoneNumberArray.push(element);
      });
    }
    body.phoneNumbers = auxiliarPhoneNumberArray;

    console.log("Body: ", body);
    dispatch(clientDetailsForm(body));
    let result = ""
    if (params?.operation == "update") {
      result = await dispatch(
        putClient({ ...body, contactId: clientDetails.contactId })
      );
    } else {
      result = await dispatch(postClient(body));
    }
    console.log("Result? ", result)
    dispatch(openPostUpdate());
  });

  const onFormulationErrorClear = () => {
    setFormulationError({
      firstName: false,
      lastName: false,
    });
  };

  const onChangeValidate = () => {
    setIsValidateError(false);
    onFormulationErrorClear();
  };

  const onStartUpUpdate = async () => {
    await dispatch(getClientDetails(params?.contactId));
  };

  const onStartUp = async () => {
    await dispatch(getTerritoryByLevel());
  };

  function allocate(clientDetails) {
    defaultState.personalDataStateKeys.forEach((key) => {
      setValue(key, clientDetails[key]);
    });
    if (country == "ar")
      setValue(
        "identification",
        clientDetails.identification
          ? clientDetails.identification[0]?.value
          : null
      );
    if (clientDetails.socialNetworks && clientDetails.socialNetworks.length) {
      const indexEmail = clientDetails.socialNetworks
        .map((item) => item.id)
        .indexOf("email");
      const email = clientDetails.socialNetworks[indexEmail].value;
      setValue("email", email);
    }
    if (clientDetails.phoneNumbers?.length) {
      clientDetails.phoneNumbers.map((item) => {
        if (item.phoneOrderId == 1) {
          if (country == "ar") setValue("cellArea", item.areaCode);
          setValue("cellNumber", item.number);
        } else {
          if (country == "ar") setValue("areaOtherNumber", item.areaCode);
          setValue("otherNumber", item.number);
        }
      });
    }
    if (clientDetails.addressInfo?.length) {
      defaultState.addressStateKeys.forEach((key) => {
        setValue(key, clientDetails.addressInfo[0][key]);
      });
    }
    if (clientDetails.comments?.length) {
      setValue("comments", clientDetails.comments[0].value);
    }
  };

  useEffect(() => {
    onStartUp();
    if (params?.operation == "update") {
      onStartUpUpdate();
    }
    
  }, []);

  useEffect(() => {
    if (success) {
      location.pathname.includes("edbs") ?
      navigate(-1) :
      navigate(`/dashboard/clientlist`);
    }
    setFirstRun(true);
    setFormulationError({ firstName: false , lastName: false })
    setIsValidateError(false);
  }, [success]);

  useEffect(() => {
    if (params?.operation == "update" && clientDetails) {
      allocate(clientDetails);
    }
  }, [clientDetails]);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      const button = document.getElementById("errorsModalButon");
      button.click();
    }
    console.log("FirstRun: ", firstRun);
    setFirstRun(false);
    if (!firstRun) {
      setFormulationError({ firstName: true , lastName: true })
      setIsValidateError(true);
    }
  }, [errors]);

  

  return (
    <>
      <WarningModal errors={errors} />
      <button
        id="errorsModalButon"
        className="invisible position-absolute"
        data-bs-toggle="modal"
        data-bs-target={`#modalFormErros`}
      ></button>
      <FormProvider {...methods}>
        <div className="container--padding bg-white">
          <div className=" container--padding--card-holder ">
            <div>
              <div className="w-100 mt-2 ">
                <form
                  style={{ width: "766px" }}
                  className="mt-5"
                  onSubmit={onSubmit}
                >
                  {loader ? (
                    <FormSkeleton />
                  ) : (
                    <>
                      <h3 className=" museo-sans-500 mb-3 s20">
                        <FormattedMessage id="client.details.card.title" />
                      </h3>
                      <hr className=" m-0" />
                      <PersonalData onChangeValidate={onChangeValidate} formulationError={formulationError} />
                      <h3 className=" museo-sans-500 s20">
                        <FormattedMessage id="invitation.modal.list.contact.data" />
                      </h3>
                      <hr className=" m-0" />
                      <ContactData onChangeValidate={onChangeValidate} isValidateError={isValidateError} register={register} />
                      <h3 className=" museo-sans-500 s20">
                        <FormattedMessage id="settings.user.data.address" />
                      </h3>
                      <hr className=" m-0" />
                      <div
                        style={{ gap: "24px" }}
                        className="d-flex flex-wrap my-4 "
                      >
                        {addressCountrySelector(
                          country,
                          register,
                          watch,
                          setValue,
                          control
                        )}
                      </div>
                      <hr className="m-0" />
                      <div className="d-flex flex-wrap mt-4 mb-4 pb-3">
                        <ClientListFormTextArea
                          placeholder={"Escribí tus notas aquí"}
                          label={"Notas (opcional)"}
                          name="comments"
                          register={register}
                        />
                      </div>
                      <ButtonSubmit onClick={() => {}}>
                        {params?.operation == "update" ? <FormattedMessage id="save" /> : <FormattedMessage id="create" />}
                      </ButtonSubmit>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
}
