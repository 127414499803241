import { createAction } from '@reduxjs/toolkit';

const openNotificationDeletePopUp = createAction('openNotificationDeletePopUp');
const closeNotificationDeletePopUp = createAction('closeNotificationDeletePopUp');
const resetSucessToast = createAction('resetSucessToast');
const openPostUpdate = createAction('openPostUpdate');
const closePostUpdate = createAction('closePostUpdate');
const checkLevel = createAction("checkLevel");
const clearClientDetailsForm = createAction('clearClientDetailsForm');

export  {openNotificationDeletePopUp, clearClientDetailsForm, closeNotificationDeletePopUp, resetSucessToast, openPostUpdate, closePostUpdate,checkLevel} 