import React, { useState } from "react";
import DescendantLine from "./DescendantLine";
import { ReactComponent as SVGFilledDownChev } from "../../../common/svgs/ic-chevron-filled-down.svg";
import { ReactComponent as SVGFilledRightChev } from "../../../common/svgs/ic-slide-right.svg";
import { ReactComponent as SVGRightChev } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as SVGCircleYellow } from "../../../common/svgs/ic-alert-circle-sm.svg";
import { ReactComponent as SVGCircleRed } from "../../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as SVGCircleGreen } from "../../../common/svgs/ic-check-circle-sm.svg";
import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";
import ProgressBarTemplateGroup from "../../../common/Share/ProgressBarTemplateGroup";
import DescendantsSublist from "./DescendantsSublist";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePersonalGroupName, defineDescendantModalUserInfo, defineModalUserSalesTab } from "../../Framework/redux/DescendantsAction";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import checkReduceRequirement from "../../../common/Share/checkReduceRequirement";

export default function DescendantsSublistRow({ index, end, gen,  user }) {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  const history = useNavigate();
  const onCollapse = () => {
    setToggle(!toggle);
  };

  const addProgressBar = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarTemplateGroup
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarTemplateGroup
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else {
        aux.push(
          <ProgressBarTemplateGroup
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      }
    }

    return aux;
  };

  const returnQualificationStatus = (boolean, level, data) => {
    if (level < 2) {
      if (
        data.triperiodicSales.status == "completed"
      ) {
        return (
          <div
            style={{ width: "24px", height: "24px" }}
            className="svg-force-size  desc--svg-adjustment"
          >
            {" "}
            <SVGCircleGreen />{" "}
          </div>
        );
      } else {
        if (data.triperiodicSales.status == "risk") {

          return (
            <div
              style={{ width: "24px", height: "24px" }}
              className="svg-force-size  desc--svg-adjustment"
            >
              {" "}
              <SVGCircleRed />{" "}
            </div>
          );
          
        } else {
          return (
            <div
              style={{ width: "24px", height: "24px" }}
              className="svg-force-size  desc--svg-adjustment"
            >
              {" "}
              <SVGCircleYellow />{" "}
            </div>
          );

        }
      }
    } else {
      return (
        <div
          style={{ width: "38px" }}
          className=" position-relative d-flex justify-content-center flex-column gap-2 "
        >
          <div>
            <ProgressBarTemplateGroup
              completed={
                (data.personalSales.actualAmount * 100) /
                data.personalSales.totalAmount
              }
              incompleted={
                data.personalSales.actualAmount >=
                data.personalSales.totalAmount ? false : true
              }
              gap={data.personalSales.status}
              status={data.personalSales.status}
              level={level}
            />
          </div>
          <div className="d-flex gap-1">
            {addProgressBar(
              data.incorporatedSales.actualActives,
              data.incorporatedSales.totalNeed,
              level,
              data.incorporatedSales.status
            )}
          </div>
          <div>
            <ProgressBarTemplateGroup
              completed={
                (data.groupSales.actualAmount * 100) /
                data.groupSales.totalAmount
              }
              incompleted={
                data.groupSales.actualAmount >= data.groupSales.totalAmount ? false : true
              }
              gap={data.groupSales.status}
              status={data.groupSales.status}
              level={level}
            />
          </div>
          {
            checkReduceRequirement(data.consultantStatus?.reducedRequirements ) &&
            <>
              <div style={{width: "12px", height: "12px", right: "-6px", border: "1px white solid", bottom: "-6px"}} className=" rounded-circle bg-white position-absolute svg-force-size " >
                <div style={{top: "-8px", height: "12px"}} className=" position-relative" >
                  <Purple/>

                </div>
              </div>
            </>
          }
        </div>
      );
    }
  };

  const sortColors = (color) => {
    if (color === "completed") {
      return { color: "#16BE7D" };
    }
    if (color === "warning") {
      return { color: "#DCB632" };
    }
    if (color === "risk") {
      return { color: "#F06E69" };
    }
    if (color === "normal" || color === "gap") {
      return { color: "#DDDDDD" };
    }
  };

  const onClickPersonalGroup = (root) => {
    dispatch(changePersonalGroupName({name: user?.profileResume?.name , clientId: root}))
    history(`/dashboard/descendants/personal-group/${root}`);
  };

  function changeModalInfo(salesModal){
    dispatch(defineDescendantModalUserInfo({
        id: user.clientId,
        name: user.profileResume.name,
        firstName: user.profileResume.firstName,
        lastName: user.profileResume.lastName,
        level: user.profileResume.level,
        gender: user.profileResume.gender,
        picture: user.profileResume.image,
        roleDescription: user.profileResume.roleDescription,
        uplineName: user.profileResume.uplineName,
        uplineId: user.profileResume.uplineId,
        socialNetwork: user.profileResume.socialNetwork,
        currencyCode: user.performance.currencyCode,
        personalSalesActual: user.performance.personalSales.actualAmount,
        personalSalesTotal: user.performance.personalSales.totalAmount,
        personalSalesStatus: user.performance.personalSales.status,
        incorporatedSalesActual: user.performance.incorporatedSales.actualActives,
        incorporatedSalesTotal: user.performance.incorporatedSales.totalNeed,
        incorporatedSalesStatus: user.performance.incorporatedSales.status,
        groupSalesActual: user.performance.groupSales.actualAmount,
        groupSalesTotal: user.performance.groupSales.totalAmount,
        groupSalesStatus: user.performance.groupSales.status,
        triperiodicSalesActual: user.performance.triperiodicSales.actualAmount,
        triperiodicSalesTotal: user.performance.triperiodicSales.totalAmount,
        triperiodicSalesStatus: user.performance.triperiodicSales.status,
        triperiodicSalesPeriods: user.performance.triperiodicSales.periods,
        billedAmount: user.balanceSummary.billed.amount,
        billedStatus: user.balanceSummary.billed.status,
        balanceAmount: user.balanceSummary.balance.amount,
        balanceStatus: user.balanceSummary.balance.status,
        toInvoiceAmount: user.balanceSummary.orderStatusAmounts.find(element => element.state === "toInvoice").amount,
        toInvoiceStatus: user.balanceSummary.orderStatusAmounts.find(element => element.state === "toInvoice").status,
        notSentAmount: user.balanceSummary.orderStatusAmounts.find(element => element.state === "notSent").amount,
        notSentStatus: user.balanceSummary.orderStatusAmounts.find(element => element.state === "notSent").status
    }))
    dispatch(defineModalUserSalesTab(salesModal))
  };

  return (
    <div className=" position-relative desc--grid-collapse w-100 ">
      {
        index == end ?
        <>
          <DescendantLine gen={gen} index={index} end={true} desc={user?.hasDescendants} />
        </>
        :
        <>
          <DescendantLine gen={gen} index={index} end={false} desc={user?.hasDescendants} />
        </>
      }
      <> 
      <div className={` position-relative desc--grid-tree gap-4 w-100 ${toggle ? " mt-4 " : " my-4 "}  `} style={!toggle ? {height: "42px"}:{height: "auto"}}>
        {
          user?.hasDescendants ?
          <div  onClick={user?.hasDescendants && onCollapse} className=" pointer d-flex align-items-center gap-2">
            {
                user?.hasDescendants ?
                <>
                    {toggle ? (
                    <div onClick={onCollapse} style={{width: "16px", paddingBottom: "5px", paddingLeft: "2px",  transform: "rotate(90deg)"}} className="  svg-force-size pointer">
                        <SVGFilledRightChev />
                    </div>
                    ) : (
                    <div onClick={onCollapse} style={{width: "16px"}} className=" svg-force-size pointer">
                        <SVGFilledRightChev />
                    </div>
                    )}
                </>
                :
                <div className=" invisible">
                        <SVGFilledDownChev />
                </div>
            }
          {
            user?.isPersonalIncorporated ?
            <div
            style={{
              
              height: "42px",
              width: "42px",
              border: "solid 1.5px #456ECE",
              left: "-2px"
            }}
            className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
          >
            <ImageCircleHandler name={user?.profileResume?.name} width={"36px"} height={"36px"} url={user?.profileResume?.image} firstName={user?.profileResume?.firstName} lastName={user?.profileResume?.lastName} top={2} />
          </div>
            :
            <div
            style={{
              height: "40px",
              width: "40px",
              border: "solid 0px #456ECE",
              left: "-2px"
            }}
            className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
          >
            <ImageCircleHandler name={user?.profileResume?.name} width={"36px"} height={"36px"} url={user?.profileResume?.image} firstName={user?.profileResume?.firstName} lastName={user?.profileResume?.lastName} top={2} />
          </div>
          }
          <div style={{left: "-4px"}} className=" position-relative blackColor d-flex flex-column">
            <p className=" s14 museo-sans-700" >{user?.profileResume?.name}</p>
            <div className="s12 d-flex grayColor gap-1 ">
              <p className="text-uppercase " >{gen}° <FormattedMessage id="gen" /></p>
              <p>|</p>
              <p>
              <ReformatLevelByGender gender={user?.profileResume?.gender} level={user?.profileResume?.level} roleDescriptionDefault={user?.profileResume?.roleDescription} />
              </p>
            </div>
          </div>
          </div> 
          :
          <div   className="  d-flex align-items-center gap-2">
            {
                user?.hasDescendants ?
                <>
                    {toggle ? (
                    <div onClick={onCollapse} className="pointer">
                        <SVGFilledDownChev />
                    </div>
                    ) : (
                    <div onClick={onCollapse} className="pointer">
                        <SVGFilledRightChev />
                    </div>
                    )}
                </>
                :
                <div className=" invisible">
                        <SVGFilledDownChev />
                </div>
            }
          {
            user?.isPersonalIncorporated ?
            <div
            style={{
              height: "42px",
              width: "42px",
              border: "solid 1.5px #456ECE",
              left: "-2px"
            }}
            className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
          >
            <ImageCircleHandler name={user?.profileResume?.name} width={"36px"} height={"36px"} url={user?.profileResume?.image} firstName={user?.profileResume?.firstName} lastName={user?.profileResume?.lastName} top={2} />
          </div>
            :
            <div
            style={{
              height: "40px",
              width: "40px",
              border: "solid 0px #456ECE",
              left: "-2px"
            }}
            className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
          >
            <ImageCircleHandler name={user?.profileResume?.name} width={"36px"} height={"36px"} url={user?.profileResume?.image} firstName={user?.profileResume?.firstName} lastName={user?.profileResume?.lastName} top={2} />
          </div>
          }
          <div className=" blackColor d-flex flex-column">
          <p className=" s14 museo-sans-700" >{user?.profileResume?.name}</p>
            <div className="s12 d-flex grayColor gap-1 ">
              <p className="text-uppercase " >{gen}° <FormattedMessage id="gen" /></p>
              <p>|</p>
              <p>
              <ReformatLevelByGender gender={user?.profileResume?.gender} level={user?.profileResume?.level} roleDescriptionDefault={user?.profileResume?.roleDescription} />
                </p>
            </div>
          </div>
          </div> 
        }
        <div   onClick={() => onClickPersonalGroup(user?.clientId)}
          className=" pointer museo-sans-700 s14 align-items-center d-flex skyColor justify-content-center">
            <FormattedMessage id="show" />
        </div>
        <div  data-bs-toggle="modal" data-bs-target="#descendantConsultantInfo" onClick={() => changeModalInfo(false)} className=" pointer d-flex justify-content-end align-items-center gap-2">
          <p style={sortColors(user?.performance?.groupSales?.status)} className=" s14 dm-sans-medium ">
            {formatAmount(user?.performance?.groupSales?.actualAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
          </p>
          <SVGRightChev />
        </div>
        <div data-bs-toggle="modal" data-bs-target="#descendantConsultantInfo" onClick={() => changeModalInfo(true)} className=" pointer d-flex justify-content-center align-items-center gap-2">
          {user &&
            returnQualificationStatus(
              user.isPersonalIncorporated,
              user.profileResume?.level,
              user.performance
            )}
          <SVGRightChev />
        </div>
      {toggle && (
      <div    className=" w-100 desc---grid-overwhole " >
        <div >

          <DescendantsSublist gen={gen+1} id={user.clientId} level={user?.profileResume?.level} />
        </div>
      </div>
      )}
      </div>
    </>
    </div>
  );
}