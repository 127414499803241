const personalDataStateKeys = ["firstName", "lastName", "birthday"];

const addressStateKeys = [
  "canton",
  "city",
  "commune",
  "department",
  "district",
  "externalNumber",
  "internalNumber",
  "locality",
  "municipality",
  "neighborhood",
  "postalCode",
  "province",
  "reference",
  "region",
  "signs",
  "state",
  "street",
  "township",
];

export const defaultState = {
  personalDataStateKeys,
  addressStateKeys,
  comments: "",
};

export function bodyAdapter(data, country) {
  const dataSplitedState = () => {
    let dataSplited = data?.birthday?.split("-");

    if (dataSplited) {
      return dataSplited[0] == undefined ||
        dataSplited[0] == "undefined" ||
        dataSplited[1] == ""
        ? undefined
        : data.birthday;
    }

    return undefined;
  };

  const countryStateType = () => {
    if (country == "cl") {
      return "CASA";
    }

    return null;
  };

  const countryStateArea = () => {
    if (country == "ar") {
      return data.cellArea;
    }

    return null;
  };

  const valuesReturnEmpty = (value) => {
    return value || "";
  };

  const valuesReturnNull = (value) => {
    return value || null;
  };

  const valuesReturnAlt = (value) => {
    // Change value.value to value ( Found unexpected error in the code)
    return value || value?.value;
  };

  const body = {
    firstName: data.firstName,
    lastName: data.lastName,
    birthday: dataSplitedState(),
    contactOriginType: "LEGACY",
    phoneNumbers:
      !data.cellNumber && !data.otherNumber
        ? []
        : data.cellNumber && !data.otherNumber
        ? [
            {
              type: countryStateType(),
              phoneOrderId: 1,
              areaCode: countryStateArea(),
              number: valuesReturnEmpty(data.cellNumber),
            },
          ]
        : [
            {
              type: countryStateType(),
              phoneOrderId: 1,
              areaCode: countryStateArea(),
              number: valuesReturnEmpty(data.cellNumber),
            },
            {
              type: countryStateType(),
              phoneOrderId: 2,
              areaCode: country == "ar" ? data.areaOtherNumber : null,
              number: valuesReturnEmpty(data.otherNumber),
            },
          ],
    socialNetworks: [
      {
        id: "email",
        value: data.email,
      },
    ],
    addressInfo: [
      {
        street: valuesReturnNull(data.street),
        externalNumber: valuesReturnNull(data.externalNumber),
        internalNumber: valuesReturnNull(data.internalNumber),
        municipality: valuesReturnAlt(data.municipality),
        district: valuesReturnAlt(data.district),
        department: valuesReturnAlt(data.department),
        region: valuesReturnAlt(data.region),
        locality: valuesReturnAlt(data.locality),
        neighborhood: valuesReturnAlt(data.neighborhood),
        city: valuesReturnAlt(data.city),
        commune: valuesReturnAlt(data.commune),
        state: valuesReturnAlt(data.state),
        postalCode: valuesReturnAlt(data.postalCode),
        province: valuesReturnAlt(data.province),
        canton: valuesReturnAlt(data.canton),
        township: valuesReturnAlt(data.township),
        signs: valuesReturnNull(data.signs),
        reference: valuesReturnNull(data.reference),
      },
    ],
    comments: [
      {
        type: "standard",
        value: data.comments,
      },
    ],
  };

  if (country === "ar" && data.identification) {
    body.identification = [
      {
        type: "DNI",
        value: data.identification,
      },
    ];
  }

  return body;
}

export function getBase64(text) {
  // Codifico el texto en UTF-8
  let textoCodificado = encodeURIComponent(text);
  // Convierto los caracteres codificados en una cadena de caracteres que representen su representación UTF-8
  let textoUTF8 = textoCodificado.replace(
    /%([0-9A-F]{2})/g,
    function (match, p1) {
      return String.fromCharCode("0x" + p1);
    }
  );
  // retorno la conversion en su base 64 y quito =
  return btoa(textoUTF8).replace(/=/g, "");
}
