import ProgressBarTemplateGroup from "./ProgressBarTemplateGroup";

import { ReactComponent as SVGCircleYellow } from "../../common/svgs/ic-alert-circle-sm.svg";
import { ReactComponent as SVGCircleRed } from "../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as SVGCircleGreen } from "../../common/svgs/ic-check-circle-sm.svg";
import { ReactComponent as Purple } from "../../common/svgs/ic-req-red.svg";
import checkReduceRequirement from "./checkReduceRequirement";

export function ShareAddProgressBar(actual, total, level, status) {
    let aux = [];
    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);
    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarTemplateGroup
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarTemplateGroup
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else {
        aux.push(
          <ProgressBarTemplateGroup
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      }
    }
    return aux;
}

export function ShareQualificationStatusDesc (boolean, level, data) {
    if (level < 2) {
      if (
        data.triperiodicSales.status == "completed"
      ) {
        return (
          <div
            style={{ width: "24px", height: "24px" }}
            className="svg-force-size  desc--svg-adjustment"
          >
            {" "}
            <SVGCircleGreen />{" "}
          </div>
        );
      } else {
        if (data.triperiodicSales?.periods[0]?.state == "actual" && data.triperiodicSales.status == "risk" ) {

          return (
            <div
              style={{ width: "24px", height: "24px" }}
              className="svg-force-size  desc--svg-adjustment"
            >
              {" "}
              <SVGCircleRed />{" "}
            </div>
          );
          
        } else {
          return (
            <div
              style={{ width: "24px", height: "24px" }}
              className="svg-force-size  desc--svg-adjustment"
            >
              {" "}
              <SVGCircleYellow />{" "}
            </div>
          );

        }
      }
    } else {
      return (
        <div
          style={{ width: "38px" }}
          className=" position-relative d-flex justify-content-center flex-column gap-2 "
        >
          <div>
            <ProgressBarTemplateGroup
              completed={
                (data.personalSales.actualAmount * 100) /
                data.personalSales.totalAmount
              }
              incompleted={
                data.personalSales.actualAmount >=
                data.personalSales.totalAmount ? false : true
              }
              gap={data.personalSales.status}
              status={data.personalSales.status}
              level={level}
            />
          </div>
          <div className="d-flex gap-1">
            {ShareAddProgressBar(
              data.incorporatedSales.actualActives,
              data.incorporatedSales.totalNeed,
              level,
              data.incorporatedSales.status
            )}
          </div>
          <div>
            <ProgressBarTemplateGroup
              completed={
                (data.groupSales.actualAmount * 100) /
                data.groupSales.totalAmount
              }
              incompleted={
                data.groupSales.actualAmount >= data.groupSales.totalAmount ? false : true
              }
              gap={data.groupSales.status}
              status={data.groupSales.status}
              level={level}
            />
          </div>
          {
            checkReduceRequirement(data.consultantStatus?.reducedRequirements ) &&
            <>
              <div style={{width: "12px", height: "12px", right: "-6px", border: "1px white solid", bottom: "-6px"}} className=" rounded-circle bg-white position-absolute svg-force-size " >
                <div style={{top: "-8px", height: "12px"}} className=" position-relative" >
                  <Purple/>

                </div>
              </div>
            </>
          }
        </div>
      );
    }
  };