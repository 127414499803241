import React from "react"

import ClientListFormOneColumnInput from "./elements/ClientListFormOneColumnInput"
import InputPhoneNumber from "./elements/ClientListInputPhoneNumber"
import { useFormContext } from "react-hook-form"

export default function ContactData({isValidateError,onChangeValidate}) {
  const { register, watch } = useFormContext()
  const country = localStorage.getItem("country");
  return(
    <div  style={{ gap: "24px" }} className="d-flex flex-wrap my-4 pb-3">
      <InputPhoneNumber
        placeholderOne={"Ej.: 011"}
        placeholderTwo={"Ej.: 1234-5678"}
        label={"Celular"}
        name1="cellArea"
        name2="cellNumber"
        register={register}
        onChangeValidate={onChangeValidate}
        isValidateError={isValidateError}
      />
      {(country !== "uy" || country !== "pa") ?
      <>
      <div className=" px-3 pe-0">
        <InputPhoneNumber
          placeholderOne="Ej.: 011"
          placeholderTwo={"Ej.: 4234-5678"}
          label={"Telefono alternativo (opcional)"}
          name1="areaOtherNumber"
          name2="otherNumber"
          register={register}
        />

      </div>
        <ClientListFormOneColumnInput
          placeholder={"Ej.: maria@gmail.com"}
          label={"Email (opcional)"}
          type="email"
          name="email"
          maxLength={60}
          register={register}
          watch={watch}
        />
        
      </>
      :
      <div className=" px-3 pe-0">
        <ClientListFormOneColumnInput
          placeholder={"Ej.: maria@gmail.com"}
          label={"Email (opcional)"}
          type="email"
          name="email"
          maxLength={60}
          register={register}
          watch={watch}
        />

      </div>
      }
    </div>
  )
}