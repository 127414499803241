import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";

import { getDataFirstGeneration } from "../../Framework/redux/FirstStepsAllSideEffect";
import { cleanFirstGenerationData, isExtraFetchGenerationFalse, isExtraFetchGenerationTrue } from "../../Framework/redux/FirstStepsAllActions";
import FirstStepsFirstGenerationInfiniteList from "./FirstStepsFirstGenerationInfiniteList";

const DashboardFirstStepsFirstGeneration = () => {
  const dispatch = useDispatch();

  const [returnedEmpty, setReturnEmpty] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");
  const firstGenerationInfo = useSelector(
    (state) => state?.firstStepsAllReducer?.firstGenerationData
  );
  const filtering = useSelector((state) => state?.firstStepsAllReducer?.filter);

  const extraRef = useRef(null);

  const onStartUp = () => {
    callService();
    dispatch(isExtraFetchGenerationTrue())
  };

  const callService = async () => {
    let ordernarPor = "stepsReached";
    let orden = "desc";
    let auxOffSet = 0;
    dispatch(cleanFirstGenerationData());
    await dispatch(getDataFirstGeneration({ ordernarPor, orden, auxOffSet }));
  };

  const callbackExtras = async () => {
    if (returnedEmpty) {
      return ""
    };
    let ordernarPor = "stepsReached";
    let orden = "desc";
    setLoader(true);
    let result = null;
    let auxOffSet = offSet + 10;

    result = await dispatch(
      getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
    );
    if (!result?.payload?.firstGeneration) {
     dispatch(isExtraFetchGenerationFalse())
     setReturnEmpty(true);
    }
    setIsVisible(false);
    setOffSet(auxOffSet);
    setLoader(false);
    return result;
  };

  const activateVisibleComponente = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    onStartUp();
  }, []);

  useEffect(() => {
    if (isVisible) {
      callbackExtras();
    }
  }, [isVisible, extraRef.current]);

  return (
    <div className="">
      <div
        className=" bg-white w-100 position-relative h-100"
        style={{ zIndex: 1 }}
      >
        <div className="  table--header--1eraGen pt-4 px-0 museo-sans-500 s12 grayColor">
          <p className=" text-uppercase">
            <FormattedMessage id="edb.details.list.name" />
          </p>
          <p className=" text-uppercase">
            {" "}
            <FormattedMessage id="first.steps.page.table.header.advance" />{" "}
          </p>
          <p className=" text-uppercase">
            {" "}
            <FormattedMessage id="first.steps.page.table.header.progress" />{" "}
          </p>
          <p></p>
        </div>
        {firstGenerationInfo?.firstGeneration?.length > 0 ? (
          <FirstStepsFirstGenerationInfiniteList
            callbackExtras={activateVisibleComponente}
            returnedEmpty={returnedEmpty}
            isVisible={isVisible}
            list={firstGenerationInfo?.firstGeneration}
            biggerThan1000={biggerThan1000}
            extraRef={extraRef}
            loader={loader}
          />
        ) : (
          <>
            {filtering == true ? (
              <div
                style={{
                  paddingBottom: "73px",
                  maxHeight: "827px",
                  minHeight: biggerThan1000
                    ? "250px"
                    : !windowWidth == 1280
                    ? "350px"
                    : "390px",
                }}
                className="hidden-y-scroll d-flex flex-column w-100 justify-content-center align-items-center"
              >
                <p className="museo-sans-500 s24">
                  <FormattedMessage id="ffss.1ra.no.info" />
                </p>
                <div style={{ marginLeft: "90px", marginRight: "90px" }}>
                  <p className="museo-sans-500 s14 grayColor text-center">
                    <FormattedMessage id="ffss.1ra.no.info.desc" />
                  </p>
                </div>
              </div>
            ) : firstGenerationInfo &&
              firstGenerationInfo?.firstGeneration?.length > 0 ? (
              <></>
            ) : (
              <>
                {firstGenerationInfo?.firstGeneration == undefined ? (
                  <div
                    style={{
                      paddingBottom: "73px",
                      maxHeight: "827px",
                      minHeight: biggerThan1000
                        ? "250px"
                        : !windowWidth == 1280
                        ? "350px"
                        : "390px",
                    }}
                    className="hidden-y-scroll d-flex flex-column w-100 justify-content-center align-items-center"
                  >
                    <p className="museo-sans-500 s24">
                      <FormattedMessage id="ffss.1ra.no.info" />
                    </p>
                    <div style={{ marginLeft: "90px", marginRight: "90px" }}>
                      <p className="museo-sans-500 s14 grayColor text-center">
                        <FormattedMessage id="ffss.1ra.no.info.desc" />
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingBottom: "73px",
                      maxHeight: "827px",
                      minHeight: biggerThan1000
                        ? "250px"
                        : !windowWidth == 1280
                        ? "350px"
                        : "390px",
                    }}
                    className="hidden-y-scroll d-flex flex-column w-100 justify-content-center align-items-center"
                  >
                    <div
                      style={{ top: "-30px" }}
                      className=" position-relative d-flex flex-column justify-content-center align-items-center"
                    >
                      <div className="spinner-border blueColor" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardFirstStepsFirstGeneration;
