import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ModalClipboard from "../../../common/Share/ModalClipboard";

import { ReactComponent as NoOrdersIcon } from '../../../common/svgs/Empty-Pedidos-Ordenes.svg'
import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";
import { newInvitationClose } from "../../Framework/redux/InvitationsAction";
import { fetchInvitationTable } from "../../Framework/redux/InvitationsSideEffect";
import InvitationsDashboardShowCaseRow from "../components/InvitationsDashboardShowCaseRow";
import InvitationsDashboardShowCaseRowAll from "../components/InvitationsDashboardShowCaseRowAll";
import InvitationsDashboardModalInfo from "../components/modals/InvitationsDashboardModalInfo";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";

export default function InvitationsDashboardShowcase(params) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const invitationData = useSelector(
    (state) => state.invitationData.invitationTable
  );
  const showModalCopyInvitation = useSelector((state) => state.invitationData.invitationCopy);

  const biggerThan1025 = useMediaPredicate("(min-width: 1025px)");
  const biggerThan1290 = useMediaPredicate("(min-width: 1290px)");

  const onShowAll = () => {
    history("invitations/list")
  };

  const onClickNewInvitation = () => {
    history("invitations/add")
  };

  const onStartUp = async () => {
    await dispatch(fetchInvitationTable());
  };

  const onCloseModal = () => {
    dispatch(newInvitationClose());
  };

  useEffect(() => {
    onStartUp();
  }, []);

  return (
    <>
      <InvitationsDashboardModalInfo />
      <ModalClipboard
            bottom={0}
            show={showModalCopyInvitation}
            close={onCloseModal}
            title={multiLangJSONParse()["buttons.copied"] || "Copiado"}
            text={"Dirección de la invitación copiada con éxito"}
          />
      <div className=" w-100">
        <div className=" mb-4 d-flex align-items-center gap-4 ">
          <div style={{ gap: "10px" }} className=" d-flex align-items-center">
            <p className=" s20  museo-sans-700 ">
              <FormattedMessage id="invitation.navbar.your.invitation" />
            </p>
            <div style={{ top: -2 }} className=" position-relative">
              <div
                data-bs-toggle="modal"
                data-bs-target="#modal-invitations-info"
                className=" position-relative pointer"
              >
                <HelpIconNoTarget />
              </div>
            </div>
          </div>
          {
            invitationData?.data?.invitations?.length > 0 &&
            <p onClick={onShowAll} className=" pointer museo-sans-700 skyColor s14">
              <FormattedMessage id="view.more.female" />
            </p>

          }
        </div>
        <div className="p-4 cards-shadow bg-white rounded-16 overflow-hidden">
          {invitationData?.error ? (
            <>
              <div className=" w-100">
                <ShareErrorHandlerServices service={onStartUp} />
              </div>
            </>
          ) : (
            <>
              <div style={{ gap: "12px" }} className="  d-flex w-100 h-100">
                {
                    invitationData?.loader ?
                    <>
                        <SkeletonInvitationRow />
                        <SkeletonInvitationRow />
                        {
                            biggerThan1290 &&
                            <SkeletonInvitationRow />

                        }
                        {
                            biggerThan1025 &&
                            <>

                            <SkeletonInvitationRow />
                            </>

                        }
                        <SkeletonInvitationRowSeeAll />
                    </>
                    :
                    <>
                    {
                      invitationData?.data?.invitations?.length > 0 ? 
                        <>
                            <InvitationsDashboardShowCaseRow
                            data={invitationData?.data?.invitations[0]}
                            />
                            <InvitationsDashboardShowCaseRow
                            data={invitationData?.data?.invitations[1]}
                            />
                            {
                                biggerThan1025 &&
                                <InvitationsDashboardShowCaseRow
                                data={invitationData?.data?.invitations[2]}
                                />

                            }
                            {
                                biggerThan1290 &&
                                <InvitationsDashboardShowCaseRow
                                data={invitationData?.data?.invitations[3]}
                                />

                            }
                            
                            <InvitationsDashboardShowCaseRowAll params={invitationData?.data} onShowAll={onShowAll} />
                            
                        
                        </>
                        :
                        <div className="d-flex justify-content-between align-items-center w-100 h-100" >
                          <div className=" d-flex flex-column gap-2 w-100 h-100 justify-content-center museo-sans-500 " >
                                <p className=" museo-sans-700 s16 " >
                                <FormattedMessage id="invitation.message.empty.state.title" />
                                </p>
                                <p className="  s14 grayColor " >
                                  <FormattedMessage id="invitation.message.empty.state" />
                                </p>
                          </div>
                          <div onClick={onClickNewInvitation} style={{width: "288px", height: "40px" , border: "1px solid #5AAFF1 " }} className=" d-flex justify-content-center align-items-center rounded-8 skyColor pointer " >
                              <p className=" museo-sans-700" >
                                <FormattedMessage id="invitation.add.modal.title" />
                              </p>
                          </div>
                        </div>
                    }
                    </>
                }
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export function SkeletonInvitationRow() {
  return (
    <div className="d-flex">
      <div style={{width: "200px"}} >
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div
              className=" s16 museo-sans-700 brightGrayBg rounded-3"
              style={{ width: "134px", height: "16px", marginBottom: "4px" }}
            ></div>
          </div>
        </div>
        <div style={{ gap: "6px" }} className=" d-flex pt-2 mt-1 flex-column">
          <div className="d-flex flex-column" >
                <div className="d-flex gap-4 w-100 justify-content-between position-relative" >
                    <div style={{height: "4px", top: 6}} className="brightGrayBg w-100 position-absolute" >

                    </div>
                <div
            style={{ height: "16px", width: "16px" }}
            className=" rounded-circle brightGrayBg"
          ></div>
          <div
            style={{ height: "16px", width: "16px" }}
            className=" rounded-circle brightGrayBg"
          ></div>
          <div
            style={{ height: "16px", width: "16px" }}
            className=" rounded-circle brightGrayBg"
          ></div>
          <div
            style={{ height: "16px", width: "16px" }}
            className=" rounded-circle brightGrayBg"
          ></div>
                </div>
                <div className=" my-2" >

                </div>
                <div
              className=" s16 museo-sans-700 brightGrayBg rounded-3"
              style={{ width: "134px", height: "16px", marginBottom: "4px" }}
            ></div>
            <div
              className=" s16 museo-sans-700 brightGrayBg rounded-3"
              style={{ width: "134px", height: "16px", marginBottom: "4px" }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="h-100 brightGrayBg position-relative "
        style={{ width: "1px", marginLeft: "16px", marginRight: "16px" }}
      >
        <p className="invisible">.</p>
      </div>
    </div>
  );
}
export function SkeletonInvitationRowSeeAll() {
  return (
    <div className="d-flex align-items-center">
      <div
        style={{ width: "167px", height: "48px" }}
        className="d-flex flex-row"
      >
        <div
          style={{
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            gap: "5px",
          }}
          className="d-flex flex-row align-items-center justify-content-center brightGrayBg"
        ></div>
        <div style={{ paddingLeft: "8px" }}>
          <div
            className="museo-sans-700 s16 brightGrayBg rounded-3 mt-1"
            style={{ width: "104px", height: "12px" }}
          ></div>
          <div
            className="museo-sans-700 s14 p-0 mt-1 border-0 rounded-3 brightGrayBg"
            style={{ width: "84px", height: "12px" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
