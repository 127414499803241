import "./navbar.css";
import { useLocation,useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  faRightFromBracket,
  faGear,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as LogoNav } from "../../../svgs/Logo-Just-Sidebar.svg";

import { ReactComponent as Red } from "../../../svgs/ic-alert-circle-red.svg";

import { ReactComponent as Yellow } from "../../../svgs/ic-alert-circle-yellow.svg";

import { ReactComponent as Green } from "../../../svgs/ic-check-circle.svg";

import { ReactComponent as Purple } from "../../../svgs/ic-req-red.svg";

import { ReactComponent as Gray } from "../../../svgs/ic-alert-circle-grey.svg";

import { getScore } from "../../../../Dashboard/Framework/redux/dashboardSideEffect";
import ImageCircleHandler from "../../../Share/ImageCircleHandler";
import NavBarSecondRow from "../components/NavBarSecondRow";
import NavBarFirstRow from "../components/NavBarFirstRow";
import ReformatLevelByGender from "../../../Share/ReformatLevelByGender";
import ShareErrorHandlerServices from "../../../Share/ShareErrorHandlerServices";
import { fetchUserProfile } from "../../../../Login/Framework/redux/loginSideEffects";

export default function NavBar({ blur, pushToTop, toggleBlurred }) {
  const [dropdownAccount, setDropdownAccount] = useState(false);
  const [dropdownOne, setDropdownOne] = useState(false);
  const [dropdownTwo, setDropdownTwo] = useState(false);
  const [dropdownThree, setDropDownThree] = useState(false)

  const dispatch = useDispatch();
  const history = useNavigate();

  const profileData = useSelector((state) => state.userInfo.userInfo);
  const status = useSelector((state) => state.scoreData.consultantStatus);
  const profileError = useSelector((state) => state.userInfo.profileError);

  const location = useLocation();

  const activeBackground = "#ffffff34";

  const activeBackgroundOnDashboardIf = () => {
    if (location?.pathname?.indexOf("/dashboard/performance") != -1) {
      return true;
    }
    if (location?.pathname?.indexOf("/dashboard/earning/period") != -1) {
      return true;
    }
    if (
      location?.pathname?.indexOf("/dashboard/performance/personalSales") != -1
    ) {
      return true;
    }
    if (
      location?.pathname?.indexOf("/dashboard/performance/personalSales") != -1
    ) {
      return true;
    }
    if (
      location?.pathname?.indexOf("/dashboard/performance/personalSales") != -1
    ) {
      return true;
    }
    if (
      location?.pathname?.indexOf("/dashboard/performance/personalSales") != -1
    ) {
      return true;
    }
    if (location?.pathname?.indexOf("/dashboard/") != -1) {
      return false;
    } else {
      return true;
    }
  };

  const activeBackgroundOnEDBIf = () => {
    if (location?.pathname?.indexOf("dashboard/edbs") != -1) {
      return true;
    }
    if (location?.pathname?.indexOf("/dashboard/edbs/list/open") != -1) {
      return true;
    }
    if (location?.pathname?.indexOf("/dashboard/edbs/list/dated") != -1) {
      return true;
    }
    if (location?.pathname?.indexOf("/dashboard/edbs/list/listed") != -1) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    onStartUp();
    const navStatus = document.getElementById("nav-toggler");

    navStatus.addEventListener("mouseleave", function closeSubmenues(event) {
      setDropdownOne(false);
      setDropdownTwo(false);
      setDropDownThree(false);
      setDropdownAccount(false);
    });
  }, []);

  const onHistory = (location) => {
    const formBody = localStorage.getItem("formBody");
    if (formBody) {
      const clickMe = document.getElementById("warning-step-back");
      localStorage.setItem("path", location);
      clickMe.click();
    } else {
      history(location);
    }
  };

  const onStartUp = async () => {
    await dispatch(getScore());
  };

  const decenderPersonalProfile = () => {
    let clientId = localStorage.getItem("clientId");
    history(`descendants/personal-group/${clientId}`);
  };

  const showDropdownOne = () => {
    setDropdownOne(!dropdownOne);
    setDropdownTwo(false);
    setDropDownThree(false);
    setDropdownAccount(false);
  };

  const showDropdownTwo = () => {
    setDropdownTwo(!dropdownTwo);
    setDropdownOne(false);
    setDropDownThree(false);
    setDropdownAccount(false);
  };

  const showDropdownThree = () => {
    setDropdownOne(false);
    setDropdownTwo(false);
    setDropDownThree(!dropdownThree)
    setDropdownAccount(false);
  };

  const showDropdownAccount = () => {
    setDropdownTwo(false);
    setDropdownOne(false);
    setDropDownThree(false);
    setDropdownAccount(!dropdownAccount);
  };

  const onBoarding = () => {
    const activateModalButton = document.getElementById("activateOnBoarding");
    activateModalButton.click();
  };

  const startOnBoard = async () => {
    await onHistory("/dashboard");
    pushToTop();
    setTimeout(() => {
      toggleBlurred();
      onBoarding();
    }, 1000);

    setTimeout(() => {}, 500);
  };

  const fetchProfile = async () => {
    await dispatch(fetchUserProfile());
  };

  const onNewConsultant = () => {
    onHistory("/directora/logging");
  };

  const directorId = localStorage.getItem("directorId");
  const invitationToggler = localStorage.getItem("invitationToggle");

  return (
    <nav
    style={{zIndex: 5}}
      id="nav-toggler"
      className={`museo-sans-500 navbarBg text-white menu-shadow p-0 m-0 ${
        blur && "blurred"
      } `}
    >
      {profileError ? (
        <div className="m-2 d-flex justify-content-center align-items-center">
            <ShareErrorHandlerServices
              service={fetchProfile}
              navbar={true}
              small={true}
            />
            
        </div>
      ) : (
          <div
            onClick={() => onHistory("/dashboard/account")}
            id="profile-holder"
            className=" pointer position-relative mt-1"
          >
            {status.checkMarkStatus === "normal" ||
            status.checkMarkStatus === "warning" ||
            !status.checkMarkStatus ? (
              <></>
            ) : (
              <div
                id="profile--status"
                style={{ borderRadius: "50%", width: "18px", height: "18px" }}
                className=" bg-white position-absolute svg-force-size "
              >
                {status.checkMarkStatus === "reduced" && <Purple />}
                {status.checkMarkStatus === "gap" && <Gray />}
                {status.checkMarkStatus === "risk" && <Red />}
                {status.checkMarkStatus === "completed" && <Green />}
                {status.checkMarkStatus === "warning" && <Yellow />}
              </div>
            )}
            <div className=" w-100 d-flex text-white justify-content-center align-items-center">
              <ImageCircleHandler
                width={"45px"}
                height={"45px"}
                url={profileData.image}
                firstName={profileData?.firstName}
                lastName={profileData?.lastName}
                top={0}
              />
            </div>
            <div id="profile-info" className="p-2 pt-3">
              <h5 className="s16">
                {profileData.firstName == ""
                  ? profileData?.name
                    ? profileData?.name
                    : "Name"
                  : `${profileData.firstName} ${profileData.lastName}`}
              </h5>
              <p className="s12">
                <ReformatLevelByGender
                  level={profileData.level}
                  gender={profileData.gender}
                  roleDescriptionDefault={profileData?.roleDescription}
                />
              </p>
            </div>
          </div>
      )}
      <div id="nav-list" className="s12 hidden-y-scroll " >
        <span className="nav-spacer my-2 mx-3" />
        <NavBarFirstRow
          activeBackground={activeBackground}
          onHistory={onHistory}
          location={location}
          showDropdownAccount={showDropdownAccount}
          dropdownAccount={dropdownAccount}
          showDropdownTwo={showDropdownTwo}
          showDropdownThree={showDropdownThree}
          activeBackgroundOnDashboardIf={activeBackgroundOnDashboardIf}
          showDropdownOne={showDropdownOne}
          dropdownOne={dropdownOne}
          decenderPersonalProfile={decenderPersonalProfile}
          profileData={profileData}
          activeBackgroundOnEDBIf={activeBackgroundOnEDBIf}
          dropdownTwo={dropdownTwo}
          dropdownThree={dropdownThree}
          invitationToggler={invitationToggler}
        />
        
        <span className="nav-spacer my-3 mx-3" />
        <NavBarSecondRow
          activeBackground={activeBackground}
          onHistory={onHistory}
          location={location}
          showDropdownAccount={showDropdownAccount}
          dropdownAccount={dropdownAccount}
          showDropdownTwo={showDropdownTwo}
          activeBackgroundOnDashboardIf={activeBackgroundOnDashboardIf}
          showDropdownOne={showDropdownOne}
          dropdownOne={dropdownOne}
          decenderPersonalProfile={decenderPersonalProfile}
          profileData={profileData}
          activeBackgroundOnEDBIf={activeBackgroundOnEDBIf}
          dropdownTwo={dropdownTwo}
        />
        <span className="nav-spacer my-3 mx-3" />
        <ul className="nav--btn-holder w-100">
          {
            !directorId &&
            <>
              <div
                className="nav-info"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <i className="s14">
                  <FontAwesomeIcon icon={faGear} />
                </i>
                <div className="nav-desc museo-sans-500 ">
                  <p>
                    <FormattedMessage
                      id="nav.label.eight.index"
                      defaultMessage="Configuration"
                    />
                  </p>
                </div>
              </div>
              <div className="nav-info" onClick={() => startOnBoard()}>
                <i className="s14">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </i>
                <div className="nav-desc museo-sans-500 ">
                  <p>
                    <FormattedMessage id="nav.label.twelve.index" />
                  </p>
                </div>
              </div>
            
            </>
          }
          
          <div
            className="nav-info"
            data-bs-toggle="modal"
            data-bs-target="#logOutModal"
          >
            <i className="s14">
              <FontAwesomeIcon icon={faRightFromBracket} />
            </i>
            <div className="nav-desc museo-sans-500 ">
              <p>
                <FormattedMessage
                  id="nav.label.ten.index"
                  defaultMessage="Sign Out"
                />
              </p>
            </div>
          </div>
        </ul>
        <span className="nav-spacer my-3 mx-3" />
        <ul className="nav--btn-holder w-100" >
        {
            directorId &&
            <div
            className="nav-info pointer "
            onClick={onNewConsultant}
          >
            <i className="s14 invisible ">
              <FontAwesomeIcon icon={faRightFromBracket} />
            </i>
            <div className="nav-desc museo-sans-500 ">
              <p>
                <FormattedMessage id="sale.director.navbar.another.consultant" defaultMessage={"Ingresar con otra consultora"} />
              </p>
            </div>
          </div>
          }
        </ul>
      </div>
      <div id="nav-footer">
        <LogoNav />
      </div>
    </nav>
  );
}
