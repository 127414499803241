import React from "react";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import ProgressBarDashboard from "../../../../common/Share/ProgressBarDashboard";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGPendingFilled } from "../../SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import { ReactComponent as SVGCompletedFilled } from "../../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGCIncompletedFilled } from "../../../../common/svgs/ic-x-circle-bg.svg";
import { ReactComponent as SVGCBloquedFilled } from "../../SVGs/Bloqueado/ic-item-desbloq-sm-filled-yellow.svg";

import imageOne from "../../imgs/posibleImage08.png"
import imageTwo from "../../imgs/posibleImage09.png"


export default function FirstStepsModalHelpOne() {
  const scrollbarRef = useRef(null);

  return (
    <>
      <div
        className="modal fade"
        id="modalFirstTwo"
        aria-hidden="true"
        aria-labelledby="modalFirstTwo"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "610px" }}
        >
          <div style={{width: "608px", height: "608px"}} className="modal-content modal-radius">
            <div style={{width: "608px", height: "608px"}} className=" modal-body p-0">
              <div className=" h-100" >
                <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
                  <div className="d-flex">
                    <HelpIconNoTarget />
                    <h5 className="museo-sans-500 px-2 s20 m-0">
                      <FormattedMessage id="first.steps.title" />
                    </h5>
                  </div>
                  <div
                    className=" pointer "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <SVGClose />
                  </div>
                </div>
                <hr className="m-0"></hr>
                <div className="d-flex flex-column justify-content-between" style={{height: "495px"}} >
                  <div
                    ref={scrollbarRef}
                    style={{ maxHeight: "380px" }}
                    className=" s16 hidden-y-scroll d-flex flex-column align-items-center"
                  >
                      <div className="mt-4 d-flex flex-column gap-3 museo-sans-500 s16 px-4 mx-3">
                      <p>
                        <span className="museo-sans-700 ">“<FormattedMessage id="first.steps.title" />” </span>
                        <FormattedMessage id="first.steps.modal.help.first.steps.p.one" />
                      </p>
                      <p>
                        <FormattedMessage id="first.steps.modal.help.first.steps.p.two" />
                      </p>
                      <p className=" museo-sans-700 s16">
                        <FormattedMessage id="first.steps.modal.help.first.steps.p.three" />
                      </p>
                      <div className=" d-flex flex-column gap-0">
                        <p className=" museo-sans-700"><FormattedMessage id="first.steps.modal.help.first.steps.p.four" /></p>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.five" />
                        </p>
                      </div>
                      <p>
                        <FormattedMessage id="first.steps.modal.help.first.steps.p.six" />
                      </p>
                      <div className=" d-flex flex-column gap-0">
                        <p className=" museo-sans-700"><FormattedMessage id="first.steps.modal.help.first.steps.p.seven" /></p>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.eight" />
                        </p>
                      </div>
                      <div>
                        <RowContainerTemplate status={"warning"} />
                      </div>
                      <div>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.nine" />
                        </p>
                      </div>
                      <div>
                        <hr />
                      </div>
                      <div>
                        <RowContainerTemplate status={"completed"} />
                      </div>
                      <div>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.ten" />
                        </p>
                      </div>
                      <div>
                        <hr />
                      </div>
                      <div>
                        <RowContainerTemplate status={"risk"} />
                      </div>
                      <div>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.elven" />
                        </p>
                      </div>
                      <div>
                        <hr />
                      </div>
                      <div>
                        <img src={imageOne} />
                      </div>
                      <div>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.twelve" />
                        </p>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.third" />
                        </p>
                      </div>
                      <div>
                        <hr />
                      </div>
                      <div>
                        <img src={imageTwo} />
                      </div>
                      <div>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.fourteen" />
                        </p>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.fifthteen" />
                        </p>
                      </div>
                      <div>
                        <hr />
                      </div>
                      <div className=" s16" >
                        <p className=" museo-sans-700">
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.sixteen.title" />
                        </p>
                        <p>
                          <FormattedMessage id="first.steps.modal.help.first.steps.p.sixteen" />
                        </p>
                      </div>
                      <div style={{borderRadius: "8px"}} className=" museo-sans-500 backgroundShallowBlue blueColor s14 px-3 py-2"  >
                        <FormattedMessage id="first.steps.modal.help.first.steps.p.seventeen.one" /><FormattedMessage id="currency" /><FormattedMessage id="first.steps.modal.help.first.steps.p.seventeen.two" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <ModalWhiteShadow
                      left={"5%"}
                      bottom={95}
                      width={"90%"}
                      scrollbarRef={scrollbarRef}
                      aidHide={40}
                    />
                    <div style={{zIndex: 1000}} className=" position-relative w-100 d-flex mt-4  mb-4 pb-3 justify-content-center">
                      <button
                        type="button"
                        className=" btnLoginWidth museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <FormattedMessage
                          id="buttons.understood"
                          defaultMessage="Entendido"
                        />
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function RowContainerTemplate({ status }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const switchContent = (status) => {
    switch (status) {
      case "completed":
        return "Alcanzado en semana 2";
        
      case "warning":
        return "Pendiente";
        
      case "gap":
        return "Bloqueado";
        
      case "risk":
        return "No Alcanzado";
        
    }
  };

  const switchTitle = (status) => {
    switch (status) {
      case "completed":
        return "ALCANZADO";
        
      case "warning":
        return "PENDIENTE";
        
      case "gap":
        return "BLOQUEADO";
        
      case "risk":
        return "NO ALCANZADO";
        
    }
  };

  const switchSVGs = (status) => {
    switch (status) {
        case "completed":
          return <SVGCompletedFilled />
          
        case "warning":
          return <SVGPendingFilled />
          
        case "gap":
          return < SVGCBloquedFilled />
          
        case "risk":
          return < SVGCIncompletedFilled />;
      }
  }

  return (
    <>
      <div
        style={{ height: "120px" }}
        className=" mb-2 cards-shadow w-100 s12 p-3 px-4 rounded-4 my-3"
      >
        <p className=" museo-sans-700 s16">{switchTitle(status)}</p>
        <hr />
        <div className=" s14 d-flex justify-content-between">
          <div className=" d-flex gap-1 museo-sans-500 ">
            <div
              style={{ height: "14px", width: "14px", top: -2 }}
              className=" position-relative svg-force-size"
            >
              {
                switchSVGs(status)
              }
            </div>
            <p className=" text-uppercase" >{switchContent(status)}</p>
          </div>
          <div className="d-flex justify-content-evenly dm-sans-medium gap-1 s14">
            <div>
              {formatAmount(
                4000,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}{" "}
            </div>
            <div className=" grayColor">
              /{" "}
              {formatAmount(
                5500,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </div>
          </div>
        </div>
        <div className=" mt-1">
          <ProgressBarDashboard
            completed={status == "completed" ? 100 : 60}
            incompleted={status == "completed" ? false : true}
            gap={status == "gap" ? true : false}
            status={status}
            height={12}
          />
        </div>
      </div>
    </>
  );
}
