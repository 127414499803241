export default function ProgressBarTemplateGroup({ completed, incompleted, level, status, gap }) {

    const containerStyles = {
      height: 6,
      width: "100%",
      backgroundColor: "#EDEDED",
      borderRadius: 50,
    };
  
    const labelStyles = {
      padding: 5,
      color: "white",
      fontWeight: "bold",
    };
  
    const adjustBackground = () => {
      if (gap == true || gap == "gap") {
        const grayBackground = "darkGrayBg";
        return grayBackground;
        
      }
      if (incompleted) {
        if (status != "warning") {
          const redBackground = "lightRedBg";
          return redBackground;
        }
        const goldenBackground = "goldBg";
        return goldenBackground;
      }
      const progressGreeBg = "lightGreenBg"
      return progressGreeBg;
    }
  
    return (
      <div className="progress-bar" style={containerStyles}>
          <div
          className={`${adjustBackground()}`}
          style={{
            height: "100%",
            borderRadius: "inherit",
            width: `${completed ? completed : 0}%`,
          }}
        >
          <span style={labelStyles}></span>
        </div>
      </div>
    );
  }
  