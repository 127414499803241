import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import HeaderNewBtn from "../components/HeaderNewBtn";
import HeaderNotifications from "../components/HeaderNotifications";
import "./header.css";
import { getBadgeCounter, getPeriod } from "../../Framework/HeaderSideEffect";
import HeaderProgress from "../components/HeaderProgress";
import { ReactComponent as SVGBell } from "../../../svgs/ic-bell-lg.svg";

import HeaderModalStatus from "../components/modals/HeaderModalStatus";
import HeaderModalPrevEDB from "../components/modals/HeaderModalPrevEDB";
import { fetchUserProfile } from "../../../../Login/Framework/redux/loginSideEffects";
import ShareNameShortMonth from "../../../Share/ShareNameShortMonth";
import HeaderColumnOne from "../components/columns/HeaderColumnOne";
import HeaderColumnTwo from "../components/columns/HeaderColumnTwo";
import HeaderColumnThree from "../components/columns/HeaderColumnThree";

export default function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();
  const [headerTogglerBtn, setHeaderTogglerBtn] = useState(false);
  const [headerTogglerNotfi, setHeaderTogglerNotfi] = useState(false);
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const headerData = useSelector((state) => state?.headerData);
  const status = useSelector((state) => state.scoreData.consultantStatus);
  const periodGap = useSelector(
    (state) => state.headerData.period.periodProcessGap
  );
  const profileError = useSelector((state) => state.userInfo.profileError);
  const directorId = localStorage.getItem("directorId");

  const getDate = (string) => {
    if (string === undefined) {
      return;
    }
    let arrDate = string.split("T");
    let arrTime = arrDate[0].split("-");
    let day = arrTime[2];
    let month = arrTime[1];
    let monthName = ShareNameShortMonth(month);
    let resolve = monthName + " " + day;
    return resolve;
  };

  const onStartUp = async () => {
    await dispatch(getPeriod());
    !directorId && (await dispatch(getBadgeCounter()));
  };

  const togglePlusModal = (evt) => {
    evt.preventDefault();
    setHeaderTogglerBtn(!headerTogglerBtn);
  };

  const toggleNotfi = (evt) => {
    evt.preventDefault();
    setHeaderTogglerNotfi(!headerTogglerNotfi);
  };

  const onBreadcrumbs = () => {
    if (location?.pathname?.indexOf("/dashboard/") !== -1) {
      return "header-two-cols";
    }
    return "";
  };

  const onClickCenterProgress = () => {
    history("/dashboard/calendar");
  };

  const onReturnClick = () => {
    const fullUrl = window.location.href;
    const pathname = new URL(fullUrl).pathname;
    const parts = pathname.split("/");
    const name = parts[parts.length - 2];
    if (location.pathname.includes("/dashboard/edbs/form/type")) {
      const clickMe = document.getElementById("warning-step-back");
      clickMe.click();
      return;
    }
    if (name == "firstSteps") {
      history("/dashboard");
    } else {
      history(-1);
    }
  };

  const fetchProfile = () => {
    dispatch(fetchUserProfile());
  };

  const onHeaderClick = () => {
    const button = document.getElementById("on-submit-confirm");
    button.click();
  };

  useEffect(() => {
    onStartUp();
  }, [params]);

  const invitationToggler = localStorage.getItem("invitationToggle");
  const isDashboard = location.pathname.indexOf("/dashboard/") !== -1;

  return (
    <>
      <HeaderModalStatus
        level={profileData.level}
        status={status.checkMarkStatus}
      />
      {headerData?.extLinks?.links && (
        <HeaderModalPrevEDB link={headerData?.extLinks?.links[0]?.link} />
      )}

      <div
        id="header-nav--spacing"
        style={{ zIndex: 3 }}
        className=" header-shadow lightWhiteBg d-flex justify-content-center "
      >
        <div>
          <header
            className={`justify-content-between lightWhiteBg ${onBreadcrumbs()}`}
          >
            <HeaderColumnOne
              profileData={profileData}
              status={status}
              periodGap={periodGap}
              isDashboard={isDashboard}
              fetchProfile={fetchProfile}
              profileError={profileError}
              onReturnClick={onReturnClick}
            />
            <HeaderColumnTwo
              profileData={profileData}
              isDashboard={isDashboard}
              directorId={directorId}
              onClickCenterProgress={onClickCenterProgress}
              onStartUp={onStartUp}
              headerData={headerData}
              getDate={getDate}
            />
            <HeaderColumnThree
              toggleNotfi={toggleNotfi}
              onHeaderClick={onHeaderClick}
              togglePlusModal={togglePlusModal}
              headerTogglerBtn={headerTogglerBtn}
              setHeaderTogglerBtn={setHeaderTogglerBtn}
              headerData={headerData}
              invitationToggler={invitationToggler}
              headerTogglerNotfi={headerTogglerNotfi}
              setHeaderTogglerNotfi={setHeaderTogglerNotfi}
              directorId={directorId}
            />
            <div style={{ display: "none" }} className=" invisible">
              <HeaderProgress />
            </div>
          </header>
        </div>
      </div>
    </>
  );
}
