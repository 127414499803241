import React from "react";
import { useSelector } from "react-redux";
import { useState} from "react";


import "../pages/Configuration.css"


/*import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";*/

import UserDataMainPage from "../components/UserDataMainPage";
import UserDataChangeEmail from "../components/UserDataChangeEmail";
import ConfigChangeSuccessful from "../components/ConfigChangeSuccessful";
import UserDataChangePhoneNumber from "../components/UserDataChangePhoneNumber";
import UserDataPhoneNumberVerifyCode from "../components/UserDataPhoneNumberVerifyCode";
import UserDataChangeStreetAddress from "../components/UserDataChangeStreetAddress";
import UserDataConfirmNewStreetAddress from "../components/UserDataConfirmNewStreetAddress";
import UserDataChangeProfilePicture from "../components/UserDataChangeProfilePicture";

export default function UserData() {

  const userData = useSelector((state) => state.userInfo.userInfo);

  const [value, setValue] = useState(1.1);


  const emailChangeDescription = "Enviamos un mail a nombreapellido@mail.com para validar tu cuenta. Por favor revisa en tu casilla e ingresá el link enviado."

  const phoneNumberChangeDescription = "¡Tu número de celular ha sido cambiado con éxito!"

  const streetAddressChangeDescription = "¡Tu dirección ha sido cambiada con éxito!"

  const profilePictureChangeDescription = "¡Tu foto de perfil ha sido cambiada con éxito"

  function userDataSwitch(param) {
    switch (param) {
      case 1.1:
        return <UserDataMainPage userData={userData} travelFunction={setValue}/>
      case 1.2:
        return <UserDataChangeEmail travelFunction={setValue}/>
      case 1.3:
        return <ConfigChangeSuccessful travelFunction={setValue} title={"settings.user.data.email.title"} description={emailChangeDescription}/>
      case 1.4:
        return <UserDataChangePhoneNumber travelFunction={setValue}/>
      case 1.5:
        return <UserDataPhoneNumberVerifyCode travelFunction={setValue}/>
      case 1.6:
        return <ConfigChangeSuccessful travelFunction={setValue} title={"settings.user.data.phone.title"} description={phoneNumberChangeDescription}/>
      case 1.7:
        return <UserDataChangeStreetAddress travelFunction={setValue}/> 
      case 1.8:
        return <UserDataConfirmNewStreetAddress travelFunction={setValue}/>
      case 1.9:
        return <ConfigChangeSuccessful travelFunction={setValue} title={"settings.user.data.street.title"} description={streetAddressChangeDescription}/>
      case 2.0:
        return <UserDataChangeProfilePicture travelFunction={setValue}/>
      case 2.1:
        return <ConfigChangeSuccessful travelFunction={setValue} title={"settings.user.data.profile.picture.title"} description={profilePictureChangeDescription}/>
      
    }
  }

  return (
    <section className="position-relative h-100 config-section-container">
      {userDataSwitch(value)}
    </section>
  );
}
