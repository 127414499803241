import React, { useState } from "react";

import UserData from './UserData';
/*import Notifications from './Notifications';*/
import Password from './Password';
import DistributionAgreement from "./DistributionAgreement";

import '../pages/Configuration.css'

import { ReactComponent as UserSVG } from '../../../svgs/user.svg'
import { ReactComponent as DistributionAgreementSVG } from '../../../svgs/distribution-agreement.svg'
import { ReactComponent as TermsAndConditionsSVG } from '../../../svgs/terms-and-conditions.svg'
import { ReactComponent as SVGClose } from "../../../svgs/ic-x-lg.svg";


import { FormattedMessage } from "react-intl";

import { useMediaPredicate } from 'react-media-hook';
import ConfigPrivacyPolicy from './ConfigPrivacyPolicy';
import ConfigTermsAndConditions from './ConfigTermsAndConditions';

export default function Configuration({keySetter}) {

  const biggerThan992 = useMediaPredicate("(min-width: 992px)");
  const location = localStorage.getItem("country");

  const [value, setValue] = useState(1);

  function switchConfig(param) {
    switch (param) {
      case 1:
        return <UserData />;
      case 3:
        return <DistributionAgreement />;
      case 4:
        return <ConfigPrivacyPolicy />;
      case 5:
        return <ConfigTermsAndConditions />;
    }
  }

  return (
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog modal-dialog-centered`}
            style={{width: "1016px", maxWidth: "unset"}}
        >
          <div className="modal-content modal-radius overflow-hidden config-modal-height">
            <div className="ms-0 me-0 border-bottom-0 bg-light modal-header-styles">
              <h5 className="modal-title museo-sans-500 s20" id="exampleModalLabel">
                <FormattedMessage
                  id="nav.label.eight.index"
                  defaultMessage="Settings"
                />
              </h5>
              <div 
                onClick={() => {
                   setValue(1) 
                   keySetter(Math.random())
                }} 
                className="pointer " data-bs-dismiss="modal" aria-label="Close"
              >
                    <SVGClose />
              </div>
            </div>
            <div className="modal-body overflow-hidden p-0">
              <div className="container-fluid h-100 p-0">
                <div className={`d-flex h-100 ${!biggerThan992 && "flex-column"}`}>{/*row */}
                  <div
                    className={`col-lg-4 pt-3 ${
                      biggerThan992 && "border-end grayBorder"
                    }`}
                    style={{width: "304px"}}
                  >
                    <aside className="d-flex flex-column h-100">
                      <div>
                        <div
                          onClick={() => setValue(1)}
                          className={`${
                            value === 1 && "selected-menu-button"
                          } hover-pointer d-flex justify-content-start align-items-center ps-4 config-menu-button`}
                          
                        >
                          <UserSVG />
                          <p className="ms-3 mb-0 museo-sans-500 s16">
                            <FormattedMessage
                              id="settings.data"
                              defaultMessage="My data"
                            />
                          </p>
                        </div>
                      </div>

                      <div>
                        {
                          location == "pe" ? 
                          null
                          :
                        <div
                          onClick={() => setValue(3)}
                          className={`${
                            value === 3 && "selected-menu-button"
                          } hover-pointer d-flex justify-content-start align-items-center ps-4 config-menu-button`}
                        >
                          <DistributionAgreementSVG />
                          <p className="ms-3 mb-0 museo-sans-500 s16">
                            <FormattedMessage
                              id="settings.distribution.agreement"
                              defaultMessage="Distribution agreement"
                            />
                          </p>
                        </div>

                        }
                      </div>

                     
                      { location !== "ar" &&
                        <div
                          onClick={() => setValue(5)}
                          className={`${
                            value === 5 && "selected-menu-button"
                          } hover-pointer d-flex justify-content-start align-items-center ps-4 config-menu-button`}
                        >
                          <TermsAndConditionsSVG />
                          <p className="ms-3 mb-0 museo-sans-500 s16">
                            <FormattedMessage
                              id="politics.meta.terms"
                              defaultMessage="Términos y condiciones"
                            />
                          </p>
                        </div>
                      }

                      <button className="align-self-center mt-auto museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding bronceBg config-logout-button" type="button" data-bs-toggle="modal" data-bs-target="#logOutModal">
                        <svg className="me-1" id="sm" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <rect id="bg" width="16" height="16" fill="none"/>
                            <path id="vector" d="M5.538,11.85H1.385a.482.482,0,0,1-.462-.5v-9a.482.482,0,0,1,.462-.5H5.538A.481.481,0,0,0,6,1.35a.482.482,0,0,0-.462-.5H1.385A1.448,1.448,0,0,0,0,2.35v9a1.448,1.448,0,0,0,1.385,1.5H5.538a.5.5,0,0,0,0-1Z" transform="translate(2 1.152)" fill="#5aaff1" stroke="#5aaff1" strokeWidth="0.5"/>
                            <path id="vector-2" data-name="vector" d="M176.633,110.346l-2.517-3.036a.363.363,0,0,0-.585.005.588.588,0,0,0,0,.715l1.8,2.169h-4.819a.516.516,0,0,0,0,1.012h4.819l-1.8,2.169a.588.588,0,0,0,0,.715.363.363,0,0,0,.585.005l2.517-3.036a.588.588,0,0,0,0-.72Z" transform="translate(-162.756 -102.705)" fill="#5aaff1" stroke="#5aaff1" strokeWidth="0.5"/>
                        </svg>
                        <FormattedMessage id="buttons.log.out" defaultMessage="Log out"/>
                      </button>

                    </aside>
                  </div>
                  <div className="col-lg-8 col-12 pt-3 position-relative pb-4 ps-4 ps-lg-0">
                    {switchConfig(value)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
