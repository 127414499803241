import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { replaceURLString } from "../FirstStepFetchCMSDataById";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as LogoNav } from "../../../../common/svgs/Logo-Just-Sidebar.svg";
import FirstStepsGuidelinesDate from "../FirstStepsGuidelinesDate";

export default function FirstStepsModalGuidelinesA() {
  const CMSData = useSelector(
    (state) => state.firstStepsAllReducer.CMSOne?.data
  );
  const data = useSelector(
    (state) => state.firstStepsAllReducer?.firstStepsModalDetails?.data
  );

  const CMSLoader = useSelector(
    (state) => state?.firstStepsAllReducer?.CMSLoader
  );

  const controlLegals = (legals) => {
    if (!legals) {
      return "";
    }

    const splitByN = legals.split("\n");

    return (
      <div className=" d-flex flex-column gap-3 mt-1">
        {splitByN.map((elm) => {
          return <p> {elm} </p>;
        })}
      </div>
    );
  };

  const controlTitle = (title) => {
    if (!title) {
      return "";
    }

    const splitByN = title.split("\n");

    return (
      <>
        {splitByN.map((elm) => {
          return <p>{elm}</p>;
        })}
      </>
    );
  };

  const scrollbarRef = useRef(null);

  return (
    <>
      <div
        className="modal fade "
        id="modalFirstStepsGuidelinesA"
        aria-hidden="true"
        aria-labelledby="modalFirstStepsGuidelinesA"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered "
          style={{ maxWidth: "807px" }}
        >
          <div
            className="modal-content modal-radius"
            style={{ maxHeight: "640px" }}
          >
            <div
              className="d-flex "
              style={{
                padding: "32px 56px 32px 32px",
                height: "640px",
                gap: "32px",
              }}
            >
              {CMSLoader ? (
                <>
                  <div
                    style={{ borderRadius: "14px" }}
                    className=" lightui1-shimmer position-relative w-100 h-100"
                  ></div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "359px",
                      overflow: "hidden",
                      minWidth: "359px",
                    }}
                    className="position-relative"
                  >
                    <div className=" d-flex flex-column position-absolute justify-content-between align-items-center w-100 h-100 pt-4">
                      <div className="d-flex flex-column align-items-center">
                        <div
                          style={{ width: "62px", height: "36px" }}
                          className=" svg-force-size"
                        >
                          <LogoNav />
                        </div>
                        <div style={{ marginTop: "18px" }}>
                          <p
                            className=" s24 museo-sans-700 text-center"
                            style={{ color: "#CED2FF", width: "234px" }}
                          >
                            {controlTitle(CMSData?.title)}
                          </p>
                        </div>
                        <p className=" s16 museo-sans-500 text-white">
                          <FormattedMessage id="first.steps.new.consultnats" />
                        </p>
                      </div>
                      <div className="w-100">
                        <div
                          style={{
                            height: "32px",
                            background: "#CED2FF",
                            bottom: "32px",
                          }}
                          className=" d-flex  position-relative justify-content-center align-items-center s12 museo-sans-500"
                        >
                          <FirstStepsGuidelinesDate
                            initDate={data?.data?.planStartDate}
                            endDate={data?.data?.planEndDate}
                          />
                        </div>
                      </div>
                    </div>
                    <img
                      style={{
                        borderRadius: "15px",
                      }}
                      src={replaceURLString(
                        CMSData?.imageUrl,
                        CMSData?.legalsImage?.filename_download,
                        CMSData?.legalsImage?.id
                      )}
                    />
                  </div>
                </>
              )}

              <div
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-all-modal"
                className=" position-absolute invisible"
              >
                close
              </div>
              <div className="w-100 d-flex flex-column position-relative">
                <div
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ right: "-14px", top: "8px" }}
                  className="pointer position-absolute"
                >
                  <SVGClose />
                </div>
                <div className="h-100 w-100" style={{ marginTop: "18px" }}>
                  <p className=" museo-sans-700 s20">
                    <FormattedMessage id="first.steps.guidelines.program.title" />
                  </p>
                  <div className=" mt-3">
                    <div
                      ref={scrollbarRef}
                      style={{ height: "440px" }}
                      className=" hidden-y-scroll d-flex flex-column gap-3 museo-sans-500 s16"
                    >
                      <div className="">{controlLegals(CMSData?.legals)}</div>
                      <div className=" invisible" style={{ height: "15px" }}>
                        .
                      </div>
                    </div>
                    <ModalWhiteShadow
                      left={"0%"}
                      width={"98%"}
                      bottom={"18px"}
                      scrollbarRef={scrollbarRef}
                      aidHide={40}
                      height={"70px"}
                    />
                    <div className=" d-flex w-100 justify-content-center">
                      <button
                        type="button"
                        className=" w-100 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        style={{ height: "40px", zIndex: 9999999 }}
                      >
                        <FormattedMessage id="buttons.understood" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
