import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchDescIncorpExtra, fetchDescPersonalGroupExtra } from "../../Framework/redux/DescendantsSideEffect";
import DescendantsPersonalListRow from "./DescendantsPersonalListRow";

export default function DescendantsPersonalList({ 
  groups,
   gen,
   isVisible,
   loading,
   setIsVisible,
   profileData,
   root
  }) {
    
    const [returnedEmpty, setReturnEmpty] = useState(false);
    const [offSet, setOffSet] = useState(0);
    const [loader , setLoader] = useState(false)

    const dispatch = useDispatch();
    const extraRef = useRef(null);

    const extraGroupsIncorp = useSelector(
      (state) => state.descendantData.dataIncorpExtra
    );

    const extraPersonalGroup = useSelector(
      (state) => state.descendantData.dataPersonalGroupExtra
    );

    const callbackExtras = async () => {
      setLoader(true);
      let result = null;
      let auxOffSet = offSet + 10;
      if (profileData.level < 2) {
        result = await dispatch(
          fetchDescIncorpExtra({ root: root, offSet: auxOffSet })
        );
        if (result.payload.incorporated.length == 0) {
          setReturnEmpty(true);
        }
        setOffSet(auxOffSet);
      } else {
        result = await dispatch(
          fetchDescPersonalGroupExtra({ root: root, offSet: auxOffSet })
        );
        if (result.payload.personalGroup.length == 0) {
          setReturnEmpty(true);
        }
        setOffSet(auxOffSet);
      }
      setLoader(false);
      return result;
    };
   
    useEffect(() => {
      if (extraRef.current) {
        const observer = new IntersectionObserver((entries) => {
          const entry = entries[0];
          setIsVisible(entry.isIntersecting);
        });
        observer.observe(extraRef.current);
      }
  
      if (isVisible) {
        callbackExtras();
      }
    }, [isVisible, extraRef.current]);

  return (
    <div  style={{ overflowY: "scroll", maxHeight: "600px" }} className=" pe-3 hidden-y-scroll pt-3 mt-3 d-flex flex-column">
      {" "}
      {groups &&
        groups.map((elm) => {
          return (
            <>
              {" "}
              
              <DescendantsPersonalListRow gen={1} user={elm} />{" "}
            </>
          );
        })}
        {extraPersonalGroup &&
        profileData.level > 1 &&
        extraPersonalGroup.map((elm) => {
          return (
            <>
              {" "}
              <DescendantsPersonalListRow
                gen={1}
                user={elm}
              />{" "}
            </>
          );
        })}
      {extraGroupsIncorp &&
        profileData.level < 2  &&
        extraGroupsIncorp.map((elm) => {
          return (
            <>
              {" "}
              <DescendantsPersonalListRow
                gen={1}
                user={elm}
              />{" "}
            </>
          );
        })}
        {!returnedEmpty && (
        <>
          <div ref={extraRef} className="mt-4" style={{ height: "30px" }}>
            {isVisible ? (
              loader ? (
                <div style={{top: "-30px"}} className=" position-relative d-flex flex-column justify-content-center align-items-center">
                  <div className="spinner-border blueColor" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p className="museo-sans-500 s14 mt-2">
                    <FormattedMessage
                      id="group.incorporations.descendant.loading.more"
                      defaultMessage="Cargando más consultoras"
                    />
                  </p>
                </div>
              ) : (
                <></>
              )
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}
