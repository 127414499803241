import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput"
import ClientListFormSelect from "../elements/ClientListFormSelect"


export default function Uruguay({ watch, ...props }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')

  return (
    <>
      <ClientListFormOneColumnInput
        placeholder={"Ej.: Calle y Número"}
        label={"Domicilio (opcional)"}
        name="street"
        {...props}
      />
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          placeholder={"Seleccionar un Departamento"}
          label={"Departamento"}
          father={true}
          name="department"
          isUpdate={isUpdate}
          watch={watch}
          {...props}
          level={1}
        />

      </div>
      <ClientListFormSelect
        placeholder={"Seleccionar una Localidad"}
        label={"Localidad"}
        father={watch('department')}
        name="locality"
        isUpdate={isUpdate}
        watch={watch}
        {...props}
        level={2}
      />
      <div className=" px-3 pe-0">

        <ClientListFormSelect
          placeholder={"Seleccionar un Barrio"}
          label={"Barrio"}
          father={watch('locality')}
          name="neighborhood"
          isUpdate={isUpdate}
          watch={watch}
          {...props}
          level={3}
          last
        />

      </div>
      <ClientListFormOneColumnInput
        placeholder={""}
        label={"Entre y/o Esquina"}
        name="signs"
        {...props}
      />
    </>
  )
}