import React from 'react'

import ReturnToAndTitle from '../../../Share/ReturnToAndTitle'
import { InputComponent } from './InputComponent'
import ContinueButton from './ContinueButton'
import { FormattedMessage } from 'react-intl'

export default function UserDataChangeEmail({travelFunction}) {
  return (
    <>
        <ReturnToAndTitle
          travelFunction={travelFunction}
          returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
          title={"settings.user.data.email.title"}
        >
          <p>
            <FormattedMessage
              id="settings.user.data.email.p1"
              defaultMessage="Set a new email address"
            />
            <br />
            <FormattedMessage
              id="settings.user.data.email.p2"
              defaultMessage="We'll send you an email to verify it."
            />
          </p>
        </ReturnToAndTitle>
        <InputComponent
          type="email"
          id="contraseña"
          placeholder="nombreapellido@mail.com"
        />
        <ContinueButton
          continueFunction={travelFunction}
          whereTo={1.3}
          text={"Continuar"}
        />
      </>
  )
}
