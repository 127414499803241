import React from 'react'
import { useRef, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import EDBClosedRow from './EDBClosedRow'

import { useDispatch } from 'react-redux'
import { ClosedExtraEDBMeetings } from '../../Framework/redux/EDBsSideEffects'
import EDBEmpty from './EDBEmpty';
import Loader from './../../../common/Share/Loader';

import { FormattedMessage } from "react-intl";
import SkeletonEDBRowList from '../../../common/Share/SkeletonEDBRowList'
import ShareKeyGenerator from '../../../common/Share/ShareKeyGenerator'


export default function EDBClosedList({dataWasFiltered, order, closedFilterWasUpdated, edbType, closedFilterOffsetState, setClosedFilterOffsetState, prizeLevel, date}) {
 
    const [isVisible, setIsVisible] = useState()
    
    const dispatch = useDispatch()
    const extraLoaderRef = useRef()

  /*const closedExtraEDBs = useSelector((state) => state.edbData.closedExtraEDBs);
  const loadingExtraClosedEDBs = useSelector(
    (state) => state.edbData.loadingExtraClosedEDBs
  );*/
  const filterInformation = useSelector((state) => state.edbData.filters)
  const rawFiltersState = useSelector((state) => state.edbData.rawFilters)
    const closedExtraEDBs = useSelector((state) => state.edbData.closedExtraEDBs)
    const loadingExtraClosedEDBs = useSelector((state) => state.edbData.loadingExtraClosedEDBs)
    const closedEDBs = useSelector((state) => state.edbData.closedEDBs)
    const loadingClosedEDBs = useSelector((state) => state.edbData.loadingClosedEDBs)

    function defineSortBy(orderObject){
        if(orderObject.highLow && orderObject.type.sale){
            return "sales.desc"
        } else if(!orderObject.highLow && orderObject.type.sale){
            return "sales.asc"
        } else if(orderObject.highLow && orderObject.type.guest){
            return "assistants.desc"
        } else if(!orderObject.highLow && orderObject.type.guest){
            return "assistants.asc"
        } else if(orderObject.highLow && orderObject.type.level){
            return "hostLevel.desc"
        } else if(!orderObject.highLow && orderObject.type.level){
            return "hostLevel.asc"
        }
    }

      function defineEDBType(edbTypeObject){
        if(edbTypeObject.virtual){
            return "virtual"
        } else if(edbTypeObject.onSite){
            return "presencial"
        }
        return
      }

      function definePrizeLevel(awardObject){
        if(awardObject.noAward){
            return "noPrize"
        } else if(awardObject.oneAward){
            return "firstPrize"
        } else if(awardObject.twoAward){
            return "secondPrize"
        }
        return
      }

      function defineDate(dateObject){ 
        const dateOriginArray = dateObject.origin.split("-")
        const dateOriginFixedString = dateOriginArray.join("")
        const dateTargetArray = dateObject.target.split("-")
        const dateTargetFixedString = dateTargetArray.join("")
        return {dateStart: dateOriginFixedString, dateEnd: dateTargetFixedString }
      }

    async function loadNewElements(thereIsAFilter) {
        if(thereIsAFilter){
            await dispatch(ClosedExtraEDBMeetings({offset: closedFilterOffsetState, limit:10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
            setClosedFilterOffsetState(closedFilterOffsetState + 10)
        } else if(filterInformation.wasFiltered){
            await dispatch(ClosedExtraEDBMeetings({offset: closedFilterOffsetState, limit:10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
            setClosedFilterOffsetState(closedFilterOffsetState + 10)
        } else {
            await dispatch(ClosedExtraEDBMeetings({offset: closedFilterOffsetState, limit:10}))
            setClosedFilterOffsetState(closedFilterOffsetState + 10)
        }
    }

    useEffect(() => {
        if(extraLoaderRef.current){
            const observer = new IntersectionObserver((entries) => {
                const entry = entries[0]
                setIsVisible(entry.isIntersecting)
            })
            observer.observe(extraLoaderRef.current)
        }

        if(isVisible){
            loadNewElements(dataWasFiltered)
        }
    }, [isVisible, extraLoaderRef.current, loadingClosedEDBs])


function displayRows() {
    return closedEDBs.map((element) => {
      return (
        <EDBClosedRow
          key={ShareKeyGenerator()}
          id={element.id}
          date={element.date}
          title={element.title}
          hostName={element.hostName}
          edbType={element.edbType}
          edbState={element.edbState}
          daysToClose={element.daysToClose}
          edbStatus={element.edbStatus}
          accumulatedAmount={element.accumulatedAmount}
          amountStatus={element.amountStatus}
        />
      );
    });
  }
  /*Array para probar el caso en que no haya Encuentros */
  let testArray = [];

  return (
    <div className=' w-100' >
    {
      /**
       *  <div className="edb-empty-height position-relative">
        <SkeletonEDBRowList />
        <div>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
        </div>
      </div>
       * 
       */
    }
      {loadingClosedEDBs ? (
        <div className="edb-empty-height position-relative">
        <SkeletonEDBRowList />
        <div>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
        </div>
      </div>
      ) : (
        <>
          <div className="d-flex text-uppercase ps-3 pe-2 s12 museo-sans-500 grayColor justify-content-between align-items-center row-height">
            <div className="" style={{ width: "14%" }}>
              <FormattedMessage id="edb.list.table.time" defaultMessage="" />
            </div>
            <div className="" style={{ width: "21%" }}>
              <FormattedMessage id="edb.list.table.theme" defaultMessage="" />
            </div>
            <div className="" style={{ width: "20%" }}>
              <FormattedMessage id="edb.list.table.host" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.type" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.status" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
            <FormattedMessage id="edb.list.table.sales" defaultMessage="" />
            </div>
            <div className="" style={{ width: "5%" }}></div>
          </div>
          {closedEDBs.length < 1 ? (
            <div className="d-flex justify-content-center align-items-center edb-empty-height">
              <EDBEmpty
                title={
                  <FormattedMessage
                    id="edb.no.closed.edbs"
                    defaultMessage="No hay encuentros Cerrados"
                  />
                }
                description={
                  <FormattedMessage
                    id="edb.no.closed.edbs.description"
                    defaultMessage="No hay encuentros Cerrados"
                  />
                }
              ></EDBEmpty>
            </div>
          ) : (
            <>
              {displayRows()}
              {!loadingClosedEDBs && closedEDBs.length >= 10 ? (
                <div
                  on
                  ref={extraLoaderRef}
                  className="mt-4"
                  style={{ height: "30px" }}
                >
                  {isVisible ? (
                    loadingExtraClosedEDBs ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="spinner-border blueColor" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="museo-sans-500 s14 mt-2">
                        <FormattedMessage id='edb.list.loading.more' />
                        </p>
                        
                      </div>
                    ) : closedExtraEDBs.length < 1 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="museo-sans-500 s14">
                        <FormattedMessage id='edb.list.loading.end' />
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  );
}
