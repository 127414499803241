import React from "react";
import { ReactComponent as SVGOferts0to24 } from "../imgs/oferts0to24.svg";
import { ReactComponent as SVGOferts24to54 } from "../imgs/oferts25to54.svg";
import { ReactComponent as SVGOferts55to75 } from "../imgs/oferts55to75.svg";

import { ReactComponent as SVGOfertsDescAndGift } from "../imgs/ofertsDescAndGift.svg";
import { ReactComponent as SVGOfertsDescAndGiftLila } from "../imgs/ofertsDescAndGiftLila.svg";
import { ReactComponent as SVGOfertsDescAndGiftPink } from "../imgs/ofertsDescAndGiftPink.svg";
import { ReactComponent as SVGOfertsGiftJustCoins } from "../imgs/GiftJustcoins.svg";
import { ReactComponent as SVGOfertsJustCoins } from "../imgs/DescuentoJustcoins.svg";
import { ReactComponent as SVGOfertsJustCoinsLila } from "../imgs/DescuentoJustcoinsLila.svg";
import { ReactComponent as SVGOfertsJustCoinsPink } from "../imgs/DescuentoJustcoinsPink.svg";
import { ReactComponent as SVGOfertsGift } from "../imgs/ofertsGift.svg";

const OfertsDiscountsHolder = ({ number, status }) => {
  const checkOfferStatus = ({ number, status }) => {
    switch (status) {
      case "Oferta":
        return (
          <>
            <div
              style={{
                borderRadius: "50%",
                height: "39px",
                width: "46px",
                position: "absolute",
                left: 16,
                top: 16,
              }}
            >
              {number <= 33 ? (
                <>
                  <SVGOferts0to24 />
                </>
              ) : (
                <>
                  {number <= 66 ? (
                    <>
                      <SVGOferts24to54 />
                    </>
                  ) : (
                    <>
                      {number <= 100 && (
                        <>
                          <SVGOferts55to75 />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        );
      case "Regalo":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            <SVGOfertsGift />
          </div>
        );
      case "Oferta + Regalo":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            {number <= 33 ? (
              <>
                <SVGOfertsDescAndGift />
              </>
            ) : (
              <>
                {number <= 66 ? (
                  <>
                    <SVGOfertsDescAndGiftLila />
                  </>
                ) : (
                  <>
                    {number <= 100 && (
                      <>
                        <SVGOfertsDescAndGiftPink />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      case "Descuento + Justcoins":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            {number <= 33 ? (
              <>
                <SVGOfertsJustCoins />
              </>
            ) : (
              <>
                {number <= 66 ? (
                  <>
                    <SVGOfertsJustCoinsLila />
                  </>
                ) : (
                  <>
                    {number <= 100 && (
                      <>
                        <SVGOfertsJustCoinsPink />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      case "Justcoins":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            <SVGOfertsGiftJustCoins />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <>
      {checkOfferStatus({ number, status })}
      {status != "Regalo" && (
        <>
          {status != "Justcoins" && (
            <>
              <p
                className="dm-sans-bold s16"
                style={{
                  position: "absolute",
                  left: 37,
                  top: 32,
                  color: "white",
                }}
              >
                {number ? number : 10}%
              </p>
              <p
                className="dm-sans-bold s16"
                style={{
                  position: "absolute",
                  left: 31,
                  top: 49,
                  color: "white",
                }}
              >
                DESC.
              </p>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OfertsDiscountsHolder;
