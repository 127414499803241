export default function ShareLocalityController(countryCode) {
  const references = {
    mx: [
      "street",
      "state",
      "municipality",
      "locality",
      "postalCode",
      "reference",
    ],
    cl: ["street", "region", "commune", "province"],
    pe: ["street", "district", "city", "department", "reference"],
    co: ["street", "neighborhood", "city", "department"],
    uy: ["street", "signs", "department", "locality", "neighborhood"],
    cr: ["province", "canton", "district", "street", "signs"],
    pa: ["street", "signs", "reference", "district", "province"],
    ar: ["street", "province", "locality", "postalCode"],
  };
  const needInput = [
    "street",
    "signs",
    "reference",
    "neighborhood",
    "internalNumber",
    "externalNumber",
  ];
  const needSelect = [
    "municipality",
    "state",
    "canton",
    "region",
    "commune",
    "province",
    "postalCode",
    "locality",
    "district",
    "city",
    "department",
  ];

  const referencesWithLevels = {};


  Object.keys(references).forEach((country) => {
    let levelCounter = 0
    referencesWithLevels[country] = references[country].map((reference) => {
      return {
        name: reference,
        isLevel: country == "pe" ? -1 : needSelect.includes(reference)
          ? ++levelCounter
          : -1,
      };
    });
  });

  const countryReferences = referencesWithLevels[countryCode];
  const maxLevel = Math.max(...countryReferences.map((ref) => ref.isLevel));
  
  return {references: countryReferences, maxLevel}
}
