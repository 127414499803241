import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";

import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as SVGChevron } from "../../../common/svgs/c-chevron-right-sm.svg";
import { ReactComponent as SVGEmptyStar } from "../../../common/svgs/Empty-grupos-calificados.svg";
import { ReactComponent as SVGStarReduce } from "../../../common/svgs/ic-req-red.svg";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import StyledMultiLang from "../../../common/Share/StyledMultiLang";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import checkReduceRequirement from "../../../common/Share/checkReduceRequirement";
import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";
import { getGroup, getScore } from "../../Framework/redux/dashboardSideEffect";
import { getPotentialLeaders } from "../../../Performance/Framework/redux/performanceSideEffects";

export default function DashboardQualificationGroups({
  loading,
  addProgressBar,
  addProgressBarTemplate,
  scoreData,
  status,
  gap,
  onHistory,
  root,
  level,
  descendant,
  reduced,
  groupsData,
  onClickDetails,
  potentialLeaderCards,
  scoreDataPotentialLeader,
}) {
  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");
  const errorScoreData = useSelector(
    (state) => state.scoreData?.scoreDataError
  );
  const groupsDataError = useSelector(
    (state) => state.scoreData?.groupsDataError
  );

  const potentialDataError = useSelector(
    (state) => state.performanceData?.potentialLeadersError
  );

  const reducedColor = { color: "#D18DEF" };
  const dispatch = useDispatch();

  const fetchScore = async () => {
    await dispatch(getScore());
  };

  const fetchGroup = async () => {
    await dispatch(getGroup());
  };

  const fetchPotential = async () => {
    await dispatch(getPotentialLeaders());
  };

  return (
    <div className=" position-relative d-flex ">
      <>
        {errorScoreData ? (
          <div className=" position-relative p-3 px-4 mx-2 w-100">
            <div>
              <h4 className=" m-0 s16 museo-sans-700 text-uppercase ">
                <StyledMultiLang
                  loading={loading}
                  id="dashboard.qualification.personal.title"
                  def={"Personal"}
                />
              </h4>
            </div>
            <div className="mt-5">
              <ShareErrorHandlerServices service={fetchScore} />
            </div>
            <div className="my-5"></div>
          </div>
        ) : (
          <QualifiedExtra
            descendant={descendant}
            scoreData={scoreData}
            onClickDetails={onClickDetails}
            status={status}
            biggerThan1000={biggerThan1000}
            loading={loading}
            onHistory={onHistory}
            root={root}
            level={level}
            gap={gap}
            reduced={reduced}
            addProgressBar={addProgressBar}
            addProgressBarTemplate={addProgressBarTemplate}
            scoreDataPotentialLeader={scoreDataPotentialLeader}
          />
        )}
      </>
      <div className=" p-3 px-4 w-100 mx-2">
        {level > 1 && (
          <>
            {level > 2 && (
              <QualifiedHeader
                descendant={descendant}
                groupsData={groupsData}
                reducedColor={reducedColor}
                reduced={reduced}
                level={level}
                onClickDetails={onClickDetails}
                status={status}
                biggerThan1000={biggerThan1000}
                loading={loading}
                gap={gap}
              />
            )}

            <div className=" d-flex flex-column h-100 pb-3 gap-1">
              <div className=" ">
                {level > 2 && (
                  <>
                    {groupsDataError ? (
                      <>
                        <div className=" pb-4 d-flex flex-row museo-sans-500 s14 justify-content-left align-items-center gap-1 pt-3">
                          <FormattedMessage
                            id="qualified.leaders"
                            defaultMessage={"Líderes Calificadas"}
                          />
                          <i
                            data-bs-toggle="modal"
                            data-bs-target="#qualifiedLeader"
                            className="pointer"
                          >
                            <HelpIconNoTarget />
                          </i>
                        </div>
                        <div className=" position-relative w-100 my-3 ">
                          <ShareErrorHandlerServices service={fetchGroup} />
                        </div>
                      </>
                    ) : (
                      <>
                        {groupsData?.array?.length > 0 ? (
                          <QualifiedLeaders
                            descendant={descendant}
                            groupsData={groupsData}
                            onClickDetails={onClickDetails}
                            status={status}
                            biggerThan1000={biggerThan1000}
                            addProgressBarTemplate={addProgressBarTemplate}
                            loading={loading}
                            gap={gap}
                          />
                        ) : (
                          <>
                            {potentialLeaderCards?.groups?.length == 0 &&
                              groupsData?.array?.length == 0 && (
                                <div className=" h-100 flex-column text-center p-3 d-flex justify-content-center align-items-center w-100">
                                  <SVGEmptyStar />
                                  <p className=" museo-sans-500 s20">
                                    <StyledMultiLang
                                      loading={loading}
                                      id="dashboard.qualifications.empty.title"
                                    />
                                  </p>
                                  <p className=" grayColor museo-sans-500 s14 ">
                                    <StyledMultiLang
                                      loading={loading}
                                      id="dashboard.qualifications.empty.subtitle"
                                    />
                                  </p>
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {potentialDataError ? (
                  <>
                    <div className="d-flex flex-row museo-sans-500 s14 justify-content-left align-items-center gap-1 py-3">
                      <FormattedMessage
                        id="potential.leaders"
                        defaultMessage={"Potenciales líderes"}
                      />
                      <i
                        data-bs-toggle="modal"
                        data-bs-target="#potentialLeader"
                        className="pointer"
                      >
                        <HelpIconNoTarget />
                      </i>
                    </div>
                    <div className=" position-relative w-100 my-3 ">
                      <ShareErrorHandlerServices service={fetchPotential} />
                    </div>
                  </>
                ) : (
                  <>
                    {potentialLeaderCards?.groups?.length > 0 && (
                      <PotentialLeaders
                        descendant={descendant}
                        groupsData={groupsData}
                        onClickDetails={onClickDetails}
                        potentialLeaderCards={potentialLeaderCards}
                        status={status}
                        biggerThan1000={biggerThan1000}
                        loading={loading}
                        level={level}
                        addProgressBarTemplate={addProgressBarTemplate}
                        gap={gap}
                      />
                    )}
                  </>
                )}
              </div>
              <QualifiedFooter
                potentialLeaderCards={potentialLeaderCards}
                descendant={descendant}
                groupsData={groupsData}
                onClickDetails={onClickDetails}
                status={status}
                biggerThan1000={biggerThan1000}
                loading={loading}
                level={level}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function QualifiedExtra({
  descendant,
  scoreData,
  loading,
  root,
  onHistory,
  level,
  gap,
  reduced,
  addProgressBar,
  scoreDataPotentialLeader,
}) {
  const reducedColor = { color: "#D18DEF" };
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const datasd = useSelector((state) => state?.businessData?.data);
  const scoreDataLeader = useSelector((state) => state?.scoreData);

  const controlDescHistory = (router, boolean, index) => {
    if (boolean) {
      let newRouter = "";
      if (index == 1) {
        newRouter = router.replace(
          "/performance/personalSales",
          `/otherUserSales/${root}/personalSales`
        );
      }
      if (index == 2) {
        newRouter = router.replace(
          "/performance/personalIncorporated",
          `/otherUserSales/${root}/personalIncorporated`
        );
      }
      if (index == 3) {
        newRouter = router.replace(
          "/performance/groupSales",
          `/otherUserSales/${root}/groupSales`
        );
      }
      if (index == 4) {
        newRouter = router.replace(
          "/performance/descendantSales",
          `/otherUserSales/${root}/descendantSales`
        );
      }
      onHistory(newRouter);
    } else {
      onHistory(router);
    }
  };

  return (
    <>
      <div className=" p-3 px-4 mx-2 w-100">
        <div>
          <h4 className=" m-0 s16 museo-sans-700 text-uppercase ">
            <StyledMultiLang
              loading={loading}
              id="dashboard.qualification.personal.title"
              def={"Personal"}
            />
          </h4>
        </div>
        <div className=" d-flex flex-column justify-content-between h-100 pb-3">
          {level == 1 ? (
            <div>
              <div
                onClick={() =>
                  controlDescHistory(
                    "/dashboard/performance/personalSales",
                    descendant,
                    1
                  )
                }
                className=" pointer my-4 py-1"
              >
                <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                  <p>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualification.ul.personal.sale"
                    />
                  </p>
                  <div className="   d-flex dm-sans-medium gap-1">
                    <p className=" ">
                      {formatAmount(
                        scoreDataPotentialLeader?.personalSales?.actualAmount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                    <p className=" grayColor">/</p>
                    <p className=" grayColor">
                      {formatAmount(
                        scoreDataPotentialLeader?.personalSales?.totalAmount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                    <p className=" pointer ps-1">
                      <SVGChevron />
                    </p>
                  </div>
                </div>
                <ProgressBarParams
                  height={12}
                  level={level}
                  status={scoreDataPotentialLeader?.personalSales?.status}
                  incompleted={
                    scoreDataPotentialLeader?.personalSales?.actualAmount <
                    scoreDataPotentialLeader?.personalSales?.totalAmount
                  }
                  gap={gap}
                  completed={
                    (scoreDataPotentialLeader?.personalSales?.actualAmount *
                      100) /
                    scoreDataPotentialLeader?.personalSales?.totalAmount
                  }
                />
              </div>
              <div
                onClick={() =>
                  controlDescHistory(
                    "/dashboard/performance/personalIncorporated",
                    descendant,
                    2
                  )
                }
                className=" pointer my-4 py-1"
              >
                <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                  <p>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualification.ul.incorporated"
                    />
                  </p>
                  <div className="   d-flex dm-sans-medium gap-1">
                    <p className=" ">
                      {
                        scoreDataPotentialLeader?.incorporatedSales
                          ?.actualActives
                      }
                    </p>
                    <p className=" grayColor">/</p>
                    {reduced ? (
                      <div className=" gap-1 d-flex">
                        <p style={reducedColor} className=" grayColor">
                          {
                            scoreDataPotentialLeader?.incorporatedSales
                              ?.totalNeed
                          }
                        </p>
                        <div className="" style={{ width: "14px" }}>
                          <SVGStarReduce />
                        </div>
                      </div>
                    ) : (
                      <p className=" grayColor">
                        {scoreDataPotentialLeader?.incorporatedSales?.totalNeed}
                      </p>
                    )}
                    <p className=" ps-1">
                      <SVGChevron />
                    </p>
                  </div>
                </div>
                <div className=" d-flex gap-1">
                  {addProgressBar(
                    scoreDataPotentialLeader?.incorporatedSales?.actualActives,
                    scoreDataPotentialLeader?.incorporatedSales?.totalNeed,
                    level,
                    scoreDataPotentialLeader?.incorporatedSales?.status
                  ).map((JSX) => {
                    return JSX;
                  })}
                </div>
              </div>
              <div
                onClick={() =>
                  controlDescHistory(
                    "/dashboard/performance/groupSales",
                    descendant,
                    3
                  )
                }
                className={` ${
                  level > 1 ? "pointer" : "pointer-events-none"
                } my-4 py-1`}
              >
                <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                  <p>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualification.ul.group.sale"
                    />
                  </p>
                  <div className="   d-flex dm-sans-medium gap-1">
                    <p className=" ">
                      {formatAmount(
                        scoreDataPotentialLeader?.groupSales?.actualAmount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                    <p className=" grayColor">/</p>
                    {reduced ? (
                      <div className=" gap-1 d-flex">
                        <p style={reducedColor} className="grayColor">
                          {formatAmount(
                            scoreDataPotentialLeader?.groupSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        <div className="" style={{ width: "14px" }}>
                          <SVGStarReduce />
                        </div>
                      </div>
                    ) : (
                      <p className="grayColor">
                        {formatAmount(
                          scoreDataPotentialLeader?.groupSales?.totalAmount,
                          cultureInfo?.languageCode,
                          cultureInfo?.defaultCurrencyCode
                        )}
                      </p>
                    )}
                    {level > 1 ? (
                      <p className=" pointer ps-1">
                        <SVGChevron />
                      </p>
                    ) : (
                      <p className=" invisible pointer ps-1">
                        <SVGChevron />
                      </p>
                    )}
                  </div>
                </div>
                <ProgressBarParams
                  height={12}
                  level={level}
                  status={scoreDataPotentialLeader?.groupSales?.status}
                  incompleted={
                    scoreDataPotentialLeader?.groupSales?.actualAmount <
                    scoreDataPotentialLeader?.groupSales?.totalAmount
                  }
                  gap={gap}
                  completed={
                    (scoreDataPotentialLeader?.groupSales?.actualAmount * 100) /
                    scoreDataPotentialLeader?.groupSales?.totalAmount
                  }
                />
              </div>
              {level >= 6 && (
                <div
                  onClick={() =>
                    controlDescHistory(
                      "/dashboard/performance/descendantSales",
                      descendant,
                      4
                    )
                  }
                  className=" pointer my-4 py-1"
                >
                  <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                    <p>
                      <StyledMultiLang
                        loading={loading}
                        id="dashboard.qualification.ul.sale.desc"
                      />
                    </p>
                    <div className="   d-flex dm-sans-medium gap-1">
                      <p className=" ">
                        {formatAmount(
                          scoreDataPotentialLeader?.descentSales?.actualAmount,
                          cultureInfo?.languageCode,
                          cultureInfo?.defaultCurrencyCode
                        )}
                      </p>
                      <p className=" grayColor">
                        /{" "}
                        {formatAmount(
                          scoreDataPotentialLeader?.descentSales?.totalAmount,
                          cultureInfo?.languageCode,
                          cultureInfo?.defaultCurrencyCode
                        )}
                      </p>
                      <p className=" pointer ps-1">
                        <SVGChevron />
                      </p>
                    </div>
                  </div>
                  <ProgressBarParams
                    height={12}
                    level={level}
                    status={scoreDataPotentialLeader?.descentSales?.status}
                    incompleted={
                      scoreDataPotentialLeader?.descentSales?.actualAmount <
                      scoreDataPotentialLeader?.descentSales?.totalAmount
                    }
                    completed={
                      (scoreDataPotentialLeader?.descentSales?.actualAmount *
                        100) /
                      scoreDataPotentialLeader?.descentSales?.totalAmount
                    }
                    gap={gap}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div
                onClick={() =>
                  controlDescHistory(
                    "/dashboard/performance/personalSales",
                    descendant,
                    1
                  )
                }
                className=" pointer my-4 py-1"
              >
                <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                  <p>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualification.ul.personal.sale"
                    />
                  </p>
                  <div className="   d-flex dm-sans-medium gap-1">
                    <p className=" ">
                      {scoreDataLeader?.consultantStatus?.potentialLeader ? (
                        <>
                          {formatAmount(
                            datasd?.personalSales?.actualAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      ) : (
                        <>
                          {formatAmount(
                            scoreData?.personalSales?.actualAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      )}
                    </p>
                    <p className=" grayColor">/</p>
                    <p className=" grayColor">
                      {scoreDataLeader?.consultantStatus?.potentialLeader ? (
                        <>
                          {formatAmount(
                            datasd?.personalSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      ) : (
                        <>
                          {formatAmount(
                            scoreData?.personalSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      )}
                    </p>
                    <p className=" pointer ps-1">
                      <SVGChevron />
                    </p>
                  </div>
                </div>
                {scoreDataLeader?.consultantStatus?.potentialLeader ? (
                  <ProgressBarParams
                    height={12}
                    level={level}
                    status={datasd?.personalSales?.status}
                    incompleted={
                      datasd?.personalSales?.actualAmount <
                      datasd?.personalSales?.totalAmount
                    }
                    gap={gap}
                    completed={
                      (datasd?.personalSales?.actualAmount * 100) /
                      datasd?.personalSales?.totalAmount
                    }
                  />
                ) : (
                  <ProgressBarParams
                    height={12}
                    level={level}
                    status={scoreData?.personalSales?.status}
                    incompleted={
                      scoreData?.personalSales?.actualAmount <
                      scoreData?.personalSales?.totalAmount
                    }
                    gap={gap}
                    completed={
                      (scoreData?.personalSales?.actualAmount * 100) /
                      scoreData?.personalSales?.totalAmount
                    }
                  />
                )}
              </div>
              <div
                onClick={() =>
                  controlDescHistory(
                    "/dashboard/performance/personalIncorporated",
                    descendant,
                    2
                  )
                }
                className=" pointer my-4 py-1"
              >
                <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                  <p>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualification.ul.incorporated"
                    />
                  </p>
                  <div className="   d-flex dm-sans-medium gap-1">
                    <p className=" ">
                      {scoreData?.incorporatedSales?.actualActives}
                    </p>
                    <p className=" grayColor">/</p>
                    {reduced ? (
                      <div className=" gap-1 d-flex">
                        <p style={reducedColor} className=" grayColor">
                          {scoreData?.incorporatedSales?.totalNeed}
                        </p>
                        <div className="" style={{ width: "14px" }}>
                          <SVGStarReduce />
                        </div>
                      </div>
                    ) : (
                      <p className=" grayColor">
                        {scoreData?.incorporatedSales?.totalNeed}
                      </p>
                    )}
                    <p className=" ps-1">
                      <SVGChevron />
                    </p>
                  </div>
                </div>
                <div className=" d-flex gap-1">
                  {addProgressBar(
                    scoreData?.incorporatedSales?.actualActives,
                    scoreData?.incorporatedSales?.totalNeed,
                    level,
                    scoreData?.incorporatedSales?.status
                  ).map((JSX) => {
                    return JSX;
                  })}
                </div>
              </div>
              <div
                onClick={() =>
                  controlDescHistory(
                    "/dashboard/performance/groupSales",
                    descendant,
                    3
                  )
                }
                className={` ${
                  level > 1 ? "pointer" : "pointer-events-none"
                } my-4 py-1`}
              >
                <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                  <p>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualification.ul.group.sale"
                    />
                  </p>
                  <div className="    d-flex dm-sans-medium gap-1">
                    <p className=" ">
                      {scoreDataLeader?.consultantStatus?.potentialLeader ===
                      true ? (
                        <>
                          {formatAmount(
                            datasd?.groupSales?.actualAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      ) : (
                        <>
                          {formatAmount(
                            scoreData?.groupSales?.actualAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </>
                      )}
                    </p>
                    <p className=" grayColor">/</p>
                    {reduced ? (
                      <div className=" gap-1 d-flex">
                        <p style={reducedColor} className="grayColor">
                          {scoreDataLeader?.consultantStatus
                            ?.potentialLeader === true ? (
                            <>
                              {formatAmount(
                                datasd?.groupSales?.totalAmount,
                                cultureInfo?.languageCode,
                                cultureInfo?.defaultCurrencyCode
                              )}
                            </>
                          ) : (
                            <>
                              {formatAmount(
                                scoreData?.groupSales?.totalAmount,
                                cultureInfo?.languageCode,
                                cultureInfo?.defaultCurrencyCode
                              )}
                            </>
                          )}
                        </p>
                        <div className="" style={{ width: "14px" }}>
                          <SVGStarReduce />
                        </div>
                      </div>
                    ) : (
                      <p className="grayColor">
                        {scoreDataLeader?.consultantStatus?.potentialLeader ===
                        true ? (
                          <>
                            {formatAmount(
                              datasd?.groupSales?.totalAmount,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </>
                        ) : (
                          <>
                            {formatAmount(
                              scoreData?.groupSales?.totalAmount,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </>
                        )}
                      </p>
                    )}

                    <p className=" pointer ps-1">
                      <SVGChevron />
                    </p>
                  </div>
                </div>
                {scoreDataLeader?.consultantStatus?.potentialLeader === true ? (
                  <ProgressBarParams
                    height={12}
                    level={level}
                    status={datasd?.groupSales?.status}
                    incompleted={
                      datasd?.groupSales?.actualAmount <
                      datasd?.groupSales?.totalAmount
                    }
                    gap={gap}
                    completed={
                      (datasd?.groupSales?.actualAmount * 100) /
                      datasd?.groupSales?.totalAmount
                    }
                  />
                ) : (
                  <ProgressBarParams
                    height={12}
                    level={level}
                    status={scoreData?.groupSales?.status}
                    incompleted={
                      scoreData?.groupSales?.actualAmount <
                      scoreData?.groupSales?.totalAmount
                    }
                    gap={gap}
                    completed={
                      (scoreData?.groupSales?.actualAmount * 100) /
                      scoreData?.groupSales?.totalAmount
                    }
                  />
                )}
              </div>
              {level >= 6 && (
                <div
                  onClick={() =>
                    controlDescHistory(
                      "/dashboard/performance/descendantSales",
                      descendant,
                      4
                    )
                  }
                  className=" pointer my-4 py-1"
                >
                  <div className=" py-1 museo-sans-500 s14 d-flex justify-content-between">
                    <p>
                      <StyledMultiLang
                        loading={loading}
                        id="dashboard.qualification.ul.sale.desc"
                      />
                    </p>
                    <div className="   d-flex dm-sans-medium gap-1">
                      <p className=" ">
                        {formatAmount(
                          scoreData?.descentSales?.actualAmount,
                          cultureInfo?.languageCode,
                          cultureInfo?.defaultCurrencyCode
                        )}
                      </p>
                      <p className=" grayColor">
                        /{" "}
                        {formatAmount(
                          scoreData?.descentSales?.totalAmount,
                          cultureInfo?.languageCode,
                          cultureInfo?.defaultCurrencyCode
                        )}
                      </p>
                      <p className=" pointer ps-1">
                        <SVGChevron />
                      </p>
                    </div>
                  </div>
                  <ProgressBarParams
                    height={12}
                    level={level}
                    status={scoreData?.descentSales?.status}
                    incompleted={
                      scoreData?.descentSales?.actualAmount <
                      scoreData?.descentSales?.totalAmount
                    }
                    completed={
                      (scoreData?.descentSales?.actualAmount * 100) /
                      scoreData?.descentSales?.totalAmount
                    }
                    gap={gap}
                  />
                </div>
              )}
            </div>
          )}

          <div className="mb-3">
            {!descendant && (
              <Link
                className=" s14 skyColor museo-sans-700 pointer button-hover-light-text button-hover-svg-light-stroke d-flex align-items-center gap-2"
                to="account"
              >
                <StyledMultiLang
                  loading={loading}
                  id="dashboard.qualification.view.history.sale"
                />{" "}
                <SVGRightArrow />{" "}
              </Link>
            )}
          </div>
        </div>
      </div>
      {level > 1 && (
        <div style={{ width: "1px" }} className=" my-3 mx-3 brightGrayBg ">
          <p className=" invisible">.</p>
        </div>
      )}
    </>
  );
}

export function QualifiedLeaders({
  descendant,
  groupsData,
  onClickDetails,
  status,
  biggerThan1000,
  loading,
  gap,
  addProgressBarTemplate,
}) {
  
  const JSXUnknownText = (
    <div className=" h-100 flex-column text-center p-3 d-flex justify-content-center align-items-center w-100">
      <SVGEmptyStar />
      <p className=" museo-sans-500 s20">
        <StyledMultiLang
          loading={loading}
          id="dashboard.qualifications.empty.title"
        />
      </p>
      <p className=" grayColor museo-sans-500 s14 ">
        <StyledMultiLang
          loading={loading}
          id="dashboard.qualifications.empty.subtitle"
        />
      </p>
    </div>
  );

  return (
    <>
      <div className=" pb-4 d-flex flex-row museo-sans-500 s14 justify-content-left align-items-center gap-1 pt-3">
        <FormattedMessage
          id="qualified.leaders"
          defaultMessage={"Líderes Calificadas"}
        />
        <i
          data-bs-toggle="modal"
          data-bs-target="#qualifiedLeader"
          className="pointer"
        >
          <HelpIconNoTarget />
        </i>
      </div>
      <>
        {descendant ? (
          <>
            {groupsData?.array && groupsData?.array?.length !== 0 ? (
              <div className=" dashboard-group-min-width dashboard-group-gap d-flex flex-wrap">
                {groupsData?.array.map((member) => {
                  return (
                    <div
                      id={member.clientId}
                      onClick={() => onClickDetails(member.clientId)}
                      data-bs-toggle="modal"
                      data-bs-target="#modalQualificationFirstGenA"
                      style={{ width: "62px" }}
                      className="position-relative mx-2 pointer text-center d-flex flex-column"
                    >
                      <div className="d-flex flex-column gap-2">
                        <ProgressBarParams
                          height={6}
                          level={member?.profileResume?.level}
                          status={member?.personalSales?.status}
                          incompleted={
                            member?.personalSales?.actualAmount <
                            member?.personalSales?.totalAmount
                          }
                          completed={
                            (member?.personalSales?.actualAmount * 100) /
                            member?.personalSales?.totalAmount
                          }
                          gap={gap}
                        />
                        <div className="d-flex gap-1">
                          {addProgressBarTemplate(
                            member?.incorporatedSales?.actualActives,
                            member?.incorporatedSales?.totalNeed,
                            member?.profileResume?.level,
                            member?.incorporatedSales?.status
                          )?.map((JSX) => {
                            return JSX;
                          })}
                        </div>
                        <ProgressBarParams
                          height={6}
                          level={member?.profileResume?.level}
                          status={member?.groupSales?.status}
                          gap={gap}
                          incompleted={
                            member?.groupSales?.actualAmount <
                            member?.groupSales?.totalAmount
                          }
                          completed={
                            (member?.groupSales?.actualAmount * 100) /
                            member?.groupSales?.totalAmount
                          }
                        />
                        <div
                          style={{
                            right: "-5px",
                            bottom: "28px",
                            borderRadius: "50%",
                          }}
                          className=" position-absolute bg-white outline-profile-picture"
                        >
                          {checkReduceRequirement(
                            member?.consultantStatus?.reducedRequirements
                          ) && (
                            <div
                              className=" position-relative"
                              style={{
                                top: "-8px",
                                width: "12px",
                                height: "12px",
                              }}
                            >
                              <SVGStarReduce />
                            </div>
                          )}
                        </div>
                      </div>
                      <p
                        style={{ maxHeight: "38px" }}
                        className=" py-1 s12 museo-sans-500 overflow-hidden "
                      >
                        {member?.profileResume?.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>{JSXUnknownText}</>
            )}
          </>
        ) : (
          <>
            {biggerThan1000 ? (
              <>
                {groupsData?.array && groupsData?.array?.length !== 0 ? (
                  <div className=" dashboard-group-min-width dashboard-group-gap  d-flex flex-wrap">
                    {groupsData?.array?.map((member) => {
                      return (
                        <div
                          id={member.clientId}
                          onClick={() => onClickDetails(member.clientId)}
                          data-bs-toggle="modal"
                          data-bs-target="#modalQualificationFirstGenA"
                          style={{ width: "62px" }}
                          className=" position-relative mx-2 pointer text-center d-flex flex-column"
                        >
                          {checkReduceRequirement(
                            member?.consultantStatus?.reducedRequirements
                          ) && (
                            <div
                              style={{
                                right: "-5px",
                                bottom: "28px",
                                borderRadius: "50%",
                              }}
                              className=" position-absolute bg-white outline-profile-picture"
                            >
                              {checkReduceRequirement(
                                member?.consultantStatus?.reducedRequirements
                              ) && (
                                <div
                                  className=" position-relative"
                                  style={{
                                    top: "-8px",
                                    width: "12px",
                                    height: "12px",
                                  }}
                                >
                                  <SVGStarReduce />
                                </div>
                              )}
                            </div>
                          )}
                          <div className="d-flex flex-column gap-2">
                            <ProgressBarParams
                              height={6}
                              level={member?.profileResume?.level}
                              status={member?.personalSales?.status}
                              incompleted={
                                member?.personalSales?.actualAmount <
                                member?.personalSales?.totalAmount
                              }
                              completed={
                                (member?.personalSales?.actualAmount * 100) /
                                member?.personalSales?.totalAmount
                              }
                              gap={gap}
                            />
                            <div className="d-flex gap-1">
                              {addProgressBarTemplate(
                                member?.incorporatedSales?.actualActives,
                                member?.incorporatedSales?.totalNeed,
                                member?.profileResume?.level,
                                member?.incorporatedSales?.status
                              )?.map((JSX) => {
                                return JSX;
                              })}
                            </div>
                            <ProgressBarParams
                              height={6}
                              level={member?.profileResume?.level}
                              status={member?.groupSales?.status}
                              gap={gap}
                              incompleted={
                                member?.groupSales?.actualAmount <
                                member?.groupSales?.totalAmount
                              }
                              completed={
                                (member?.groupSales?.actualAmount * 100) /
                                member?.groupSales?.totalAmount
                              }
                            />
                          </div>
                          <p
                            style={{ maxHeight: "38px" }}
                            className=" py-1 s12 museo-sans-500 overflow-hidden "
                          >
                            {member?.profileResume?.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>{JSXUnknownText}</>
                )}
              </>
            ) : (
              <>
                {groupsData?.array && groupsData?.array?.length !== 0 ? (
                  <div className=" dashboard-group-min-width dashboard-group-gap  d-flex flex-wrap">
                    {groupsData?.array?.map((member) => {
                      return (
                        <div
                          id={member.clientId}
                          onClick={() => onClickDetails(member.clientId)}
                          data-bs-toggle="modal"
                          data-bs-target="#modalQualificationFirstGenA"
                          style={{ width: "62px" }}
                          className=" position-relative mx-2 pointer text-center d-flex flex-column"
                        >
                          <div
                            style={{
                              right: "-5px",
                              bottom: "28px",
                              borderRadius: "50%",
                            }}
                            className=" position-absolute bg-white outline-profile-picture"
                          >
                            {checkReduceRequirement(
                              member?.consultantStatus?.reducedRequirements
                            ) && (
                              <div
                                className=" position-relative"
                                style={{
                                  top: "-8px",
                                  width: "12px",
                                  height: "12px",
                                }}
                              >
                                <SVGStarReduce />
                              </div>
                            )}
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <ProgressBarParams
                              height={6}
                              level={member?.profileResume?.level}
                              status={member?.personalSales?.status}
                              completed={
                                (member?.personalSales?.actualAmount * 100) /
                                member?.personalSales?.totalAmount
                              }
                              incompleted={
                                member?.personalSales?.actualAmount <
                                member?.personalSales?.totalAmount
                              }
                              gap={gap}
                            />
                            <div className="d-flex gap-1">
                              {addProgressBarTemplate(
                                member?.incorporatedSales?.actualActives,
                                member?.incorporatedSales?.totalNeed,
                                member?.profileResume?.level,
                                member?.incorporatedSales?.status
                              )?.map((JSX) => {
                                return JSX;
                              })}
                            </div>
                            <ProgressBarParams
                              height={6}
                              level={member?.profileResume?.level}
                              status={member?.groupSales?.status}
                              gap={gap}
                              incompleted={
                                member?.groupSales?.actualAmount <
                                member?.groupSales?.totalAmount
                              }
                              completed={
                                (member?.groupSales?.actualAmount * 100) /
                                member?.groupSales?.totalAmount
                              }
                            />
                          </div>
                          <p
                            style={{ maxHeight: "38px" }}
                            className=" py-1 s12 museo-sans-500 overflow-hidden "
                          >
                            {member?.profileResume?.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>{JSXUnknownText}</>
                )}
              </>
            )}
          </>
        )}
      </>
      <div className="pb-4 mb-3"></div>
    </>
  );
}

export function PotentialLeaders({
  onClickDetails,
  potentialLeaderCards,
  level,
  addProgressBarTemplate,
  gap,
}) {
  return (
    <>
      <div className="pb-4">
        {potentialLeaderCards?.groups?.length != 0 && (
          <div className=" ">
            <div className="d-flex flex-row museo-sans-500 s14 justify-content-left align-items-center gap-1">
              <FormattedMessage
                id="potential.leaders"
                defaultMessage={"Potenciales líderes"}
              />
              <i
                data-bs-toggle="modal"
                data-bs-target="#potentialLeader"
                className="pointer"
              >
                <HelpIconNoTarget />
              </i>
            </div>
          </div>
        )}
      </div>
      <>
        {potentialLeaderCards?.groups &&
        potentialLeaderCards?.groups?.length != 0 ? (
          <div className=" dashboard-group-min-width dashboard-group-gap d-flex flex-wrap">
            {potentialLeaderCards?.groups?.map((member) => {
              return (
                <div
                  id={member.clientId}
                  onClick={() => onClickDetails(member.clientId, "potential")}
                  data-bs-toggle="modal"
                  data-bs-target="#modalQualificationFirstGenB"
                  style={{ width: "62px" }}
                  className=" position-relative mx-2 pointer text-center d-flex flex-column"
                >
                  <div
                    style={{
                      right: "-5px",
                      bottom: "28px",
                      borderRadius: "50%",
                    }}
                    className=" position-absolute bg-white outline-profile-picture"
                  >
                    {checkReduceRequirement(
                      member?.consultantStatus?.reducedRequirements
                    ) && (
                      <div
                        className=" position-relative"
                        style={{
                          top: "-8px",
                          width: "12px",
                          height: "12px",
                        }}
                      >
                        <SVGStarReduce />
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <ProgressBarParams
                      height={6}
                      level={member?.profileResume?.level}
                      status={member?.personalSales?.status}
                      incompleted={
                        member?.personalSales?.actualAmount <
                        member?.personalSales?.totalAmount
                      }
                      completed={
                        (member?.personalSales?.actualAmount * 100) /
                        member?.personalSales?.totalAmount
                      }
                      gap={gap}
                    />
                    <div className="d-flex gap-1">
                      {addProgressBarTemplate(
                        member?.incorporatedSales?.actualActives,
                        member?.incorporatedSales?.totalNeed,
                        member?.profileResume?.level,
                        member?.incorporatedSales?.status
                      )?.map((JSX) => {
                        return JSX;
                      })}
                    </div>
                    <ProgressBarParams
                      height={6}
                      level={member?.profileResume?.level}
                      status={member?.groupSales?.status}
                      gap={gap}
                      incompleted={
                        member?.groupSales?.actualAmount <
                        member?.groupSales?.totalAmount
                      }
                      completed={
                        (member?.groupSales?.actualAmount * 100) /
                        member?.groupSales?.totalAmount
                      }
                    />
                  </div>
                  <p
                    style={{ maxHeight: "38px" }}
                    className=" py-1 s12 museo-sans-500 overflow-hidden "
                  >
                    {member?.profileResume?.name}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {level == 2 && (
              <div className=" h-100 flex-column text-center p-3 d-flex justify-content-center align-items-center w-100">
                <p className=" museo-sans-500 s20 pb-2">
                  <FormattedMessage id="dashboard.qualifications.potential.leader.empty.title" />
                </p>
                <p className=" grayColor museo-sans-500 s14 ">
                  <FormattedMessage id="dashboard.qualifications.potential.leader.empty.p" />
                </p>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export function QualifiedFooter({
  descendant,
  groupsData,
  biggerThan1000,
  potentialLeaderCards,
  level,
}) {
  return (
    <>
      {level > 2 ? (
        <>
          {descendant ? (
            <></>
          ) : (
            <>
              {groupsData?.array &&
                groupsData?.array.length !== 0 &&
                (biggerThan1000 ? (
                  <div
                    className="mb-3 d-flex align-items-end   "
                    style={{ paddingTop: "40px" }}
                  >
                    <Link
                      style={{ bottom: 32 }}
                      className="  position-absolute s14 skyColor museo-sans-700 pointer button-hover-light-text button-hover-svg-light-stroke d-flex align-items-center gap-2"
                      to="/dashboard/performance/qualifiedGroups"
                    >
                      {" "}
                      <FormattedMessage
                        id="dashboard.qualifications.view.groups"
                        defaultMessage={"Ver mis grupos"}
                      />
                      <SVGRightArrow />{" "}
                    </Link>
                  </div>
                ) : (
                  <div
                    className=" mb-3 d-flex align-items-end  "
                    style={{ paddingTop: "40px" }}
                  >
                    <Link
                      style={{ bottom: 32 }}
                      className=" position-absolute s14 skyColor museo-sans-700 pointer button-hover-light-text button-hover-svg-light-stroke d-flex align-items-center gap-2"
                      to="/dashboard/performance/qualifiedGroups"
                    >
                      <FormattedMessage
                        id="dashboard.qualifications.view.groups"
                        defaultMessage={"Ver mis grupos"}
                      />{" "}
                      <SVGRightArrow />{" "}
                    </Link>
                  </div>
                ))}
            </>
          )}
        </>
      ) : (
        <>
          {potentialLeaderCards?.groups?.length != 0 && (
            <>
              {biggerThan1000 ? (
                <div
                  className="mb-3 pb-3 d-flex align-items-end   "
                  style={{ paddingTop: "40px" }}
                >
                  <Link
                    style={{ bottom: 32 }}
                    className="  position-absolute s14 skyColor museo-sans-700 pointer button-hover-light-text button-hover-svg-light-stroke d-flex align-items-center gap-2"
                    to="/dashboard/performance/qualifiedGroups"
                  >
                    {" "}
                    <FormattedMessage
                      id="dashboard.qualifications.view.groups.two"
                      defaultMessage={"Ver mis grupos"}
                    />
                    <SVGRightArrow />{" "}
                  </Link>
                </div>
              ) : (
                <div
                  className=" mb-3 pb-3 d-flex align-items-end  "
                  style={{ paddingTop: "40px" }}
                >
                  <Link
                    style={{ bottom: 32 }}
                    className=" position-absolute s14 skyColor museo-sans-700 pointer button-hover-light-text button-hover-svg-light-stroke d-flex align-items-center gap-2"
                    to="/dashboard/performance/qualifiedGroups"
                  >
                    <FormattedMessage
                      id="dashboard.qualifications.view.groups.two"
                      defaultMessage={"Ver mis grupos"}
                    />{" "}
                    <SVGRightArrow />{" "}
                  </Link>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export function QualifiedHeader({ descendant, groupsData, loading, level }) {
  return (
    <div className=" mb-3 align-items-center justify-content-between d-flex">
      {level > 2 && (
        <div className=" align-items-center d-flex justify-content-between">
          <h4 className=" m-0 s16 museo-sans-700">
            <StyledMultiLang
              loading={loading}
              id="dashboard.qualifications.title.two"
              def={"GRUPOS CALIFICADOS 1RA GENERACIÓN"}
            />
          </h4>
          <i
            data-bs-toggle="modal"
            data-bs-target="#qualifiedGroupsModal"
            className="pointer px-2 d-flex"
          >
            <HelpIconNoTarget size={"sm"} />
          </i>
        </div>
      )}
      {groupsData && groupsData?.array?.length > 0 && (
        <div style={{ minWidth: "40px" }} className="dm-sans-500 d-flex">
          <p> {groupsData?.actualQualified} </p>
          <p> </p>
          <p className=" grayColor">/ {groupsData?.totalNeed}</p>
        </div>
      )}
    </div>
  );
}
