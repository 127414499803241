import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./clientList.css";

import {
  getClientDetails,
  getClientDetailsLinks,
  getClientList,
} from "../../Framework/redux/ClientListSideEffect";
import ClienteListTableBody from "../components/ClientListTableBody";
import ClientListTableHeader from "../components/ClientListTableHeader";
import ClientListRowSkeleton from "../components/skeletons/ClientListRowSkeleton";
import ClientListHeaderSkeleton from "../components/skeletons/ClientListHeaderSkeleton";
import ClientListEmptyData from "../components/ClientListEmpyData";
import ModalClipboard from "../../../common/Share/ModalClipboard";
import {
  closeNotificationDeletePopUp,
  closePostUpdate,
  resetSucessToast,
} from "../../Framework/redux/ClientListAction";
import { FormattedMessage } from "react-intl";
import { ClientListDetailsContentCard } from "../components/ClientListDetailsContent";
import { useMediaPredicate } from "react-media-hook";

function searchByNameOrLastname(array, search) {
  return array.filter(
    (person) =>
      person?.firstName
        ?.split(" ")[0]
        ?.toLowerCase()
        ?.startsWith(search?.toLowerCase()) ||
      person?.firstName
        ?.split(" ")[1]
        ?.toLowerCase()
        ?.startsWith(search?.toLowerCase()) ||
      person?.lastName
        ?.split(" ")[0]
        ?.toLowerCase()
        ?.startsWith(search?.toLowerCase()) ||
      person?.lastName
        ?.split(" ")[1]
        ?.toLowerCase()
        ?.startsWith(search.toLowerCase()) ||
      person?.firstName?.toLowerCase()?.startsWith(search?.toLowerCase()) ||
      person?.lastName?.toLowerCase()?.startsWith(search?.toLowerCase())
  );
}

function sortContactsByLastname(list) {
  return [...list].sort((a, b) => {
    let lastNameA = a?.lastName?.trim()?.toUpperCase();
    let lastNameB = b?.lastName?.trim()?.toUpperCase();

    if (lastNameA < lastNameB) {
      return -1;
    }
    if (lastNameA > lastNameB) {
      return 1;
    }
    return 0;
  });
}

function groupByFirstLetter(list) {
  const auxList = [...list]?.reduce((accumulator, current) => {
    let firstLetter = current?.lastName?.trim()?.charAt(0)?.toLowerCase();

    if (!accumulator[firstLetter]) {
      accumulator[firstLetter] = [];
    }

    accumulator[firstLetter]?.push(current);
    return accumulator;
  }, {});

  return auxList;
}

export default function ClientList() {
  const [search, setSearch] = useState("");
  const [contactCard, setContactCard] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    clientTable,
    clientDetails: { success },
  } = useSelector((state) => state.clientListData);
  const modalDelte = useSelector((state) => state.clientListData.actionDelete);
  const modalPostUpdate = useSelector(
    (state) => state.clientListData.actionPostOrUpdate
  );
  const clientDetails = useSelector(
    (state) => state.clientListData.clientDetails
  );
  const scrollRef = useRef(null);
  const biggerThan1100 = useMediaPredicate("(min-width: 1100px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1400px)");

  useEffect(() => {
    onStartUp();
  }, []);

  const onStartUp = async () => {
    await dispatch(getClientList());
  };

  const onCloseModal = () => {
    dispatch(closeNotificationDeletePopUp());
  };

  const onCloseModalPost = () => {
    dispatch(closePostUpdate());
  };

  const clientListStatus = () => {
    if (clientTable.loader) {
      return (
        <>
          <ClientListHeaderSkeleton />
          {[...Array(6).keys()].map((i) => (
            <ClientListRowSkeleton key={i} />
          ))}
        </>
      );
    } else if (!contacts.length) {
      return <>
      <ClientListTableHeader
            isEmpty={true}
            search={search}
            setSearch={setSearch}
            navigate={navigate}
          />
      <ClientListEmptyData />
      </>
    } else {
      return (
        <>
          <ClientListTableHeader
            search={search}
            setSearch={setSearch}
            navigate={navigate}
          />
          <div className="d-flex w-100 gap-4 ">
                  <div className={` ${(contactCard && !biggerThan1280) ? "w-50" : "w-100" } `}>
                    {Array.isArray(contactsResult) ? (
                      <ClienteListTableBody
                        onCardClick={onClickActivateCard}
                        contactsResult={contactsResult}
                        navigate={navigate}
                        withLastStyle
                      />
                    ) : (
                      Object.keys(contactsResult).map((letter) => (
                        <>
                          <p
                            className="museo-sans-500 s12 mt-5"
                            style={{ color: "#908F9A", alignSelf: "start" }}
                          >
                            {letter.toUpperCase() ?? ""}
                          </p>
                          <ClienteListTableBody
                            onCardClick={onClickActivateCard}
                            contactsResult={contactsResult[letter]}
                            navigate={navigate}
                          />
                        </>
                      ))
                    )}
                  </div>
                  {contactCard && (
                    <div className=" w-50 position-relative d-flex flex-column mt-5 pt-4 ">
                      <div className=" position-fixed " >
                        <div className="d-flex align-items-center gap-2 museo-sans-500 ">
                          <>
                            <p className=" s20 museo-sans-500">
                              <FormattedMessage id="client.details.card.title" />
                            </p>
                            {clientDetails.data?.contactOriginType != "PERSONAL_SITE" && (
                              <>
                                <p
                                  onClick={() => onEditValuesEDB(clientDetails?.data)}
                                  className=" pointer s14 button-hover-light-text museo-sans-700 skyColor px-1 "
                                >
                                  <FormattedMessage id="buttons.edit.data" />
                                </p>
                              </>
                            )}
                          </>
                        </div>
                        
                        <ClientListDetailsContentCard
                          data={clientDetails?.data}
                          loading={clientDetails?.loader}
                          chooseHost={true}
                          socialArray={clientDetails?.data?.socialNetworks}
                        />
                      </div>
                    </div>
                  )}
                </div>
        </>
      );
    }
  };

  const onEditValuesEDB = (data) => {
    navigate(`/dashboard/edbs/form/client/update/${data?.contactId}`);
  };

  const onClickActivateCard = async (id) => {
    setContactCard(true);
    await dispatch(getClientDetails(id));
    await dispatch(getClientDetailsLinks(id));
  };

  const contacts = clientTable.data
    ? sortContactsByLastname(clientTable.data.contacts)
    : [];
  const contactsResult =
    search.length > 2
      ? searchByNameOrLastname(contacts, search)
      : groupByFirstLetter(contacts);

  useEffect(() => {
    if (success?.code) {
      setTimeout(() => {
        dispatch(resetSucessToast());
      }, 3000);
    }
  }, [success]);

  useEffect(() => {
    const myDiv = document.getElementById("container--private-holder");
    myDiv.scrollTop = 0;
  }, []);

  return (
    <div className="container--padding bg-white " ref={scrollRef}>
      <ModalClipboard
        show={modalPostUpdate}
        close={onCloseModalPost}
        title={
          success?.type == "updated" ? "Modificar cliente" : "Crear cliente"
        }
        text={
          success?.type == "updated"
            ? "Cliente modificado con éxito"
            : "¡Cliente creado con éxito!"
        }
      />
      <ModalClipboard
        show={modalDelte}
        close={onCloseModal}
        title={<FormattedMessage id="notification.modal.delete.title.one" />}
        text={<FormattedMessage id="notification.modal.delete.subtitle.one" />}
      />
      <div className=" container--padding--card-holder ">
        <div style={{width: !biggerThan1100 ?  "880px" : ""}} className="pt-4">
          {
            clientListStatus()
          }
        </div>
      </div>
    </div>
  );
}
