import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as ArrowLeftIcon } from "../../../common/svgs/arrow-left-sm.svg";
import { ReactComponent as SVGShare } from "../../../common/svgs/ic-share-sm.svg";
import { ReactComponent as SVGPrint } from "../../../common/svgs/ic-print-sm.svg";
import { ReactComponent as SVGChevronDown } from "../../../common/svgs/ic-chevron-down-sm.svg";
import { ReactComponent as SVGChevronUp } from "../../../common/svgs/ic-chevron-up-sm.svg";
import { ReactComponent as SVGChevronRight } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as SVGEDB } from "../../../common/svgs/ic-edbs.svg";

import { ReactComponent as SVGEmpty } from "../../../common/svgs/Empty-Pedidos-Ordenes.svg";

import { ReactComponent as SVGNotEDB } from "../../../common/svgs/ic-pedido-cliente-sm.svg";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  fetchTrackingOrderId,
  getPersonalSaleDetailsBills,
  getPersonalSaleDetailsOrders,
  getPersonalSaleDetailsOrdersEDB,
  getPersonalSaleDetailsPendings,
  getPersonalSaleDetailsRequest,
} from "../../Framework/redux/performanceSideEffects";

import { calendarCurrentPeriod } from "../../../Calendar/Framework/redux/CalendarSideEffects";

import { useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useRef } from "react";
import PersonalSalesModalOrder from "./Modals/PersonalSalesModalOrder";
import PersonalSalesModalOrderEDB from "./Modals/PersonalSalesModalOrderEDB";
import PersonalSalesWeekSalesModal from "./PersonalSalesWeekSalesModal";

import ModalClipboard from "../../../common/Share/ModalClipboard";
import HandleSwissDates from "../../../common/Share/HandleSwissDate";
import PersonalSalesModalShare from "./Modals/PersonalSalesModalShare";

import ReactToPrint from "react-to-print";
import Loader from "../../../common/Share/Loader";
import PersonalSalesDetailsEmpty from "./PersonalSalesDetailsEmpty";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import DetailsHasShipment from "../../../common/Share/DetailsHasShipment";
import PersonalSalesDetailsOrders from "./PersonalSalesDetailsOrders";

export default function PersonalSalesDetails() {
  const location = useLocation();
  const componentRef = useRef();
  const data = useSelector((state) => state.performanceData.orderDetailsData);
  const hasShipment = useSelector((state) => state.performanceData.orderDetailsHasShipment);
  const loading = useSelector((state) => state.performanceData.loader);
  const weekSalesInfo = useSelector(
    (state) => state.performanceData.salesByWeek
  );

  const currentPeriodInfo = useSelector(
    (state) => state.calendarData.currentPeriodInfo
  );
  const [closeClipModal, setCloseClipModal] = useState(false);
  const profileData = useSelector((state) => state.userInfo);

  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");

  const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)

  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();

  const current = currentPeriodInfo?.periodWeeks.filter((element) => {
    return element.week === currentPeriodInfo?.currentWeek;
  });

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  let currentWeekForModal;
  if (current !== undefined) {
    currentWeekForModal = current[0]?.id;
  }

  const [togglers, setTogglers] = useState({
    togglerOne: false,
    togglerTwo: false,
  });

  const [hideOnPrint, setHideOnPrint] = useState(false);

  useEffect(() => {
    if (params.status == 3) {
      dispatch(getPersonalSaleDetailsBills({ orderId: params.id }));
      dispatch(fetchTrackingOrderId({trackingId: params.id}))
      dispatch(calendarCurrentPeriod());
    }
    if (params.status == 1) {
      dispatch(getPersonalSaleDetailsRequest({ orderId: params.id }));
      dispatch(calendarCurrentPeriod());
    }
    if (params.status == 2) {
      dispatch(getPersonalSaleDetailsPendings({ orderId: params.id }));
      dispatch(calendarCurrentPeriod());
    }



  }, [params.id]);

  const onToggleOne = () => {
    setTogglers({ ...togglers, togglerOne: !togglers.togglerOne });
  };

  let index = 0;

  const onToggleTwo = () => {
    setTogglers({ ...togglers, togglerTwo: !togglers.togglerTwo });
  };

  const fetchOrderDetails = (id) => {
    dispatch(getPersonalSaleDetailsOrders({ orderId: id }));
  };

  const fetchOrderDetailsEDB = (id) => {
    dispatch(getPersonalSaleDetailsOrdersEDB({ orderId: id }));
  };

  const returnToList = () => {
    history(-1);
  };


  const [copyToggler, setCopyToggler] = useState(false);
  const onOpenCopyModal = () => {
    setCopyToggler(true);
  };

  const onPrint = async () => {
    await setTogglers({ togglerOne: true, togglerTwo: true });
    await setHideOnPrint(true);
  };

  const afterOnPrint = async () => {
    await setHideOnPrint(false);
  };

  const onUnknownCharacter = (string) => {
    let adjustedString = string;

    return adjustedString;
  };

  const trueIfOther = () => {
    if(location?.pathname?.indexOf("/dashboard/detailsSales/") != -1) {
      return true;
    } else {
      return false;
    }
  };

  const onCloseCopyModal = (boolean) => {
    setCloseClipModal(boolean ? boolean : false);
  };

  
  return data?.totalProducts !== 0 ? (
    <div className="">
      <PersonalSalesModalShare
        open={onOpenCopyModal}
        socialArray={profileData.userInfo.socialNetwork}
        data={data}
      />
      <PersonalSalesWeekSalesModal
        semanaActual={currentWeekForModal}
        dataVentasSemanales={weekSalesInfo}
      />
      <PersonalSalesModalOrder
        open={onOpenCopyModal}
        socialArray={profileData.userInfo.socialNetwork}
        data={data}
      />
      <PersonalSalesModalOrderEDB
        open={onOpenCopyModal}
        socialArray={profileData.userInfo.socialNetwork}
        data={data}
      />
      {
        // if(location?.pathname?.indexOf("/dashboard/detailsSales/") !== -1)
      }
      <div className={` ${trueIfOther() && "container--padding" }  container--padding--card-holder`} >
        <div>
          <div className=" w-100 my-4 py-2 d-flex justify-content-between">
            <div
              onClick={returnToList}
              className=" pointer museo-sans-700 s14 gap-2 skyColor align-items-end d-flex"
            >
              <ArrowLeftIcon />{" "}
              <FormattedMessage
                id="personal.sales.back.button"
                defaultMessage="Volver a todos los pedidos"
              />
            </div>
            <div className=" d-flex">
              <button
                data-bs-toggle="modal"
                data-bs-target="#modalShareExample"
                className={`${
                  !biggerThan1280 ? "ps-2 pe-2 me-2" : "ps-4 pe-4 me-4"
                } d-flex justify-content-center gap-2 align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding position-relative perf-history-week`}
              >
                <SVGShare />
                <FormattedMessage
                  id="dashboard.share.button.short"
                  defaultMessage="Compatir"
                />
              </button>
              <ReactToPrint
                content={reactToPrintContent}
                removeAfterPrint
                onBeforeGetContent={onPrint}
                onAfterPrint={afterOnPrint}
                trigger={() => {
                  return (
                    <button
                      className={`${
                        !biggerThan1280 ? "ps-2 pe-2 me-2" : "ps-4 pe-4 me-4"
                      } d-flex justify-content-center gap-2 align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding position-relative perf-history-week`}
                    >
                      <SVGPrint />
                      <FormattedMessage
                        id="buttons.print"
                        defaultMessage="Imprimir"
                      />
                    </button>
                  );
                }}
                documentTitle="Details"
                pageStyle="print"
              />
            </div>
          </div>
          <div className=" w-100" ref={componentRef}>
            <div className=" f9GrayBorder position-relative mb-4 p-5 rounded-3 museo-sans-500 cards-shadow">
              <Loader loading={loading} />
              {data && (
                <div className=" ">
                  <div className="d-flex flex-column">
                    <p className=" dm-sans-medium grayColor s12">
                      {" "}
                      <HandleSwissDates date={data.date} />{" "}
                    </p>
                    <h1 className=" my-2 museo-sans-700 s24 ">
                      <FormattedMessage id="personal.sale.details.id.request" />{" "}
                      {data.id}
                    </h1>
                    <div className="s12 text-uppercase d-flex gap-1 ">
                      <p>
                        <FormattedMessage
                          id="personal.sale.details.status"
                          defaultMessage="estado"
                        />
                        :
                      </p>
                      <p
                        className={` ${
                          data.status === "facturado"
                            ? "lightGreenColor"
                            : "darkYellowColor"
                        } `}
                      >
                        {data.status}
                      </p>
                      <p className="  grayColor">|</p>{" "}
                      <p className="">
                        <FormattedMessage id="personal.sale.details.id.bill" />:
                      </p>
                      <p className=" dm-sans-medium grayColor ">
                        {data.invoiceNumber}
                      </p>
                    </div>
                  </div>
                  {
                    params.status == 3 &&
                    <DetailsHasShipment elm={hasShipment} personalSale={true} show={closeClipModal} close={onCloseCopyModal} hideOnPrint={hideOnPrint} />
                  }
                  <hr />
                  <div>
                    <div className=" my-3 museo-sans-700 d-flex justify-content-between">
                      <h2 className=" s16">
                        <FormattedMessage id="personal.sale.details.products.score" />
                        :{" "}
                        <span className=" dm-sans-bold">
                          {data.products?.length ? data.products.length : 0}
                        </span>
                      </h2>
                      {!hideOnPrint && (
                        <div
                          onClick={onToggleOne}
                          className=" pointer d-flex gap-2 align-items-center s14 skyColor"
                        >
                          <FormattedMessage
                            id="personal.sale.details.products.show"
                            defaultMessage="Ver productos"
                          />
                          {togglers.togglerOne ? (
                            <SVGChevronUp />
                          ) : (
                            <SVGChevronDown />
                          )}
                        </div>
                      )}
                    </div>
                    {togglers.togglerOne && (
                      <>
                        {
                          <div className=" my-4">
                            {data.products.map((elm) => {
                              return (
                                <div className=" my-2 d-flex ">
                                  <div
                                    style={{ width: "80px", height: "80px" }}
                                    className=" p-1 rounded-2 border grayBorder position-relative"
                                  >
                                    <div
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        background: "#8889DB",
                                        right: "-12px",
                                        top: "-12px",
                                      }}
                                      className=" rounded-circle dm-sans-medium s12 whiteColor d-flex justify-content-center align-items-center  position-absolute"
                                    >
                                      x{elm.totalItems}
                                    </div>
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      src={elm.img}
                                      href={elm.img}
                                      alt=""
                                    />
                                  </div>
                                  <div className=" px-4 pe-0 w-100 d-flex justify-content-between ">
                                    <div className=" d-flex flex-column">
                                      <p className=" museo-sans-500 s14">
                                        {onUnknownCharacter(elm.title)}
                                      </p>
                                      <p className=" dm-sans-medium s12 grayColor ">
                                        {elm.id}
                                      </p>
                                    </div>
                                    <div className="  d-flex align-items-end flex-column">
                                      <p className=" dm-sans-medium  s14 ">
                                      {formatAmount(elm?.totalPrice,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                      </p>
                                      <div className=" grayColor d-flex s12 gap-1 justify-content-end  ">
                                        <p className="  museo-sans-500 ">
                                          <FormattedMessage id="personal.sale.details.p.u" />
                                          :{" "}
                                        </p>
                                        <p className=" dm-sans-medium   ">
                                        {formatAmount(elm?.price,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        }
                      </>
                    )}
                    <div className=" grayColor s14  museo-sans-500 d-flex justify-content-between">
                      <p>
                        <FormattedMessage id="personal.sale.details.products.sub.total" />
                      </p>
                      <p className=" dm-sans-medium">
                      {data && formatAmount(data?.totalAmountDetail[0].amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div className=" museo-sans-700 d-flex justify-content-between">
                      <h2 className=" s16">
                        <FormattedMessage id="personal.sale.details.taxes" />
                      </h2>
                      {!hideOnPrint && (
                        <div
                          onClick={onToggleTwo}
                          className=" pointer d-flex gap-2 align-items-center s14 skyColor"
                        >
                          <FormattedMessage id="personal.sale.details.detail.show" />
                          {togglers.togglerTwo ? (
                            <SVGChevronUp />
                          ) : (
                            <SVGChevronDown />
                          )}
                        </div>
                      )}
                    </div>
                    {togglers.togglerTwo && (
                      <>
                        {data &&
                          data.totalAmountDetail.map((elm) => {
                            return (
                              <div className=" my-3 grayColor s14  museo-sans-500 d-flex justify-content-between">
                                <p>{elm.description}</p>
                                <p className=" dm-sans-medium">
                                  {" "}
                                  {formatAmount(elm?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                </p>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                  <hr />
                  <div className=" d-flex justify-content-between">
                    <h2 className=" s16">
                      <FormattedMessage id="personal.sale.details.sale.total" />
                    </h2>
                    <p className=" dm-sans-bold s16">
                    {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </p>
                  </div>
                  <div className=" grayColor s14  museo-sans-500 d-flex justify-content-between">
                    <p>
                      <FormattedMessage id="personal.sale.details.sale.total.pvp" />
                    </p>
                    <p className=" dm-sans-medium s14 ">
                    {data && formatAmount(data?.totalPVPAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className=" invisible mb-2">.</div>
            <div className=" position-relative mb-4 pb-2 p-4 f9GrayBg f9GrayBorder rounded-3 museo-sans-500 cards-shadow">
              <Loader loading={loading} />
              {data ? (
                <div className=" p-2 d-flex flex-column ">
                  <h2 className=" m-0 s16 museo-sans-700 mb-4 pb-2 ">
                    <FormattedMessage
                      id="personal.sale.details.orden.total"
                      defaultMessage="Órdenes del pedido"
                    />{" "}
                    :{" "}
                    {data.orders || data.orders.length < 1
                      ? data.orders?.length
                      : 0}
                  </h2>
                  <div className={`s12 mb-4 pb-2 museo-sans-500 text-uppercase grayColor grid--order-details${ biggerThan1280 ? "" : `--smaller`} `}>
                      <p>
                        <FormattedMessage id="personal.sale.details.list.one" />
                      </p>
                      <p>
                        <FormattedMessage id="personal.sale.details.list.two" />
                      </p>
                      <p>
                        <FormattedMessage id="personal.sale.details.list.three" />
                      </p>
                      <p>
                        <FormattedMessage id="personal.sale.details.list.four" />
                      </p>
                      <p>
                        <FormattedMessage id="personal.sale.details.list.five" />
                      </p>
                      <p className=" invisible">. </p>
                    </div>
                  {
                    <PersonalSalesDetailsOrders orders={data?.orders} fetchOrderDetails={fetchOrderDetails} fetchOrderDetailsEDB={fetchOrderDetailsEDB} hideOnPrint={hideOnPrint} biggerThan1280={biggerThan1280} cultureInfo={cultureInfo} formatAmount={formatAmount} HandleSwissDates={HandleSwissDates} SVGEmpty={SVGEmpty} SVGEDB={SVGEDB} SVGNotEDB={SVGNotEDB} SVGChevronRight={SVGChevronRight} data={data} /> 
                  }
                </div>
              ) : (
                <div>
                  <Loader loading={loading} />
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
  ) : (
    <PersonalSalesDetailsEmpty />
  );
}
