import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";

export default function InvitationsDashboardShowCaseRowAll(params) {
  const history = useNavigate();
  const biggerThan1025 = useMediaPredicate("(min-width: 1025px)");
  const biggerThan1290 = useMediaPredicate("(min-width: 1290px)");
  let reduceValue = 4;
  if (!biggerThan1025 && !biggerThan1290) {
    reduceValue = 2;
  }
  if (biggerThan1025 && !biggerThan1290) {
    reduceValue = 2;
  }
  const showRest = () => {
    let value = params?.params?.invitationsCount;
    return (
      <>
        +{value - reduceValue}{" "}
        <FormattedMessage id="invitation.dashboard.show.msg.invitation" />
      </>
    );
  };

  return (
    <div className="d-flex align-items-center">
      <div
        style={{ width: "180px", height: "48px" }}
        className="d-flex flex-row"
      >
        <div
          style={{
            background: "#908F9A",
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            gap: "5px",
          }}
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <div
            style={{
              background: "#FFFFFF",
              borderRadius: "50%",
              width: "6px",
              height: "6px",
            }}
          ></div>
          <div
            style={{
              background: "#FFFFFF",
              borderRadius: "50%",
              width: "6px",
              height: "6px",
            }}
          ></div>
          <div
            style={{
              background: "#FFFFFF",
              borderRadius: "50%",
              width: "6px",
              height: "6px",
            }}
          ></div>
        </div>
        <div style={{ paddingLeft: "8px" }}>
          <div
            style={{ maxWidth: "122px" }}
            className="museo-sans-700 s16 text-nowrap"
          >
            {params?.params?.invitationsCount > reduceValue && (
              <>{showRest()}</>
            )}
          </div>
          <button
            className="museo-sans-700 s14 p-0 border-0 bg-transparent"
            style={{ color: "#5AAFF1" }}
            onClick={() => history("invitations/list")}
          >
            <FormattedMessage id="view.more.female" />
          </button>
        </div>
      </div>
    </div>
  );
}
