import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux';
import HelpIconNoTarget from '../../../../common/Share/HelpIconNoTarget'
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

export default function DashboardFirstStepsDetailsModal  ()  {

  const element = useSelector((state) => state.firstStepsData.elementDetails);

  return (
    <div
    className="modal fade"
    id="modalDetailsInfo"
    aria-hidden="true"
    aria-labelledby="modalDetailsInfo"
    tabIndex="-1"
  >
    <div
      className="modal-dialog modal-dialog-centered"
      style={{ maxWidth: "610px"}}
    >
      <div className="modal-content modal-radius" style={{height:"439px"}}>
        <div className=" modal-body p-0">
          <div>
            <div style={{maxHeight: "112px",paddingBottom: "40px",marginTop: "40px"}} className="d-flex align-items-center justify-content-between px-5">
              <div className="d-flex">
                <HelpIconNoTarget />
                <h5 className="museo-sans-500 px-2 s20 m-0">
                  <FormattedMessage
                    id="dashboard.steps"
                    defaultMessage={"encuentros de bienestar"}
                  />
                </h5>
              </div>
              <div className=" pointer " data-bs-dismiss="modal"
                aria-label="Close">
                  <SVGClose />
              </div>
            </div>
            <hr className="m-0 p-0"></hr>
            <div className='px-4 mx-3 d-flex flex-column gap-3 museo-sans-500 s16' style={{paddingTop: "32px",paddingBottom:"40px"}}>
              <p>
                {element?.nombre}
              </p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
            <div className=" w-100 d-flex justify-content-center">
                <button
                  type="button"
                  className=" btnLoginWidth museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
