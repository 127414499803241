import { ReactComponent as SVGVideo } from "../../../common/svgs/ic-video-sm.svg";
import { FormattedMessage } from "react-intl";

export default function EDBDetailsCardGuest({ data, type, onClick, loading }) {

  return (
    <div
      style={{ borderRadius: "15px" }}
      className="p-4 d-flex flex-column justify-content-between edb-details--cards f9GrayBorder edb-details-card-shadow "
    >
      {
        loading ?
          <SkeletonEDBDetailsGuest />
        :
        <>
        <div>
          <div className=" d-flex">
            <p className=" museo-sans-500 s20 "> 
            {
               type !== "fechado" ?
               <>
                <FormattedMessage id="edb.details.guest.attendees" />
               </>
               :
               <>
                <FormattedMessage id="edb.details.guest.invited" />
               </>
            }
            </p>
          </div>
          <div style={{height: "93.5px"}} id="edb-card-guests-content" className="mt-4 ">
            
            <div className=" d-flex flex-column">
              <p className=" dm-sans-bold  s34  ">
                {data ? data.totalRegistered : "3"}
              </p>
              <p className=" museo-sans-500 s16">
              <FormattedMessage id="edb.details.guest.total" />
              </p>
            </div>
            {
              type !== "fechado" &&
              <>
                <div
                  style={{ width: "1px" }}
                  className=" mx-3 grayBg opacity-25  d-flex flex-column"
                ></div>
                <div className=" px-0 d-flex flex-column">
                  <p className=" dm-sans-bold  s34  ">
                  {data ? data.registeredWithPurchases : "3"}
                  </p>
                  <p className=" museo-sans-500 s16">
                  <FormattedMessage id="edb.details.guest.with.purchase" />
                  </p>
                </div>
              </>
            }
          </div>
        </div>
        <div id="edb-card-links" className=" gap-2 py-1 d-flex flex-column">
          <p 
          onClick={() => onClick("658693857", "c5b977e224","¿Cómo llegar siempre a premio?" )} 
          className=" museo-sans-700 pointer s14 skyColor">
            ¿Cómo llegar siempre a premio? <SVGVideo />
          </p>
          <p
          onClick={() => onClick("660663242", "7bea6dd12e","¿Cómo cargar una orden de EDB?" )}  
          className=" museo-sans-700 pointer s14 skyColor">
            ¿Cómo cargar una orden de EDB? <SVGVideo />
          </p>
        </div>
        
        </>
      }
    </div>
  );
}

export function SkeletonEDBDetailsGuest() {
  return <>
        <div className=" mt-3 d-flex flex-column " >
          <div className="d-flex" >
            <div style={{height: "12px"}}  className="lightui1-shimmer w-75 rounded-3 brightGrayBg" >
  
            </div>
            <div style={{height: "12px"}}  className="lightui1-shimmer invisible w-75 rounded-3 brightGrayBg" >
  
            </div>
            <div style={{height: "12px"}}  className="lightui1-shimmer w-75 rounded-3 brightGrayBg" >
  
            </div>
            <div style={{height: "12px"}}  className="lightui1-shimmer invisible w-75 rounded-3 brightGrayBg" >
  
            </div>
          </div>
          <div className=" mt-4 pt-3 d-flex gap-3 " >
            <div className=" d-flex flex-column gap-3 pe-3 " >
              <div className=" brightGrayBg rounded-circle " style={{height: "38px", width: "38px"}} >

              </div>
              <div style={{height: "8px"}}  className="lightui1-shimmer rounded-3 brightGrayBg" >
  
              </div>
              <div style={{height: "8px"}}  className="lightui1-shimmer  rounded-3 brightGrayBg" >
  
              </div>
            </div>
            <div className=" h-100 brightGrayBg me-3 " style={{width: "1px"}} ></div>
            <div className=" d-flex flex-column gap-3 pe-3 " >
              <div className="lightui1-shimmer brightGrayBg rounded-circle " style={{height: "38px", width: "38px"}} >

              </div>
              <div style={{height: "8px"}}  className="lightui1-shimmer  rounded-3 brightGrayBg" >
  
              </div>
            </div>
            <div className=" d-flex flex-column gap-3 pe-3 " >
              <div className="lightui1-shimmer brightGrayBg rounded-circle " style={{height: "38px", width: "38px"}} >

              </div>
              <div style={{height: "8px"}}  className=" lightui1-shimmer rounded-3 brightGrayBg" >
  
              </div>
            </div>
          </div>
          <div className=" mt-3 d-flex flex-column justify-content-end gap-4 " >
            <div style={{height: "8px"}}  className="lightui1-shimmer invisible w-75 rounded-3 brightGrayBg" >
  
            </div>
            <div style={{height: "8px"}}  className="lightui1-shimmer w-75 rounded-3 brightGrayBg" >
  
            </div>
            <div style={{height: "8px"}}  className="lightui1-shimmer w-75 rounded-3 brightGrayBg" >
  
            </div>
          </div>
        </div>
    </>
}

