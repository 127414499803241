import React from "react";
import { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import Radius from "./../../../common/Share/Radius";

import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-sm.svg";
import { ReactComponent as SVGChevArrowUp } from "../../../common/svgs/ic-chevron-up-sm.svg";
import { ReactComponent as RedWarningIcon } from "../../../common/svgs/ic-red-triangle-warning-with-circle.svg";

import { useDispatch } from "react-redux";
import {
  getPotentialLeaders,
  getQualifiedGroups,
} from "../../Framework/redux/performanceSideEffects";
import { useSelector } from "react-redux";
import { useMediaPredicate } from "react-media-hook";
import QualifiedGroupsCard from "./../components/QualifiedGroupsCard";
import PerformanceLoadingMoreRequests from "../components/PerformanceLoadingMoreRequests";
import QualifiedGroupsModal from "../components/QualifiedGroupsModal";
import NoRegistersMessage from "../../../common/Share/NoRegistersMessage";
import { useNavigate } from "react-router-dom";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export default function QualifiedGroups() {
  const smallerThan1024 = useMediaPredicate("(max-width: 1024px)");

  const qualifiedInfo = useSelector(
    (state) => state.performanceData.qualifiedGroups
  );
  const potentialLeader = useSelector(
    (state) => state.performanceData.potentialLeaders
  );
  const filteredqualifiedInfo = qualifiedInfo?.groups?.filter(
    (element) => element.qualified === false
  );
  const filteredpotentialLeaderInfo = potentialLeader?.groups?.filter(
    (element) => element.qualified === false
  );
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const [toggler, setToggler] = useState(true);

  const dispatch = useDispatch();
  const history = useNavigate();

  const [switchSelector, setSwitchSelector] = useState(false);

  const [orderBySelector, setOrderBySelector] = useState(false);

  const [skeleton, setSkeleton] = useState(true);

  const [ascOrder, setAscOrder] = useState(true);

  function selectOrderAndClose(selected, order) {
    setAscOrder(selected);
    setSwitchSelector(false);
    dispatch(getQualifiedGroups({ order: order }));
    dispatch(getPotentialLeaders({ order: order }));
    setTimeout(() => {
      setOrderBySelector(false);
    }, 250);
  }

  const renderQualifiedGroups = (groups, currency) => {
    return (
      <>
        {groups?.map((element) => {
          return (
            <QualifiedGroupsCard
              key={ShareKeyGenerator()}
              data={element}
              currency={currency}
            />
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (!profileData) {
      history("/dashboard");
    }

    if (profileData.level == 2) {
      setToggler(false);
    }

    dispatch(getQualifiedGroups(null));
    dispatch(getPotentialLeaders());

    setTimeout(() => {
      setSkeleton(false);
    }, 1000);
  }, [profileData]);

  const onToggler = (boolean) => {
    setToggler(!boolean);
  };

  return qualifiedInfo ? (
    <div className="container--padding--card-holder">
      <div>
        <div className=" w-100 d-flex justify-content-between flex-column">
          <QualifiedGroupsModal
            cantidadNecesaria={toggler ? qualifiedInfo?.totalNeed : potentialLeader?.totalNeed}
            rol={profileData?.roleDescription}
          />
          {
            // ORDER DES ASC - START
          }
          <div className=" w-100 d-flex justify-content-between mt-3 mb-3">
            <div className="d-flex flex-wrap  justify-items-center align-items-center p-1">
              {profileData?.level != 2 && (
                <>
                  <div className="d-flex flex-wrap justify-items-center align-items-center lightVioletBg p-1 rounded-3">
                    <div
                      onClick={() => onToggler(false)}
                      className={`${toggler ? "bronceBg" : "hover-pointer"
                        } rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center`}
                    >
                      <p className="ms-2 museo-sans-700 s14 lightBlueColor user-select-none">
                        <FormattedMessage id="qualified.leaders" />
                      </p>
                    </div>
                    <div
                      onClick={() => onToggler(true)}
                      className={`${!toggler ? "bronceBg" : "hover-pointer"
                        } rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center`}
                    >
                      <p className="ms-2 museo-sans-700 s14 lightBlueColor user-select-none">
                        <FormattedMessage id="potential.leaders" />
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "24px" }}></div>
                  <>
                    {skeleton ? (
                      <div
                        className={`hide-qualified-switch rounded-pill position-relative d-flex align-items-center lightGreyBg`}
                      >
                        <div
                          className={`position-absolute bronceBg rounded-circle ${switchSelector
                              ? "qualified-switch-true"
                              : "qualified-switch-false"
                            }`}
                        ></div>
                      </div>
                    ) : (
                      <div
                        onClick={() => setSwitchSelector(!switchSelector)}
                        className={`hide-qualified-switch hover-pointer rounded-pill position-relative d-flex align-items-center ${switchSelector ? "skyBg" : "brightSkyBg"
                          }`}
                      >
                        <div
                          className={`position-absolute bronceBg rounded-circle ${switchSelector
                              ? "qualified-switch-true"
                              : "qualified-switch-false"
                            }`}
                        ></div>
                      </div>
                    )}
                    <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                      <FormattedMessage
                        id="qualified.groups.hide"
                        defaultMessage="Ocultar grupos calificados"
                      />
                    </p>
                  </>
                </>
              )}
            </div>
            <div
              className={`d-flex align-items-center position-relative ${orderBySelector && "qualified-tooltip-container"
                } `}
            >
              <button
                onClick={() => setOrderBySelector(!orderBySelector)}
                className={`${true ? "ps-2 pe-2" : "ps-4 pe-4"
                  } d-flex gap-2 justify-content-center align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding position-relative`}
                style={{ height: "40px" }}
              >
                <FormattedMessage
                  id="qualified.groups.order.by.new"
                  defaultMessage="Ordenar por"
                />
                {orderBySelector ? <SVGChevArrowUp /> : <SVGChevArrowDown />}
              </button>
              <div
                id="qualifiedGroupsOrderBy"
                className="order-select-tooltip position-absolute bronceBg"
              >
                <div
                  className="d-flex hover-pointer"
                  onClick={() => selectOrderAndClose(true, "asc")}
                >
                  <Radius status={ascOrder} />
                  <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                    <FormattedMessage
                      id={
                        smallerThan1024
                          ? "qualified.groups.asc.small"
                          : "qualified.groups.asc"
                      }
                      defaultMessage="Ascenden"
                    />
                  </p>
                </div>
                <div
                  className="d-flex hover-pointer mt-2"
                  onClick={() => selectOrderAndClose(false, "desc")}
                >
                  <Radius status={!ascOrder} />
                  <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                    <FormattedMessage
                      id={
                        smallerThan1024
                          ? "qualified.groups.desc.small"
                          : "qualified.groups.desc"
                      }
                      defaultMessage="Descenden"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          {
            // ORDER DES ASC - END
          }

          {skeleton ? (
            <div className="d-flex justify-content-center align-items-center">
              <PerformanceLoadingMoreRequests
                type={"group"}
                text={
                  <FormattedMessage
                    id="group.incorporations.descendant.loading"
                    defaultMessage="Cargando consultoras"
                  />
                }
                small={false}
              />
            </div>
          ) : qualifiedInfo?.groups?.length > 0 ? (
            <div
              className="mt-3"
              style={{
                gap: "14px",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(392px, auto))",
              }}
            >
              {switchSelector ? (
                <>
                  {toggler
                    ? renderQualifiedGroups(
                      filteredqualifiedInfo,
                      qualifiedInfo?.currencyCode
                    )
                    : renderQualifiedGroups(
                      filteredpotentialLeaderInfo,
                      potentialLeader?.currencyCode
                    )}
                </>
              ) : (
                <>
                  {toggler
                    ? renderQualifiedGroups(
                      qualifiedInfo?.groups,
                      qualifiedInfo?.currencyCode
                    )
                    : renderQualifiedGroups(
                      potentialLeader?.groups,
                      potentialLeader?.currencyCode
                    )}
                </>
              )}
            </div>
          ) : (
            <>
              {profileData?.level < 3 ? (
                <>
                  {potentialLeader?.groups?.length < 1 ? (
                    <>
                      <div className=" h-100 flex-column text-center p-3 d-flex justify-content-center align-items-center w-100">
                        <p className=" museo-sans-500 s20 pb-2">
                          <FormattedMessage id="dashboard.qualifications.potential.leader.empty.title" />
                        </p>
                        <p className=" w-75 grayColor museo-sans-500 s14 ">
                          <FormattedMessage id="dashboard.qualifications.potential.leader.empty.p" />
                        </p>
                      </div>
                    </>
                  ) : (
                    <div
                      className="mt-3"
                      style={{
                        gap: "14px",
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fill, minmax(392px, auto))",
                      }}
                    >
                      {switchSelector ? (
                        <>
                          {toggler ? renderQualifiedGroups(filteredqualifiedInfo, qualifiedInfo?.currencyCode) : renderQualifiedGroups(filteredpotentialLeaderInfo, potentialLeader?.currencyCode)}
                        </>
                      ) : (
                        <>
                          {toggler ? renderQualifiedGroups(qualifiedInfo?.groups, qualifiedInfo?.currencyCode) : renderQualifiedGroups(potentialLeader?.groups, potentialLeader?.currencyCode)}
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <NoRegistersMessage
                      icon={<RedWarningIcon />}
                      title={
                        <FormattedMessage
                          id="performance.no.results.title"
                          defaultMessage="Sin resultados"
                        />
                      }
                      description={
                        <FormattedMessage
                          id="performance.no.qualified.groups.description"
                          defaultMessage="No se encontraron resultados"
                        />
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className=" container--padding--card-holder  d-flex align-items-center flex-column">
      <div  >
        <div className="w-100 d-flex justify-content-between flex-column" >
        <QualifiedGroupsModal
          cantidadNecesaria={toggler ? qualifiedInfo?.totalNeed : potentialLeader?.totalNeed}
          rol={profileData?.roleDescription}
        />
      <div className="d-flex justify-content-between mt-3 mb-3">
        <div className="d-flex flex-wrap  justify-items-center align-items-center p-1">
          <div className="d-flex flex-wrap justify-items-center align-items-center lightVioletBg p-1 rounded-3">
            <div
              onClick={() => onToggler(false)}
              className={`${toggler ? "bronceBg" : "hover-pointer"
                } rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center`}
            >
              <p className="ms-2 museo-sans-700 s14 lightBlueColor user-select-none">
                <FormattedMessage id="qualified.leaders" />
              </p>
            </div>
            <div
              onClick={() => onToggler(true)}
              className={`${!toggler ? "bronceBg" : "hover-pointer"
                } rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center`}
            >
              <p className="ms-2 museo-sans-700 s14 lightBlueColor user-select-none">
                <FormattedMessage id="potential.leaders" />
              </p>
            </div>
          </div>
          <div style={{ width: "24px" }}></div>
          {skeleton ? (
            <div
              className={`hide-qualified-switch rounded-pill position-relative d-flex align-items-center lightGreyBg`}
            >
              <div
                className={`position-absolute bronceBg rounded-circle ${switchSelector
                    ? "qualified-switch-true"
                    : "qualified-switch-false"
                  }`}
              ></div>
            </div>
          ) : (
            <div
              onClick={() => setSwitchSelector(!switchSelector)}
              className={`hide-qualified-switch hover-pointer rounded-pill position-relative d-flex align-items-center ${switchSelector ? "skyBg" : "brightSkyBg"
                }`}
            >
              <div
                className={`position-absolute bronceBg rounded-circle ${switchSelector
                    ? "qualified-switch-true"
                    : "qualified-switch-false"
                  }`}
              ></div>
            </div>
          )}
          <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
            <FormattedMessage
              id="qualified.groups.hide"
              defaultMessage="Ocultar grupos calificados"
            />
          </p>
        </div>
        <div
          className={`d-flex align-items-center position-relative ${orderBySelector && "qualified-tooltip-container"
            } `}
        >
          <button
            onClick={() => setOrderBySelector(!orderBySelector)}
            className={`${true ? "ps-2 pe-2" : "ps-4 pe-4"
              } d-flex gap-2 justify-content-center align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding position-relative`}
            style={{ height: "40px" }}
          >
            <FormattedMessage
              id="qualified.groups.order.by.new"
              defaultMessage="Ordenar por"
            />
            {orderBySelector ? <SVGChevArrowUp /> : <SVGChevArrowDown />}
          </button>
          <div
            id="qualifiedGroupsOrderBy"
            className="order-select-tooltip position-absolute bronceBg"
          >
            <div
              className="d-flex hover-pointer"
              onClick={() => selectOrderAndClose(true, "asc")}
            >
              <Radius status={ascOrder} />
              <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                <FormattedMessage
                  id={
                    smallerThan1024
                      ? "qualified.groups.asc.small"
                      : "qualified.groups.asc"
                  }
                  defaultMessage="Ascenden"
                />
              </p>
            </div>
            <div
              className="d-flex hover-pointer mt-2"
              onClick={() => selectOrderAndClose(false, "desc")}
            >
              <Radius status={!ascOrder} />
              <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                <FormattedMessage
                  id={
                    smallerThan1024
                      ? "qualified.groups.desc.small"
                      : "qualified.groups.desc"
                  }
                  defaultMessage="Descenden"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <PerformanceLoadingMoreRequests
          type={"group"}
          text={
            <FormattedMessage
              id="group.incorporations.descendant.loading"
              defaultMessage="Cargando consultoras"
            />
          }
          small={false}
        />
      </div>
        </div>

      </div>
    </div>
  );
}
