import { FormattedMessage } from "react-intl";

export default function FirstStepsBeta(params) {
    
    return <>
        <button 
        data-bs-toggle="modal"
        data-bs-target="#modalFirstStepsBeta"
        style={{ borderRadius: "40px", width: "40px", height: "20px"}} 
        className=" position-relative d-flex justify-content-center pointer border-0 blueBg museo-sans-500 s12 text-white"  
        >
            <p style={{top: 2}} className=" position-absolute" >
                <FormattedMessage id="first.steps.beta" />
            </p>
        </button>
    </>
}