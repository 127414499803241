import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import ShareModalShareBody from "../../../common/Share/ShareModalShareBody";

export default function AccountModalShare({ socialArray, user, open }) {

  const configExist = (auxId, message) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map(elm => elm.id).indexOf(auxId);
    }
    return resolve;
  }

    const sharedDataPhoneText = socialArray && `${multiLangJSONParse()["share.message.your.account.phone.parse.one"]}${user?.roleDescription}${multiLangJSONParse()["share.message.your.account.phone.parse.two"]}${user?.firstName}%20${user?.lastName}%20%0AID:%20${localStorage?.getItem("clientId")}%20%0ATel:%20%2B${user?.phone}%20%0AEmail:%20${user?.email}%20${socialArray[configExist("tienda_virtual")]?.value ? `${multiLangJSONParse()["share.message.your.account.phone.parse.three"]}` + socialArray[configExist("tienda_virtual")]?.value : ""}%20%0A%20%0A${socialArray[configExist("whatsapp")]?.value && "WhatsApp:%20" + `https://wa.me/${socialArray[configExist("whatsapp")]?.value}`}`;

    const sharedData = socialArray && `${multiLangJSONParse()["share.message.your.account.web.parse.one"]}${user?.roleDescription}${multiLangJSONParse()["share.message.your.account.web.parse.two"]}\n${user?.firstName} ${user?.lastName} ID: ${localStorage?.getItem("clientId")}\n Tel: +${user?.phone}\nEmail: ${user?.email}\n\n${socialArray[configExist("tienda_virtual")]?.value && `${multiLangJSONParse()["share.message.your.account.web.parse.three"]}` + socialArray[configExist("tienda_virtual")]?.value}\n\n${socialArray[configExist("whatsapp")]?.value && "WhatsApp: " + `https://wa.me/${socialArray[configExist("whatsapp")]?.value}`} `
    
    const doThis = async () =>{
      
      try {
        await navigator.clipboard.writeText(sharedData);
        open();
        
      } catch (error) {
        unsecuredCopyToClipboard(sharedData);
      }
    }

    const unsecuredCopyToClipboard = async (text) => {
    
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        open();
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    }
  
  return (
    <>
      {socialArray && (
        <ShareModalShareBody sharedDataPhoneText={sharedDataPhoneText} sharedData={sharedData} socialArray={socialArray} configExist={configExist} doThis={doThis} />
      )}
    </>
  );
}
