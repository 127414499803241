import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGVirtual } from "../../../../common/svgs/ic-edb-presencial-blue-sm.svg";
import { ReactComponent as SVGPresence } from "../../../../common/svgs/ic-edb-virtual-blue-sm.svg";

export default function ClientListDetailsModalHost({ buttonData }) {

  const setLinkById = (id, array) => {
    if (!id || !array) {
      return "";
    }
    const link = array.find((elm) => elm.id == id).link;

    return link;
  };

  const onClick = (index, data) => {
    if (data?.links?.length > 1) {
      return "";
    }

    const clickMe = document.getElementById(
      index == 1 ? "schedule_edbv" : "schedule_edb"
    );
    clickMe.click();
  };

  return (
    <div
      className="modal fade"
      id="modalHosting"
      aria-hidden="true"
      aria-labelledby="modalHosting"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "496px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex justify-content-between p-4 m-3 ">
                <div className="d-flex align-items-center">
                  <h5 className="museo-sans-500 s20 m-0">
                    <FormattedMessage id="client.details.modal.host.title" />
                  </h5>
                </div>
                <div
                  className="pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className="d-flex flex-column align-items-center">
                <div className=" museo-sans-500 mt-4 px-4 mx-3">
                  <p className="s16">
                    <FormattedMessage id="client.details.modal.host.content.one" />
                  </p>
                  <p className="s16 pt-4 mt-2">
                    <FormattedMessage id="client.details.modal.host.content.two" />
                  </p>
                  <div className="d-flex gap-4 my-4 pb-2 w-100 ">
                    <div
                      onClick={() => onClick(2, buttonData?.links)}
                      style={{ width: "74px" }}
                      className="d-flex gap-2 pointer flex-column align-items-center"
                    >
                      <div
                        style={{
                          width: "64px",
                          height: "64px",
                          borderRadius: "50%",
                          background: "#5AAFF11A",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div
                          style={{ width: "24px" }}
                          className=" svg-force-size"
                        >
                          <SVGVirtual />
                        </div>
                      </div>
                      <div className=" museo-sans-500 s14 text-center grayColor ">
                        <FormattedMessage id="client.details.moda.host.button.one" />
                      </div>
                    </div>
                    <div
                      onClick={() => onClick(1, buttonData?.links)}
                      style={{ width: "74px" }}
                      className="d-flex gap-2 pointer flex-column align-items-center"
                    >
                      <div
                        style={{
                          width: "64px",
                          height: "64px",
                          borderRadius: "50%",
                          background: "#5AAFF11A",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div
                          style={{ width: "24px" }}
                          className=" svg-force-size"
                        >
                          <SVGPresence />
                        </div>
                      </div>
                      <div className=" museo-sans-500 s14 grayColor ">
                      <FormattedMessage id="client.details.moda.host.button.two" />
                      </div>
                    </div>
                    <div className=" invisible">
                      {buttonData?.data?.links?.length > 0 && (
                        <>
                          <a
                            className="invisible"
                            target="_blank"
                            id="schedule_edbv"
                            href={`${setLinkById(
                              "schedule_edbv",
                              buttonData?.data?.links
                            )}`}
                          >a</a>
                          <a
                            className="invisible"
                            target="_blank"
                            id="schedule_edb"
                            href={`${setLinkById(
                              "schedule_edb",
                              buttonData?.data?.links
                            )}`}
                          >b</a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
