import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import  { getCMSDataOne, getCMSDataTwo, getDataConsultant, getDataFirstGeneration, getDataLiderAndDirector, getIncorporatedOnly, getRestOfGroupOnly } from "./FirstStepsAllSideEffect";
import { resetFirstSteps } from "../../../EDBs/Framework/redux/EDBsActions";
import { activateFirstGeneration, activeError, cleanFilter, cleanFirstGenerationData, cleanIncorporatedAndRestOfGroupData, fetchFirstStepsCardDetail, fetchFirstStepsDetails, filteringData, firstStepsActiveError, isExtraFetchGenerationFalse, isExtraFetchGenerationTrue, toogleIsFirstGenerationActive } from "./FirstStepsAllActions";


const initialState = {
  firstSteps: null,
  firstStepsIncorp: null,
  firstStepsModalDetails: null,
  firstGenerationData: [],
  firstStepsCardDetail: null,
  firstStepsIncorporatedOnly: null,
  firstStepsRestOnly: null,
  CMSOne: null,
  CMSTwoExternal: null,
  isFirstGenerationActive: false,
  firstStepsError: false,
  filter: false,
  CMSLoader: true,
  TableLoader: true,
  firstGenerationExtrasCall: true,
};

const FirstStepsAllReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(isExtraFetchGenerationFalse, (state, action) => {
      state.firstGenerationExtrasCall = false
    })
    .addCase(isExtraFetchGenerationTrue, (state, action) => {
      state.firstGenerationExtrasCall = true
    })
    .addCase(getDataConsultant.fulfilled, (state,action) => {
      state.firstSteps = action.payload;
      state.firstStepsError = false;
    })
    .addCase(firstStepsActiveError, (state, action) => {
      state.firstStepsError = true;
    })
    .addCase(getCMSDataTwo.fulfilled, (state, action) => {
      state.CMSTwoExternal = action.payload;
    })
    .addCase(filteringData, (state,action)=> {
      state.filter = action.payload;
    })
    .addCase(cleanIncorporatedAndRestOfGroupData, (state,action)=> {
      state.firstStepsIncorporatedOnly = [];
      state.firstStepsRestOnly = [];
      state.firstStepsIncorp = [];
      state.firstGenerationData = [];
    })
    .addCase(getDataLiderAndDirector.fulfilled, (state,action) => {
      state.firstStepsIncorp = action.payload;
      state.firstStepsError = false;
      state.TableLoader = false;
    })
    .addCase(getDataLiderAndDirector.pending, (state, action) => {
      state.TableLoader = true;
    })
    .addCase(getIncorporatedOnly.fulfilled, (state,action) => {
      state.firstStepsIncorporatedOnly = action.payload;
      state.firstStepsError = false;
    })
    .addCase(getRestOfGroupOnly.fulfilled, (state,action) => {
      state.firstStepsRestOnly = action.payload;
      state.firstStepsError = false;
    })
    .addCase(resetFirstSteps, (state,action)=>{
      state.firstStepsIncorp = null;
      state.firstGenerationData = [];
    })
    .addCase(fetchFirstStepsCardDetail, (state,action) => {
      state.firstStepsCardDetail = action.payload;
    })
    .addCase(fetchFirstStepsDetails, (state, action) => {
      state.firstStepsModalDetails = action.payload;
    })
    .addCase(cleanFirstGenerationData,(state,action) => {
      state.firstGenerationData = [];
    })
    .addCase(toogleIsFirstGenerationActive,(state,action)=> {
      if (action?.payload > 0) {
        state.isFirstGenerationActive = true;
      }
      if ( action?.payload <= 0) {
        state.isFirstGenerationActive = false;
      } 
    })
    .addCase(activateFirstGeneration, (state, action) => {
      state.isFirstGenerationActive = action.payload;
    })
    .addCase(getDataFirstGeneration.fulfilled, (state,action) => {
      if(state.firstGenerationData.length == 0) {
        state.firstGenerationData = action.payload;
      } else {
        if (action.payload.length == 0) {
          
        } else {
          action.payload.firstGeneration.map((elm) => {
            state.firstGenerationData.firstGeneration.push(elm)
          })
        }
      }
      state.firstStepsError = false;
    })
    .addCase(getCMSDataOne.fulfilled, (state, action) => {
      state.CMSOne = action.payload;
      state.CMSLoader = false;
    })
    .addCase(getCMSDataOne.pending, (state, action) => {
      state.CMSLoader = true;
    })

    .addMatcher(
      isAnyOf(
        getDataFirstGeneration.rejected,
        getDataConsultant.rejected,
        getDataLiderAndDirector.rejected,
        getIncorporatedOnly.rejected,
        getRestOfGroupOnly.rejected
      ),
      (state, action) => {
        state.firstStepsError = true;
      }
    )

}
)
export default FirstStepsAllReducer