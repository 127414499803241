export default function checkTogglerJustCoins () {

    const country = localStorage.getItem("country");

    // CS MX AR UR

    if ((country == "mx") || (country == "ar") || (country == "ur") || (country == "cr")) {
        return true;
    } else {
        return false;
    }

}