import React, { useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";
import "../../../Dashboard/Presentation/pages/dashboard.css";
import { useState } from "react";
import imagen1 from "../../../common/img/Swiss-just.png";
import OfertsDiscountsHolder from "./OfertsDiscountsHolder";
import OfertsImageHolder from "./OfertsImageHolder";
import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import { useDispatch, useSelector } from "react-redux";
import getTimeZoneByCountry from "../../../common/Share/formatDateMoment";
import { notificationSharingData } from "../../Framework/redux/OfertsActions";
import OfertsModalSharing from "./modals/OfertsModalSharing";
import replaceElementsURL from "../../../common/Share/replaceElementURL";

export default function OfertsDashboard({ element, getData, onOpenCopyModal }) {
  const dispatch = useDispatch();
  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");
  const [toggler, setToggler] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  

  const dispatchNewInfo = (boolean) => {

    if (boolean) {
      
      const payload = {
        data: element,
        title: `Ofertas y Promos`,
      };
      dispatch(notificationSharingData(payload));
      const id = document.getElementById("pointer-modal-card");
      id.click();
      return;
    }

    const payload = {
      data: element,
      title: `Ofertas y Promos`,
    };

    dispatch(notificationSharingData(payload));
    const id = document.getElementById("button-sharing-modal");
    id.click();
  };

  const transformContentIfLongTitle = (str) => {
    if (str?.length > 50) {
      let aux = str?.slice(0, 47);
      aux = aux + "...";
      return aux;
    } else {
      return str;
    }
  };

  const transformContent = (str) => {
    if (str?.length > 50) {
      let aux = str?.slice(0, 47);
      aux = aux + "...";
      return aux;
    } else {
      return str;
    }
  };
  
  const countUpperCaseAndLowerCase = (str) => {
    let upperCaseCount = 0;
    let lowerCaseCount = 0;
  
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      if (char === char.toUpperCase() && char !== char.toLowerCase()) {
        upperCaseCount++;
      } else if (char === char.toLowerCase() && char !== char.toUpperCase()) {
        lowerCaseCount++;
      }
    }
  
    return { upperCaseCount, lowerCaseCount };
  };

  const transformContentGift = (str) => {
    if (str?.length > 56) {
      let aux = str?.slice(0, 53);
      aux = aux + "...";
      let auxMay = aux
          const letterCounts = countUpperCaseAndLowerCase(auxMay);
          if (letterCounts.upperCaseCount > 15) {
            return auxMay.slice(0,41) + "..."
          }
          else {
            return auxMay.slice(0,49) + "..."
          }
     
    } else {
      return str;
    }
  };
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setToggler(false);
  }, [element]);

  useEffect(() => {
  }, [windowWidth]);

  let a = element?.regularPrice;


  return (
    <>
      <OfertsModalSharing open={onOpenCopyModal} />

      {toggler === true ? (
        <Skeleton />
      ) : (
        <>
          {dateFormat(
            element?.dateStartOfPublication,
            element?.dateEndOfPublication
          ) && (
            <div
              style={{
                borderRadius: "16px 16px 16px 16px",
                objectFit: "cover",
                height: "380px",
                objectPosition: "top",
                width: biggerThan1000
                  ? "288px"
                  : !windowWidth == 1280
                  ? "251px"
                  : "273px",
              }}
              src={
                element?.saleImage != undefined
                  ? `${replaceElementsURL(
                      element?.imageBaseURL,
                      element?.saleImage?.id,
                      element?.saleImage?.filename_download
                    )}`
                  : imagen1
              }
              className={`position-relative cards-Offers cards-shadow overflow-hidden bg-white ${
                element.isAvailable ? null : "opacity-50"
              }`}
            >
              {
                 element.isAvailable &&
                 <div
                 onClick={() => dispatchNewInfo(true)}
                 style={{ zIndex: 10, top: 0, height:"320px", width: biggerThan1000
                   ? "288px"
                   : !windowWidth == 1280
                   ? "251px"
                   : "273px", }}
                 className=" position-absolute pointer "
               ></div>
              }
          

              <>
                <div
                  className=" position-absolute invisible"
                  data-bs-toggle="modal"
                  data-bs-target="#modalOffer"
                  id="pointer-modal-card"
                ></div>
                <div
                  onClick={
                    element?.isAvailable ? () => dispatchNewInfo(true) : null
                  }
                  className={`  ${
                    element?.isAvailable && "pointer"
                  } d-flex justify-content-center position-relative `}
                  style={{
                    height: "141px",
                    width: "288px",
                  }}
                >
                  {
                    element?.discountPercentage > 0 ?
                    <>
                      <OfertsDiscountsHolder
                        number={element?.discountPercentage}
                        status={element?.badgeType}
                      />
                    </>
                    :
                    <>
                      {
                        element?.badgeType == "Regalo" ||  element?.badgeType == "Justcoins" ? 
                        
                        <OfertsDiscountsHolder
                          number={element?.discountPercentage}
                          status={element?.badgeType}
                        />
                      :
                      null
                      }
                    </>
                  }
                  {
                    element?.badgeType != "Regalo" &&
                    <OfertsImageHolder
                      url={element?.imageBaseURL}
                      filename={element?.productImage?.filename_download}
                      id={element?.productImage?.id}
                      short={false}
                    />

                  }
                  
                  <img
                    style={{
                      borderRadius: "16px 16px 0px 0px",
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      objectPosition: "top",
                    }}
                    src={
                      element?.saleImage != undefined
                        ? `${replaceElementsURL(
                            element?.imageBaseURL,
                            element?.saleImage?.id,
                            element?.saleImage?.filename_download
                          )}`
                        : imagen1
                    }
                  />
                </div>
                <div className="d-flex flex-column py-4 px-4 mx-2 justify-content-between bg-white">
                  <div className="d-flex flex-column">
                    <div className="d-flex gap-4" style={{ maxWidth: "224px" }}>
                      <div
                        className="d-flex flex-column"
                        style={{ gap: "8px" }}
                      >
                        <p
                          className="museo-sans-500 s12 text-uppercase"
                          style={{ color: "#908F9A" }}
                        >
                          {transformContentIfLongTitle(element?.type)}
                        </p>
                        <h5
                          className="museo-sans-700 s16"
                          style={{ height: "38px" }}
                        >
                          {transformContentIfLongTitle(element?.title)}
                        </h5>
                      </div>
                    </div>
                    {element?.badgeType == "Regalo" ? (
                      <>
                        <div
                          style={{
                            width: "224px",
                            height: "48px",
                            textOverflow: "ellipsis",
                            marginBottom: "19px",
                          }}
                        >
                          <p
                            className="museo-sans-500 s16"
                            style={{ color: "#908F9A" }}
                          >
                            {transformContentGift(element?.description)}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex flex-column"
                          style={{ gap: "4px" }}
                        >
                          <div
                            style={{
                              width: "224px",
                              height: "37px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <h5 className="s16 museo-sans-500">
                              {transformContent(element?.productName)}
                            </h5>
                          </div>
                          <div
                            className="d-flex flex-row align-items-center"
                            style={{ gap: "12px", height: "26px" }}
                          >
                            <p className="dm-sans-bold s20">
                              {
                                element?.discountPrice &&
                                <>
                                  {formatAmount(
                                    +element?.discountPrice,
                                    cultureInfo?.languageCode,
                                    cultureInfo?.defaultCurrencyCode
                                  )}
                                </>
                              }
                            </p>
                            <p
                              style={{
                                color: "#908F9A",
                                textDecoration: "line-through",
                              }}
                              className="dm-sans-bold s12"
                            >
                              {
                                element?.regularPrice && 
                                <>
                                  {formatAmount(
                                    +element?.regularPrice,
                                    cultureInfo?.languageCode,
                                    cultureInfo?.defaultCurrencyCode
                                  )}
                                </>
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      paddingTop: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: "24px",
                    }}
                  >
                    {element?.isAvailable ? (
                      <button
                        onClick={() => {
                          dispatchNewInfo(false);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#modalNotiSharing"
                        id={`button-sharing-modal-${element?.id}`}
                        className="skyColor bg-white skyBorder museo-sans-700 s14 rounded-3"
                        style={{ width: "224px", height: "40px" }}
                      >
                        <p className="my-auto">
                          <FormattedMessage id="nav.label.eleven.index" />
                        </p>
                      </button>
                    ) : (
                      <div
                        className="  museo-sans-700 s14 rounded-5 d-flex justify-content-center"
                        style={{
                          width: "224px",
                          height: "32px",
                          borderRadius: "50%",
                          background: "#EDEDED",
                          border: "1px",
                          borderColor: "#EDEDED",
                        }}
                      >
                        <p
                          className="my-auto "
                          style={{ color: "#908F9A", background: "#EDEDED" }}
                        >
                          <FormattedMessage id="nav.label.fifthy.index" />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </div>
          )}
        </>
      )}
    </>
  );
}

export function Skeleton(params) {
  return (
    <>
      <div className="cards-Offers cards-shadow edb-card-radius d-flex flex-column bronceBg">
        <div
          style={{ height: "156px", borderRadius: "16px 16px 0px 0px" }}
          className=" w-100 brightGrayBg  "
        ></div>
        <div className="p-3" style={{ height: "211px" }}>
          <div
            style={{ height: "12px" }}
            className=" w-100 mt-3 rounded-3 brightGrayBg  "
          ></div>

          <div className=" mt-4 pt-3 d-flex flex-column gap-2">
            <div
              style={{ height: "8px" }}
              className=" w-100 rounded-3 brightGrayBg  "
            ></div>
            <div
              style={{ height: "8px" }}
              className=" w-100 rounded-3 brightGrayBg  "
            ></div>
            <div
              style={{ height: "8px" }}
              className=" w-50   rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className=" mt-4 d-flex flex-column gap-2">
            <div
              style={{ height: "40px", width: "244px" }}
              className=" w-100 rounded-3 brightGrayBg  "
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export function dateFormat(dateStart, dateEnd, index) {
  const country = localStorage.getItem("country");
  const timeZone = getTimeZoneByCountry(country);

  const fechaActual = new Date().toLocaleString("en-US", { timeZone });

  let idElm;

  const fechaInicio = new Date(dateStart).toLocaleString("en-US", { timeZone });
  const fechaFin = new Date(dateEnd).toLocaleString("en-US", { timeZone });
  const fechaActualFormateada = new Date().toLocaleString("en-US", {
    timeZone,
  });

  const estaEnRango = isWithinRange(
    fechaActualFormateada,
    fechaInicio,
    fechaFin
  );

  if (estaEnRango) {
    idElm = index;
    return estaEnRango;
  }

  return { estaEnRango, idElm };

  function isWithinRange(date, startDate, endDate) {
    const dateObj = new Date(date);
    const startObj = new Date(startDate);
    const endObj = new Date(endDate);

    return dateObj >= startObj && dateObj <= endObj;
  }
}
