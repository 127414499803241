import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGMail } from "../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGCopy } from "../../common/svgs/ic-copy-md.svg";
import { ReactComponent as SVGCross} from "../../common/svgs/ic-x-lg.svg";

export default function ShareModalShareBody({sharedDataPhoneText, sharedData, socialArray, configExist, doThis, modalTitle}) {
  return (
    <div>
      <div className="d-flex justify-content-between m-3 p-4">
        <div className="d-flex">
          <h5 className="museo-sans-500 s20 m-0">
            <FormattedMessage id={`${modalTitle ? modalTitle : "social.sharedata.title"}`} />
          </h5>
        </div>
        <div className="pointer" data-bs-dismiss="modal" aria-label="Close">
          <SVGCross />
        </div>
      </div>
      <hr className="m-0"></hr>
      <div className=" p-4 d-flex  mt-0 gap-3">
        {configExist("email") !== -1 && (
          <a
            href={`mailto:${
              socialArray[configExist("email")].value
            }?&subject=Te Comparto?&body=${encodeURIComponent(sharedData)}`}
            target="_blank"
            className="ms-2 bronceStroke grayColor cancelBootstrap"
          >
            <div
              style={{ alignItems: "center" }}
              className=" pointer d-flex flex-column justify-content-center"
            >
              <div
                style={{
                  width: "64px",
                  height: "64px",
                  alignItems: "center",
                }}
                className=" bubble-social rounded-circle d-flex justify-content-center"
              >
                <SVGMail />
              </div>
              <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                <FormattedMessage id="social.sharedata.link.mail" />
              </p>
            </div>
          </a>
        )}
        {configExist("whatsapp") !== -1 && (
          <a
            href={`https://wa.me/?text=${sharedDataPhoneText}`}
            target="_blank"
            className="ms-2 bronceStroke grayColor cancelBootstrap"
          >
            <div
              style={{ alignItems: "center" }}
              className=" pointer d-flex flex-column justify-content-center"
            >
              <div
                style={{
                  width: "64px",
                  height: "64px",
                  alignItems: "center",
                }}
                className=" bubble-social rounded-circle d-flex justify-content-center"
              >
                <SVGWhatsap />
              </div>
              <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                <FormattedMessage id="social.sharedata.link.wap" />
              </p>
            </div>
          </a>
        )}
        {configExist("messenger") !== -1 && (
          <a
            href={`https://m.me/?text=${sharedDataPhoneText}`}
            target="_blank"
            className="ms-2 bronceStroke bronceFill grayColor cancelBootstrap"
          >
            <div
              style={{ alignItems: "center" }}
              className=" pointer d-flex flex-column justify-content-center"
            >
              <div
                style={{
                  width: "64px",
                  height: "64px",
                  alignItems: "center",
                }}
                className=" bubble-social rounded-circle d-flex justify-content-center"
              >
                <SVGMessenger />
              </div>
              <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                <FormattedMessage id="social.sharedata.link.msg" />
              </p>
            </div>
          </a>
        )}

        <div
          onClick={doThis}
          style={{ alignItems: "center" }}
          className=" grayColor pointer d-flex flex-column justify-content-center"
        >
          <div
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{
              width: "64px",
              height: "64px",
              alignItems: "center",
            }}
            className=" bubble-social rounded-circle d-flex justify-content-center"
          >
            <SVGCopy />
          </div>
          <p className=" w-100 museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
            <FormattedMessage id="social.sharedata.link.copydata" />
          </p>
        </div>
      </div>
    </div>
  );
}
