import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const getEventDates  = createAsyncThunk(
    'getEventDates',
    async (data , {rejectWithValue}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/events/${clientId}/dates`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getEventsForSpecificDate  = createAsyncThunk(
    'getEventsForSpecificDate',
    async (date , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");
            const events = await customNetServAxios().get(`${location}/events/${clientId}?date=${date}`);
            return events
           
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const calendarCurrentPeriod  = createAsyncThunk(
    'calendarCurrentPeriod',
    async (date , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const currentPeriod = await customNetServAxios().get(`${location}/calendar/period-data`);
            return currentPeriod
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const currentYearPeriods  = createAsyncThunk(
    'currentYearPeriods',
    async (year , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const currentYear = new Date().getFullYear();
            const yearPeriods = await customNetServAxios().get(`${location}/calendar/year-data/${currentYear}`);
            return yearPeriods
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const pastYearPeriods  = createAsyncThunk(
    'pastYearPeriods',
    async (year , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const pastYear = (new Date().getFullYear() - 1)
            const pastYearPeriods = await customNetServAxios().get(`${location}/calendar/year-data/${pastYear}`);
            return pastYearPeriods
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const nextYearPeriods  = createAsyncThunk(
    'nextYearPeriods',
    async (year , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const nextYear = (new Date().getFullYear() + 1)
            const nextYearPeriods = await customNetServAxios().get(`${location}/calendar/year-data/${nextYear}`);
            return nextYearPeriods
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const thisYearPeriodData = createAsyncThunk(
    'thisYearPeriodData',
    async (yearPeriod, {rejectWithValue}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");


            let prevPerid = yearPeriod - 1;
            let afterPerid = yearPeriod + 1;


            prevPerid =  JSON.stringify(prevPerid);
            afterPerid = JSON.stringify(afterPerid)
            
            let serrogatedPrevPeriod = [];
            let serrogatedAfterPeriod = [];

            prevPerid.forEach(element => {
                serrogatedPrevPeriod.push(element)
            });

            afterPerid.forEach(element => {
                serrogatedAfterPeriod.push(element)
            });


            if (serrogatedPrevPeriod[3] == "0" && serrogatedPrevPeriod[2] == "0") {
                if (serrogatedPrevPeriod[1] == 0) {
                    prevPerid = `${serrogatedPrevPeriod[0] -1}912`
                } else {
                    prevPerid = `${serrogatedPrevPeriod[0]}${serrogatedPrevPeriod[1] - 1}12`
                }
            }

            if (serrogatedAfterPeriod[3] == "3" && serrogatedAfterPeriod[2] == "1") {
                if (serrogatedAfterPeriod[1] == 9) {
                    afterPerid = `${serrogatedAfterPeriod[0] +1}001`
                } else {
                    afterPerid = `${serrogatedAfterPeriod[0]}${serrogatedAfterPeriod[1] + 1}01`
                }
            }


            const result = await customNetServAxios().get(`${location}/events/${clientId}/periodic/preview?yearPeriod=${yearPeriod}`);
            const resultPrev = await customNetServAxios().get(`${location}/events/${clientId}/periodic/preview?yearPeriod=${prevPerid}`);
            const resultAfter = await customNetServAxios().get(`${location}/events/${clientId}/periodic/preview?yearPeriod=${afterPerid}`);

            const totalDates = {dates: [...result.dates, ...resultPrev.dates, ...resultAfter.dates  ]};

            return totalDates;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export { getEventDates, getEventsForSpecificDate, calendarCurrentPeriod, currentYearPeriods, pastYearPeriods, nextYearPeriods, thisYearPeriodData }