import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchImportant = createAsyncThunk(
    'fetchImportant',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/great-practices/${clientId}/important-cards`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchEcommerce = createAsyncThunk(
    'fetchEcommerce',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/great-practices/${clientId}/ecommerce-cards
            `);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

export { fetchImportant, fetchEcommerce  }