import React from "react";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { OpenExtraEDBMeetings } from "../../Framework/redux/EDBsSideEffects";
import { FormattedMessage } from "react-intl";

import Loader from "../../../common/Share/Loader";
import EDBOpenRow from "./EDBOpenRow";
import EDBEmpty from "./EDBEmpty";
import SkeletonEDBRowList from "../../../common/Share/SkeletonEDBRowList";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export default function EDBOpenList({dataWasFiltered, order, edbType, openFilterOffsetState, setOpenFilterOffsetState, prizeLevel, date}) {
  const dispatch = useDispatch();
 
  const [isVisible, setIsVisible] = useState();

  const extraOpenLoaderRef = useRef();
  const openElementsOffset = useRef(10);

  const filterInformation = useSelector((state) => state.edbData.filters)

  const openEDBs = useSelector((state) => state.edbData.openEDBs);
  const loadingOpenEDBs = useSelector((state) => state.edbData.loadingOpenEDBs);

  const openExtraEDBs = useSelector((state) => state.edbData.closedExtraEDBs);
  const loadingExtraOpenEDBs = useSelector(
    (state) => state.edbData.loadingExtraClosedEDBs
  );

  function defineSortBy(orderObject){
    if(orderObject.highLow && orderObject.type.sale){
        return "sales.desc"
    } else if(!orderObject.highLow && orderObject.type.sale){
        return "sales.asc"
    } else if(orderObject.highLow && orderObject.type.guest){
        return "assistants.desc"
    } else if(!orderObject.highLow && orderObject.type.guest){
        return "assistants.asc"
    } else if(orderObject.highLow && orderObject.type.level){
        return "hostLevel.desc"
    } else if(!orderObject.highLow && orderObject.type.level){
        return "hostLevel.asc"
    }
}

  function defineEDBType(edbTypeObject){
    if(edbTypeObject.virtual){
        return "virtual"
    } else if(edbTypeObject.onSite){
        return "presencial"
    }
    return
  }

  function definePrizeLevel(awardObject){
    if(awardObject.noAward){
        return "noPrize"
    } else if(awardObject.oneAward){
        return "firstPrize"
    } else if(awardObject.twoAward){
        return "secondPrize"
    }
    return
  }

  function defineDate(dateObject){ 
    const dateOriginArray = dateObject.origin.split("-")
    const dateOriginFixedString = dateOriginArray.join("")
    const dateTargetArray = dateObject.target.split("-")
    const dateTargetFixedString = dateTargetArray.join("")
    return {dateStart: dateOriginFixedString, dateEnd: dateTargetFixedString }
  }

  async function loadNewElements(thereIsAFilter) {
    if(thereIsAFilter){
        await dispatch(OpenExtraEDBMeetings({offset: openFilterOffsetState, limit:10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
        setOpenFilterOffsetState(openFilterOffsetState + 10)
    } else if(filterInformation.wasFiltered){
        await dispatch(OpenExtraEDBMeetings({offset: openFilterOffsetState, limit:10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
        setOpenFilterOffsetState(openFilterOffsetState + 10)
    } else {
        await dispatch(OpenExtraEDBMeetings({offset: openFilterOffsetState, limit:10}))
        setOpenFilterOffsetState(openFilterOffsetState + 10)
    }
}

  useEffect(() => {
    if(extraOpenLoaderRef.current){
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0]
            setIsVisible(entry.isIntersecting)
        })
        observer.observe(extraOpenLoaderRef.current)
    }

    if(isVisible){
        loadNewElements(dataWasFiltered)
    }
}, [isVisible, extraOpenLoaderRef.current, loadingOpenEDBs])

  function displayRows() {
    return openEDBs.map((element) => {
      return (
        <EDBOpenRow
          key={ShareKeyGenerator()}
          id={element.id}
          date={element.date}
          title={element.title}
          hostName={element.hostName}
          edbType={element.edbType}
          edbState={element.edbState}
          daysToClose={element.daysToClose}
          edbStatus={element.edbStatus}
          accumulatedAmount={element.accumulatedAmount}
          amountStatus={element.amountStatus}
        />
      );
    });
  }

  return (
    <div className=" w-100" >
      {loadingOpenEDBs ? (
        <div className="edb-empty-height position-relative">
          <SkeletonEDBRowList />
        <div>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
        </div>
          {
            /** SKELETON
             * <SkeletonEDBRowList />
        <div>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
        </div>
             * 
             * 
             */
          }
        
      </div>
      ) : (
        <>
          <div className=" w-100 d-flex text-uppercase ps-3 pe-2 s12 museo-sans-500 grayColor justify-content-between align-items-center row-height">
            <div className="" style={{ width: "14%" }}>
              <FormattedMessage id="edb.list.table.time" defaultMessage="" />
            </div>
            <div className="" style={{ width: "21%" }}>
              <FormattedMessage id="edb.list.table.theme" defaultMessage="" />
            </div>
            <div className="" style={{ width: "20%" }}>
              <FormattedMessage id="edb.list.table.host" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.type" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.status" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.sales" defaultMessage="" />
            </div>
            <div className="" style={{ width: "5%" }}></div>
          </div>
          {openEDBs.length < 1 ? (
            <div className="w-100 d-flex justify-content-center align-items-center edb-empty-height">
              <EDBEmpty
                title={
                  <FormattedMessage
                    id="edb.no.open.edbs"
                    defaultMessage="No hay encuentros Abiertos"
                  />
                }
                description={
                  <FormattedMessage
                    id="edb.no.open.edbs.description"
                    defaultMessage="No hay encuentros Abiertos"
                  />
                }
              ></EDBEmpty>
            </div>
          ) : (
            <>
              {displayRows()}
              {!loadingOpenEDBs && openEDBs.length >= 10 ? (
                <div
                  on
                  ref={extraOpenLoaderRef}
                  className="mt-4 w-100 "
                  style={{ height: "30px" }}
                >
                  {isVisible ? (
                    loadingExtraOpenEDBs ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="spinner-border blueColor" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="museo-sans-500 s14 mt-2">
                        <FormattedMessage id='edb.list.loading.more' />
                        </p>
                        
                      </div>
                    ) : openExtraEDBs.length < 1 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="museo-sans-500 s14">
                        <FormattedMessage id='edb.list.loading.end' />
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  );
}
