import DashboardQualification from "./DashboardQualification";

export default function DashboardQualificationContainer({level, scoreData, profileData, groupData, periodGap, closeComplete, setCloseComplete}) {

    return(
        <>
         {(level > "1" ||
              scoreData.potentialLeaderScore) && (
              <div className=" w-100">
                <DashboardQualification
                  reduced={scoreData.consultantStatus.reducedRequirements}
                  potentialLeader={scoreData?.consultantStatus?.potentialLeader}
                  status={scoreData.consultantStatus.checkMarkStatus}
                  groupsData={groupData}
                  scoreDataPotentialLeader={scoreData.potentialLeaderScore}
                  scoreData={scoreData?.generalDataArray}
                  level={level}
                  gap={periodGap}
                  succesLeaderMessage={!!(scoreData?.consultantStatus?.potentialLeader && closeComplete)}
                  profileDataLoader={profileData.loader}
                  setCloseComplete={setCloseComplete}
                  closeComplete={closeComplete}
                />
              </div>
            )}
        </>
    )
}
