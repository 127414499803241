
import { useMediaPredicate } from "react-media-hook";
import { useDispatch, useSelector } from "react-redux";
import { FirstStepsHelpMessage } from "../../../FirstSteps/Presentation/components/FirstStepsHelpMessage";

import { fetchFirstStepsCardDetail } from "../../Framework/redux/FirstStepsAllActions"

import FirstStepsCardContainerProgress from "./FirstStepsCardContainerProgress";
import { replaceURLString } from "./FirstStepFetchCMSDataById";
import FirstStepsMeasureOutdated from "./FirstStepsMeasureOutdated";
import { useState } from "react";
import { useEffect } from "react";
import FirstStepsTagHolder from "./FirstStepsTagHolder";
import { FormattedMessage } from "react-intl";

export default function FirstStepsPersonalStepsCard({
  number,
  data,
  extraData,
  redeemEndDate,
  metadata,
}) {
  const biggerThan1025 = useMediaPredicate("(min-width: 1025px)");
  const biggerThan1281 = useMediaPredicate("(min-width: 1281px)");
  const [CMSData, setAuxCMData] = useState();
  const dispatch = useDispatch();

  const CMSDataArray = useSelector(
    (state) => state.firstStepsAllReducer.CMSOne?.data
  );

  useEffect(() => {
    let auxIndex = null;

    for (let index = 0; index < CMSDataArray?.stepId?.length; index++) {
      if (CMSDataArray?.stepId[index]?.stepId == number) {
        auxIndex = index;
      }
    }
    if (auxIndex != null) {
      setAuxCMData(CMSDataArray?.stepId[auxIndex]);
    }

  }, [])


  const onViewDetails = (data, extraData, img) => {
    const object = { data, extraData, img }
    dispatch(fetchFirstStepsCardDetail(object))
    const click = document.getElementById(`view-details-${data?.stepId}`);
    click.click();
  };

  const transformContent = (str) => {
    if (str?.length > 50) {
      let aux = str?.slice(0, 47);
      aux = aux + "...";
      return aux;
    } else {
      return str;
    }
  };

  return (
    <>
      {data?.stepDetail && (
        <>
          <div
            style={
              biggerThan1025
                ? biggerThan1281
                  ? { width: "391.3px", height: "616px", borderRadius: "12px" }
                  : { width: "352px", height: "645px", borderRadius: "12px" }
                : { width: "275px", height: "663px", borderRadius: "12px" }
            }
            className=" d-flex flex-column bg-white museo-sans-500"
          >
            <div style={{ height: "190px" }} className=" position-relative">

              <img className=" w-100 position-relative " style={{ borderRadius: "16px 16px 0px 0px",
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      top: 2,
                      objectPosition: "center", }} src={replaceURLString(CMSData?.imageUrl, CMSData?.image?.filename_download, CMSData?.image?.id)} />
                      <FirstStepsTagHolder type={CMSData?.benefit} numberType={CMSData?.stepId} size={"sm"} amount={CMSData?.amount} />
            </div>
            <div
              style={{ height: "100%" }}
              className={` ${biggerThan1025 || biggerThan1281 ? "px-3 mx-3" : "px-2 mx-3"
                } d-flex flex-column py-3  justify-content-between `}
            >
              <div
                className={`d-flex h-100 pt-2 flex-column justify-content-between gap-2   `}
              >
                <div>
                  <div className=" text-uppercase d-flex align-items-center museo-sans-500 gap-1 s12 ">
                    <FirstStepsMeasureOutdated data={data} />
                  </div>
                  <div className="mt-3 pb-1" >
                    <p
                    style={(!biggerThan1281) ? {height: "60px"} : {height: "auto"}}
                      className={` ${biggerThan1025 || biggerThan1281 ? "s20" : "s16"
                        } museo-sans-700 grayColor `}
                    >
                      {data?.stepDescription}
                    </p>
                    <p
                      className={` ${biggerThan1025 || biggerThan1281 ? "s20" : "s16"
                        } museo-sans-700 blackColor `}
                    >
                      {
                        !biggerThan1281 ?
                          <>
                            {transformContent(CMSData?.title)}
                          </>
                          :
                          <>
                            {CMSData?.title}
                          </>
                      }

                    </p>
                  </div>
                  <div
                    className={` ${biggerThan1025 || biggerThan1281 && "s14"
                      }  museo-sans-500 py-2 `}
                  >
                    <FirstStepsCardContainerProgress
                      extraData={extraData}
                      data={data}
                      index={data?.stepId}
                    />
                  </div>
                  <div
                    style={{ borderRadius: "8px" }}
                    className={` ${biggerThan1025 || biggerThan1281 ? "s14" : "s10"
                      }  museo-sans-500 `}
                  >
                    <div >

                      {
                        <div className=" h-100 bg-transparent d-flex justify-content-end align-items-end ">
                          <FirstStepsHelpMessage
                            data={data}
                            index={data?.stepId}
                            extraData={extraData}
                            redeemEndDate={redeemEndDate} 
                            amount={CMSData && CMSData.benefit === 'DISCOUNT_PCT' && CMSData.amount}
                            />
                        </div>
                      }
                    </div>

                  </div>
                </div>
              </div>
              <div style={{ height: "32px" }}></div>
              <div style={{ height: "40px" }} className="pb-4">
                <button
                  onClick={() => onViewDetails(data, extraData, replaceURLString(CMSData?.imageUrl, CMSData?.image?.filename_download, CMSData?.image?.id))}
                  style={{ background: "white", height: "40px" }}
                  className=" w-100 btnCustom skyBorder skyColor museo-sans-700 s14"
                >
                  <FormattedMessage id="on.boarding.third.title.one" />
                </button>
              </div>
            </div>
            <button id={`view-details-${data?.stepId}`}
              data-bs-toggle="modal"
              data-bs-target={`#modalFirstStepsCardDetail`}
              className="invisible " >

            </button>
          </div>
        </>
      )}
    </>
  );
}