import { useEffect, useRef, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SVGClose } from "../../../svgs/ic-x-lg.svg";
import { ReactComponent as SVGBell } from "../../../svgs/ic-bell-lg.svg";
import { getNotifications } from "../../Framework/HeaderSideEffect";
import HeaderNotificationList from "./HeaderNotificationList";
import Loader from "../../../Share/Loader";
import ShareErrorHandlerServices from "../../../Share/ShareErrorHandlerServices";
import ShareKeyGenerator from "../../../Share/ShareKeyGenerator";
import { useOnClickOutside } from "../../../Share/ShareFunctionUseOnClickOutside";

export default function HeaderNotifications({ toggle, toggler }) {
  const directorId = localStorage.getItem("directorId");
  const refNot = useRef();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const dataNotifica = useSelector(
    (state) => state.headerData.notifications.notifications
  );
  const notificationsError = useSelector(
    (state) => state.headerData.notificationsError
  );

  const loading = useSelector((state) => state.headerData.loader);

  const closingModal = () => {
    toggler(false);
  };

  const onStartUp = async () => {
    setLoader(true);
    !directorId && (await dispatch(getNotifications()));
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  useOnClickOutside(refNot, () => toggler(false));

  useEffect(() => {
    onStartUp();
  }, []);

  return toggle ? (
    <div id="header--model-notific" className=" bg-white">
      <div className="notification--header d-flex justify-content-between align-items-center">
        <h5 className=" m-0">
          <FormattedMessage
            id="header.button.notf.title"
            defaultMessage="Notifications"
          />
        </h5>
        <div onClick={closingModal} className=" pointer ">
          <SVGClose />
        </div>
      </div>
      <hr className=""></hr>
      <ul
        id="header--notfication-scroll"
        style={{ overflowY: "scroll", maxHeight: "460px" }}
        className=" m-0 list-unstyled h-100 w-100"
      >
        <Loader loading={loading} />
        <Loader loading={loader} />
        {!loader && (
          <div
            style={{ left: "0px" }}
            className=" d-flex position-absolute shadow-effect"
          ></div>
        )}
        {notificationsError ? (
          <div className=" mt-5 pt-5">
            <ShareErrorHandlerServices service={onStartUp} />
          </div>
        ) : (
          <>
            {dataNotifica?.length > 0 ? (
              dataNotifica.map((x) => {
                return (
                  <>
                    {" "}
                    <HeaderNotificationList
                      key={ShareKeyGenerator()}
                      data={x}
                    />{" "}
                  </>
                );
              })
            ) : (
              <div
                style={{ alignItems: "center" }}
                className=" h-100 d-flex justify-content-center"
              >
                <div
                  style={{ alignItems: "center" }}
                  className=" pointer d-flex justify-content-center flex-column gap-2 "
                >
                  <div
                    style={{
                      width: "92px",
                      height: "92px",
                      alignItems: "center",
                    }}
                    className="  d-flex brightBlueBg rounded-circle justify-content-center  "
                  >
                    <SVGBell />
                  </div>
                  <p className=" p-0 museo-sans-500 s20 ">
                    <FormattedMessage
                      id="header.button.notf.empty"
                      defaultMessage="You don't have notifications"
                    />
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        <></>
      </ul>
    </div>
  ) : null;
}
