import React from "react";
import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "./elements/ClientListFormOneColumnInput";
import ClientListFormInputDate from "./elements/ClientListFormInputDate";
import { useFormContext } from "react-hook-form";



export default function PersonalData({formulationError, onChangeValidate }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')
 
  const { register, watch, setValue, control } = useFormContext();
  const country = localStorage.getItem("country");
  return (
    <div style={{ gap: "24px" }} className="d-flex flex-wrap my-4 pb-3">
      <ClientListFormOneColumnInput
        placeholder="Ej.: María"
        label="Nombre"
        name="firstName"
        type="text"
        maxLength={55}
        register={register}
        watch={watch}
        onChangeValidate={onChangeValidate}
        isError={formulationError.firstName}
      />
      <div className=" px-3 pe-0">
        <ClientListFormOneColumnInput
          placeholder="Ej.: María"
          label="Apellido"
          name="lastName"
          type="text"
          maxLength={60}
          register={register}
          watch={watch}
          onChangeValidate={onChangeValidate}
          isError={formulationError.lastName}
        />
      </div>
      {country == "ar" && (
        <ClientListFormOneColumnInput
          placeholder="Ej.: 33543234"
          label="DNI (opcional)"
          name="identification"
          type="number"
          register={register}
          watch={watch}
        />
      )}
      {country === "ar" ? (
          <div className="px-3 pe-0">
            <ClientListFormInputDate
              label="Fecha de cumpleaños (opcional)"
              name="birthday"
              setValue={setValue}
              watch={watch}
              control={control}
            />
          </div>
      ) : (
          <ClientListFormInputDate
            label="Fecha de cumpleaños (opcional)"
            name="birthday"
            setValue={setValue}
            watch={watch}
            isUpdate={isUpdate}
            control={control}
          />
      )}
    </div>
  );
}
