import { OpenEDBMeetings, DatedEDBMeetings, ClosedEDBMeetings, ClosedExtraEDBMeetings, OpenExtraEDBMeetings, DatedExtraEDBMeetings, FilteredClosedEDBMeetings, FilteredOpenEDBMeetings, FilteredDatedEDBMeetings } from "./EDBsSideEffects";
import { changeClosedEDBsOffset, saveFilters, keepRawFilters, resetFilters, clientDetailsForm, clearClientDetailsForm } from "./EDBsActions";

import { createReducer } from "@reduxjs/toolkit";


const initialState = {
  rawFilters: {},
  filters: {},
  openEDBs: [],
  closedEDBs: [],
  closedEDBsLength: 0,
  datedEDBs: [],
  openExtraEDBs: [],
  closedExtraEDBs: [],
  datedExtraEDBs: [],
  loadingOpenEDBs: false,
  loadingClosedEDBs: false,
  loadingDatedEDBs: false,
  loadingExtraOpenEDBs: false,
  loadingExtraClosedEDBs: false,
  loadingExtraDatedEDBs: false,
  extraOpenEDBsOffset: 10,
  extraClosedEDBsOffset: 10,
  extraDatedEDBsOffset: 10,
  formBody: {
    body: "",
    clientDetails: ""
  }
};

const EDBsReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(OpenEDBMeetings.pending, (state, action) => {
        state.loadingOpenEDBs = true
    })

    .addCase(clientDetailsForm, (state, action) => {
      state.formBody.clientDetails = action.payload;
    })

    .addCase(clearClientDetailsForm, (state, action) => {
      state.formBody.clientDetails = null;
    })

    .addCase(OpenEDBMeetings.fulfilled, (state, action) => {
      state.openEDBs = action.payload.edbs;
      state.loadingOpenEDBs = false
    })

    .addCase(OpenEDBMeetings.rejected, (state, action) => {
        state.loadingOpenEDBs = false
    })
    

    .addCase(DatedEDBMeetings.pending, (state, action) => {
        state.loadingDatedEDBs = true
    })

    .addCase(DatedEDBMeetings.fulfilled, (state, action) => {
        state.datedEDBs = action.payload.edbs;
        state.loadingDatedEDBs = false
    })

    .addCase(DatedEDBMeetings.rejected, (state, action) => {
        state.loadingDatedEDBs = false
    })


    .addCase(ClosedEDBMeetings.pending, (state, action) => {
        state.loadingClosedEDBs = true
    })

    .addCase(ClosedEDBMeetings.fulfilled, (state, action) => {
      state.closedEDBs = action.payload.edbs;
      state.closedEDBsLength = state.closedEDBs.length
      state.loadingClosedEDBs = false
    })

    .addCase(ClosedEDBMeetings.rejected, (state, action) => {
        state.loadingClosedEDBs = false
    })


    .addCase(ClosedExtraEDBMeetings.pending, (state, action) => {
        state.loadingExtraClosedEDBs = true
    })

    .addCase(ClosedExtraEDBMeetings.fulfilled, (state, action) => {
      state.closedExtraEDBs = action.payload.edbs;
      
      
      if(state.closedExtraEDBs.length > 0) {
        
        state.closedEDBs = [...state.closedEDBs, ...state.closedExtraEDBs]
      }
      state.loadingExtraClosedEDBs = false
    })

    .addCase(ClosedExtraEDBMeetings.rejected, (state, action) => {
        state.loadingExtraClosedEDBs = false
    })


    .addCase(OpenExtraEDBMeetings.pending, (state, action) => {
        state.loadingExtraOpenEDBs = true
    })

    .addCase(OpenExtraEDBMeetings.fulfilled, (state, action) => {
      state.openExtraEDBs = action.payload.edbs;
     
      if(state.openExtraEDBs.length > 0) {
        state.openEDBs = [...state.openEDBs, ...state.openExtraEDBs]
      }
      state.loadingExtraOpenEDBs = false
    })

    .addCase(OpenExtraEDBMeetings.rejected, (state, action) => {
        state.loadingExtraOpenEDBs = false
    })



    .addCase(DatedExtraEDBMeetings.pending, (state, action) => {
        state.loadingExtraDatedEDBs = true
    })

    .addCase(DatedExtraEDBMeetings.fulfilled, (state, action) => {
      state.datedExtraEDBs = action.payload.edbs;
      
      if(state.datedExtraEDBs.length > 0) {
        state.datedEDBs = [...state.datedEDBs, ...state.datedExtraEDBs]
      }
      state.loadingExtraDatedEDBs = false
    })

    .addCase(DatedExtraEDBMeetings.rejected, (state, action) => {
        state.loadingExtraDatedEDBs = false
    })


    /******Filtrados principales******/

    .addCase(FilteredClosedEDBMeetings.pending, (state, action) => {
        state.loadingClosedEDBs = true
    })
    .addCase(FilteredClosedEDBMeetings.fulfilled, (state, action) => {
      state.closedEDBs = action.payload.edbs;
      
      state.loadingClosedEDBs = false
    })
    .addCase(FilteredClosedEDBMeetings.rejected, (state, action) => {
        state.loadingClosedEDBs = false
    })

    .addCase(FilteredOpenEDBMeetings.pending, (state, action) => {
        state.loadingOpenEDBs = true
    })
    .addCase(FilteredOpenEDBMeetings.fulfilled, (state, action) => {
      state.openEDBs = action.payload.edbs;
      
      state.loadingOpenEDBs = false
    })
    .addCase(FilteredOpenEDBMeetings.rejected, (state, action) => {
        state.loadingOpenEDBs = false
    })

    .addCase(FilteredDatedEDBMeetings.pending, (state, action) => {
        state.loadingDatedEDBs = true
    })
    .addCase(FilteredDatedEDBMeetings.fulfilled, (state, action) => {
      state.datedEDBs = action.payload.edbs;
      
      state.loadingDatedEDBs = false
    })
    .addCase(FilteredDatedEDBMeetings.rejected, (state, action) => {
        state.loadingDatedEDBs = false
    })


    .addCase(saveFilters, (state, action) => {
        state.filters = action.payload
    })

    .addCase(keepRawFilters, (state, action) => {
        state.rawFilters = action.payload
    })

    .addCase(resetFilters, (state, action) => {
        state.rawFilters = {}
        state.filters = {}
    })

    .addCase(changeClosedEDBsOffset, (state, action) => {
        state.extraClosedEDBsOffset = state.extraClosedEDBsOffset + action.payload
    })
});

export default EDBsReducer;