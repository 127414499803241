import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import NetSrv from "../../../common/services/NetSrv";

import { getEventsForSpecificDate } from "../../Framework/redux/CalendarSideEffects";
import { changeEventSectionTitle } from "../../Framework/redux/CalendarActions";
import CalendarEventResume from "./CalendarEventResume";
import { FormattedMessage } from "react-intl";
import { multiLangGetCountry } from "../../../common/Share/MultiLangGetCountry";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export default function CalendarDay({
  currentPeriod,
  datesArray,
  dateObject,
  dataInfo,
}) {
  const dispatch = useDispatch();
  const [eventsToUpdate, setEventsToUpdate] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);

  
  function blueBorder() {
    if (
      dateObject.toLocaleDateString(multiLangGetCountry(), { weekday: "long" }) ===
      "jueves"
    ) {
      return "calendar-blue-left-border";
    } else {
      return "";
    }
  }

  function getEventsOrNot(dateToCompare) {
    setLoadingEvents(true);
    
    if (dataInfo) {
      let index = dataInfo.dates.findIndex((elm) => elm.date == dateToCompare);
      if (index != "-1") {
        setEventsToUpdate(dataInfo.dates[index].firstEvents);
      }
    }

    setLoadingEvents(false);
  }

  useEffect(() => {
    const dayForStart = dateObject.getDate();
    const monthForStart = dateObject.getMonth();
    const yearForStart = dateObject.getFullYear();
    const dateForFirstServiceCall = `${yearForStart}-${String(
      monthForStart + 1
    ).padStart(2, "0")}-${String(dayForStart).padStart(2, "0")}`;


    const timeoutId = setTimeout(() => {
      getEventsOrNot(dateForFirstServiceCall);
    }, 0);

    return () => {
      setEventsToUpdate([]);
      clearTimeout(timeoutId);
    };
  }, [datesArray]);

  function isTodayToday() {
    const todaysDay = new Date().toLocaleDateString();

    if (todaysDay === dateObject.toLocaleDateString()) {
      return true;
    } else {
      return false;
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function eventsDayAndRequest() {
    const day = dateObject.getDate();
    const month = dateObject.getMonth();
    const year = dateObject.getFullYear();

    const fechaParaElServicio = `${year}-${String(month + 1).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")}`;

    if (isTodayToday()) {
      dispatch(
        changeEventSectionTitle(
          <FormattedMessage id="calendar.event.section.title.default" />
        )
      );
    } else {
      dispatch(
        changeEventSectionTitle(
          `${capitalizeFirstLetter(
            dateObject.toLocaleDateString(multiLangGetCountry(), { weekday: "long" })
          )} ${dateObject.toLocaleDateString(multiLangGetCountry(), { day: "numeric" })}`
        )
      );
    }

    dispatch(getEventsForSpecificDate(fechaParaElServicio));
  }

  function displayEventResume() {
    
    if (eventsToUpdate.length <= 3) {
      return eventsToUpdate.map((element) => {
        return <CalendarEventResume key={ShareKeyGenerator()} eventData={element} />;
      });
    } else {
      const arrayOfThree = [
        eventsToUpdate[0],
        eventsToUpdate[1],
        eventsToUpdate[2],
      ];
      const howManyEventsAreLeft = eventsToUpdate.length - 3;
      const eventsPlusNumber = arrayOfThree.map((element) => {
        return <CalendarEventResume key={ShareKeyGenerator()} eventData={element} />;
      });
      eventsPlusNumber.push(
        <div className="museo-sans-700 s8 mt-2">{`${howManyEventsAreLeft} más`}</div>
      );
      return eventsPlusNumber;
    }
  }

  function periodDayHighlight() {
    let fechaInicial;
    let fechaFinal;

    if (currentPeriod !== undefined) {
      fechaInicial = new Date(currentPeriod[0]?.startDate);
      fechaInicial.setHours(0, 0, 0, 0);
      fechaFinal = new Date(currentPeriod[0]?.endDate);
      fechaFinal.setHours(0, 0, 0, 0);
      if (
        currentPeriod?.length > 0 &&
        dateObject >= fechaInicial &&
        dateObject <= fechaFinal
      ) {
        return "calendar-period-day-highlight";
      }
    }
    return "";
  }

  function hasEventHoverEffect () {
    if(eventsToUpdate.length > 0) {
      return "calendar-day--hover-effect"
    } else {
      return ""
    }
  }

  return (
    <div
      onClick={() => eventsDayAndRequest()}
      className={`calendar-borders ${blueBorder()} ${periodDayHighlight()} ${hasEventHoverEffect()} ps-2 pe-2 calendar-day`}
    >
      <div
        className={`calendar-number-container mt-2 ${
          isTodayToday() && "blueBg bronceColor"
        } dm-sans-medium s14`}
      >
        {dateObject.getDate()}
      </div>
      <div className="mt-1 position-relative">
        {loadingEvents ? (
          "Cargando"
        ) : eventsToUpdate.length > 0 ? (
          displayEventResume()
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
