import DescendantsPersonalSublistRow from "./DescendantsPersonalSublistRow";
import Loader from "../../../common/Share/Loader";
import { useEffect } from "react";
import { useState } from "react";
import customNetServAxios from "../../../common/services/NetSrv";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function DescendantsPersonalSublist({
  gen,
  id,
  level,
  isVisible,
  setIsVisible,
    setOffSet,
    offSet
}) {
  const [loading, setLoading] = useState(false);
  const [returnedEmpty, setReturnEmpty] = useState(false);
  const [list, setList] = useState();
  const profileData = useSelector((state) => state.userInfo.userInfo);

  const extraSubRef = useRef();
  const dispatch = useDispatch();

  let lineIndex = 0;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");

      let answer = undefined;

      
         
            answer = await customNetServAxios().get(
              `${location}/descendants-tree/${clientId}/incorporated/root/${id}?offset=0&limit=20&search=`
            );
          




      let secondKey = Object.keys(answer)[1]; //fetched the key at second index
      //setList(answer[secondKey]);


      parcheOneZeroThree(answer[secondKey].length, answer[secondKey])

      setLoading(false);
    }
  };

  const parcheOneZeroThree = async (lengthNumber, first) => {

    //Data.
    let auxLengthNumber = lengthNumber;
    let offSet = auxLengthNumber;
    let answer = undefined
    let location = localStorage.getItem("country");
    let clientId = localStorage.getItem("clientId");
    let auxiliarList = first;

    while (auxLengthNumber == 20) {
      //Seek more if need be.
            answer = await customNetServAxios().get(
              `${location}/descendants-tree/${clientId}/incorporated/root/${id}?offset=${offSet}&limit=20&search=`
            );
          

          
          let secondKey = Object.keys(answer)[1];

          if (answer[secondKey].length != 0) {
            
              answer[secondKey].map((elm) => {
                auxiliarList.push(elm)
              })
          }

          offSet = offSet + 20

          auxLengthNumber = answer[secondKey].length;

          
          
    }

    setList(auxiliarList);
  }


  return (
    <div className=" d-flex position-relative flex-column w-100 ">
      {list ? (
        <>
          {list?.map((elem) => {
            lineIndex = lineIndex + 1;
            return (
              <DescendantsPersonalSublistRow
                index={lineIndex}
                end={list?.length}
                gen={gen}
                user={elem}
              />
            );
          })}
        </>
      ) : (
        <div style={{ height: "100px" }} className=" position-relative w-100">
          <Loader loading={loading} />
        </div>
      )}
    </div>
  );
}