import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { sendDataDetails } from "../../Framework/redux/InvitationsAction";

import {changePersonalGroupName} from "../../../Descendants/Framework/redux/DescendantsAction"
import { FormattedMessage } from "react-intl";
import { putInvitation } from "../../Framework/redux/InvitationsSideEffect";

export default function InvitationsDetailsButtonMessage({ stages, id, data }) {

  const dispatch = useDispatch();
  const history = useNavigate();

  const onClickShowProfile = (id, name) => {
    dispatch(changePersonalGroupName({ name: name, clientId: id }))
    history(`/dashboard/descendants/profile/${id}`)

  };

  const runPutService = async (id) => {
    await dispatch(putInvitation(id));
  }

  const onShowMessage = (stages, id) => {

    if (stages[3]?.stageDetail?.state == "COMPLETED") {
      return (
        <div  data-bs-dismiss="modal"
        aria-label="Close" onClick={() => onClickShowProfile(data?.inviteeInfo?.clientProfile?.clientId, data?.inviteeInfo?.clientProfile?.name)} className=" pointer d-flex align-items-center gap-2">
          <p className=" s16 skyColor museo-sans-500 "><FormattedMessage id="calendar.event.card.view.profile" /></p>
          <div style={{ top: -2 }} className="position-relative">
            <SVGRightArrow />
          </div>
        </div>
      );
    }
    if (stages[2]?.stageDetail?.state == "COMPLETED") {
      return (
        <div  data-bs-dismiss="modal"
        aria-label="Close" onClick={() => onClickShowProfile(data?.inviteeInfo?.clientProfile?.clientId, data?.inviteeInfo?.clientProfile?.name)} className=" pointer d-flex align-items-center gap-2">
          <p className=" s16 skyColor museo-sans-500 "><FormattedMessage id="calendar.event.card.view.profile" /></p>
          <div style={{ top: -2 }} className="position-relative">
            <SVGRightArrow />
          </div>
        </div>
      );
    }
    if (stages[1]?.stageDetail?.state == "COMPLETED") {
      return (
        <div
          onClick={() => onClickRegister(id)}
          className=" pointer d-flex align-items-center gap-2"
        >
          <p className=" s16 skyColor museo-sans-500 ">
          <FormattedMessage id="invitation.show.register" />
          </p>
          <div style={{ top: -2 }} className="position-relative">
            <SVGRightArrow />
          </div>
          <span
            data-bs-toggle="modal"
            data-bs-target={`#modal-details-${id}`}
            className=" invisible"
            id={`id-${id}`}
          >
            .
          </span>
        </div>
      );
    }
    if (stages[0]?.stageDetail?.state == "COMPLETED") {
      return (
        <div
          data-bs-toggle="modal"
          data-bs-target={`#modalShare-${id}`}
          onClick={() => runPutService(id)}
          className=" pointer d-flex align-items-center gap-2"
        >
          <p className=" s16 skyColor museo-sans-500 ">
            <FormattedMessage id="invitation.resend.info" />
          </p>
          <div style={{ top: -2 }} className="position-relative">
            <SVGRightArrow />
          </div>
        </div>
      );
    }

  

    // https://incorporarme-test.swissjust.com/?country=ar&sponsorId=5726&personalId=2131970039E8430493BC1BB2A9ABD01A

    return (
     <>
     </>
    );
  };

  const onClickInvite = () => {
    const clickMe = document.getElementById("invite-link");
    clickMe.click();
  };

  const onClickRegister = (id) => {
    const clickMe = document.getElementById(`id-${id}`);
    dispatch(sendDataDetails(data));
    clickMe.click();
  };

  return onShowMessage(stages, id)
}
