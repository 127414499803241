import { useState, useEffect, useContext } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ReactComponent as WhatsappIcon } from "../../../common/svgs/ic-whatsapp-md.svg";
import { ReactComponent as EmailIcon } from "../../../common/svgs/ic-mail-md.svg";

import ShareComponentInput from "../../../common/Share/ShareComponentInput";
import ShareInputSelect from "../../../common/Share/ShareInputSelect";
import EDBsFormRouter from "../components/EDBsFormRouter";
import { body } from "../../Framework/EDBsFormBody";
import {
  fetchTopics,
  postNewEdb,
  putEdb,
} from "../../Framework/redux/EDBFormSideEffect";
import ClientListTerritoryPerCountry from "../../../ClientList/Presentation/components/ClientListTerritoryPerCountry";
import { getClientDetails } from "../../../ClientList/Framework/redux/ClientListSideEffect";
import {
  activateError,
  activatePhoneError,
  deactivateError,
} from "../../Framework/redux/EDBFormActions";

import { ReactComponent as SVGRedCross } from "../img/redcross.svg";
import { ReactComponent as SVGRedTriangle } from "../img/redtriangle.svg";
import { ReactComponent as SVGBackgrounfTriangle } from "../img/elipse.svg";
import { ModalClipboardContext } from "../../../common/routes/routes";
import { clearClientDetailsForm } from "../../Framework/redux/EDBsActions";

export default function EDBsForm() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const auxiliarForm = { body };
  const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
  const [formBody, setFormBody] = useState(
    auxFormBody ? { body: auxFormBody } : auxiliarForm
  );
  const [formTopics, setFormTopics] = useState([]);
  const country = localStorage.getItem("country");
  const data = useSelector((state) => state.edbFormData.topics.value);
  const profileUser = useSelector((state) => state.userInfo);
  const clientDetails = useSelector(
    (state) => state.clientListData.clientDetails?.data
  );
  const errorToggler = useSelector(
    (state) => state.edbFormData.errorTogger.value
  );
  const errorPhoneToggler = useSelector(
    (state) => state.edbFormData.errorPhoneToggler.value
  );

  const isPut =
    location.pathname.includes("present/") ||
    location.pathname.includes("virtual/");
  const params = useParams();

  const { setModalTexts } = useContext(ModalClipboardContext);

  const onStartUp = async () => {
    await dispatch(fetchTopics());
    dispatch(deactivateError());
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));

    if (auxFormBody?.hostData?.hostId && !clientDetails) {
      await dispatch(getClientDetails(auxFormBody.hostData.hostId));
      return;
    }
    if (!clientDetails) {
      return "";
    }
    let hostDataPhoneUpdate = {
      phone: "",
      areaCode: "",
      type: null,
    };
    if (
      Array.isArray(clientDetails?.phoneNumbers) &&
      clientDetails?.phoneNumbers.length > 0
    ) {
      hostDataPhoneUpdate = {
        phone: clientDetails?.phoneNumbers[0]?.number,
        area: clientDetails?.phoneNumbers[0]?.areaCode,
        type: clientDetails?.phoneNumbers[0]?.type || null,
      };
    }

    const updatedHostData = {
      hostId: clientDetails?.contactId,
      hostName:
        clientDetails?.firstName || "" + " " + clientDetails?.lastName || "",
      hostEmail: findEmail(clientDetails?.socialNetworks),
      phone: hostDataPhoneUpdate,
    };

    const updatedFormBody = {
      ...auxFormBody,
      hostData: updatedHostData,
      clientPhone: profileUser.userInfo?.phone || null,
      contactEdbPhone: {
        type: null,
        area: auxFormBody?.contactEdbPhone?.area || "",
        phone: auxFormBody?.contactEdbPhone?.phone || "",
      },
    };

    localStorage.setItem("formBody", JSON.stringify(updatedFormBody));
  };

  const onChangeByName = (value, name) => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    if (!value || !name) {
      return "";
    }
    let newFormBody = { ...auxFormBody };
    if (name === "time") {
      setFormBody({
        ...formBody,
        body: {
          ...formBody.body,
          time: value.value == "time" ? null : value.value,
        },
      });
    }
    if (name === "contactEdbArea") {
      let object = {
        phone: auxFormBody?.contactEdbPhone?.phone || "",
        area: value.value,
        type: null,
      };
      newFormBody["contactEdbPhone"] = object;
    }
    if (name === "contactEdbPhone") {
      let object = {
        phone: value?.value,
        area: auxFormBody?.contactEdbPhone?.area || "",
        type: null,
      };
      newFormBody["contactEdbPhone"] = object;
    }
    if (name !== "contactEdbPhone" && name !== "contactEdbArea") {
      newFormBody[name] = value?.value;
    }
    let { time } = newFormBody;
    if (time == "time") {
      time = null;
    }
    newFormBody = { ...newFormBody, time: time };
    setFormBody(newFormBody);
    localStorage.setItem("formBody", JSON.stringify(newFormBody));
    dispatch(deactivateError());
  };

  const onChangeByNameInput = (name, any, value) => {
    onChangeByName({ value: value }, name);
  };

  const toNewClient = () => {
    const isPresent = location.pathname.includes("present");
    localStorage.setItem("formBodyType", isPresent ? "present" : "virtual");
    history("/dashboard/edbs/form/client/add");
  };

  const toListClients = () => {
    const isPresent = location.pathname.includes("present");
    localStorage.setItem("formBodyType", isPresent ? "present" : "virtual");
    history("/dashboard/edbs/form/client/list");
  };

  const birthdayControl = (stringBirthday) => {
    if (!stringBirthday) {
      return "";
    }
    let splitValues = stringBirthday.split("-");

    let daySplitted = splitValues[0];
    let monthSplitted = splitValues[1];

    daySplitted = +daySplitted;
    monthSplitted = +monthSplitted;

    return (
      <>
        {" "}
        {monthSplitted} <FormattedMessage id="of" />{" "}
        <FormattedMessage id={`month.${daySplitted}`} />{" "}
      </>
    );
  };

  const onDateChange = (evt) => {
    const { name, value } = evt.target;
    const [year, month, day] = value.split("-");
    if (year?.length > 4) {
      let savedDate = auxFormBody?.date;
      evt.target.value = savedDate;
      return;
    } else {
      onChangeByName({ value: value }, name);
    }
  };

  const findEmail = (socialNetworks) => {
    const id = "email";
    if (!socialNetworks) {
      return "";
    }
    const email = socialNetworks.find((item) => item.id === id);
    return email?.value;
  };

  const onSubmitClick = async () => {
    debugger
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));

    const edbType = location.pathname.includes("virtual")
      ? "virtual"
      : "presencial";
    const isPut =
      location.pathname.includes("present/") ||
      location.pathname.includes("virtual/");

    const updateLocalStorage = { ...auxFormBody, edbType: edbType };

    let result = await dispatch(
      isPut
        ? putEdb({ form: updateLocalStorage, id: params.edbId })
        : postNewEdb(updateLocalStorage)
    );
    if (result?.error?.message == "Rejected" || !result?.payload) {
      dispatch(activateError());
      if (result?.payload.includes("ingresado no es")) {
        dispatch(activatePhoneError());
      }
    }
    if (result?.error?.message != "Rejected" && result?.payload) {
      const modalData = {
        title: isPut ? (
          <FormattedMessage id="edb.form.modal.pop.up.message.mod.edb.title" />
        ) : (
          <FormattedMessage id="edb.form.modal.pop.up.message.create.edb.title" />
        ),
        text: isPut ? (
          <FormattedMessage id="edb.form.modal.pop.up.message.mod.edb.p" />
        ) : (
          <FormattedMessage id="edb.form.modal.pop.up.message.create.edb.p" />
        ),
        status: "confirm",
        show: false,
        bottom: 0,
      };
      setModalTexts(modalData);
      dispatch(clearClientDetailsForm());
      localStorage.removeItem("formBody");
      if (isPut) {
        const direction = localStorage.getItem("formBodyType");
        if (direction == "abierto") {
          history(`/dashboard/edbs/details/open/${params.edbId}`);
        } else {
          history(`/dashboard/edbs/details/dated/${params.edbId}`);
        }
      } else {
        history("/dashboard/edbs");
      }
    }
  };

  const optionsHours = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      optionsHours.push({
        label: time,
        name: time,
        value: time,
      });
    }
  }

  useEffect(() => {
    onStartUp();
  }, []);

  useEffect(() => {
    let aux = [];

    if (Array.isArray(data?.topics) && data?.topics?.length > 0) {
      data?.topics.forEach((element) => {
        aux.push({
          label: element.title,
          name: element.title,
          value: element.id,
        });
      });
    }

    setFormTopics(aux);
  }, [data]);

  return (
    <>
      <div className=" museo-sans-500 s14 container--padding container--padding--card-holder">
        <div className=" ">
          <div className=" w-100 mt-5">
            <div>
              <p>
                <FormattedMessage id="Detalles de evento" />
              </p>
              <div className="w-100 d-flex my-3 gap-3">
                <div className="d-flex flex-column w-50 gap-2">
                  <p>
                    <FormattedMessage id="date" />
                  </p>
                  <div className="no-calendar no-calendar-s17 ">
                    <input
                      autoComplete="off"
                      name={"date"}
                      value={formBody?.date ?? auxFormBody?.date}
                      onChange={(evt) => onDateChange(evt)}
                      id={"date-end"}
                      data-date-format="DDMMYYYY"
                      className={` pointer w-100 dm-sans-medium s14 p-2 ${
                        !formBody?.date && errorToggler
                          ? "input-error-border"
                          : "input-border"
                      } `}
                      type="date"
                    />
                  </div>
                  <ErrorMessage
                    errorToggler={!formBody?.date && errorToggler}
                  />
                </div>
                <div className="w-50 d-flex flex-column gap-2">
                  <p className=" s14">
                    <FormattedMessage id="edb.form.details.time.start" />
                  </p>
                  <ShareInputSelect
                    errorToggler={
                      !formBody?.time && !auxFormBody?.time && errorToggler
                    }
                    onChange={onChangeByName}
                    name={"time"}
                    value={formBody.time ?? auxFormBody?.time}
                    options={optionsHours}
                    width={"100%"}
                  />
                  <ErrorMessage
                    errorToggler={
                      !formBody?.time && !auxFormBody?.time && errorToggler
                    }
                  />
                </div>
              </div>
              <div className="w-100 d-flex my-3 gap-3">
                <div className="w-50 d-flex flex-column gap-2 ">
                  <p className=" s14 museo-sans-500">
                    <FormattedMessage id="edb.form.details.subject.date" />
                  </p>
                  <ShareInputSelect
                    errorToggler={!auxFormBody?.topicId && errorToggler}
                    onChange={onChangeByName}
                    options={formTopics}
                    name="topicId"
                    value={
                      formTopics.find(
                        (topic) => topic.value === auxFormBody?.topicId
                      )?.label || null
                    }
                    width={"100%"}
                  />
                  <ErrorMessage
                    errorToggler={!auxFormBody?.topicId && errorToggler}
                  />
                </div>
                <div className="w-50"></div>
              </div>
            </div>
            <div>
              <p>
                <FormattedMessage id="edb.form.type.title" />
              </p>
              <EDBsFormRouter />
            </div>
            <Outlet />
            <div className=" mt-4">
              <p>
                <FormattedMessage id="edb.form.details.host" />
              </p>
              <hr />
              {clientDetails && (
                <div className="my-3">
                  <div className=" bg-white p-4 shadow rounded-8 ">
                    <p>
                      {clientDetails.firstName + " " + clientDetails.lastName}
                    </p>
                    <div className="d-flex w-100 my-3 gap-3 museo-sans-500 ">
                      {clientDetails?.phoneNumbers?.length > 0 && (
                        <div className="w-100">
                          <p
                            style={{ letterSpacing: "0.72px" }}
                            className=" grayColor text-uppercase "
                          >
                            <FormattedMessage id="TELÉFONO" />
                          </p>
                          <div className="w-100 d-flex justify-content-between">
                            <div className="d-flex gap-1">
                              {clientDetails?.phoneNumbers[0]?.areaCode && (
                                <p>
                                  {clientDetails?.phoneNumbers[0]?.areaCode}
                                </p>
                              )}
                              <p>{clientDetails?.phoneNumbers[0]?.number}</p>
                            </div>
                            <a
                              style={{ top: -4 }}
                              className="position-relative"
                              href={`https://wa.me/${clientDetails?.phoneNumbers[0]?.areaCode}${clientDetails?.phoneNumbers[0]?.number}`}
                              target="_blank"
                            >
                              <WhatsappIcon />
                            </a>
                          </div>
                        </div>
                      )}
                      {findEmail(clientDetails.socialNetworks) && (
                        <div className="w-100">
                          <p
                            style={{ letterSpacing: "0.72px" }}
                            className=" grayColor text-uppercase "
                          >
                            <FormattedMessage id="settings.user.data.email" />
                          </p>
                          <div className="d-flex justify-content-between w-100">
                            <p>{findEmail(clientDetails.socialNetworks)}</p>
                            <a
                              style={{ top: -4 }}
                              className="position-relative"
                              href={`mailto:${findEmail(
                                clientDetails.socialNetworks
                              )}`}
                              target="_blank"
                            >
                              <EmailIcon />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex w-100 my-3 gap-3 museo-sans-500 ">
                      {clientDetails?.birthday && (
                        <div className=" museo-sans-500 w-100">
                          <p
                            className=" text-uppercase grayColor s12"
                            style={{ letterSpacing: "0.72px" }}
                          >
                            <FormattedMessage id="birthday" />
                          </p>
                          <p className=" s16">
                            {birthdayControl(clientDetails?.birthday)}
                          </p>
                        </div>
                      )}
                      <div className="w-100">
                        {ClientListTerritoryPerCountry(
                          clientDetails?.addressInfo,
                          true
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!clientDetails && errorToggler && (
                <div
                  style={{ width: "264px" }}
                  className=" d-flex flex-column gap-3 position-relative align-items-center justify-content-center "
                >
                  <div
                    style={{ width: "80px", height: "80px" }}
                    className=" d-flex justify-content-center align-items-center position-relative "
                  >
                    <div className=" position-absolute ">
                      <SVGBackgrounfTriangle />
                    </div>
                    <div className=" position-absolute ">
                      <SVGRedTriangle />
                    </div>
                  </div>
                  <p className=" s24 museo-sans-500 text-center ">
                    <FormattedMessage id="edb.form.client.details.error.title" />
                  </p>
                  <p className=" s14 text-center grayColor ">
                    <FormattedMessage id="edb.form.client.details.error.message" />
                  </p>
                </div>
              )}
              <div className="w-100 d-flex my-3 gap-3">
                <button
                  onClick={toListClients}
                  style={{ width: "288px", height: "41px" }}
                  className="d-flex skyColor bg-transparent skyBorder rounded-8 justify-content-center align-items-center s14 "
                >
                  {clientDetails ? "Cambiar Anfitrión/a" : "Buscar Anfitrión/a"}
                </button>
              </div>
              <p className=" grayColor">
                <FormattedMessage id="edb.form.host.to.select" />
              </p>
              <p onClick={toNewClient} className=" skyColor pointer">
                <FormattedMessage id="edb.form.host.create.contact" />
              </p>
              <div></div>
            </div>
            <div className="mt-4">
              <p>
                <FormattedMessage id="edb.form.contact.title" />
              </p>
              <hr />
              {profileUser.userInfo?.phone && (
                <p className=" grayColor">
                  <FormattedMessage id="edb.form.contact.subtext.one" />{" "}
                  {profileUser.userInfo?.phone}.
                </p>
              )}
              <div className="w-50 my-3 gap-2 d-flex">
                <ShareComponentInput
                  name={"contactEdbPhone"}
                  label={
                    <FormattedMessage id="edb.form.contact.phone.optional" />
                  }
                  value={auxFormBody?.contactEdbPhone?.phone}
                  onChange={onChangeByNameInput}
                  placeholder={"Ej.: 2345-6789"}
                  ignoreTitle={country === "ar"}
                  errorToggler={errorPhoneToggler}
                />
              </div>
              <div className={`${errorPhoneToggler ? "mb-3" : ""}`}>
                <ErrorMessage errorToggler={errorPhoneToggler} />
              </div>
            </div>

            <button
              id="on-submit-confirm"
              onClick={() => onSubmitClick()}
              style={{ width: "288px", height: "41px" }}
              className="d-flex mb-5 skyBg text-white border-0 rounded-8 justify-content-center align-items-center s14 "
            >
              {isPut ? (
                <FormattedMessage id="edb.form.save.encounter" />
              ) : (
                <FormattedMessage id="edb.form.create.encounter" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export function ErrorMessage({ errorToggler }) {
  return (
    <div className="d-flex gap-2">
      {errorToggler && (
        <>
          <SVGRedCross />
          <p className=" redColor s12">
            <FormattedMessage id="input.error.required" />
          </p>
        </>
      )}
    </div>
  );
}
