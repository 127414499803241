import React from 'react'

/*import { colors } from '../../../../Colors/ColorPallets';*/

import { useMediaPredicate } from 'react-media-hook';
/* export default function InputComponent({type, id, label, ref}) {
    return(
        <div className='w-100 h-25'>
            <label htmlFor={id} className='form-label'>{label}</label>
            <input type={type} className='form-control w-50' id={id} ref={ref}/> 
        </div>
    )
} */

export const InputComponent = React.forwardRef((props, ref) => {

    const biggerThan992 = useMediaPredicate("(min-width: 992px)");

    return (
        <div className={`w-100 ${biggerThan992 && "h-25"}`}>
            {
                props.label
                ? <label htmlFor={props.id} className='form-label'>{props.label}</label>
                : null
            }
            <input type={props.type} className={`form-control input-radius ${biggerThan992 ? "w-50" : "w-100"}`} id={props.id} placeholder={props.placeholder}/> 
        </div>
    )
})