import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ShareFlagIcons from "../../../common/Share/ShareFlagIcons";

export default function InvitationsAddFormTwoInput({
  label,
  placeholderTwo,
  onChangeOne,
  onChangeTwo,
  errorControl,
}) {
  const country = localStorage.getItem("country");
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52",
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const countryList = Object.keys(numbers).reduce((acc, key) => {
    acc[numbers[key]] = key;
    return acc;
  }, {});

  const updateLocalOne = (auxCountry) => {
    setFirstValue(numbers[auxCountry||country]);
    onChangeOne(numbers[auxCountry||country]);
  };

  const onChangeLocalTwo = (evt) => {
    const newValue = evt.target.value;
    setSecondValue(newValue);
    onChangeTwo(newValue);
  };

  useEffect(() => {
    country && updateLocalOne();
  }, []);

  console.log("firstValue", firstValue  )

  return (
    <>
      <div className="d-flex flex-column gap-2">
        <div>
          <p className=" museo-sans-700 s14">{label}</p>
        </div>
        <div
          className="d-flex position-relative justify-content-between gap-3"
          style={{ height: "56px" }}
        >
          <div
            style={errorControl ? { borderColor: "#f06e69" } : null}
            className=" w-100 museo-sans-500 s16 d-flex rounded-16 input-border h-100"
          >
            <div
              style={{ width: "120px" }}
              className=" position-relative p-3 gap-2 d-flex justify-content-center align-items-center "
            >
              <div style={{ top: -3, left: 1 }} className=" position-relative">
                <ShareFlagIcons code={countryList[firstValue]} height={"22px"} />
              </div>
              <input
                value={firstValue}
                readOnly
                className=" grayColor no-outline-input border-0  h-100 w-100"
              />
              
            </div>
            <div
              className=" "
              style={{
                height: "100%",
                backgroundColor: "#CED2FF",
                width: "1px",
              }}
            >
              <p className=" invisible">.</p>
            </div>
            <div className="p-2">
              <input
                maxLength={10}
                placeholder={placeholderTwo}
                value={secondValue}
                onChange={onChangeLocalTwo}
                className=" no-outline-input border-0 h-100 w-100"
              />
            </div>
          </div>
          {errorControl && (
            <div
              style={{ left: 0, bottom: -20 }}
              className=" position-absolute "
            >
              <p className=" museo-sans-700 s12 lightRedColor ">
                <>
                  <FormattedMessage id="invitation.create.label.one" />
                </>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
