import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGRedCross } from "../../../../../EDBs/Presentation/img/redcross.svg";

export default function ClientListFormOneColumnInput({
  label,
  placeholder,
  name,
  register,
  type,
  watch,
  onChangeValidate,
  isError,
  ...props
}) {

  const [error, setError] = useState();
  const [data, setData] = useState();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const emailValue = e.target.value;
    if (emailValue && !validateEmail(emailValue)) {
      setError('Por favor, introduce un email válido.');
    } else {
      setError('');
    }
  };

  const handleChangeNumber = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, '');
    event.target.value = newValue;
  };

  const handleChangeText = (event) => {
    const updatedValue = event.target.value.replace(/\d/g, '');
    event.target.value = updatedValue;
  };
  
  const stateOnChange = (evt)  => {
    onChangeValidate();
    setData(evt.target?.value);
    if(type === "email") {
      return handleChange(evt)
    }
    if (type === 'number') {
      return handleChangeNumber(evt)
    }
    if (type === 'text') {
      return handleChangeText(evt)
    }
    return null
  };

  useEffect(() => {
    if (type === 'email' && watch('email')) {
      handleChange({ target: { value: watch('email') }})
    }
  }, [])

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <p className=" museo-sans-500 s14">{label}</p>
      </div>
      <div style={{ width: "360px", height: "40px" }}>
        <div className={` museo-sans-500 s14 d-flex justify-content-center align-items-center rounded-2 input-border w-100 h-100 p-2 bg-white ${((error || isError) && !data) ? "redBorder" : ""}`} >
          <input
            style={{ backgroundColor: '#ffffff' }}
            placeholder={placeholder}
            className=" border-0 m-1 h-100 w-100 bg-white"
            {...register(name)}
            onChange={(evt) => stateOnChange(evt)}
            {...props}
          />
        </div>
      </div>
      {((error || isError) && !data) && <p className="s12 museo-sans-500 d-flex gap-1" style={{ color: 'red' }}>{isError ? 
      <>
       <SVGRedCross />
      <FormattedMessage id="input.error.required" /> 
      </>
      : error}</p>}
    </div>
  );
}