
export default function ProgressBar({ completed , incompleted, header,status, height }) {
  
    const containerStyles = {
      height: height ? height : 8,
      width: "100%",
      backgroundColor: "#F2F2F2",
      borderRadius: 50,
    };
  
    const labelStyles = {
      padding: 5,
      color: "white",
      fontWeight: "bold",
    }; 
    
  
    return (
      <div className="progress-bar" style={containerStyles}>
        {header ? 
        
        <div className="blueBg"
            style={{
              height: "100%",
              borderRadius: "inherit",
              width: `${completed ? completed : 0}%`,
            }}
          >
            <span style={labelStyles}></span>
          </div>
        
        : 
        
        !incompleted ? (
          <div 
            style={{
              background: "#58BB82",
              height: "100%",
              borderRadius: "inherit",
              width: `${completed ? completed : 0}%`,
            }}
          >
            <span style={labelStyles}></span>
          </div>
        ) : (
          <>
          { status === "warning" ? 
          <div className="goldBg"
          style={{
            height: "100%",
            borderRadius: "inherit",
            width: `${completed ? completed : 0}%`,
          }}
        >
          <span style={labelStyles}></span>
        </div>
          :
          <div className="lightRedBg"
            style={{
              height: "100%",
              borderRadius: "inherit",
              width: `${completed ? completed : 0}%`,
            }}
          >
            <span style={labelStyles}></span>
          </div>
          }
          </>
          
        )}
  
      </div>
    );
  }
  