import React from "react";

import { ReactComponent as ArrowRight } from "../../../common/svgs/arrow-right.svg";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import "../pages/clientList.css";
import { useLocation } from "react-router-dom";

export default function ClienteListTableBody({
  contactsResult,
  onCardClick,
  navigate,
  withLastStyle,
}) {
  const location = useLocation();

  const controlOnClickAction = (person) => {
    let doSelectedCard = location.pathname.includes("dashboard/edbs/form/client/list") || location.pathname.includes("/edbs/details")
    doSelectedCard ? onCardClick(person.contactId) : navigate(`/dashboard/clientlist/details/${person.contactId}`);
  };

  return contactsResult.map((person) => (
    <div
      style={{ paddingTop: "20px", paddingBottom: "20px" }}
      className={`w-100 d-flex align-items-center museo-sans-500 s14 justify-content-between pe-4 pointer ${
        withLastStyle ? "border-bottom-2" : "border-bottom-1"
      }`}
      onClick={() => controlOnClickAction(person)}
    >
      <div className="d-flex align-items-center">
        <ImageCircleHandler
          firstName={person?.firstName?.trim()}
          lastName={person?.lastName?.trim()}
          width={32}
          fontFamily={"museo-sans-500"}
          height={32}
          size={14}
          styles={{
            marginRight: 22,
            backgroundColor: "#456ECE",
            color: "white",
          }}
        />
        <p className=" museo-sans-700 s14">
          {`${person.firstName} ${person.lastName}`}
        </p>
      </div>
      <div className="d-flex align-tems-center">
        <ArrowRight width={18} height={18} />
      </div>
    </div>
  ));
}

// Commented for the time being - person.has_cards
