import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import StyledMultiLang from "../../../common/Share/StyledMultiLang";

import { Link } from "react-router-dom";

import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";
import DashboardGraphConsultant from "./Graph/DashboardGraphConsultant";
import DashboardGraphLeader from "./Graph/DashboardGraphLeader";
import { FormattedMessage } from "react-intl";

export default function DashboardGraphContainer({
    scoreData,
    errorScoreData,
    profileData,
    toggler,
    onClickOne,
    onClickTwo,
    periodGap,
    fetchScore,
    reduceNumber,
  }) {
    
    return(
        <div className=" w-100">
              <div className=" align-items-center pb-3 d-flex gap-2">
                <h3 className=" m-0 museo-sans-500  s20">
                  <StyledMultiLang
                    loading={scoreData.loader}
                    id="dashboard.main.title.earnings"
                    def={"Tus Ganancias del Período"}
                  />
                </h3>
                <i data-bs-toggle="modal" data-bs-target="#modalPeriodSales">
                  <HelpIconNoTarget />
                </i>
                {
                  errorScoreData ?
                  <p className=" px-3 museo-sans-700 s14 grayColor ">
                    <StyledMultiLang
                      loading={scoreData.loader}
                      id="dashboard.main.subtitle.earnings"
                      def={"Ver tus ganancias de períodos anteriores"}
                    />
                  </p>
                  :
                    <Link to={"earning/period"}>
                      <p className=" px-3 LinkOpacityHover pointer museo-sans-700 s14 skyColor">
                        <StyledMultiLang
                          loading={scoreData.loader}
                          id="dashboard.main.subtitle.earnings"
                          def={"Ver tus ganancias de períodos anteriores"}
                        />
                      </p>
                    </Link>
                }
              </div>
              {profileData.userInfo.level < "2" &&
                scoreData?.consultantStatus?.potentialLeader && (
                  <div className=" position-relative">
                    <div
                      className=" position-relative "
                      style={{ width: "200px", height: "30px" }}
                    >
                      <div
                        onClick={onClickOne}
                        style={{
                          width: "184px",
                          height: "38px",
                          top: -7,
                          borderRadius: "16px 16px 0px 0px ",
                          zIndex: 0,
                        }}
                        className={`${
                          toggler ? "lightGreyBg grayColor" : "bg-white"
                        } tabs-shadow pointer d-flex justify-content-center align-items-center py-2   position-absolute`}
                      >
                        <p className=" museo-sans-500 s14 ">
                          <FormattedMessage
                            id="level.two.qualified"
                            defaultMessage={"Si fueras Líder"}
                          />
                        </p>
                      </div>
                      <div
                        onClick={onClickTwo}
                        style={{
                          width: "184px",
                          height: "38px",
                          top: -7,
                          borderRadius: "16px 16px 0px 0px ",
                          left: 184,
                          zIndex: 0,
                        }}
                        className={` ${
                          toggler ? "bg-white" : "lightGreyBg grayColor"
                        } tabs-shadow pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
                      >
                        <p className=" museo-sans-500 s14 ">
                          <FormattedMessage
                            id="level.one.not.inclusive"
                            defaultMessage={"Consultora"}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              <div
                style={
                  profileData.userInfo.level < "2" &&
                  scoreData?.consultantStatus?.potentialLeader
                    ? { minHeight: "443px", borderRadius: "0px 16px 16px 16px" }
                    : { minHeight: "443px", borderRadius: "16px" }
                }
                className=" position-relative bg-white tabs-shadow "
              >
                <>
                  {
                    errorScoreData ?
                    <div className=" pt-5 w-100 h-100 d-flex justify-content-center align-items-center" >
                      <div className=" pt-5 mt-5" >
                        <ShareErrorHandlerServices service={fetchScore} />
                      </div>
                    </div>
                    :
                    <>
                      {periodGap && (
                        <div
                          data-bs-toggle="modal"
                          data-bs-target="#modalEarningGap"
                          style={{ zIndex: 1000 }}
                          className=" pointer position-absolute w-100 h-100"
                        ></div>
                      )}
                      {profileData.userInfo.level < "2" &&
                      !scoreData?.consultantStatus?.potentialLeader ? (
                        <DashboardGraphConsultant toggler={toggler} gap={periodGap} />
                      ) : (
                        <>
                          {profileData.userInfo.level >= "2" ? (
                            <DashboardGraphLeader toggler={toggler} gap={periodGap} />
                          ) : (
                            <>
                              {(profileData.userInfo.level < "2" ||
                                scoreData?.consultantStatus?.checkMarkStatus ==
                                  "completed") &&
                              !toggler ? (
                                  <DashboardGraphLeader
                                    toggler={!toggler}
                                    gap={periodGap}
                                    profileData={profileData}
                                    isPotentialLeader={true}
                                  />
                              ) : (
                                  <DashboardGraphConsultant
                                    toggler={!toggler}
                                    gap={periodGap}
                                    profileData={profileData}
                                  />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  }
                </>
              </div>
            </div>
    )
}