import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Red } from "../../../common/svgs/ic-alert-circle-red.svg";

import { ReactComponent as Yellow } from "../../../common/svgs/ic-alert-circle-yellow.svg";

import { ReactComponent as Green } from "../../../common/svgs/ic-check-circle.svg";

import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";

import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";

import { clearInsideArray, pushInsideArray } from "../../Framework/redux/DescendantsAction";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";

export default function DescendantsProfileTitle({data, score}) {

    const history = useNavigate();
    const dispatch = useDispatch();

    const onClickLink = (root) => {
        dispatch(clearInsideArray());
        dispatch(pushInsideArray({clientId: root, name: data?.uplineName }))
        history(`/dashboard/descendants/profile/asc/${root}`)
    }
    
    return <div className=" m-3 p-3 h-100  d-flex flex-column justify-content-between gap-5 " >
        <div className=" d-flex gap-2 " >
        <h3 style={{width: "fitContent"}} className=" s20 museo-sans-700 m-0">
            <ReformatLevelByGender level={data.level} gender={data.gender} roleDescriptionDefault={data?.roleDescription}  />
        </h3>
        <div className="" >
            <div style={{width: "16px" , height: "16px"}} >
                {score?.consultantStatus?.checkMarkStatus === "reduced" && <Purple />}
                {score?.consultantStatus?.checkMarkStatus === "risk" && <Red />}
                {score?.consultantStatus?.checkMarkStatus === "completed" && <Green />}
                {score?.consultantStatus?.checkMarkStatus === "warning" && (
                    <Yellow />
                    )}
            </div>
        </div>
        </div>
        <div className=" s14" >
            <p className=" museo-sans-500" >
                <FormattedMessage id="performance.user.tooltip.ascendant" />
            </p>
            <div className=" d-flex gap-1" >
            <p onClick={() => onClickLink(data?.uplineId)} className=" d-flex gap-2 align-items-center  pointer skyColor museo-sans-700">
                {data?.uplineName}
                <SVGRightArrow />
            </p>

            </div>
        </div>
    </div>
}