import React, { useEffect } from "react";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { ReactComponent as SVGBloqued } from "../../../FirstSteps/Presentation/SVGs/Bloqueado/ic-item-bloq-sm-stroke-black.svg";
import { ReactComponent as SVGReached } from "../../../FirstSteps/Presentation/SVGs/ReachedCompletedGreen/ic-check-circle-sm.svg";
import { ReactComponent as SVGFailed } from "../../../FirstSteps/Presentation/SVGs/NoAlcanzado/ic-x-circle-sm.svg";
import { ReactComponent as SVGCompleted } from "../../../FirstSteps/Presentation/SVGs/Completado/ic-item-gift-sm.svg";
import { ReactComponent as SVGpending } from "../../../FirstSteps/Presentation/SVGs/Pending/ic-item-pending-sm-stroke-black.svg";

import { ReactComponent as ArrowRight } from "../../../common/svgs/ic-chevron-right-md.svg";
import DashboardFirstStepsRow from "../../../Dashboard/Presentation/components/DashboardFirstStepsRow";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

const DarshboardFirstStepsPersonalIncorporated = (data) => {
  const stepsData = useSelector(
    (state) => state?.firstStepsAllReducer?.firstStepsIncorp
  );
  const existIncorpData = useSelector(
    (state) => state?.firstStepsAllReducer?.existIncorp
  );
  const datoGuardado = localStorage.getItem("incorp");

  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const findLeastOne = (array, boolean) => {
    if (!array) {
      return undefined
    }
    let aux = array.find((elm) => elm.isPersonalIncorporated == boolean);

    return aux;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [windowWidth]);

  let ConsultantsGroup = 0;
  let varRestGroup = 0;

  for (
    let index = 0;
    index < stepsData?.personalGroup?.consultantsGroup.length;
    index++
  ) {
    if (
      stepsData?.personalGroup?.consultantsGroup[index]
        ?.isPersonalIncorporated == true
    ) {
      ConsultantsGroup++;
    } else {
      varRestGroup++;
    }
  }

  return (
    <>
      <div className="container--padding--card-holder">
        <div className="w-100">
          <div
            className=" bg-white w-100 position-relative h-100"
            style={{ zIndex: 1 }}
          >
            <div className="table--header--1eraGen px-0 pt-4 museo-sans-500 s12 grayColor">
              <p>
                {" "}
                <FormattedMessage id="group.incorporations.descendant.name" />{" "}
              </p>
              <p className=" text-uppercase">
                {" "}
                <FormattedMessage id="first.steps.page.table.header.advance" />
              </p>
              <p className=" text-uppercase">
                {" "}
                <FormattedMessage id="first.steps.page.table.header.progress" />
              </p>
              <p></p>
            </div>
            {findLeastOne(stepsData?.personalGroup?.consultantsGroup, true) ? (
              <>
                <div
                  style={{
                    paddingBottom: "73px",
                    maxHeight: "827px",
                    minHeight: biggerThan1000
                      ? "980px"
                      : !windowWidth == 1280
                      ? "350px"
                      : "390px",
                  }}
                  className="hidden-y-scroll"
                >
                  {stepsData?.personalGroup?.consultantsGroup?.map((elm) => {
                    return (
                      <>
                        {elm.isPersonalIncorporated == true && (
                          <>
                            <DashboardFirstStepsRow
                              elm={elm}
                              short={false}
                              incorp={"true"}
                              key={ShareKeyGenerator()}
                            />
                            <hr
                              style={{
                                marginBottom: "0px",
                                marginTop: "0px",
                              }}
                            ></hr>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                {datoGuardado == true ? (
                  <></>
                ) : (
                  <div
                    style={{
                      paddingBottom: "73px",
                      maxHeight: "827px",
                      minHeight: biggerThan1000
                        ? "250px"
                        : !windowWidth == 1280
                        ? "350px"
                        : "390px",
                    }}
                    className="hidden-y-scroll d-flex flex-column w-100 justify-content-center align-items-center"
                  >
                    <p className="museo-sans-500 s24">
                      <FormattedMessage id="ffss.no.info" />
                    </p>
                    <div style={{ marginLeft: "90px", marginRight: "90px" }}>
                      <p className="museo-sans-500 s14 grayColor text-center">
                        <FormattedMessage id="ffss.no.info.desc" />
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DarshboardFirstStepsPersonalIncorporated;
