import React from "react";
import { FormattedMessage } from "react-intl";
import ProfileWeb from "../../../common/img/ProfileModal.png";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

export default function LoginProfileModal({onCameraControl, handleModal, openCameraModal}) {


  const onAccept = (evt) => {
    handleModal(evt);
    onCameraControl(evt);
  };

  return (
    <div
      className="modal fade"
      id="profileModalLabel"
      aria-hidden="true"
      aria-labelledby="profileModalLabel"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        
      >
        <div style={{width: "496px", height: "502px" }}  className="modal-content modal-radius">
          <div className="modal-body d-flex px-3" style={{ width: "490px", height: "500px", alignContent: "center" }}>
            <div
              className=" position-absolute"
              style={{ top: "15px", right: "15px" }}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <SVGClose />
            </div>

            <div>
              <div className="d-flex flex-column align-items-center my-3">
                <img src={ProfileWeb} alt="" className="my-3" />
                <div className="d-flex flex-column align-items-center mb-4">
                  <h5 className="museo-sans-700 s24">
                  <FormattedMessage
            id="login.profile.modal.title"
            defaultMessage="1. Profile settings"
          />
                    </h5>
                  <p className="museo-sans-500 s16 grayColor text-center">
                  <FormattedMessage
            id="login.profile.modal.desc"
            defaultMessage="1. Profile settings"
          />
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <button
                  type="button"
                  className=" btnLoginWidth mb-3 museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-border-none button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openCameraModal}
                  
                >
                  <FormattedMessage
            id="buttons.access.cam"
            defaultMessage="1. Profile settings"
          />
                </button>
                <button
                  type="button"
                  className=" btnLoginWidth mb-3 museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-bootstrap-radius button-padding"
                  style={{ background: "transparent" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
            id="buttons.cancel"
            defaultMessage="1. Profile settings"
          />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
