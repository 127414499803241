import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import { ReactComponent as SVGTrash } from "../img/trashCan.svg";

export default function EDBDetailsTableList({
  data,
  type,
  loading,
  cultureInfo,
  setInviteeInfo,
}) {
  return (
    <div className=" w-100 mb-3 container--padding container--no-padding py-3">
      <h3 className="py-4 s20 museo-sans-500">
        {type !== "something" ? (
          <FormattedMessage id="edb.details.list.title.assist" />
        ) : (
          <FormattedMessage id="edb.details.list.title.invited" />
        )}
      </h3>
      <div>
        <div className=" d-flex justify-content-between py-1">
          {loading ? (
            <SkeletonEDBDetailsList />
          ) : (
            <p className=" museo-sans-500 s12 text-uppercase grayColor">
              <FormattedMessage id="edb.details.list.name" />
            </p>
          )}

          {type !== "something" && (
            <>
              {loading ? (
                <SkeletonEDBDetailsList type={type} />
              ) : (
                <p className=" museo-sans-500 s12 text-uppercase grayColor">
                  <FormattedMessage id="edb.details.list.buy" />
                </p>
              )}
            </>
          )}
        </div>
        <div className=" museo-sans-700">
          <>
            {loading ? (
              <>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
                <hr></hr>
                <div className="edb--details-table">
                  <SkeletonEDBDetailsListRow type={type} />
                </div>
              </>
            ) : (
              <>
                {data ? (
                  data.attendees.length > 0 ? (
                    data.attendees.map((guest, index) => {
                      return (
                        <>
                          <hr></hr>
                          <div className=" d-flex justify-content-between ">
                            <p
                              id={guest.attendeeId}
                              className=" museo-sans-700 s14"
                            >
                              {guest.name}
                            </p>
                            <div className="d-flex s14 gap-2">
                            {type !== "fechado" && (
                                <p className=" grayColor museo-sans-700 ">
                                  {guest.totalAmount !== 0 ? (
                                    <>
                                      {data && (
                                        <>
                                          {formatAmount(
                                            guest.totalAmount,
                                            cultureInfo?.languageCode,
                                            cultureInfo?.defaultCurrencyCode
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <FormattedMessage id="edb.details.list.no.buys" />
                                  )}
                                </p>
                              )}
                                {((guest.totalAmount == 0 && index != 0) && ( ShareCheckEnvKey("CREATE_EDB") )) && (
                                  <div
                                  data-bs-toggle="modal"
                                  data-bs-target="#modalDeleteInvitee"
                                  onClick={() =>
                                    setInviteeInfo({id: guest.attendeeId, name: guest.name})
                                  }
                                  style={{ top: -2, width: "16px" }}
                                  className=" pointer position-relative svg-force-size "
                                  >
                                    <SVGTrash />
                                  </div>
                                )}
                                </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <hr></hr>
                      <div></div>
                    </>
                  )
                ) : null}
                <hr></hr>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export function SkeletonEDBDetailsList() {
  return (
    <div
      style={{ height: "8px", width: "47px" }}
      className="lightui1-shimmer brightGrayBg rounded-3 "
    ></div>
  );
}

export function SkeletonEDBDetailsListRow({ type }) {
  return (
    <>
      <div
        style={{ height: "8px", width: "47px" }}
        className="lightui1-shimmer brightGrayBg rounded-3 "
      ></div>
      {type != "fechado" && (
        <div
          style={{ height: "8px", width: "47px" }}
          className="lightui1-shimmer brightGrayBg rounded-3 "
        ></div>
      )}
    </>
  );
}
