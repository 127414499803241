import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const getClientList = createAsyncThunk(
    'getClientList',
    async (data , {rejectWithValue}) => {
        try {
            
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/contacts-data/${clientId}/contacts`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getClientDetails = createAsyncThunk(
    'getClientDetails',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/contacts-data/${clientId}/contact/${data != "id" ? data : "110485"}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getClientDetailsLinks = createAsyncThunk(
    'getClientDetailsLinks',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/external-links/${clientId}/contact/${data != "id" ? data : "110485"}/links`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getTerritoryByLevel = createAsyncThunk(
    'getTerritoryByLevel',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/territory-divisions/${clientId}/locations?level=${!data?.level ? 1 : `${data?.level}${data?.value ? `&parent_id=${data.value}` : ''}` }`);
            const object = {data: response, level: data?.level || 1}
            return object;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
) 

const deleteClient = createAsyncThunk(
    "deleteClient",
    async (data,{ rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().delete(`${location}/contacts-data/${clientId}/contact/${data != "id" ? data : "110485"}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const postClient = createAsyncThunk(
    "postClient",
    async (data,{ rejectWithValue}) => {
        try {
            debugger
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400]).post(`${location}/contacts-data/${clientId}/contact`, data);
            if (!response) {
                throw new Error("Error al crear el cliente");
            }
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const putClient = createAsyncThunk(
    "putClient",
    async (data,{ rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400]).put(`${location}/contacts-data/${clientId}/contact/${data?.contactId}`, data);
            if (!response) {
                throw new Error("Error al crear el cliente");
            }
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

export {
    getClientList,
    getClientDetails,
    getClientDetailsLinks,
    deleteClient,
    getTerritoryByLevel,
    postClient,
    putClient
} 