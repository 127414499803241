import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function LoginPassRec() {
  const history = useNavigate();

  const message = useSelector((state) => state.userInfo.message);

  const handlePassRecovery = (evt) => {
    evt.preventDefault();
    history("/recovery/pin");
  };

  return (
    <div id="login-layout" className=" container--padding py-5">
      <div className="">
        <h1 className="mt-3 grayColor s12 museo-sans-500 text-uppercase">
        <FormattedMessage
              id="login.recovery.page.title"
              defaultMessage="1. Validate identity"
              />
          </h1>
        <hr></hr>
        <h2 className="s24 museo-sans-700">
        <FormattedMessage
              id="login.page.password.recovery.title"
              defaultMessage="Forgot password?"
              />
          </h2>
        <p className="s16 museo-sans-500  w-75">
        <FormattedMessage
              id="login.page.password.recovery.description.one"
              defaultMessage="Estás por comenzar el proceso de recuperación de tu contraseña. Te enviaremos un código de verificación al email que tenemos registrado en tus datos del sistema actual. "
              />
          </p>
          <p className="s16 my-3 museo-sans-500  w-75" >
        <FormattedMessage
              id="login.page.password.recovery.description.two"
              defaultMessage="Te enviaremos un código de verificación al email que tenemos registrado en tus datos del sistema actual."
              />
          </p>
          <p className="s16 museo-sans-500 ">
            {message ? message : ""}
          </p>
      </div> 
      <div className="  align-self-end">
        <button className="s14 btnLoginWidth museo-sans-700 py-2 text-white btnCustom skyBg" onClick={handlePassRecovery}>
        <FormattedMessage
              id="buttons.send.code"
              defaultMessage="Recover password"
              />
          </button>
      </div>
    </div>
  );
}
