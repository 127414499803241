import React, { useState } from 'react'
import '../pages/EDBs.css'

export default function EDBFourStepsCard({children, image}) {
    
  return (
    <div className=" edb-four-steps edb-card-box-shadow edb-card-radius d-flex flex-column bronceBg overflow-hidden">
        <div>
           
            <img src={image} alt="" className='w-100'/>
            
        </div>
        <div className='d-flex flex-column justify-content-between p-4'>
            {children}
        </div>

    </div>
  )
}

// style={{width: "252px", height: "446px"}}