import { Navigate, Outlet, useLocation } from "react-router-dom";
import PublicLayout from "../UI/PublicLayout";
import PrivateLayout from "../UI/PrivateLayout";
import { createContext, useState } from "react";
import ShareModalClipboard from "../Share/ShareModalClipboard";

const ModalClipboardContext = createContext();

export function PrivateRoute() {
  const token = localStorage.getItem("token");
  return token ? <PrivateLayout /> : <Navigate replace to="/" />;
}
export function PublicRoute() {
  const location = useLocation();

  const [modalClipboardData, setModalClipboardData] = useState({
    title: "",
    text: "",
    status: "confirm",
    show: false,
    bottom: 0,
  });

  const toggleModalClipboard = () => {
    setModalClipboardData({
      ...modalClipboardData,
      show: !modalClipboardData.show,
    });
  };

  const setModalTexts = (params) => {
    setModalClipboardData({
      bottom: params.bottom,
      title: params.title,
      text: params.text,
      status: params.status,
      show: true,
    });
  };

  return (
    <ModalClipboardContext.Provider value={{ setModalTexts }}>
      <ShareModalClipboard
        data={modalClipboardData}
        close={toggleModalClipboard}
      />
      {location?.pathname?.indexOf("/dashboard") === -1 ? (
        <PublicLayout />
      ) : (
        <Outlet />
      )}
    </ModalClipboardContext.Provider>
  );
}


export {ModalClipboardContext}