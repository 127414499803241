import ClientListTerritoryPerCountry from "../ClientListTerritoryPerCountry";
import { multiLangJSONParse } from "../../../../common/Share/MultiLangJSONParse";
import ShareModalShareBody from "../../../../common/Share/ShareModalShareBody";
export default function ClientListDetailsModalShare({ socialArray, data, open }) {

  const configExist = (auxId, clientData) => {
    let resolve = 0;
      
    if (!clientData?.socialNetworks?.length == 0) {
      resolve = clientData ? clientData?.socialNetworks?.length == 0 ? "" : clientData?.socialNetworks.map(elm => elm.id).indexOf(auxId) : socialArray.map(elm => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const resolvePhone = (data, index) => {
    if (!Array.isArray(data)) {
      return "";
    }
    if (data?.length == 0) {
      return "";
    }
    let auxData = data[index ? index : 0];
    let auxString = (auxData?.areaCode ? auxData?.areaCode : "" )+""+auxData?.number;
    return auxString
  };

  const resolveAddress = (data, isMobile) => {
    if (!Array.isArray(data)) {
      return "";
    }
    if (data?.length == 0) {
      return "";
    }
    let arrayInfo = ClientListTerritoryPerCountry(data);
    let resultValue = "";

    if (!arrayInfo) {
      return "";
    }

    arrayInfo.forEach(element => {
      if (element == null) {
        return "";
      }
      if (isMobile) {
        resultValue = resultValue + "%20" + element
      } else {
        resultValue = resultValue + " " + element
      }
      
    });

    return resultValue

  };

  const birthdayControl = (stringBirthday, isMobile) => {
    if (!stringBirthday) {
      return "";
    }
    let splitValues = stringBirthday.split("-");

    let daySplitted = splitValues[1];
    let monthSplitted = splitValues[0];

    daySplitted = +daySplitted;
    monthSplitted = +monthSplitted;

    if (isMobile) {
      return `${daySplitted}%20${multiLangJSONParse()["of"]}%20${multiLangJSONParse()[`month.${monthSplitted}`]}`;
    }

    return (`${daySplitted} ${multiLangJSONParse()["of"]} ${multiLangJSONParse()[`month.${monthSplitted}`]}`);
  };

    const sharedDataPhoneText = (socialArray || Array.isArray(data?.addressInfo)) && `${multiLangJSONParse()["client"]}:%20${data?.firstName}%20${data?.lastName}${data?.phoneNumbers ? `%0A${multiLangJSONParse()["phone.share"]}:%20${resolvePhone(data?.phoneNumbers, 0)}` : ""}${data?.phoneNumbers?.length > 1 ? `%0A${multiLangJSONParse()["phone.share.altenative"]}:%20${resolvePhone(data?.phoneNumbers, 1)}` : ""}${configExist("email", data) !== -1 ? data?.socialNetworks[configExist("email", data)]?.value == "" ? "" : `%0A${multiLangJSONParse()["settings.user.data.email"]}:%20${configExist("email", data) !== -1 ? data?.socialNetworks[configExist("email", data)]?.value : ""}` : ""}${data?.addressInfo ? `%0A${multiLangJSONParse()["settings.user.data.address"]}:${resolveAddress(data?.addressInfo, true)}` : `` }${data?.birthday ? `%0A${multiLangJSONParse()["birthday"]}:%20${birthdayControl(data?.birthday, true)}` : ``}`;

    const sharedData = (socialArray || Array.isArray(data?.addressInfo)) && `${multiLangJSONParse()["client"]}: ${data?.firstName} ${data?.lastName}${data?.phoneNumbers ? `\n${multiLangJSONParse()["phone.share"]}: ${resolvePhone(data?.phoneNumbers, 0)}` : ""}${data?.phoneNumbers?.length > 1 ? `\n${multiLangJSONParse()["phone.share.altenative"]}: ${resolvePhone(data?.phoneNumbers, 1)}` : ""}${configExist("email", data) !== -1 ? data?.socialNetworks[configExist("email", data)]?.value == "" ? "" : `\n${multiLangJSONParse()["settings.user.data.email"]}: ${configExist("email", data) !== -1 ? data?.socialNetworks[configExist("email", data)]?.value : ""}` : ""}${data?.addressInfo ? `\n${multiLangJSONParse()["settings.user.data.address"]}:${resolveAddress(data?.addressInfo, false)}` : `` }${data?.birthday ? `\n${multiLangJSONParse()["birthday"]}: ${birthdayControl(data?.birthday, false)}` : ``}`;
    
    const doThis = async () =>{
      try {
        await navigator.clipboard.writeText(sharedData);
        open();
        
      } catch (error) {
        unsecuredCopyToClipboard(sharedData);
      }
    }

    const unsecuredCopyToClipboard = async (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        open();
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    }
  
  return (
    <>
      {socialArray && (
        <div
          className="modal fade"
          id="modalShare"
          aria-hidden="true"
          aria-labelledby="modalShare"
          tabIndex="-1"
        >
         
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "556px" }}
          >
            <div className="modal-content modal-radius">
              <div className="modal-body p-0">
              <ShareModalShareBody modalTitle={"buttons.client.share.data"}  sharedDataPhoneText={sharedDataPhoneText} sharedData={sharedData} socialArray={socialArray} configExist={configExist} doThis={doThis} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
