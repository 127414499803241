import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGCopy } from "../../../common/svgs/ic-copy-md.svg";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import ShareModalShareBody from "../../../common/Share/ShareModalShareBody";

export default function DashboardOffersShare({
  toggle,
  socialArray,
  user,
  open,
}) {
  const configExist = (auxId, message) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const sharedDataPhoneText =
    socialArray &&
    `${multiLangJSONParse()["share.message.dashboard.offers.share.phone.parse.one"]}`;

  const sharedData =
    socialArray &&
    `${multiLangJSONParse()["share.message.dashboard.offers.share.web.parse.one"]}`;

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(sharedData);
      open();
    } catch (error) {
      unsecuredCopyToClipboard(sharedData);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      open();
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };
  return (
    <>
      {socialArray && (
        <div
          className="modal fade"
          id="modalOffersShare"
          aria-hidden="true"
          aria-labelledby="modalOffersShare"
          tabIndex="-1"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "556px" }}
          >
            <div className="modal-content modal-radius">
              <div className="modal-body p-0">
              <ShareModalShareBody modalTitle={"share.app"}  sharedDataPhoneText={sharedDataPhoneText} sharedData={sharedData} socialArray={socialArray} configExist={configExist} doThis={doThis} />
                <div className=" pt-3 mb-4 pb-3 px-3 mx-4 ">
                  <h3 className=" m-0 museo-sans-500 s16">
                    <FormattedMessage id="invitation.add.modal.link.text" />
                  </h3>
                  <button
                    data-bs-dismiss="modal"
                    onClick={doThis}
                    style={{ borderRadius: "8px" }}
                    className=" mt-3 w-100 bg-transparent d-flex align-items-center justify-content-between blueBorder grayColor "
                  >
                        <p className=" px-3 py-2 my-1  museo-sans-500 s14">
                        https://app.swissjust.com/
                        </p>
                    <div className="mx-1 me-2 mb-1" style={{ height: "24px" }}>
                      <SVGCopy />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
