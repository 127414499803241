import Vimeo from "@u-wave/react-vimeo";
import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as SVGx } from "../../../../common/svgs/ic-x-overlay.svg";
import { useMediaPredicate } from "react-media-hook";

export default function DashboardFirstStepsVideo({ sNumber, level, pLeader }) {
  const [toggler, setToggler] = useState(false);
  const [canSkip, setCanSkip] = useState(false);
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");
  const country = localStorage.getItem("country");

  const onClose = () => {
    setToggler(false);
    let object = JSON.parse(localStorage.getItem("videoFour"));
    object.seen = object.seen + 1;
    if (object.seen == 3) {
      object.status = "false";
    }
    object = JSON.stringify(object);
    localStorage.setItem("videoFour", object);
  };

  const onCloseAndSeal = () => {
    setToggler(false);
    let object = JSON.parse(localStorage.getItem("videoFour"));
    object.status = "false";
    object = JSON.stringify(object);
    localStorage.setItem("videoFour", object);
  };

  useEffect(() => {
    let result = localStorage.getItem("firstLogin");
    let object = JSON.parse(localStorage.getItem("videoFour"));
    setTimeout(() => {
      if (sNumber == 20 || result == null) {
        if (object.status == "true") {
          setToggler(true);
        }
        setTimeout(() => {
          setCanSkip(true);
        }, 3000);
      }
    }, 1000);
  }, [sNumber]);

  return (
    <>
      {toggler && (country == "pa") && (
        <div
          style={{ width: "100%", height: "100%", top: "0", left: "0" }}
          className=""
          id="vimeo--displayer"
        >
          <div
      style={{ zIndex: "998" }}
      className="bg-black d-block position-absolute opacity-75 w-100 h-100"
      onClick={onClose}
    ></div>
          {
            canSkip &&
              <>
          <VimeoRedCloseZone onClose={onClose} />
          <div
            onClick={onClose}
            style={
              biggerThan1921
                ? {
                    zIndex: "10000",
                    top: "42px",
                    right: "42px",
                    width: "42px",
                    height: "42px",
                  }
                : {
                    zIndex: "10000",
                    top: "10px",
                    right: "10px",
                    width: "32px",
                    height: "32px",
                  }
            }
            className="position-absolute whiteColor pointer svg-force-size "
          >
            <SVGx />
          </div>
          <div
            onClick={onClose}
            style={
              biggerThan1921
                ? {
                    zIndex: "10000",
                    bottom: "42px",
                    right: "42px",
                  }
                : {
                    zIndex: "10000",
                    bottom: "10px",
                    right: "10px",
                  }
            }
            className="position-absolute whiteColor pointer svg-force-size "
          >
            <p onClick={onCloseAndSeal} className=" museo-sans-700 pointer" >
            No volver a mostrar
            </p>
          </div>
              </>
          }
          
          <VimeoHolder level={level} pLeader={pLeader} />
        </div>
      )}
    </>
  );
}

export function VimeoHolder({ level, pLeader }) {
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");

  return (
    <div
      id="vimeo--holder"
      className=" shadow position-relative vh-100 d-flex justify-content-center mt-2"
      style={{ zIndex: "999", alignItems: "center" }}
    >
      <div
        style={
          biggerThan1921
            ? { width: "538px", height: "896px" }
            : { width: "338px", height: "696px" }
        }
        className=" position-relative"
      >
        <div
          style={
            biggerThan1921
              ? {
                  width: "508px",
                  height: "961px",
                  left: "15px",
                  justifyItems: "center",
                }
              : { width: "338px", height: "696px" }
          }
          className=" position-absolute "
        >
          <div
            style={
              biggerThan1921
                ? {
                    width: "538px",
                    height: "928px",
                    bottom: "20px",
                    zIndex: "800",
                    justifyItems: "center",
                  }
                : { width: "338px", height: "680px" }
            }
            className=" position-absolute bg-black w-100 shadow "
          ></div>
          <div
            style={
              biggerThan1921
                ? { zIndex: "900", top: "15px" }
                : { zIndex: "900" }
            }
            className=" d-flex justify-content-center position-absolute"
          >
            <Vimeo
              speed={true}
              showTitle={true}
              showPortrait={false}
              controls={true}
              autoplay={false}
              showByline={true}
              video={`https://player.vimeo.com/video/${"932096952"}?h=${"d7fcd1ec1f"}&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function VimeoRedCloseZone({ onClose }) {
  return (
    <>
      <div
        onClick={onClose}
        style={{ zIndex: "1004", width: "26%" }}
        className=" position-absolute  h-100 "
      ></div>
      <div
        onClick={onClose}
        style={{ zIndex: "1004", width: "26%", right: "0px" }}
        className=" position-absolute  h-100 "
      ></div>
    </>
  );
}
