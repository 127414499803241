import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postInvitation } from "../../Framework/redux/InvitationsSideEffect";
import { newInvitationCreate } from "../../Framework/redux/InvitationsAction";
import InvitationsAddForm from "../components/InvitationsAddForm";
import InvitationsAddShare from "../components/InvitationsAddShare";
import SharePhoneLibrary from "../../../common/Share/SharePhoneLibrary";

export default function InvitationsAdd() {
  const [form, setForm] = useState({
    firstName: { value: "", error: false },
    lastName: { value: "", error: false },
    phone: { area: "", body: "", error: false },
    email: { value: "", error: false },
    reference: { value: "", error: false },
  });
  const [formErrorToggle, setFormErrorToggle] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [link, setLink] = useState(null);

  const country = localStorage.getItem("country");

  const dispatch = useDispatch();
  const history = useNavigate();

  const handleChange = (field, newValue) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: {
        ...prevForm[field],
        ...(field === "phone" ? { body: newValue } : { value: newValue }),
        error: false,
      },
    }));
    setFormErrorToggle(false);
  };

  const checkEmail = (email) => {
    let emailRegex =
      /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (emailRegex.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const changeFirstName = (newValue) => handleChange("firstName", newValue);
  const changeLastName = (newValue) => handleChange("lastName", newValue);
  const changeEmail = (newValue) => handleChange("email", newValue);
  const changeReference = (newValue) => handleChange("reference", newValue);
  const changePhoneBody = (newValue) => {
    setForm({ ...form, phone: { body: newValue, area: form?.phone?.area } });
    setFormErrorToggle(false);
  };
  const changePhoneArea = (newValue) => {
    setForm({ ...form, phone: { area: newValue, body: form?.phone?.body } });
    setFormErrorToggle(false);
  };

  const checkPhone = async (area, body) => {
    if (body.trim() === "") {
      return false;
    };

    if (isNaN(area) || isNaN(body)) {
      return true;
    };

    const result = await SharePhoneLibrary(body);

    return result;
  };

  const isFailedSubmit = async () => {
    let auxErrors = {
      email: false,
      firstName: false,
      lastName: false,
      phone: false,
    };
    if (checkEmail(form.email.value) && form.email.value != "") {
      auxErrors = {
        email: true,
        firstName: auxErrors.firstName,
        lastName: auxErrors.lastName,
        phone: auxErrors.phone,
      };
    }
    if (form.firstName.value.trim() === "") {
      auxErrors = {
        email: auxErrors.email,
        firstName: true,
        lastName: auxErrors.lastName,
        phone: auxErrors.phone,
      };
    }
    let isPhoneError = await checkPhone(form.phone.area, form.phone.body);
    if (isPhoneError) {
      auxErrors = {
        email: auxErrors.email,
        firstName: auxErrors.firstName,
        lastName: auxErrors.lastName,
        phone: true,
      };
    }
    if (
      auxErrors?.email ||
      auxErrors?.firstName ||
      auxErrors?.lastName ||
      auxErrors?.phone
    ) {
      setFormErrorToggle(true);
      setForm({
        firstName: { value: form.firstName.value, error: auxErrors.firstName },
        lastName: { value: form.lastName.value, error: auxErrors.lastName },
        phone: {
          area: form.phone.area,
          body: form.phone.body,
          error: auxErrors.phone,
        },
        email: { value: form.email.value, error: auxErrors.email },
      });
      return true;
    }

    return false;
  };

  const onListClick = () => {
    history("/dashboard/invitations/list");
  };

  const onSubmit = async () => {
    let isFailedResult = await isFailedSubmit();
    if (isFailedResult) {
      return;
    };

    const body = {
      firstName: form?.firstName?.value,
      lastName: form?.lastName?.value,
      email: form?.email?.value,
      phone:
        form?.phone?.body.trim() === ""
          ? ""
          : `${form?.phone?.area}${form?.phone?.body}`,
      reference: form?.reference?.value,
    };
    const response = await dispatch(postInvitation(body));

    if (response?.payload?.invitationLink) {
      dispatch(newInvitationCreate());
      setIsSubmitSuccess(true);
      setLink(response?.payload?.invitationLink);
    }
  };

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52", // +52 Mex
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const placeHoldeCountry = {
    ar: "Ej.: 1123456789",
    cr: "Ej.: 12345678",
    pa: "Ej.: 12345678",
  };

  useEffect(() => {
    setForm({
      ...form,
      phone: {
        area: numbers[country],
        body: form?.phone?.body,
      },
    });
  }, []);

  return (
    <>
      {isSubmitSuccess ? (
        <>
          <InvitationsAddShare
            form={form}
            onListClick={onListClick}
            link={link}
          />
        </>
      ) : (
        <>
          <InvitationsAddForm
            form={form}
            formErrorToggle={formErrorToggle}
            changeFirstName={changeFirstName}
            changeLastName={changeLastName}
            changePhoneArea={changePhoneArea}
            changePhoneBody={changePhoneBody}
            changeEmail={changeEmail}
            changeReference={changeReference}
            onSubmit={onSubmit}
            placeHoldeCountry={
              placeHoldeCountry[country] || placeHoldeCountry["cr"]
            }
          />
        </>
      )}
    </>
  );
}
