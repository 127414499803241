import { useLocation, useNavigate } from "react-router-dom";
import Radius from "../../../common/Share/Radius";

export default function EDBsFormRouter(params) {

    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    const isVirtual = pathname.includes("virtual");

    const onChangeVirtual = () => {
        navigate("/dashboard/edbs/form/type/virtual")

    };
    const onChangePresent = () => {
        navigate("/dashboard/edbs/form/type/present")

    };
    
    return (
        <div>
                <div className="my-2">
                    <div className="d-flex" >
                        <div
                        onClick={() => onChangePresent()}
                        className=" pe-3 py-2 my-1 d-flex pointer gap-2"
                        >
                        <Radius status={!isVirtual} />
                        <span className={`${!isVirtual ? " s14" : "grayColor s14"}`}>
                            Encuentro Presencial
                        </span>
                        </div>
                        <div
                        onClick={() => onChangeVirtual()}
                        className=" px-3 py-2 my-1 d-flex pointer gap-2"
                        >
                        <Radius status={isVirtual} />
                        <span className={`${isVirtual ? " s14" : "grayColor s14"}`}>
                            Encuentro Virtual
                        </span>
                        </div>

                    </div>
                  </div>
                </div>
    )
}