import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";
import ShareLocalityController from "../../../common/Share/ShareLocalityController";
import SharePhoneLibrary from "../../../common/Share/SharePhoneLibrary";

const numbers = {
  ar: "+549",
  cl: "+56",
  co: "+57",
  cr: "+506",
  mx: "+52",
  pa: "+507",
  pe: "+51",
  uy: "+598",
};

const fetchTopics = createAsyncThunk(
  "fetchTopics",
  async (number, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios().get(
        `/${location}/edbs/${userId}/appointment/topics`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchEDBDetailsForFormBody = createAsyncThunk(
  "fetchEDBDetailsForFormBody",
  async (edbId, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios().get(
        `/${location}/edbs/${userId}/appointment/${edbId}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const checkFormValues = async (form, country) => {
  if (!form?.date) {
    return true;
  }
  if ((!form?.time || form?.time == "time" )) {
    return true;
  }
  if (!form?.topicId) {
    return true;
  }
  if (!form?.edbType) {
    return true;
  }
  if (form?.edbType == "virtual") {
    form.addressInfo = null;

    if (form.conference.url == "") {
      return true;
    }
  }
  if (form?.edbType == "presencial") {
    form.streaming = null;
    form.conference = null;
    form.public = false;
    let isError = false;

    const references = ShareLocalityController(country)?.references;

    references.forEach((reference) => {
      if (reference.name !== "reference") {
        if (form.addressInfo[reference.name] == "") {
          isError = true;
        }
      }
    });
    if (isError) {
      return true;
    }
  }
  if (!form?.hostData?.hostId) {
    return true;
  }

  if (!form?.contactEdbPhone?.phone) {
    return false;
  }
  
    let isPhoneError = await SharePhoneLibrary(form?.contactEdbPhone.phone);
    if (isPhoneError) {
      let responde = {msg: "El teléfono ingresado no es válido", status: isPhoneError};
      return responde;
      
    }

  return false;
};

const postNewEdb = createAsyncThunk(
  "postNewForm",
  async (form, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      let isError = await checkFormValues(form, location);
      if (isError) {
        if (isError.status) {
          throw new Error(isError.msg);
        }
        throw new Error("Faltan datos en el formulario");
      }
      if (form.contactEdbPhone.phone) {
        let valueCountry = numbers[location].concat(form?.contactEdbPhone.phone);
        form.contactEdbPhone.phone = valueCountry;
        debugger

      }
      const response = await customNetServAxios(
        "application/json;charset=UTF-8",
        false,
        [400, 500]
      ).post(`/${location}/edbs/${userId}/appointment`, form);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const putEdb = createAsyncThunk("putEdb", async (data, { rejectWithValue }) => {
  try {
    let location = localStorage.getItem("country");
    let userId = localStorage.getItem("clientId");
    let isError = await checkFormValues(data.form, location);
    debugger
    if (isError) {
      throw new Error("Faltan datos en el formulario");
    }
    if (data.form.contactEdbPhone.phone) {
      let valueCountry = numbers[location].concat(data.form?.contactEdbPhone.phone);
      data.form.contactEdbPhone.phone = valueCountry; 
      debugger
    }
    const response = await customNetServAxios(
      "application/json;charset=UTF-8",
      false,
      [400]
    ).put(`/${location}/edbs/${userId}/appointment/${data.id}`, data.form);
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const deleteEdb = createAsyncThunk(
  "deleteEdb",
  async (id, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios(
        "application/json;charset=UTF-8",
        false,
        [400]
      ).delete(`/${location}/edbs/${userId}/appointment/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const deleteInvitee = createAsyncThunk(
  "deleteInvitee",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios().delete(
        `/${location}/edbs/${userId}/appointment/${data.edbId}/assistants/${data.inviteeId}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

  const addInvitee = createAsyncThunk(
    "addInvitee",
    async (data, { rejectWithValue }) => {
      try {
        let location = localStorage.getItem("country");
        let userId = localStorage.getItem("clientId");
        const response = await customNetServAxios(null,null,[400]).post(
          `/${location}/edbs/${userId}/appointment/${data.edbId}/assistants/${data.attendeeId}`
        );
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    });

export { fetchTopics, postNewEdb, deleteEdb, putEdb, fetchEDBDetailsForFormBody, addInvitee, deleteInvitee };
