import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function ModalWhiteShadow({ scrollbarRef, left, bottom, width, aidHide, height }) {
  const [toggler, setToggler] = useState(true);

  const handleScroll = useCallback(() => {

    let maxScrollHeight = scrollbarRef.current.scrollHeight - scrollbarRef.current.clientHeight;

    let currentPosition = scrollbarRef.current.scrollTop;

    if (maxScrollHeight <= currentPosition + (aidHide ? aidHide : 0)) {
        setToggler(false)
    } else {
        setToggler(true)
    }
  }, []);

  useEffect(() => {
    const div = scrollbarRef?.current;
    div?.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <>
      {toggler ? (
          <div
            style={{ height: height? height : "40px", left: left? left : "5%", bottom: bottom? bottom : "100px", overflowX: "hidden", width: width? width : "90%" }}
            className=" d-flex position-absolute shadow-effect"
          ></div>
      ) : (
        <></>
      )}
    </>
  );
}
