import { createAction } from '@reduxjs/toolkit';

const changeClosedEDBsOffset = createAction('changeClosedEDBsOffset')

const saveFilters = createAction('saveFilters')
const keepRawFilters = createAction('keepRawFilters')
const resetFilters = createAction('resetFilters')
const resetOffersAndNotices = createAction('resetOffersAndnotices')
const resetFirstSteps = createAction('resetFirstSteps')

//FORM EDB Create

const clientDetailsForm = createAction('clientDetailsForm')
const clearClientDetailsForm = createAction('clearClientDetailsForm')

export  {changeClosedEDBsOffset, saveFilters, keepRawFilters, resetFilters,resetFirstSteps, resetOffersAndNotices, clientDetailsForm, clearClientDetailsForm} 