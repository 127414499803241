import React from "react";

import { FormattedMessage } from "react-intl";
import ProgressBar from "../../../../common/Share/ProgressBar";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGCheck } from "../../../../common/svgs/ic-check-circle-sm.svg";

import { ReactComponent as SVGMail } from "../../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGInstagram } from "../../../../common/svgs/ic-instagram-md.svg";
import { ReactComponent as SVGFacebook } from "../../../../common/svgs/ic-facebook-md.svg";

import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

import { ReactComponent as SVGRightArrow } from "../../../../common/svgs/ic-arrow-right-sm.svg";
import { useSelector } from "react-redux";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { useRef } from "react";
import { formatAmount } from "../../../../common/Share/FormatCurrency";

export default function DashboardQualificationFirstGenModal() {

    const profileData = useSelector((state) => state.userInfo.userInfo);

    const scrollbarRef = useRef(null);

    const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);


  return (
    <div
      className="modal fade"
      id="modalQualificationFirstGen"
      aria-hidden="true"
      aria-labelledby="modalQualificationFirstGen"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "608px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div >
              <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-700 px-2 s20 m-0">
                    <FormattedMessage
                      id="dashboard.modal.groups.qualified.title"
                      defaultMessage="Buenas Prácticas"
                    />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div ref={scrollbarRef} style={{ maxHeight: "311px" }} className=" hidden-y-scroll d-flex flex-column align-items-center">
                <div className="mt-4 px-5">
                  <p className="s16 py-2 museo-sans-700">
                  <FormattedMessage
                      id="dashboard.modal.groups.qualified.p.one"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage
                      id="dashboard.modal.groups.qualified.p.two"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <p className="s16 py-2 museo-sans-500">
                    “<FormattedMessage
                      id="dashboard.modal.p.eight.qualified.v2"
                      defaultMessage="Buenas Prácticas"
                    />” <FormattedMessage
                      id="dashboard.modal.p.eight.v2"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <p className="s16 py-2 museo-sans-700"><FormattedMessage
                      id="dashboard.modal.groups.qualified.subtitle.five"
                      defaultMessage="Buenas Prácticas"
                    /></p>
                  <div
                    style={{ width: "94px" }}
                    className=" p-2 d-flex gap-2 flex-column"
                  >
                    <ProgressBar completed={100} incompleted={true} />
                    <div className="d-flex gap-1">
                      <ProgressBar completed={100} incompleted={false} />
                      <ProgressBar completed={100} incompleted={false} />
                      <ProgressBar completed={100} incompleted={false} />
                      <ProgressBar completed={100} incompleted={false} />
                    </div>
                    <ProgressBar completed={100} incompleted={true} />
                    <p className=" s12 museo-sans-500 text-center">
                      Fátima Gutierrez
                    </p>
                  </div>
                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage
                      id="dashboard.modal.groups.qualified.p.five"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <div className=" d-flex gap-1 flex-column">
                    <p className="s16 museo-sans-700">1. <FormattedMessage
                      id="dashboard.qualification.ul.personal.sale"
                      defaultMessage="Buenas Prácticas"
                    /></p>
                    <p className="s16 museo-sans-700">
                      2. <FormattedMessage
                      id="dashboard.qualification.ul.incorporated"
                      defaultMessage="Buenas Prácticas"
                    />
                    </p>
                    <p className="s16 museo-sans-700">3. <FormattedMessage
                      id="dashboard.qualification.ul.group.sale"
                      defaultMessage="Buenas Prácticas"
                    /></p>
                  </div>
                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage
                      id="dashboard.modal.groups.qualified.p.six"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <div className=" flex-column position-relative donutHeaderBg rounded-2 d-flex justify-content-center mt-3 mx-5">
                    <div 
                      style={{ top: "10px", right: "10px" }}
                      className=" position-absolute "
                    >
                      <SVGClose />
                    </div>
                    <div className=" mt-5 d-flex align-items-center flex-column">
                    <img
          src={
            profileData.image
              ? profileData.image
              : "https://via.placeholder.com/60"
          }
          alt="profile"
          width="88"
          height="88"
          className="rounded-circle"
        />
                      <div className="mt-3">
                        <div
                          style={{ alignItems: "center" }}
                          className=" gap-1 d-flex"
                        >
                          <p className=" s16 museo-sans-700 ">
                            <FormattedMessage id="dashboard.modal.group.details.template.name" />
                          </p>
                          <SVGCheck />
                        </div>
                        <p className="mt-2 s14 text-center museo-sans-500 ">
                          <FormattedMessage id="dashboard.modal.group.details.template.rol" />
                        </p>
                        <div className=" justify-content-center d-flex gap-2 text-center s14 mt-3">
                          <p className=" museo-sans-500 grayColor ">
                          <FormattedMessage id="dashboard.modal.group.details.template.filter" />
                          </p>
                          <p className=" museo-sans-700 skyColor">
                          <FormattedMessage id="dashboard.modal.group.details.template.filter.followup" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="mx-5" />
                    <div className=" w-100 d-flex justify-content-around px-5 py-3" >
                        <SVGMail/> <SVGMessenger/> <SVGWhatsap/> <SVGInstagram/> <SVGFacebook/>
                        <div className=" align-items-center museo-sans-700 s14 skyColor d-flex gap-2" >
                        <p>
                        <FormattedMessage id="dashboard.modal.group.details.template.show.profile" />
                        </p>
                        <SVGRightArrow />
                      </div>
                      
                    </div>
                    <div className="bg-white shadow rounded-3 p-4 " >
                        <p className="s16 museo-sans-700" >
                        <FormattedMessage id="dashboard.modal.group.details.template.qualification" />
                        </p>
                        <div className="py-3">
                            <div className=" s14 d-flex align-items-center justify-content-between" >
                                <p className=" py-1 museo-sans-500" >
                                <FormattedMessage id="dashboard.qualification.ul.personal.sale" />
                                </p>
                                <div className="d-flex  dm-sans-medium " >
                                    <p className="" >
                                    {formatAmount(128.000, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                                    </p>
                                    <p className=" grayColor" >
                                        /  {formatAmount(230.000, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                                    </p>
                                </div>
                            </div>
                            <ProgressBar completed={70} incompleted={true} />
                        </div>
                        <div>
                            <div className=" d-flex align-items-center justify-content-between" >
                                <p className=" py-1 museo-sans-500 s14" >
                                <FormattedMessage id="dashboard.qualification.ul.incorporated" />
                                </p>
                                <div className=" s14 dm-sans-medium d-flex" >
                                    <p>3</p>
                                    <p>{" "}</p>
                                    <p className=" grayColor" > / 4</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2" >
                            
                        <ProgressBar completed={100} incompleted={true} />
                        <ProgressBar completed={100} incompleted={true} />
                        <ProgressBar completed={100} incompleted={true} />
                        <ProgressBar completed={0} incompleted={true} />
                            </div>
                        </div>
                        <div className="py-3">
                            <div className=" s14 d-flex align-items-center justify-content-between" >
                                <p className=" py-1 museo-sans-500" >
                                <FormattedMessage id="dashboard.qualification.ul.group.sale" />
                                </p>
                                <div className="d-flex  dm-sans-medium " >
                                    <p className="" >
                                    {formatAmount(156.000, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                                    </p>
                                    <p className=" grayColor" >
                                        /  {formatAmount(300.000, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}  300.000
                                    </p>
                                </div>
                            </div>
                            <ProgressBar completed={70} incompleted={true} />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <ModalWhiteShadow scrollbarRef={scrollbarRef} />
              <div className=" w-100 d-flex justify-content-center">
                <button
                  type="button"
                  className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
