import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ModalClipboard from "../../../common/Share/ModalClipboard";
import { getClientDetails, getClientDetailsLinks } from "../../Framework/redux/ClientListSideEffect";
import ClientListDetailsBanner from "../components/ClientListDetailsBanner";
import ClientListDetailsContent from "../components/ClientListDetailsContent";
import ClientListDetailsHeader from "../components/ClientListDetailsHeader";
import ClientListDetailsModalDelete from "../components/modals/ClientListDetailsModalDelete";
import ClientListDetailsModalHost from "../components/modals/ClientListDetailsModalHost";
import ClientListDetailsModalShare from "../components/modals/ClientListDetailsModalShare";

export default function ClientListDetails() {

    const contactId = useParams();
    const clientDetails = useSelector((state) => state.clientListData.clientDetails);
    const clientDetailsLinks = useSelector((state) => state.clientListData.clientDetailsLinks);
    const profileData = useSelector((state) => state.userInfo);
    const divRefJumpTop = useRef(null);

    const [copyToggler, setCopyToggler] = useState(false)

    const dispatch = useDispatch();

    const onStartUp = async () => {
        await dispatch(getClientDetails(contactId.id));
        await dispatch(getClientDetailsLinks(contactId.id));
    };

    const openClipboard = () => {
        setCopyToggler(true);
    }

    const closeClipboard = () => {
        setCopyToggler(false);
    }

    useEffect(() => {
        onStartUp();
    }, [])

    
  useEffect(() => {
    const myDiv = document.getElementById('my-div-private');
    myDiv.scrollTop = -1000;
  }, []);


    return(
        <>
                <div id="my-div-private" style={{top: -100}} className=" position-absolute invisible " >

                </div>
        
        <div>
            <ClientListDetailsBanner divRefJumpTop={divRefJumpTop} loading={clientDetails?.loader} />
            <ClientListDetailsModalHost buttonData={clientDetailsLinks} />
            <ClientListDetailsModalShare
                socialArray={profileData.userInfo.socialNetwork}
                open={openClipboard}
                data={clientDetails?.data}
            />
            <ClientListDetailsModalDelete  contactId={contactId.id}  />
            <div  className="container--padding" >

                            <ModalClipboard show={copyToggler} close={closeClipboard} />
                <div className=" container--padding--card-holder " >
                    <div className="mb-5 pb-4" >
                        <div className=" my-4 position-relative w-100 " >
                            <ClientListDetailsHeader buttonData={clientDetailsLinks} loading={clientDetails?.loader} data={clientDetails?.data} socialArray={clientDetails?.data?.socialNetworks} />
                            <ClientListDetailsContent loading={clientDetails?.loader} data={clientDetails?.data} socialArray={clientDetails?.data?.socialNetworks} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}