import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import DescendantProfileHeader from "../components/DescendantProfileHeader";
import DescendantsProfileTitle from "../components/DescendantsProfileTitle";
import DescendantProfileSales from "../components/DescendantProfileSales";
import DescendantProfilePersonalSales from "../components/DescendantProfilePersonalSales";

import AccountGraph from "../../../Account/Presentation/components/AccountGraph";
import {
  initArrays,
  putArrId,
} from "../../../Account/Framework/redux/AccountAction";
import {
  fetchBalance,
  fetchMetrics,
} from "../../../Account/Framework/redux/AccountSideEffect";

import DashboardQualification from "../../../Dashboard/Presentation/components/DashboardQualification";
import DashboardMemberDetailsModal from "../../../Dashboard/Presentation/components/Modals/DashboardMemberDetailsModal";
import DashboardTriperiodSale from "../../../Dashboard/Presentation/components/DashboardTriperiodSale";
import DashboardKeyModal from "../../../Dashboard/Presentation/components/Modals/DashboardKeyModal";
import DashboardQualificationFirstGenModal from "../../../Dashboard/Presentation/components/Modals/DashboardQualificationFirstGenModal";

import {
  fetchDescFirstGen,
  fetchDescSalesBills,
  fetchDesGroup,
  fetchDesProfile,
  fetchDesScore,
} from "../../Framework/redux/DescendantsSideEffect";
import DescendantModalInfo from "../components/modal/DescendantModalInfo";
import QualifiedGroupsModal from "../../../Performance/Presentation/components/QualifiedGroupsModal";
import StyledMultiLang from "../../../common/Share/StyledMultiLang";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import DashboardMemberDetailsLeadersModal from "../../../Dashboard/Presentation/components/Modals/DashboardMemberDetailsLeadersModal";
import { getPotentialLeaders } from "../../../Performance/Framework/redux/performanceSideEffects";
import { useRef } from "react";

export default function DescendantProfile() {
  const dispatch = useDispatch();

  const yearPeriod = useSelector(
    (state) => state?.headerData.period.currentYearPeriod
  );
  const profileData = useSelector((state) => state.userInfo);
  const qualifiedInfo = useSelector(
    (state) => state.performanceData.qualifiedGroups
  );
  const scrollbarRef = useRef();

  const [loader, setLoader] = useState(true);

  const periodReducer = useSelector((state) => state.periodBalanceData);

  const groupData = useSelector(
    (state) => state.descendantData.descendantGroupData
  );
  const profileDescData = useSelector(
    (state) => state.descendantData.descendantProfilaData
  );
  const scoreDescData = useSelector(
    (state) => state.descendantData.descendantScoreData
  );
  const billsDesc = useSelector(
    (state) => state.descendantData.descendantSalesWeeklyBill
  );
  const periodGap = useSelector(
    (state) => state.headerData.period.periodProcessGap
  );
  const dataFirstGen = useSelector(
    (state) => state.descendantData.dataFirstGen
  );
  const [graphToggler, setGraphToggler] = useState({
    toggleOne: true,
    toggleTwo: false,
    toggleThree: false,
    toggleFour: false,
  });

  const root = useParams();

  const handleScroll = () => {
    scrollbarRef.current.scrollTop = 0;
  };

  const onStartUp = async () => {
    
    setLoader(true)
    await dispatch(fetchDesProfile({ root: root.id }));
    await dispatch(fetchBalance());
    let  resolved = await dispatch(fetchMetrics({ root: root.id }));
    await dispatch(fetchDesScore({ root: root.id }));
    await dispatch(fetchDesGroup({ root: root.id }));
    await dispatch(getPotentialLeaders({root: root.id})); // Server PL.
    if (yearPeriod) {
      await dispatch(
        fetchDescSalesBills({ root: root.id, yearPeriod: yearPeriod })
      );
    }
    if (periodReducer) {
      await dispatch(initArrays());
    }
    await dispatch(fetchDescFirstGen({ root: root.id }));
    if (resolved?.payload?.metrics?.length == 1) {
      await dispatch(putArrId("0"))
    }
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  };

  const onClickToggle = (pointer) => {
    switch (pointer) {
      case 1:
        setGraphToggler({
          toggleOne: true,
          toggleTwo: false,
          toggleThree: false,
          toggleFour: false,
        });
        return;
      case 2:
        setGraphToggler({
          toggleOne: false,
          toggleTwo: true,
          toggleThree: false,
          toggleFour: false,
        });
        return;
      case 3:
        setGraphToggler({
          toggleOne: false,
          toggleTwo: false,
          toggleThree: true,
          toggleFour: false,
        });
        return;
      case 4:
        setGraphToggler({
          toggleOne: false,
          toggleTwo: false,
          toggleThree: false,
          toggleFour: true,
        });
        return;
    }
  };

  const onClickDate = (id) => {
    dispatch(putArrId(id))
  }

  useEffect(() => {
    onStartUp();
    handleScroll();
  }, [root]);

  return (
    <>
      {!loader ? (
        <>
          {profileDescData?.level < 2 && (
            <div
              style={{ background: "#8889DB", height: "124px", opacity: "65%" }}
              className=""
            ></div>
          )}
          {profileDescData?.level > 1 && profileDescData.level < 6 && (
            <div
              style={{ background: "#D18DEF", height: "124px", opacity: "65%" }}
              className=""
            ></div>
          )}
          {profileDescData?.level > 5 && (
            <div
              style={{ background: "#CED2FF", height: "124px", opacity: "65%" }}
              className=""
            ></div>
          )}
        </>
      ) : (
        <>
          <div
          ref={scrollbarRef}
            className=" w-100 brightGrayBg "
            style={{
              height: "124px",
              maxWidth: "inherit",
              overflow: "none",
              background:
                "linear-gradient(180deg, #DDDDDD 0%, #85848E 100%) 0% 0% no-repeat padding-box",
            }}
          ></div>
        </>
      )}

      <DashboardQualificationFirstGenModal />
      <DashboardMemberDetailsLeadersModal />
      <DashboardMemberDetailsModal />
      <QualifiedGroupsModal 
        cantidadNecesaria={qualifiedInfo?.totalNeed}
        rol={profileData?.userInfo?.roleDescription}
      />
      <DescendantModalInfo />
      <DashboardKeyModal />
      <div className="container--padding--card-holder" >
        <div>
          <div className=" w-100 container--padding container--no-padding ">
              <DescendantProfileHeader
              loading={loader}
                profileData={profileDescData}
                root={root.id}
              />
            <div
              id="desc-profile-grid"
              style={{ minHeight: "600px" }}
              className=" mt-5 mb-4 gap-4 "
            >
              <div style={{ minWidth: "288px" }} className="">
                <div className="mb-4">
                  <h2 className=" s20 museo-sans-500">
                    <FormattedMessage id="title" />
                  </h2>
                  <div className=" mt-4 cards-shadow rounded-3 bg-white f9GrayBorder ">
                    {!loader ? (
                      <DescendantsProfileTitle
                        loading={loader}
                        data={profileDescData}
                        score={scoreDescData}
                      />
                    ) : (
                      <>
                        <Skeleton />
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <h2 className=" s20 museo-sans-500">
                    <FormattedMessage id="edb.list.table.sales" />
                  </h2>
                  <div className="  d-flex flex-column gap-3 ">
                    <DescendantProfilePersonalSales loading={loader} data={dataFirstGen} />
                    <DescendantProfileSales loading={loader} bills={billsDesc} data={dataFirstGen} />
                  </div>
                </div>
              </div>
              <div>
                <AccountGraph
                  profileData={profileDescData}
                  onClickToggle={onClickToggle}
                  graphToggler={graphToggler}
                  periodReducer={periodReducer}
                  onClickDate={onClickDate}
                  onDesc={true}
                  loading={loader}
                />
              </div>
            </div>

            <div style={{ minHeight: "250px" }}>
              {
                loader ?
                <>
                  <SkeletonDashboardQualification 
                    descendant={true}
                    level={profileDescData?.level}
                    status={null}
                    reduced={null}
                    onClickOne={null}
                    onClickTwo={null}
                    toggler={null}
                    borderCompletedStyle={null}
                    borderNotCompletedStyle={null}
                    loading={null}
                  />
                </>
                :
                <>
                  {profileDescData?.level > "1" && (
                    <div className=" py-4 w-100">
                      <DashboardQualification
                        reduced={scoreDescData?.consultantStatus?.reducedRequirements}
                        status={scoreDescData?.consultantStatus?.checkMarkStatus}
                        scoreData={scoreDescData}
                        level={profileDescData?.level}
                        gap={periodGap}
                        groupsData={groupData}
                        descendant={true}
                        desc={true}
                        loader={loader}
                        root={root.id}
                      />
                    </div>
                  )}
                  {profileDescData?.level < "2" && (
                    <div className=" py-4 w-100">
                      <DashboardTriperiodSale
                        status={"normal"}
                        level={profileDescData.level}
                        gap={periodGap}
                        scoreData={scoreDescData}
                        descendant={true}
                        bills={billsDesc}
                        loading={loader}
                        root={root.id}
                      />
                    </div>
                  )}
                
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function SkeletonDashboardQualification({
  descendant,
  level,
  status,
  reduced,
  onClickOne,
  onClickTwo,
  toggler,
  borderCompletedStyle,
  borderNotCompletedStyle,
  loading,
}) {
  return (
    <>
      <div className="mb-4" >
        <div className=" museo-sans-500 d-flex gap-2 align-items-center mb-4 ">
          <h3 className=" m-0 s20">
            {descendant ? (
              "Calificaciones"
            ) : (
              <>
                {level == 1 ? (
                  <>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.title.one.b"
                      def={"Tus Calificaciones"}
                    />
                  </>
                ) : (
                  <StyledMultiLang
                    loading={loading}
                    id="dashboard.qualifications.title.one"
                    def={"Tus Calificaciones"}
                  />
                )}
              </>
            )}
          </h3>
          {!descendant && (
            <i
              data-bs-toggle="modal"
              data-bs-target="#modalQualificationn"
              className="pointer"
            >
              <HelpIconNoTarget />
            </i>
          )}
          {!descendant && (
            
              <p className=" px-3 s14 skyColor museo-sans-700">
                <>
                  <StyledMultiLang
                    loading={loading}
                    id="dashboard.qualifications.show.more"
                    def={"Ver todas tus calificaciones"}
                  />
                </>
              </p>
          )}
        </div>
        {level < 2 && (
          <div
            className=" position-relative "
            style={{ width: "200px", height: "30px" }}
          >
            <div
              onClick={onClickOne}
              style={{
                width: "184px",
                height: "38px",
                top: -9,
                borderRadius: "16px 16px 0px 0px ",
                zIndex: 0,
              }}
              className={`${
                toggler ? "lightWhiteBg" : "bg-white"
              } pointer d-flex justify-content-center align-items-center py-2   position-absolute`}
            >
              <p className=" museo-sans-500 s14 ">
                <FormattedMessage id="level.two" defaultMessage={"Líder de Grupo"} />
              </p>
            </div>
            <div
              style={{
                width: "184px",
                height: "38px",
                top: -9,
                borderRadius: "16px 16px 0px 0px ",
                left: 184,
                zIndex: 0,
              }}
              className={` ${
                toggler ? "bg-white" : "lightWhiteBg"
              } pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
            >
              <p className=" museo-sans-500 s14 ">
                <FormattedMessage id="level.one.not.inclusive" defaultMessage={"Consultora"} />
              </p>
            </div>
          </div>
        )}
        <div
          style={
            level < 2 && status == "completed"
              ? borderCompletedStyle
              : borderNotCompletedStyle
          }
          className=" position-relative w-100 bg-white f9GrayBorder cards-shadow p-4"
        >
          <div
            style={{ height: "32px" }}
            className="lightui1-shimmer w-100 d-flex justify-content-center lightGreyBg rounded-3 align-items-center   "
          >
            <div
              style={{ height: "12px", width: "440px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg   "
            ></div>
          </div>
          <div style={{ gap: "32px" }} className=" mt-4 pt-2 d-flex ">
            <div style={{height: "341px"}} className=" d-flex justify-content-between flex-column w-100 ">
              <div
                style={{ height: "16px", width: "129px" }}
                className="lightui1-shimmer brightGrayBg rounded-3 "
              ></div>
              <div className="d-flex gap-4 flex-column" >
                <SkeletonDashboardQualificationLeftRow />
                <SkeletonDashboardQualificationLeftRow />
                <SkeletonDashboardQualificationLeftRow />
                <SkeletonDashboardQualificationLeftRow />
              </div>
              <div style={{width: "130px", height: "8px"}} className="lightui1-shimmer brightGrayBg rounded-3"  >

              </div>
            </div>
            <div style={{ width: "1px" }} className="h-100 brightGrayBg lightui1-shimmer ">
              <p className=" invisible">.</p>
            </div>
            <div className=" d-flex flex-column justify-content-between w-100 ">
              <div>
                <div className="  d-flex justify-content-between ">
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer w-75 brightGrayBg rounded-3 "
                  ></div>
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer invisible w-25 brightGrayBg rounded-3 "
                  ></div>
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer invisible w-25 brightGrayBg rounded-3 "
                  ></div>
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer w-25 brightGrayBg rounded-3 "
                  ></div>
                </div>
                <div style={{gap: "43px"}} className="d-flex flex-wrap mt-4 pt-2 " >
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                </div>
              </div>
              <div style={{width: "130px", height: "8px"}} className="lightui1-shimmer brightGrayBg rounded-3"  >

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function SkeletonDashboardQualificationLeftRow(params) {
  return (
    <>
      <div className=" gap-3 d-flex flex-column">
        <div className=" d-flex  justify-content-between">
          <div style={{height: "12px"}} className="lightui1-shimmer rounded-3 w-100 brightGrayBg "></div>
          <div style={{height: "12px"}} className=" rounded-3 w-100  "></div>
          <div className=" w-100 d-flex gap-1 " >
            <div style={{height: "8px"}} className="lightui1-shimmer rounded-3 w-100 brightGrayBg "></div>
            <div style={{height: "8px"}} className="lightui1-shimmer rounded-3 w-100 brightGrayBg "></div>
          </div>
          
        </div>
        <div style={{height: "12px"}} className="lightui1-shimmer mt-1 w-100 rounded-3 brightGrayBg ">

        </div>
      </div>
    </>
  );
}

export function SkeletonDashboardQualificationRight(params) {
  return <>
    <div style={{width: "62px"}}  className=" d-flex flex-column align-items-center
     gap-2 position-relative" >
      <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >

      </div>
      <div style={{gap: "2px"}} className=" w-100 d-flex " >
      <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >

</div>
<div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >

      </div>
      <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >

      </div>
      <div style={{height: "6px"}} className="lightui1-shimmer rounded-3 brightGrayBg w-100 " >

      </div>
      </div>
      <div style={{height: "6px"}} className=" lightui1-shimmerrounded-3 brightGrayBg w-100 " >

      </div>
      <div style={{height: "6px", width: "21px"}} className="lightui1-shimmer rounded-3 brightGrayBg  " >

      </div>
      <div style={{height: "6px", width: "31px"}} className="lightui1-shimmer rounded-3 brightGrayBg  " >

      </div>
    </div>
  </>
}

export function Skeleton(params) {
  return (
    <>
      <div
        style={{ height: "174px" }}
        className=" d-flex justify-content-between flex-column p-3"
      >
        <div className=" p-3 d-flex gap-2 ">
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "12px", width: "12px" }}
            className="lightui1-shimmer rounded-circle brightGrayBg"
          ></div>
        </div>
        <div className=" p-3 d-flex flex-column gap-2">
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
        </div>
      </div>
    </>
  );
}
