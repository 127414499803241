import { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function InvitationsAddFormInput({label, placeholder, requiered, onChange, errorControl}) {


    const [value, setValue] = useState("")

    const onChangeLocal = (evt) => {
        const newValue = evt.target.value;
        setValue(newValue);
        onChange(newValue);
    };


    return (
        <div className="d-flex flex-column gap-2 position-relative ">
          <div className=" museo-sans-700 w-min-content  position-relative" >
            <p className="  s14">{label}</p>
            
            {
                requiered &&
                <span style={{right: -8, top: -2}} className=" position-absolute lightRedColor" >
                    *
                </span>

            }
          </div>
          <div style={{  height: "56px" }}>
            <div style={ errorControl ? {borderColor: "#f06e69" } : {}} className="museo-sans-500 s16 d-flex justify-content-center align-items-center rounded-16 input-border w-100 h-100 p-3">
              <input
                value={value}
                placeholder={placeholder }
                onChange={onChangeLocal}
                className=" m-0 p-0 no-outline-input s16 border-0 h-100 w-100"
              />
            </div>
          </div>
          {
            errorControl &&
            <div style={{left: 0, bottom: -20}} className=" position-absolute " >
                <p className=" museo-sans-700 s12 lightRedColor " >
                    {
                        label == "Email" ?
                        <>
                          <FormattedMessage id="invitation.create.label.one" />
                        </>
                        :
                        <>
                          <FormattedMessage id="invitation.create.label.two" />
                        
                        </>
                    }       
                </p>
            </div>

          }
        </div>
      );
}