import React from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

const DashboardQualificationPotentialLeaderModal = () => {
  return (
    <div
      className="modal fade"
      id="potentialLeader"
      aria-hidden="true"
      aria-labelledby="potentialLeader"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "610px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-700 px-2 s20 m-0">
                    <FormattedMessage
                      id="potential.leaders"
                      defaultMessage={"Potenciales líderes"}
                    />
                  </h5>
                </div>
                <div
                  className=" pointer "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div
                style={{ maxHeight: "348px" }}
                className=" hidden-y-scroll d-flex flex-column align-items-center"
              >
                <div className=" d-flex flex-column mt-4 px-5">
                  <p className="s16 py-2 museo-sans-500 ">
                    <FormattedMessage id="dashboard.modal.help.potential.leaders.p.one" />
                    <span className=" museo-sans-700">
                      <FormattedMessage id="dashboard.modal.help.potential.leaders.p.two" />
                    </span>
                    <FormattedMessage id="dashboard.modal.help.potential.leaders.p.three" />
                  </p>
                </div>
              </div>
              <div
                style={{ zIndex: 2 }}
                className=" position-relative w-100 d-flex justify-content-center"
              >
                <button
                  type="button"
                  className=" btnLoginWidth my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardQualificationPotentialLeaderModal;
