import React from 'react'
import ReturnToAndTitle from '../../../Share/ReturnToAndTitle'
import ContinueButton from './ContinueButton'

import { FormattedMessage } from 'react-intl'

export default function UserDataPhoneNumberVerifyCode({travelFunction}) {
  return (
    <>
        <ReturnToAndTitle
          travelFunction={travelFunction}
          returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
          title={"settings.user.data.phone.title"}
        >
          <p>
            <FormattedMessage
              id="settings.user.data.code.p1"
              defaultMessage="Set the four digit code that we sent through a SMS"
            />
          </p>
        </ReturnToAndTitle>
        <div className="container-fluid">
          <div className="row">
            <div className="col-2 ">
              <input
                type="text"
                className="form-control bg-light"
                placeholder="-"
                maxLength="1"
                onInput="this.value=this.value.replace(/[^0-9]/g,'');"
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                className="form-control bg-light"
                placeholder="-"
                maxLength="1"
                onInput="this.value=this.value.replace(/[^0-9]/g,'');"
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                className="form-control bg-light"
                placeholder="-"
                maxLength="1"
                onInput="this.value=this.value.replace(/[^0-9]/g,'');"
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                className="form-control bg-light"
                placeholder="-"
                maxLength="1"
                onInput="this.value=this.value.replace(/[^0-9]/g,'');"
              />
            </div>
          </div>
        </div>
        <ContinueButton
          continueFunction={travelFunction}
          whereTo={1.6}
          text={"Continuar"}
        />
      </>
  )
}
