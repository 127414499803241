import { useRef } from "react";
import HelpIconNoTarget from "./HelpIconNoTarget";
import ShareFlagIcons from "./ShareFlagIcons";

export default function ShareComponentInput(params) {
  const inputStyle = {
    borderColor: params.errorToggler ? "#f06e69" : "#B0D4FF",
    color: params.errorToggler ? "#f06e69" : "",
    height: "40px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
    userSelect: "none",
    position: "relative",
    zIndex: "1",
    top: params.smallerWidth ? "21px" : "0",
  };

  // ignoreTitle

  const inputRef = useRef(null);

  const controlParams = (paramValue) => {
    return paramValue || null;
  };

  const onChangeValue = (e) => {
    params?.onChange(params.name, "value", e.target.value);
  };

  return (
    <div
      className={` ${params.smallerWidth ? "w-25" : " w-100"
        } d-flex flex-column gap-2 `}
    >
      <div className="d-flex gap-2 position-relative   ">
        <label
          style={params.smallerWidth && { width: "200px" }}
          className={` ${params.ignoreTitle ? " invisible " : ""} ${params.smallerWidth ? " position-absolute " : ""
            }   s14 museo-sans-500 `}
        >
          {controlParams(params.label)}
        </label>
        {params.helpIcon && (
          <div
            style={{ width: "20px", height: "20px" }}
            className=" pointer svg-force-size"
          >
            <HelpIconNoTarget />
          </div>
        )}
      </div>
      {params.inputDate ? (
        <InputForDate
          params={params}
          inputRef={inputRef}
          inputStyle={inputStyle}
          controlParams={controlParams}
          onChangeValue={onChangeValue}
        />
      ) : (
        <InputDefault
          params={params}
          inputStyle={inputStyle}
          controlParams={controlParams}
          onChangeValue={onChangeValue}
        />
      )}
    </div>
  );
}

export function InputForDate({
  params,
  controlParams,
  inputStyle,
  onChangeValue,
}) {
  return (
    <div
      style={inputStyle}
      className="d-flex pointer bg-white justify-content-between px-2 "
    >
      <input
        onPointerDown={(e) => e.preventDefault()}
        onPointerUp={(e) => e.preventDefault()}
        onPointerMove={(e) => e.preventDefault()}
        onTouchStart={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
        readonly
        name={params.name}
        value={controlParams(params.value)}
        type={params.type || "text"}
        className=" s16 w-100 border-0"
      />
      {params.inputDate && (
        <input
          id="date-id"
          onPointerDown={(e) => e.preventDefault()}
          onPointerUp={(e) => e.preventDefault()}
          onPointerMove={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
          onMouseDown={(e) => e.preventDefault()}
          readonly
          name={params.name}
          onChange={onChangeValue}
          className="bg-transparent pointer border-0"
          style={{ color: "#FFF", width: "50px" }}
          type={"date"}
        ></input>
      )}
    </div>
  );
}

export function InputDefault({
  params,
  controlParams,
  inputStyle,
  onChangeValue,
}) {

  let country = localStorage.getItem("country");

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52", // +52 Mex
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const countryList = Object.keys(numbers).reduce((acc, key) => {
    acc[numbers[key]] = key;
    return acc;
  }, {});

  return (
    <div
      style={inputStyle}
      className=" position-relative d-flex bg-white justify-content-between px-2 "
    >
      {
        params.name == "contactEdbPhone" &&
        <div className="d-flex gap-2 s14 pe-2" >
          <div style={{ top: 6, left: 1 }} className=" position-relative">
            <ShareFlagIcons code={countryList[numbers[country]]} height={"22px"} />
          </div>
          <p style={{top: 10}} className=" dm-sans-500 grayColor position-relative" >
            {numbers[country]}
          </p>
          <div style={{
            backgroundColor: params.errorToggler ? "#f06e69" : "#B0D4FF", 
            width: "1px",
          }} className=" h-100" >

          </div>
        </div>

      }
      <input
        onChange={onChangeValue}
        autoComplete={params.autoComplete || "off"}
        name={params.name}
        placeholder={params.placeholder || ""}
        maxLength={params.maxLength || "50"}
        value={controlParams(params.value)}
        type={params.type || "text"}
        className=" s14 w-100 border-0"
      />
      {params.svgIcon && <div>{params.svgIcon}</div>}
    </div>
  );
}
