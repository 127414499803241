import {ReactComponent as SVGHelpMdIcon} from "../svgs/helpIcons/ic-help-circle-md.svg"
import {ReactComponent as SVGHelpSmIcon} from "../svgs/helpIcons/ic-help-circle-sm.svg"

export default function HelpIconNoTarget({size}) {
    // Envolver del otro lado con un div class d-flex pointer.
    return <>
        {
            size == "sm" ?
                
                <SVGHelpSmIcon />
            :
            
                <SVGHelpMdIcon />
        }
    </>    
}