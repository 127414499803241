import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useOnClickOutside } from "../../../common/Share/ShareFunctionUseOnClickOutside";

export default function GroupIncorpDescendantBilledTooltip({ value, toggler }) {
  const ref = useRef();

  useOnClickOutside(ref, () => toggler(false));

  console.log("values: ", value)

  return (
    <div ref={ref} className="billed-tooltip">
      <div
        style={{ zIndex: 1 }}
        className="rounded-4 overflow-hidden lightYellowBg darkYellowColor p-3 museo-sans-500 s12 billed-tooltip-content"
      >
        <p>
          <FormattedMessage id="performance.tooltip.hover.p.one" values={{ value: value }} />
        </p>
      </div>
    </div>
  );

}
