import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ProgressBarDashboard from "../../../common/Share/ProgressBarDashboard";
import StyledMultiLang from "../../../common/Share/StyledMultiLang";
import { ReactComponent as SVGRight } from "../../../common/svgs/c-chevron-right-sm.svg";
import { useDispatch, useSelector } from "react-redux";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";
import {
  getScore,
} from "../../Framework/redux/dashboardSideEffect";

export default function DashboardTriperiodSale({
  level,
  gap,
  status,
  scoreData,
  bills,
  loading,
  potentialLeader,
  root,
  closeComplete
}) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const errorScoreData = useSelector(
    (state) => state.scoreData?.scoreDataError
  );

  const history = useNavigate();
  const dispatch = useDispatch();

  const onClickPersonalSale = () => {
    history(
      root
        ? `/dashboard/otherUserSales/${root}/personalSales`
        : "/dashboard/performance/personalSales"
    );
  };

  const fetchScore = async () => {
    await dispatch(getScore())
  }

  return (
    <>
      <div className=" d-flex ">
        <h4 className=" s20 museo-sans-500">
          {potentialLeader ? (
              <StyledMultiLang
                id="dashboard.qualifications.title.one.b"
                def={"Tus Calificaciones"}
              />
          ) : (
            <StyledMultiLang
              id="dashboard.period.title"
              def={"Tus Calificaciones"}
            />
          )}
        </h4>
        <i
          data-bs-toggle="modal"
          data-bs-target="#modalKey"
          className="pointer d-flex px-2 "
        >
          <HelpIconNoTarget />
        </i>
      </div>
      
      {potentialLeader && (
          <div
            className=" invisible position-relative "
            style={{ width: "200px", height: "30px" }}
          >
            <div
              style={{
                width: "184px",
                height: "38px",
                top: -9,
                borderRadius: "16px 16px 0px 0px ",
                zIndex: 0,
              }}
              className={`pointer d-flex justify-content-center align-items-center py-2   position-absolute`}
            >
              <p className=" museo-sans-500 s14 ">
                <FormattedMessage
                  id="level.two"
                  defaultMessage={"Líder de Grupo"}
                />
              </p>
            </div>
            <div
              style={{
                width: "184px",
                height: "38px",
                top: -9,
                borderRadius: "16px 16px 0px 0px ",
                left: 184,
                zIndex: 0,
              }}
              className={` pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
            >
              <p className=" museo-sans-500 s14 ">
                <FormattedMessage
                  id="level.one.not.inclusive"
                  defaultMessage={"Consultora"}
                />
              </p>
            </div>
          </div>
      )}
      {
        (potentialLeader && closeComplete ) &&
        <div style={{height: "56px", marginBottom: "20px"}} className="mt-3"  >

        </div>
      }
      
      <div
        style={
          potentialLeader
            ? { minHeight: "249px", borderRadius: "0px 16px 16px 16px" }
            : { minHeight: "249px", borderRadius: "16px" }
        }
        className=" p-3 position-relative mt-3 d-flex cards-shadow bg-white"
      >
        {scoreData?.loader || loading ? (
            <SkeletonDashboardTri />
        ) : (
          <>
            {errorScoreData ? (
                <div className=" p-3 d-flex flex-column w-100">
                  <h4 className=" text-uppercase museo-sans-700 s16 ">
                    <FormattedMessage id="dashboard.period.subtitle.one" />
                  </h4>
                  <div className="my-3" >
                    <ShareErrorHandlerServices service={fetchScore} />

                  </div>

                </div>
            ) : (
              <>
                <div className=" p-3 d-flex flex-column w-100">
                  <h4 className=" text-uppercase museo-sans-700 s16 ">
                    <FormattedMessage id="dashboard.period.subtitle.one" />
                  </h4>
                  <div onClick={onClickPersonalSale} className=" pointer mt-3">
                    <div className="d-flex py-1 justify-content-between">
                      <p className=" museo-sans-500 text-capitalize s14">
                        <FormattedMessage id="dashboard.period.subtitle.one" />
                      </p>
                      <div className=" align-items-center dm-sans-medium s14 d-flex ">
                        <p>
                          {" "}
                          {formatAmount(
                            scoreData?.triperiodicSales?.actualAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        <p className=" grayColor">
                          /{" "}
                          {formatAmount(
                            scoreData?.triperiodicSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        <SVGRight />
                      </div>
                    </div>
                    {scoreData?.triperiodicSales?.actualAmount >=
                    scoreData?.triperiodicSales?.totalAmount ? (
                      <ProgressBarDashboard
                        level={level}
                        status={status}
                        gap={gap}
                        completed={100}
                        incompleted={false}
                      />
                    ) : (
                      <ProgressBarDashboard
                        level={level}
                        status={status}
                        gap={gap}
                        completed={
                          (scoreData?.triperiodicSales?.actualAmount /
                            scoreData?.triperiodicSales?.totalAmount) *
                          100
                        }
                        incompleted={true}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{ width: "1px" }}
                  className=" my-3 mx-3 brightGrayBg "
                >
                  <p className=" invisible">?</p>
                </div>
                <div className=" d-flex flex-column w-100 p-3 ">
                  <h4 className=" text-uppercase museo-sans-700 s16 ">
                    <FormattedMessage id="dashboard.period.subtitle.two" />
                  </h4>
                  <div>
                    <DashboardTriperiodItems
                      bills={bills}
                      triperiodicSales={scoreData?.triperiodicSales}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export function DashboardTriperiodItems({ triperiodicSales }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const actualColor = "blackColor";
  const activeColor = "blackColor";
  const inactiveColor = "grayColor opacity80";

  const museo500 = "museo-sans-500";
  const museo700 = "museo-sans-700";

  const dmsansMedium = "dm-sans-medium";
  const dmsansBold = "dm-sans-bold";

  let index = 0;

  const defineColor = (state) => {
    if (state == "actual") {
      return actualColor;
    } else if (state == "active") {
      return activeColor;
    } else {
      return inactiveColor;
    }
  };

  const defineFontType = (state, type) => {
    if (state == "actual" && type == "text") {
      return museo700;
    }
    if (state == "actual" && type == "number") {
      return dmsansBold;
    }
    if (state != "actual" && type == "text") {
      return museo500;
    }
    if (state != "actual" && type == "number") {
      return dmsansMedium;
    }
  };


  const calculatePeriods = () => {
    let length = triperiodicSales?.periods?.length;

    if (length) {
      return (
        <>
          {triperiodicSales?.periods?.map((elm) => {
            index++;

            return (
              <>
                {" "}
                <div className="d-flex justify-content-between s14 ">
                  <p
                    className={` ${defineColor(elm?.state)} ${defineFontType(
                      elm?.state,
                      "text"
                    )}  `}
                  >
                    {" "}
                    {elm?.title}{" "}
                  </p>
                  <p
                    className={` ${defineColor(elm?.state)} ${defineFontType(
                      elm?.state,
                      "number"
                    )}  `}
                  >
                    {formatAmount(
                      elm?.amount,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                </div>
                {index != length && <hr className=" my-3" />}
              </>
            );
          })}
        </>
      );
    }
  };

  return <div className="mt-3">{calculatePeriods()}</div>;
}

export function SkeletonDashboardTri(params) {
  return (
    <div className=" p-3 d-flex flex-column w-100 ">
      <div
        style={{ height: "32px" }}
        className="lightui1-shimmer w-100 d-flex justify-content-center lightGreyBg rounded-3 align-items-center   "
      >
        <div
          style={{ height: "12px", width: "440px" }}
          className="lightui1-shimmer rounded-3 brightGrayBg   "
        ></div>
      </div>
      <div style={{ gap: "32px" }} className=" mt-4 pt-2 d-flex ">
        <div className=" w-100">
          <div>
            <div
              style={{ height: "16px", width: "129px", marginBottom: "28px" }}
              className="lightui1-shimmer brightGrayBg rounded-3 "
            ></div>
          </div>
          <SkeletonDashboardQualificationLeftRow />
        </div>
        <div
          style={{ width: "1px" }}
          className="h-100 brightGrayBg  lightui1-shimmer"
        >
          <p className=" invisible">.</p>
        </div>
        <div className=" w-100">
          <div
            style={{ height: "16px", marginBottom: "28px" }}
            className="lightui1-shimmer w-75 brightGrayBg rounded-3 "
          ></div>
          <div>
            <SkeletonDashboardQualificationRightRow />
            <SkeletonDashboardQualificationRightRow />
            <SkeletonDashboardQualificationRightRow />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SkeletonDashboardQualificationRightRow(params) {
  return (
    <div>
      <div style={{ height: "12px" }} className=" d-flex w-100">
        <div className=" d-flex h-100 w-50">
          <div className="lightui1-shimmer rounded-3 d-flex h-100 w-75 brightGrayBg"></div>
          <div className=" rounded-3 d-flex h-100 w-25"></div>
        </div>
        <div className=" d-flex h-100 w-50">
          <div className=" rounded-3 d-flex h-100 w-75"></div>
          <div className="lightui1-shimmer rounded-3 d-flex h-100 w-25 brightGrayBg"></div>
        </div>
      </div>
      <hr></hr>
    </div>
  );
}

export function SkeletonDashboardQualificationLeftRow(params) {
  return (
      <div className=" gap-3 d-flex flex-column">
        <div className=" d-flex  justify-content-between">
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer rounded-3 w-100 brightGrayBg "
          ></div>
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer rounded-3 w-100  "
          ></div>
          <div className=" w-100 d-flex gap-1 ">
            <div
              style={{ height: "8px" }}
              className="lightui1-shimmer rounded-3 w-100 brightGrayBg "
            ></div>
            <div
              style={{ height: "8px" }}
              className="lightui1-shimmer rounded-3 w-100 brightGrayBg "
            ></div>
          </div>
        </div>
        <div
          style={{ height: "12px" }}
          className="lightui1-shimmer mt-1 w-100 rounded-3 brightGrayBg "
        ></div>
      </div>
  );
}
