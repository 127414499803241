import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as SVGShare } from "../../../common/svgs/ic-share-sm.svg";
import { ReactComponent as WhatsappIcon } from "../../../common/svgs/ic-whatsapp-md.svg";
import { ReactComponent as EmailIcon } from "../../../common/svgs/ic-mail-md.svg";
import { useDispatch } from "react-redux";
import { clientDetailsForm } from "../../../EDBs/Framework/redux/EDBsActions";
import ClientListTerritoryPerCountry from "./ClientListTerritoryPerCountry";
import { addInvitee } from "../../../EDBs/Framework/redux/EDBFormSideEffect";

export default function ClientListDetailsContent({
  loading,
  data,
  socialArray,
}) {
  const history = useNavigate();

  const onEditValues = () => {
    history(`/dashboard/clientlist/form/update/${data?.contactId}`);
  };

  return (
    <div style={{ marginTop: "120px" }} className=" ">
      <div className=" d-flex align-items-center gap-2 ">
        {loading ? (
          <>
            <p className=" lightui1-shimmer rounded-4 s16">
              <span className="invisible">11232412412411221321421</span>
            </p>
            <p className=" lightui1-shimmer rounded-4 s14">
              <span className="invisible">1123241241241</span>
            </p>
          </>
        ) : (
          <>
            <p className=" s20 museo-sans-500">
              <FormattedMessage id="client.details.card.title" />
            </p>
            {data?.contactOriginType != "PERSONAL_SITE" && (
              <p
                onClick={onEditValues}
                className=" pointer s14 button-hover-light-text museo-sans-700 skyColor px-1 "
              >
                <FormattedMessage id="buttons.edit.data" />
              </p>
            )}
          </>
        )}
      </div>
      <div>
        <ClientListDetailsContentCard
          loading={loading}
          data={data}
          socialArray={socialArray}
        />
      </div>
    </div>
  );
}

export function ClientListDetailsContentCard({
  loading,
  data,
  socialArray,
  chooseHost,
}) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const splitPhoneBySegregation = (phoneString, areaString, phoneId) => {
    let newPhoneString = phoneString;
    let newAreaString = areaString;
    let n = 4;
    for (let i = newPhoneString?.length - n; i > 0; i -= n) {
      newPhoneString =
        newPhoneString.substring(0, i) + " " + newPhoneString.substring(i);
    }

    if (!newAreaString) {
      return newPhoneString;
    }

    return areaString + " " + newPhoneString;
  };

  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const birthdayControl = (stringBirthday) => {
    if (!stringBirthday) {
      return "";
    }
    let splitValues = stringBirthday.split("-");

    let daySplitted = splitValues[0];
    let monthSplitted = splitValues[1];

    daySplitted = +daySplitted;
    monthSplitted = +monthSplitted;

    return (
      <>
        {" "}
        {monthSplitted} <FormattedMessage id="of" />{" "}
        <FormattedMessage id={`month.${daySplitted}`} />{" "}
      </>
    );
  };

  const onHostSelect = async () => {
    if (location?.pathname.includes("edbs/details")) {
      await dispatch(addInvitee({ attendeeId: data?.contactId, edbId: id }));
      history(-1);
    } else {
      dispatch(clientDetailsForm(data));
      history(-1);
    }
  };

  const checkIfValueExist = (value, index, idSubtitle) => {
    if (value?.length > index && value[index]?.number) {
      return (
        <div className=" museo-sans-500">
          <p
            className=" text-uppercase grayColor s12"
            style={{ letterSpacing: "0.72px" }}
          >
            <FormattedMessage id={`${idSubtitle}`} />
          </p>
          <div className="d-flex justify-content-between">
            <p className=" s16">
              {splitPhoneBySegregation(
                value[index]?.number,
                value[index]?.areaCode,
                value[index]?.phoneOrderId
              )}
            </p>
            <a
              href={`https://wa.me/${value[index]?.areaCode}${value[index]?.number}`}
              target="_blank"
            >
              <WhatsappIcon />
            </a>
          </div>
        </div>
      );
    }
    return "";
  };

  return (
    <div
      style={{
        width: "392px",
        borderRadius: "16px",
        border: "1px solid #CED2FF62",
      }}
      className=" account--card-shadow mt-4 bg-white"
    >
      {loading ? (
        <div style={{ padding: "32px" }} className="d-flex flex-column gap-4">
          <div className="  museo-sans-500">
            <p
              className=" text-uppercase grayColor s12"
              style={{ letterSpacing: "0.72px" }}
            >
              <FormattedMessage id="TELÉFONO" />
            </p>
            <p className=" lightui1-shimmer rounded-4 s16">
              <span className="invisible">1123241241241</span>
            </p>
          </div>
          <div className=" museo-sans-500">
            <p
              className=" text-uppercase grayColor s12"
              style={{ letterSpacing: "0.72px" }}
            >
              <FormattedMessage id="settings.user.data.email" />
            </p>
            <p className=" lightui1-shimmer rounded-4 s16">
              <span className="invisible">1123241241241</span>
            </p>
          </div>
          <div className=" museo-sans-500">
            <p
              className=" text-uppercase grayColor s12"
              style={{ letterSpacing: "0.72px" }}
            >
              <FormattedMessage id="DIRECCIÓN" />
            </p>
            <div id="" className="d-flex flex-wrap flex-column gap-1">
              <p className=" lightui1-shimmer rounded-4 s16">
                <span className="invisible">1123241241241</span>
              </p>
              <p className=" lightui1-shimmer rounded-4 s16">
                <span className="invisible">1123241241241</span>
              </p>
              <p className=" lightui1-shimmer rounded-4 s16">
                <span className="invisible">1123241241241</span>
              </p>
            </div>
          </div>
          <div className=" museo-sans-500">
            <p
              className=" text-uppercase grayColor s12"
              style={{ letterSpacing: "0.72px" }}
            >
              <FormattedMessage id="birthday" />
            </p>
            <p className=" lightui1-shimmer rounded-4 s16">
              <span className="invisible">1123241241241</span>
            </p>
          </div>
          <div
            style={{ height: "40px", borderRadius: "8px" }}
            className=" d-flex gap-2 justify-content-center align-items-center mt-4  w-100 lightui1-shimmer"
          >
            <span className="invisible">
              <SVGShare />
              <p className=" s14 museo-sans-700">
                <FormattedMessage id="buttons.client.share.data" />
              </p>
            </span>
          </div>
        </div>
      ) : (
        <div style={{ padding: "32px" }} className="d-flex flex-column gap-4">
          {data?.contactOriginType === "PERSONAL_SITE" && (
            <div className=" museo-sans-500 w-100  s12 p-3 rounded-3 backgroundShallowBlue blueColor   ">
              <FormattedMessage id="client.details.card.no.edit.message" />
            </div>
          )}
          {chooseHost && (
            <p className=" museo-sans-700 s16">
              {data?.firstName} {data?.lastName}
            </p>
          )}
          {checkIfValueExist(data?.phoneNumbers, 0, "TELÉFONO")}
          {checkIfValueExist(data?.phoneNumbers, 1, "phone.share.altenative")}
          {socialArray && (
            <>
              {socialArray[configExist("email")]?.value && (
                <div className=" museo-sans-500">
                  <p
                    className=" text-uppercase grayColor s12"
                    style={{ letterSpacing: "0.72px" }}
                  >
                    <FormattedMessage id="settings.user.data.email" />
                  </p>
                  <div className="d-flex justify-content-between">
                    <p className=" text-lowercase s16">
                      {configExist("email") !== -1 && (
                        <>{socialArray[configExist("email")]?.value}</>
                      )}
                    </p>
                    <a
                      href={`mailto:${
                        socialArray[configExist("email")]?.value
                      }`}
                      target="_blank"
                    >
                      <EmailIcon />
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
          {ClientListTerritoryPerCountry(data?.addressInfo, true)}
          {data?.birthday && (
            <div className=" museo-sans-500">
              <p
                className=" text-uppercase grayColor s12"
                style={{ letterSpacing: "0.72px" }}
              >
                <FormattedMessage id="birthday" />
              </p>
              <p className=" s16">{birthdayControl(data?.birthday)}</p>
            </div>
          )}
          {chooseHost ? (
            <div
              onClick={onHostSelect}
              style={{ height: "40px", borderRadius: "8px" }}
              className=" svg-force-hover-opacity d-flex gap-2 pointer justify-content-center align-items-center mt-4  w-100 skyBg text-white button-hover-light-text"
            >
              {location.pathname.includes("edbs/details") ? (
                <p className=" s14 museo-sans-700">
                  <FormattedMessage id="edb.details.select.new.invitee" />
                </p>
              ) : (
                <p className=" s14 museo-sans-700">
                  <FormattedMessage id="edb.form.card.select.host" />
                </p>
              )}
            </div>
          ) : (
            <div
              data-bs-toggle="modal"
              data-bs-target="#modalShare"
              style={{ height: "40px", borderRadius: "8px" }}
              className=" svg-force-hover-opacity d-flex gap-2 pointer justify-content-center align-items-center mt-4  w-100 skyBorder skyColor button-hover-light-text"
            >
              <SVGShare />
              <p className=" s14 museo-sans-700">
                <FormattedMessage id="buttons.client.share.data" />
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
