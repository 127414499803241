import React from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

import { ReactComponent as SVGOne } from "./svgs/desc01.svg";
import TwoPng from "./svgs/desc02Png.png";
import ThreePng from "./svgs/desc03Png.png";
import FourPng from "./svgs/desc04Png.png";
import FivePng from "./svgs/desc05Png.png";
import { ReactComponent as SVGTwo } from "./svgs/desc02.svg";
import { ReactComponent as SVGThree } from "./svgs/desc03.svg";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { useRef} from "react";
import { useSelector } from "react-redux";


export default function DescendantModalInfo() {

  const scrollbarRef = useRef(null);
  const profileData = useSelector((state) => state.userInfo.userInfo);
  
  return (
    <div
      className="modal fade"
      id="modalInfo"
      aria-hidden="true"
      aria-labelledby="modalInfo"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "600px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div >
              <div className="d-flex justify-content-between m-3 p-4 align-items-center">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0">
                    {
                      profileData?.level > 1 ?
                      <FormattedMessage
                      id="descendants.header.title"
                      defaultMessage="Tu Descendencia"
                    />
                    :
                    <FormattedMessage
                      id="descendants.header.title.two"
                      defaultMessage="Tu Descendencia"
                    />
                    }
                    
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className="d-flex flex-column align-items-center">
                <div ref={scrollbarRef}  style={{maxHeight: "460px"}} className=" hidden-y-scroll s16 museo-sans-500 mt-4 px-5">
                  <p className=" mb-4 py-3">
                    <FormattedMessage
                        id="descendants.modal.p.one"
                        defaultMessage="Buenas Prácticas"
                        />
                  </p>
                  <p className=" pb-3 museo-sans-700" >
                        <FormattedMessage
                        id="descendants.modal.h2.one"
                        defaultMessage="Buenas Prácticas"
                        />
                    </p>
                    <div className="d-flex pb-3 mb-4 gap-2 " >
                      <SVGOne />
                      <p style={{width: "280px"}} className=" s16 museo-sans-500 " >
                        <FormattedMessage id="descendants.modal.image.p" />
                      </p>
                    </div>
                    <p className=" pb-2 museo-sans-700" >
                        <FormattedMessage
                        id="descendants.modal.h2.two"
                        defaultMessage="Buenas Prácticas"
                        />
                    </p>
                  <p className=" pb-2">
                  <FormattedMessage
                      id="descendants.modal.p.two"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <div>
                    <img style={{width: "500px"}} src={TwoPng} alt="" />
                  </div>
                  <p className=" museo-sans-700" >
                        <FormattedMessage
                        id="descendants.modal.h2.three"
                        defaultMessage="Buenas Prácticas"
                        />
                    </p>
                  <p className=" py-2">
                  <FormattedMessage
                      id="descendants.modal.p.three"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <div className="d-flex pb-3 justify-content-center" >
                    <img style={{width: "400px"}} src={ThreePng} alt="" />
                  </div>
                  <p className=" museo-sans-700" >
                        <FormattedMessage
                        id="descendants.modal.h2.four"
                        defaultMessage="Buenas Prácticas"
                        />
                    </p>
                  <p className=" py-2">
                  <FormattedMessage
                      id="descendants.modal.p.four.a"
                      defaultMessage="Buenas Prácticas"
                    />
                    "<FormattedMessage
                      id="descendants.modal.p.four.b"
                      defaultMessage="Buenas Prácticas"
                    />"
                    <FormattedMessage
                      id="descendants.modal.p.four.c"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <div className=" pb-3" >
                    <img style={{width: "500px"}} src={FourPng} alt="" />
                  </div>
                  <p className=" museo-sans-700" >
                        <FormattedMessage
                        id="descendants.modal.h2.five"
                        defaultMessage="Buenas Prácticas"
                        />
                    </p>
                    <p className=" py-2">
                  <FormattedMessage
                      id="descendants.modal.p.five"
                      defaultMessage="Buenas Prácticas"
                    />
                  </p>
                  <div className="d-flex justify-content-center" >
                    <img style={{width: "300px"}} src={FivePng} alt="" />
                  </div>
                </div>
                <ModalWhiteShadow scrollbarRef={scrollbarRef} />
                <button
                  type="button"
                  className=" btnLoginWidth my-5 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
