import React from 'react'
import replaceElementsURL from '../../../common/Share/replaceElementURL'

const OfertsImageHolder = ({url ,id,filename,short}) => {
  return (
    <>
    {
      short == false ? 
      <>
      {
        id &&
        <img style={{borderRadius: "1%",height:"90px", width: "70px", position: "absolute",right: 7,bottom: 0}} src={`${replaceElementsURL(url, id, filename)}`} />

      }
      </>
      :
      <>
      {
        id &&
        <img style={{borderRadius: "1%",height:"176px", width: "130px", position: "absolute",right: 5,bottom: 0}} src={`${replaceElementsURL(url, id, filename)}`} />

      }
      </>
      
    }
    
    </>
  )
}

export default OfertsImageHolder