
import { useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl";
import { ReactComponent as Person } from "../../../common/svgs/person.svg"
import { ReactComponent as Search } from "../../../common/svgs/search.svg"

export default function({ search, setSearch, navigate, isEmpty }) {

  const location = useLocation();

  const onClickNewClient = () => {
    if (location.pathname.includes("edbs/form")) {
      navigate('/dashboard/edbs/form/client/add');
    }
    navigate('/dashboard/clientlist/form/add');
  };

  return (
    <div className={` w-100 d-flex justify-content-between mb-2 mt-2 align-items-center `}>
      <div className={` ${isEmpty ? "invisible" : ""} position-relative `}>
        <input
          id="search-input"
          type="text"
          className="form-control museo-sans-500 s14 "
          style={{ width: 392, height: 40, borderColor: '#8889DB', paddingRight: 35 }}
          placeholder="Buscar cliente..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <Search className="position-absolute" style={{ top: 12, right: 15 }}  />
      </div>
      <button
        className="bg-white btnCustom skyBorder skyColor museo-sans-700 s14 py-2"
        style={{ width: 184, height: 40 }}
        onClick={() => onClickNewClient()}
      >
        <Person style={{ width: 16, height: 16, marginRight: 8 }} />
        <FormattedMessage id="client.list.new.client" />
      </button>
    </div>
  )
}