import React from "react";
import { useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";
import "./Calendar.css";

import {
  calendarCurrentPeriod,
  getEventDates,
  getEventsForSpecificDate,
  thisYearPeriodData,
} from "../../Framework/redux/CalendarSideEffects";
import { currentYearPeriods } from "../../Framework/redux/CalendarSideEffects";
import { pastYearPeriods } from "../../Framework/redux/CalendarSideEffects";
import { nextYearPeriods } from "../../Framework/redux/CalendarSideEffects";
import {
  changeEventSectionTitle,
  modifyNav,
  resetNav,
} from "../../Framework/redux/CalendarActions";
import CalendarDay from "../components/CalendarDay";
import CalendarEmptyDay from "../components/CalendarEmptyDay";
import CalendarEventTitle from "../components/CalendarEventTitle";
import CalendarModalDetails from "../components/CalendarModalDetails";

import { ReactComponent as ChevronRightIcon } from "../../../common/svgs/c-chevron-right-sm.svg";
import { ReactComponent as ChevronLeftIcon } from "../../../common/svgs/ic-chevron-left-sm.svg";

import { useDispatch, useSelector } from "react-redux";
import CalendarModalBirthday from "../components/modals/CalendarModalBirthday";
import { useState } from "react";
import { getPeriod } from "../../../common/Header/Framework/HeaderSideEffect";
import CalendarSkeleton from "../components/CalendarSkeleton";
import { multiLangGetCountry } from "../../../common/Share/MultiLangGetCountry";
import { FormattedMessage } from "react-intl";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import CalendarBody from "../components/CalendarBody";

export default function Calendar() {

  const smallerThan1280 = useMediaPredicate("(max-width: 1280px)");
  const dispatch = useDispatch();
  const nav = useSelector((state) => state.calendarData.nav);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [yearPeriod, setYearPeriod] = useState("0000");
  const datesData = useSelector((state) => state.calendarData.datesData);

  const togglerSkeletonFalse = () => {
    setSkeletonLoader(false);
  };

  const togglerSkeletonTrue = () => {
    setSkeletonLoader(true);
  };

  const weekdays = [
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
    "domingo",
  ];

  async function todaysRequest() {
    togglerSkeletonTrue();
    const dateObjectForStart = new Date();

    const dayForStart = dateObjectForStart.getDate();
    const monthForStart = dateObjectForStart.getMonth();
    const yearForStart = dateObjectForStart.getFullYear();

    const dateForFirstServiceCall = `${yearForStart}-${String(
      monthForStart + 1
    ).padStart(2, "0")}-${String(dayForStart).padStart(2, "0")}`;

    dispatch(changeEventSectionTitle("Tus eventos de hoy"));

    await dispatch(getEventsForSpecificDate(dateForFirstServiceCall));

    dispatch(resetNav());
    getVal();
  }

  const getVal = async () => {
    const result = await dispatch(getPeriod());
    const val = await dispatch(
      thisYearPeriodData(result.payload.currentYearPeriod)
    );
    setYearPeriod(result.payload.currentYearPeriod);
    setTimeout(() => {
      togglerSkeletonFalse();
    }, 2000);
  };

  const onStartUp = async () => {
    try {
      await dispatch(resetNav());
      let result = await dispatch(getEventDates());
      await dispatch(calendarCurrentPeriod());
      await dispatch(currentYearPeriods());

      await dispatch(pastYearPeriods());
      await dispatch(nextYearPeriods());
    } catch (error) {
    }
  };

  useEffect(() => {
    togglerSkeletonTrue();

    onStartUp();

    {
      yearPeriod == "0000" && getVal();
    }
  }, []);

  const dt = new Date();

  if (nav !== 0) {
    dt.setDate(28);
    dt.setMonth(new Date().getMonth() + nav);
  }

  const month = dt.getMonth();
  const year = dt.getFullYear();

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const stringFirstDayOfMonth = firstDayOfMonth.toLocaleDateString(
    multiLangGetCountry(),
    { weekday: "long" }
  );

  const dateString = firstDayOfMonth.toLocaleDateString(multiLangGetCountry(), {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  const LastDayString = lastDayOfMonth.toLocaleDateString(
    multiLangGetCountry(),
    {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }
  );

  const paddingDays = weekdays.indexOf(dateString.split(", ")[0]);

  const endPaddingDays = 6 - weekdays.indexOf(LastDayString.split(", ")[0]);

  const datesArray = useSelector((state) => state.calendarData.dates);
  const thisPeriods = useSelector(
    (state) => state.calendarData.thisYearPeriods
  );
  const nextPeriods = useSelector(
    (state) => state.calendarData.nextYearPeriods
  );
  const pastPeriods = useSelector(
    (state) => state.calendarData.pastYearPeriods
  );

  function getDaysInMonth(monthValue, yearValue) {
    let date = new Date(yearValue, monthValue, 1);
    let days = [];
    while (date.getMonth() === monthValue) {
      let initialDate = new Date(date);
      days.push(initialDate);
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  function getDaysOfPreviousMonth(monthValue, yearValue) {
    let date;
    let days = [];

    if (monthValue === 0) {
      date = new Date(yearValue - 1, monthValue + 11, 1);
      while (date.getMonth() === monthValue + 11) {
        let initialDate = new Date(date);
        days.push(initialDate);
        date.setDate(date.getDate() + 1);
      }
    } else {
      date = new Date(yearValue, monthValue - 1, 1);
      while (date.getMonth() === monthValue - 1) {
        let initialDate = new Date(date);
        days.push(initialDate);
        date.setDate(date.getDate() + 1);
      }
    }
    return days;
  }

  function getDaysOfNextMonth(monthValue, yearValue) {
    let date;
    let days = [];

    if (monthValue === 11) {
      date = new Date(yearValue + 1, monthValue - 11, 1);
      while (date.getMonth() === monthValue - 11) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
    } else {
      date = new Date(yearValue, monthValue + 1, 1);
      while (date.getMonth() === monthValue + 1) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
    }
    return days;
  }

  function getPeriods(arr, fecha) {
    return arr?.periods.find((element) => {
      const fechaInicial = new Date(element.startDate);
      fechaInicial.setHours(0, 0, 0, 0);
      const fechaFinal = new Date(element.endDate);
      fechaFinal.setHours(0, 0, 0, 0);
      return fecha >= fechaInicial && fecha <= fechaFinal;
    });
  }

  function getCurrentWeek(date) {
    date.setHours(0, 0, 0, 0);
    let period;
    let weeks;
    if (date.getFullYear() === new Date().getFullYear()) {
      period = getPeriods(thisPeriods, date);
      weeks = period?.weeks;
    } else if (date.getFullYear() === new Date().getFullYear() - 1) {
      period = getPeriods(pastPeriods, date);
      weeks = period?.weeks;
    } else if (date.getFullYear() === new Date().getFullYear() + 1) {
      period = getPeriods(nextPeriods, date);
      weeks = period?.weeks;
    }

    const currentWeek = weeks?.filter((element) => {
      const fechaInicial = new Date(element.startDate);
      fechaInicial.setHours(0, 0, 0, 0);
      const fechaFinal = new Date(element.endDate);
      fechaFinal.setHours(0, 0, 0, 0);
      return date >= fechaInicial && date <= fechaFinal;
    });
    return currentWeek;
  }

  function displayDays() {
    const daysObjectArray = getDaysInMonth(month, year);
    const previousDays = getDaysOfPreviousMonth(month, year);
    const nextDays = getDaysOfNextMonth(month, year);

    let thisMonthsDates = datesArray.filter(
      (element) => element.split("-")[1] === String(month + 1).padStart(2, "0")
    );
    let finalDaysArray = [];
    const fechaDeHoy = new Date();
    let thisWeek = getCurrentWeek(fechaDeHoy);



    for (let i = 1; i <= paddingDays + daysInMonth + endPaddingDays; i++) {
      if (i <= paddingDays) {
        finalDaysArray.unshift(
          <CalendarEmptyDay
            key={ShareKeyGenerator()}
            currentPeriod={thisWeek}
            dateObject={previousDays[previousDays.length - i]}
          />
        );
      } else if (i > paddingDays + daysInMonth) {
        finalDaysArray.push(
          <CalendarEmptyDay
            key={ShareKeyGenerator()}
            currentPeriod={thisWeek}
            dateObject={nextDays[i - 1 - (paddingDays + daysInMonth)]}
          />
        );
      } else {
        let lastValueLongData = thisMonthsDates[thisMonthsDates.length - 1];
        let finalMes = i;
        finalDaysArray.push(
          <CalendarDay
            key={ShareKeyGenerator()}
            currentPeriod={thisWeek}
            datesArray={thisMonthsDates}
            dataInfo={datesData}
            dateObject={daysObjectArray[i - (paddingDays + 1)]}
            endIndex={lastValueLongData}
          />
        );
      }
    }

    return finalDaysArray;
  }

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  function getArrayOfTotalDays() {
    const daysObjectArray = getDaysInMonth(month, year);
    const previousDays = getDaysOfPreviousMonth(month, year);
    const nextDays = getDaysOfNextMonth(month, year);

    let dateObjectArray = [];

    for (let i = 1; i <= paddingDays + daysInMonth + endPaddingDays; i++) {
      if (i <= paddingDays) {
        dateObjectArray.unshift(previousDays[previousDays.length - i]);
      } else if (i > paddingDays + daysInMonth) {
        dateObjectArray.push(nextDays[i - 1 - (paddingDays + daysInMonth)]);
      } else {
        dateObjectArray.push(daysObjectArray[i - (paddingDays + 1)]);
      }
    }
    return dateObjectArray;
  }

  const dateObjects = getArrayOfTotalDays();
  const slicedDateObjects = sliceIntoChunks(dateObjects, 7);

  function getWeekNumber(arr) {
    let semana;
    let numeroDeSemana = undefined;

    for (let i = 0; i < arr.length; i++) {
      semana = getCurrentWeek(arr[i]);
      semana
        ? (numeroDeSemana = semana[0]?.week)
        : (numeroDeSemana = undefined);
    }

    return numeroDeSemana;
  }

  function spawnWeekDay(weekDay) {
    return (
      <div className="museo-sans-500 s12 blueColor ps-2 calendar-weekday-width">
        {weekDay}
      </div>
    );
  }

  function spawnWeekNumber(arr) {
    return (
      <div className="dm-sans-medium s12 skyColor calendar-week-number">
        {getWeekNumber(arr) !== undefined ? `s${getWeekNumber(arr)}` : null}
      </div>
    );
  }

  const changeMonth = (boolean) => {
    togglerSkeletonTrue();
    dispatch(modifyNav(boolean));
    let newTime = resolveChangeMonth(
      JSON.stringify(yearPeriod).slice(0, 2),
      JSON.stringify(yearPeriod).slice(2, 4),
      boolean
    );

    setYearPeriod(parseInt(newTime));

    newMonthFetch(parseInt(newTime));
    setTimeout(() => {
      togglerSkeletonFalse();
    }, 2000);
  };

  const resolveChangeMonth = (year, period, action) => {
    // false -1 | true +1
    let auxPeriod = parseInt(period);
    let auxYear = parseInt(year);
    let result = "";

    if (action) {
      auxPeriod = auxPeriod + 1;
      if (auxPeriod > 12) {
        auxPeriod = "01";
        auxYear = auxYear + 1;
        result = JSON.stringify(auxYear) + valueNotLong(auxPeriod);
      } else {
        result =
          JSON.stringify(auxYear) + valueNotLong(JSON.stringify(auxPeriod));
      }
    } else {
      auxPeriod = auxPeriod - 1;
      if (auxPeriod < 1) {
        auxPeriod = "12";
        auxYear = auxYear - 1;
        result = JSON.stringify(auxYear) + valueNotLong(auxPeriod);
      } else {
        result =
          JSON.stringify(auxYear) + valueNotLong(JSON.stringify(auxPeriod));
      }
    }
    return result;
  };

  const valueNotLong = (str) => {
    if (str.length == 1) {
      return "0" + str;
    } else {
      return str;
    }
  };

  const newMonthFetch = async (value) => {
    await dispatch(thisYearPeriodData(value));
  };

  return (
    <div className=" position-relative container--padding container--no-padding bg-white h-100 ">
      <ShareModalStructure  minHeight={"356px"} maxWidth={"496px"} id={"modalDetailsBirthday"} >
        <CalendarModalBirthday />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"608px"} id={"modalDetails"} >
        <CalendarModalDetails />
      </ShareModalStructure>
      
      <div className="container--padding--card-holder">
        <div>
          <div className=" w-100  d-flex align-items-center mt-5 mb-4">
            <div className="d-flex pe-3 w-75">
              {datesArray ? (
                  <button
                    onClick={todaysRequest}
                    type="button"
                    className="museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-bootstrap-radius button-padding today-button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <FormattedMessage id="today" />
                  </button>
              ) : (
                  <button
                    type="button"
                    className="museo-sans-700 s14 brightGrayBg brightGrayColor brightGrayBorder  button-bootstrap-radius button-padding today-button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <FormattedMessage id="today" />
                  </button>
              )}

              <div className="d-flex align-items-center justify-content-between ms-4 month-and-year-display">
                <div
                  className="blackStroke hover-pointer"
                  onClick={() => changeMonth(false)}
                >
                  <ChevronLeftIcon />
                </div>
                {datesArray ? (
                    <p className="museo-sans-500 s20 text-capitalize blackColor pt-1 user-select-none">
                      {`${dt.toLocaleDateString(multiLangGetCountry(), {
                        month: "long",
                      })} ${year}`}
                    </p>
                ) : (
                    <p
                      style={{ width: "120px" }}
                      className="museo-sans-500 s12 text-capitalize brightGrayBg brightGrayColor rounded-3 pt-1 user-select-none"
                    >
                      {`${dt.toLocaleDateString(multiLangGetCountry(), {
                        month: "long",
                      })} ${year}`}
                    </p>
                )}
                <div
                  className="blackStroke hover-pointer"
                  onClick={() => changeMonth(true)}
                >
                  <ChevronRightIcon />
                </div>
              </div>
            </div>
            <CalendarEventTitle />
          </div>

          <div className=" position-relative">
            
              <CalendarSkeleton
                toggler={skeletonLoader}
                smallerThan1280={smallerThan1280}
                daysInMonth={daysInMonth}
                stringFirstDayOfMonth={stringFirstDayOfMonth}
              />
              <CalendarBody stringFirstDayOfMonth={stringFirstDayOfMonth} smallerThan1280={smallerThan1280} daysInMonth={daysInMonth} spawnWeekDay={spawnWeekDay} displayDays={displayDays} slicedDateObjects={slicedDateObjects} spawnWeekNumber={spawnWeekNumber} />
          </div>
        </div>
      </div>
    </div>
  );
}
