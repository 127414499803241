import React from 'react'
import ReturnToAndTitle from '../../../Share/ReturnToAndTitle'
import { InputComponent } from './InputComponent'
import ContinueButton from './ContinueButton'

import { FormattedMessage } from 'react-intl'

export default function UserDataChangeStreetAddress({travelFunction}) {
  return (
    <>
        <ReturnToAndTitle
          travelFunction={travelFunction}
          returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
          title={"settings.user.data.street.title"}
        >
          <p>
            <FormattedMessage
              id="settings.user.data.code.p1"
              defaultMessage="Set a new address"
            />
          </p>
        </ReturnToAndTitle>
        <InputComponent type="text" id="direccion" placeholder="Bulnes 626" />
        <ContinueButton
          continueFunction={travelFunction}
          whereTo={1.8}
          text={"Continuar"}
        />
      </>
  )
}
