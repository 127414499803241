import { useSelector } from "react-redux";

import { formatAmount } from "../../../common/Share/FormatCurrency";

import { ReactComponent as SVGPendingFilled } from "../SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import { ReactComponent as SVGCompletedFilled } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGCIncompletedFilled } from "../../../common/svgs/ic-x-circle-bg.svg";
import { ReactComponent as SVGCBloquedFilled } from "../SVGs/Bloqueado/ic-item-bloq-grey.svg";
import { ReactComponent as SVGCUnlockedFilled } from "../SVGs/Bloqueado/ic-item-desbloq-sm-filled-yellow.svg";

import ProgressBarDashboard from "../../../common/Share/ProgressBarDashboard";
import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";

export default function FirstStepsCardContainerProgress({ data, extraData, index }) {
  const biggerThan1025 = useMediaPredicate("(min-width: 1025px)");
    const cultureInfo = useSelector(
      (state) => state.userInfo.userInfo.cultureInfo
    );
  
    const percentageCompleted = (actual, total) => {
      if (actual == 0) {
        return 0;
      }
      return (actual * 100) / total;
    };
  
    const statusChange = (status, index, extraData) => {
      if (index == 3) {
        if (status == "BLOCKED") {
          return "Bloqueado";
        }
        if (status == "UNLOCKED") {
          return "Desbloqueado";
        }
        if (status == "REACHED") {
          return `Alcanzado en semana ${extraData}`;
        }
        if (status == "COMPLETED") {
          return `Alcanzado en semana ${extraData}`;
        }
      }
  
      switch (status) {
        case "REACHED":
          return `Alcanzado en semana ${extraData}`;
        case "COMPLETED":
          return `Alcanzado en semana ${extraData}`;
        case "FAILED":
          return "No Alcanzado";
        case "PENDING":
          return "Pendiente";
        case "BLOCKED":
          return "Bloqueado";
      }
    };
  
    const statusIcon = (status, index) => {
      if (index == 3) {
        
        if (status == "BLOCKED") {
          return <SVGCBloquedFilled />;
        }
        if (status == "PENDING") {
          return <SVGCUnlockedFilled />;
        }
        if (status == "UNLOCKED") {
          return <SVGCUnlockedFilled />;
        }
        if (status == "COMPLETED") {
          return <SVGCompletedFilled />;
        }
        if (status == "REACHED") {
          return <SVGCompletedFilled />;
        }
      };
  
      switch (status) {
        case "COMPLETED":
          return <SVGCompletedFilled />;
        case "REACHED":
          return <SVGCompletedFilled />;
        case "FAILED":
          return <SVGCIncompletedFilled />;
        case "PENDING":
          return <SVGPendingFilled />;
        case "BLOCKED":
          return <SVGCBloquedFilled />;
      };
    };
  
    const changeStateForStatus = (state) => {
      switch (state) {
        case "FAILED":
          return "risk";
        case "PENDING":
          return "warning";
        case "BLOCKED":
          return "gap";
        case "COMPLETED":
          return "completed";
        case "REACHED":
          return "completed";
      }
    };
  
    return (
      <>
        <div>
          <div className=" d-flex justify-content-between align-items-center ">
            <div className=" museo-sans-500 s14 d-flex gap-1">
              <div
                style={{ width: "16px", height: "16px", top: "-2px" }}
                className=" position-relative svg-force-size"
              >
                  {
                    statusIcon(data?.stepDetail?.state, index)
                  }
              </div>
              <p className=" text-uppercase">
                {statusChange(data?.stepDetail?.state, index, data?.stepDetail?.reachedWeek)}
              </p>
              <p></p>
            </div>
          
            
          </div>
          
              <>
                {index != 3 && (
              <div className=" dm-sans-medium s14 d-flex gap-1" style={{marginLeft: "20px"}}>
                <p style={{color:"#171A22"}}>
                  {formatAmount(
                    data?.stepDetail?.currentAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p style={{color:"#908F9A"}}>
                  /{" "}
                  {formatAmount(
                    data?.stepDetail?.stepAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
            )}
              </>
           
          <div className=" mt-2">
            {index != 3 ? (
              <>
                <ProgressBarDashboard
                  completed={percentageCompleted(
                    data?.stepDetail?.currentAmount,
                    data?.stepDetail?.stepAmount
                  )}
                  incompleted={
                    percentageCompleted(
                      data?.stepDetail?.currentAmount,
                      data?.stepDetail?.stepAmount
                    ) >= 100
                      ? false
                      : true
                  }
                  height={12}
                  status={changeStateForStatus(data?.stepDetail?.state)}
                />
              </>
            ) : (
              <>
                <div
                  style={{ height: biggerThan1025 ? "56px" : "71px", background: "#f8f8f8" }}
                  className=" museo-sans-500 s14 d-flex flex-column justify-content-between p-2"
                >
                  <div className="d-flex justify-content-between">
                    <p> {data?.stepDetail?.stepDependency == 1 ? "Logro 1" : "Logro 2" }</p>
                    <div className=" museo-sans-700  d-flex gap-2">
                      {extraData?.stepDetail?.state == "REACHED" ? (
                        <p className="lightGreenColor"><FormattedMessage id="first.steps.status.reached" /></p>
                      ) : (
                        <>
                          {extraData?.stepDetail?.state == "FAILED" ? (
                            <>
                              <p className=" lightRedColor "style={{width:"91px"}}>
                                <FormattedMessage id="first.steps.status.not.reached" />
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="goldBoldColor">
                                <FormattedMessage id="first.steps.status.pending" />
                              </p>
                            </>
                          )}
                        </>
                      )}
                      <div>
                        {extraData?.stepDetail?.state == "REACHED" ? (
                          <div style={{width: "16px"}} className=" svg-force-size" >
                            {" "}
                            <SVGCompletedFilled />{" "}
                          </div>
                        ) : (
                          <div style={{width: "16px"}} className=" svg-force-size" >
                            {" "}
                            {extraData?.stepDetail?.state == "FAILED" ? (
                              <>
                                <SVGCIncompletedFilled />
                              </>
                            ) : (
                              <>
                                <SVGPendingFilled />{" "}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>
                      <FormattedMessage id="first.steps.edb.prize" />
                    </p>
                    <div className=" museo-sans-700  d-flex gap-2">
                      {data?.stepDetail?.currentEDB >=
                      data?.stepDetail?.requiredEDBs ? (
                        <p className=" lightGreenColor">
                          <FormattedMessage id="first.steps.status.reached.two" />
                        </p>
                      ) : (
                        <>
                          {extraData?.stepDetail?.state == "NOEXIST" ? (
                            <>
                              <p className=" lightRedColor " style={{width:"91px"}}>
                                <FormattedMessage id="first.steps.status.not.reached" />
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="goldBoldColor">
                                <FormattedMessage id="first.steps.status.pending" />
                              </p>
                            </>
                          )}
                        </>
                      )}
                      <div>
                        {data?.stepDetail?.currentEDB >=
                        data?.stepDetail?.requiredEDBs ? (
                          <div style={{width: "16px"}} className=" svg-force-size" >
                            {" "}
                            <SVGCompletedFilled />{" "}
                          </div>
                        ) : (
                          <div style={{width: "16px"}} className=" svg-force-size" >
                            {" "}
                            {extraData?.stepDetail?.state == "NOEXIST" ? (
                              <>
                                <SVGCIncompletedFilled />
                              </>
                            ) : (
                              <>
                                <SVGPendingFilled />{" "}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
  