import { Outlet } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { fetchUserProfile } from "../../Login/Framework/redux/loginSideEffects";
import { reSetMultiLanguage } from "../redux/commonActions";

import Header from "../Header/Presentations/pages/Header";
import Configuration from "../Config/Presentation/pages/Configuration";
import LogOut from "../LogOut/Presentation/pages/LogOut";
import PoliticsModal from "../termsAndConditions/PoliticsModal";
import EthicsCodeContent from "../termsAndConditions/EthicsCodeContent";
import DistributionAgreementContent from "../termsAndConditions/DistributionAgreementContent";
import TermsAndConditionsContent from "./../termsAndConditions/TermsAndConditionsContent";
import Footer from "../Footer/Footer";
import VimeoController from "../VimeoController/VimeoController";
import HeaderModalDelete from "../Header/Presentations/components/HeaderModalDelete";
import AlertModal from "../../Alert/Presentation/components/AlertModal";
import FirstStepModal from "../../Dashboard/Presentation/components/Modals/highlightOnBoarding/FirstStepModal";
import SecondStepModal from "../../Dashboard/Presentation/components/Modals/highlightOnBoarding/SecondStepModal";
import WelcomeModal from "../../Dashboard/Presentation/components/Modals/onBoarding/WelcomeModal";
import ThirdStepModal from "../../Dashboard/Presentation/components/Modals/highlightOnBoarding/ThirdStepModal";
import FourthStepModal from "../../Dashboard/Presentation/components/Modals/highlightOnBoarding/FourthStepModal";
import FifthStepModal from "../../Dashboard/Presentation/components/Modals/highlightOnBoarding/FifthStepModal";
import ExtraStepConsult from "../../Dashboard/Presentation/components/Modals/highlightOnBoarding/ExtraStepConsult";
import NavBar from "../NavBar/Presentation/pages/NavBar";
import ShareModalStructure from "../Share/ShareModalStructure";
import EDBModalReturn from "../../EDBs/Presentation/components/modals/EDBModalReturn";
import HeaderProgress from "../Header/Presentations/components/HeaderProgress";

export default function PrivateLayout() {
  const dispatch = useDispatch();
  const [settingsKey, setSettingsKey] = useState(Math.random());
  const [blurToggle, setBlurToggler] = useState(false);
  const notificationDetail = useSelector(
    (state) => state.headerData.notificationDetail
  );
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const divRefJumpTop = useRef(null);

  const country = localStorage.getItem("country");
  const clientId = localStorage.getItem("clientId");

  const onPrivateStart = async () => {
    await dispatch(reSetMultiLanguage(country));
    await dispatch(fetchUserProfile(clientId));
  };

  const pushToTop = () => {
    divRefJumpTop?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeBlurred = () => {
    setBlurToggler(false);
    localStorage.removeItem("firstLogin");
  };

  const toggleBlurred = () => {
    setBlurToggler(true);
  };

  useEffect(() => {
    onPrivateStart();
    if (
      localStorage.getItem("firstLogin") !== null &&
      localStorage.getItem("firstLogin") == "true"
    ) {
      setBlurToggler(true);
    } else {
      setBlurToggler(false);
      localStorage.removeItem("firstLogin");
    }
  }, [dispatch]);

  return (
    <main>
      <FirstStepModal removeBlur={removeBlurred} />
      <SecondStepModal removeBlur={removeBlurred} />
      <ThirdStepModal removeBlur={removeBlurred} />
      <FourthStepModal removeBlur={removeBlurred} />
      <FifthStepModal removeBlur={removeBlurred} />
      <ExtraStepConsult removeBlur={removeBlurred} />
      <WelcomeModal />
      <NavBar
        blur={blurToggle}
        pushToTop={pushToTop}
        toggleBlurred={toggleBlurred}
      />
      <ShareModalStructure
        minHeight={
          notificationDetail?.type === 3 || notificationDetail?.type === 4
            ? "570px"
            : ""
        }
        id={"alertDetails"}
        maxWidth={"496px"}
      >
        <AlertModal />
      </ShareModalStructure>
      <ShareModalStructure id={"modal-return"} width={"496px"}>
        <EDBModalReturn />
      </ShareModalStructure>
      <div
        data-bs-toggle="modal"
        data-bs-target="#modal-return"
        id="warning-step-back"
        className=" invisible"
      ></div>
      <div className=" invisible" >
        <HeaderProgress user={profileData} />
      </div>
      <VimeoController />
      <section
        className={` hidden-y-scroll  desc--dashboard-bg ${
          blurToggle && "blurred"
        } `}
        id="container--private-holder"
        style={{
          overflowX: "hidden",
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <div
          ref={divRefJumpTop}
          className=" position-absolute "
          style={{ top: "0" }}
        ></div>
        <HeaderModalDelete />
        <PoliticsModal
          id="ethicsCode"
          title={<FormattedMessage id="ethics.code.title" />}
        >
          <EthicsCodeContent />
        </PoliticsModal>
        <PoliticsModal
          id="distributionAgreement"
          title={<FormattedMessage id="politics.meta.distribute" />}
        >
          <DistributionAgreementContent />
        </PoliticsModal>
        <PoliticsModal
          id="termsAndConditions"
          title={<FormattedMessage id="politics.meta.terms" />}
        >
          <TermsAndConditionsContent showTitle={false} />
        </PoliticsModal>
        <LogOut />
        <Configuration key={settingsKey} keySetter={setSettingsKey} />
        <Header />
        
        <Outlet />

        <Footer />
      </section>
    </main>
  );
}
