import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { getTerritoryByLevel } from "../../../ClientList/Framework/redux/ClientListSideEffect";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import ShareComponentInput from "../../../common/Share/ShareComponentInput";
import ShareInputSelect from "../../../common/Share/ShareInputSelect";
import ShareLocalityController from "../../../common/Share/ShareLocalityController";
import { body } from "../../Framework/EDBsFormBody";
import { deactivateError } from "../../Framework/redux/EDBFormActions";
import { ErrorMessage } from "../pages/EDBsForm";

import { clientSelectAddressStyles } from "../../../common/Share/CustomSelectStyles";
import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGChevArrowUp } from "../../../common/svgs/ic-chevron-up-md.svg";

export default function EDBsFormPresent() {
  const country = localStorage.getItem("country");
  const dispatch = useDispatch();
  const valueFound = useSelector(
    (state) => state.clientListData.clientFormTerritory.data
  );
  const [locationsByLevel, setLocationsByLevel] = useState({
    levelOne: [],
    levelTwo: [],
    levelThree: [],
    levelFour: [],
  });
  const [form, setForm] = useState(ShareLocalityController(country));
  const [fatherURL, setFatherURL] = useState({
    levelOne: "",
    levelTwo: "",
    levelThree: "",
    levelFour: "",
  });
  const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
  const [addressInfo, setAddressInfo] = useState(
    auxFormBody?.addressInfo || body.addressInfo
  );
  const levelArray = ["levelOne", "levelTwo", "levelThree", "levelFour"];
  const errorToggler = useSelector(
    (state) => state.edbFormData.errorTogger.value
  );

  const onStartUp = async () => {
    setLocationsByLevel({
      levelOne: [],
      levelTwo: [],
      levelThree: [],
      levelFour: []
    });
    loadByLevels(1);
  };

  const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const compareStrings = (str1, str2) => {
  const normalizedStr1 = removeAccents(str1);
  const normalizedStr2 = removeAccents(str2);
  return normalizedStr1 === normalizedStr2;
};

  const loadByLevels = async (level, id = null) => {
    const result = await dispatch(
      getTerritoryByLevel({ level: level, value: id })
    );
    const found = form.references.find((ref) => ref.isLevel === level);
    if (result?.payload?.data?.locations && addressInfo[found?.name]) {
      
      const id = result?.payload?.data?.locations.find((loc) =>
        {
          return compareStrings(loc.value, addressInfo[found?.name])
        }
      )?.id;
      if (id && level !== form.maxLevel) {
        loadByLevels(level + 1, id);
      }
    }
  };

  const onChangeAuxFormBody = (key, value) => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    const updatedAdressInfo = { ...addressInfo, [key]: value };
    setAddressInfo(updatedAdressInfo);
    const updatedFormBody = { ...auxFormBody, addressInfo: updatedAdressInfo };
    localStorage.setItem("formBody", JSON.stringify(updatedFormBody));
  };

  const onChangeFatherURL = (level, newUrl) => {
    const auxFatherUrl = { ...fatherURL };
    dispatch(deactivateError());

    if (level === 2) {
      setFatherURL({ ...auxFatherUrl, levelOne: newUrl });
    }
    if (level == 3) {
      setFatherURL({ ...auxFatherUrl, levelTwo: newUrl });
    }
    if (level == 4) {
      setFatherURL({ ...auxFatherUrl, levelThree: newUrl });
    }

    return newUrl;
  };

  const onSameValueClean = (level, data, name) => {
    if (level === 1) {
      setLocationsByLevel({
        levelOne: locationsByLevel.levelOne,
        levelTwo: [],
        levelThree: [],
        levelFour: [],
      });
    }
    if (level === 2) {
      setLocationsByLevel({
        levelOne: locationsByLevel.levelOne,
        levelTwo: locationsByLevel.levelTwo,
        levelThree: [],
        levelFour: [],
      });
    }
    if (level === 3) {
      setLocationsByLevel({
        levelOne: locationsByLevel.levelOne,
        levelTwo: locationsByLevel.levelTwo,
        levelThree: locationsByLevel.levelThree,
        levelFour: [],
      });
    }
    if (form?.maxLevel === level) {
      return;
    }
  };

  const onChangeSelectedFather = async (level, data, name) => {
    if (data.value == name) {
      onSameValueClean(level, data, name);
      return "";
    }
    if (level === 4) {
      return "";
    }
    if (level === 1) {
      setLocationsByLevel({
        levelOne: locationsByLevel.levelOne,
        levelTwo: [],
        levelThree: [],
        levelFour: [],
      });
    }
    if (level === 2) {
      setLocationsByLevel({
        levelOne: locationsByLevel.levelOne,
        levelTwo: locationsByLevel.levelTwo,
        levelThree: [],
        levelFour: [],
      });
    }
    if (level === 3) {
      setLocationsByLevel({
        levelOne: locationsByLevel.levelOne,
        levelTwo: locationsByLevel.levelTwo,
        levelThree: locationsByLevel.levelThree,
        levelFour: [],
      });
    }
    if (form?.maxLevel === level) {
      return;
    }
    await dispatch(
      getTerritoryByLevel({
        level: level + 1,
        value: onChangeFatherURL(level + 1, data.value),
      })
    );
  };

  const onChangeAddressSelect = (key, value) => {
    if (!key?.label) {
      return "";
    }
    onChangeAuxFormBody(value, key.label);
  };

  const onChangeAddressInput = (key, empty, value) => {
    if (!key) {
      return "";
    }
    onChangeAuxFormBody(key, value);
    dispatch(deactivateError());
  };

  const returnOptionalIf = (reference) => {
    if (reference.name === "reference") {
      return multiLangJSONParse()["selected.placeholder.optional"];
    }
    return "";
  };

  const noErrorOnOptional = (reference) => {
    if (reference.name == "reference") {
      return false;
    }
    return errorToggler && !addressInfo[reference.name];
  };

  const renderAuxElement = (reference) => {
    const defaultOption = {
      value: reference.name,
      label: `${multiLangJSONParse()[`selected.${reference.name}`]}`,
    };
    const globalStyles = clientSelectAddressStyles;

    const DropdownIndicator = ({ selectProps, ...props }) => {
      return (
        <components.DropdownIndicator {...props}>
          {selectProps.menuIsOpen ? <SVGChevArrowUp /> : <SVGChevArrowDown />}
        </components.DropdownIndicator>
      );
    };

    if (reference.isLevel === -1) {
      return (
        <div className="d-flex flex-column gap-2">
          <ShareComponentInput
            errorToggler={noErrorOnOptional(reference)}
            key={reference.name}
            value={addressInfo[reference?.name] || ""}
            label={`${
              multiLangJSONParse()[`${reference.name}`]
            } ${returnOptionalIf(reference)}  `}
            name={reference.name}
            placeholder={multiLangJSONParse()[`selected.${reference.name}`]}
            onChange={onChangeAddressInput}
          />
          <ErrorMessage errorToggler={noErrorOnOptional(reference)} />
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column gap-2" key={reference.name}>
          <p>
            <FormattedMessage id={reference.name} />{" "}
          </p>
          {locationsByLevel[levelArray[reference.isLevel - 1]]?.length > 0 ? (
            <ShareInputSelect
              errorToggler={errorToggler && !addressInfo[reference.name]}
              options={
                locationsByLevel[levelArray[reference.isLevel - 1]] || null
              }
              value={addressInfo[reference?.name] || ""}
              name={reference.name}
              width={"100%"}
              onChange={onChangeAddressSelect}
              onChangeFather={onChangeSelectedFather}
              level={reference.isLevel}
            />
          ) : (
            <Select
              unstyled
              isDisabled={true}
              defaultValue={defaultOption}
              components={{ DropdownIndicator }}
              options={[]}
              noOptionsMessage={`${
                multiLangJSONParse()[`selected.${reference.name}`] ||
                "Seleccionar"
              }`}
              styles={{
                ...globalStyles,
                container: (style) => ({
                  ...style,
                  width: "100%",
                  height: "40px",
                }),
                indicatorSeparator: (styles) => ({
                  ...styles,
                  borderLeft: "1px solid #CED2FF",
                }),
              }}
              className={`s14 rounded-2 ${"input-border"} bg-white`}
              classNames={{
                control: (state) =>
                  state.isDisabled &&
                  "brightGrayBgOpacity20 custom-select-placeholder s14",
                option: (state) =>
                  state.isFocused && "text-onhover-bold museo-sans-700",
                dropdownIndicator: (state) =>
                  state.isDisabled && "svg-force-path-stroke-grey s14",
                menuList: () => "remove-scrollbar",
              }}
              placeholder={`${
                multiLangJSONParse()[`selected.${reference.name}`] ||
                "Seleccionar"
              }`}
            />
          )}
          <ErrorMessage
            errorToggler={errorToggler && !addressInfo[reference.name]}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    if (!valueFound) return;

    const { levelOne, levelTwo, levelThree, levelFour } = valueFound;

    const updateLevel = (level, levelKey) => {
      if (!level || !level.locations) return;

      const updatedLocations = level.locations.map((location) => ({
        value: location.id,
        label: location.value,
      }));

      setLocationsByLevel((prevState) => ({
        ...prevState,
        [levelKey]: updatedLocations,
      }));
    };

    updateLevel(levelOne, "levelOne");
    updateLevel(levelTwo, "levelTwo");
    updateLevel(levelThree, "levelThree");
    updateLevel(levelFour, "levelFour");
  }, [valueFound]);

  useEffect(() => {
    onStartUp();
  }, []);

  return (
    <div className="edb-form-grid gap-3">
      {form?.references && addressInfo && (
        <>
          {form.references.map((reference) => {
            return renderAuxElement(reference);
          })}
        </>
      )}
    </div>
  );
}
