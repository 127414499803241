import React from 'react'

import ReturnToAndTitle from '../../../Share/ReturnToAndTitle'
import ContinueButton from './ContinueButton'
import { InputComponent } from './InputComponent'

import { FormattedMessage } from 'react-intl'

export default function UserDataChangePhoneNumber({travelFunction}) {
  return (
    <>
        <ReturnToAndTitle
          travelFunction={travelFunction}
          returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
          title={"settings.user.data.phone.title"}
        >
          <p>
            <FormattedMessage
              id="settings.user.data.phone.p1"
              defaultMessage="Set a new phone number."
            />
            <br />
            <FormattedMessage
              id="settings.user.data.phone.p2"
              defaultMessage="We'll send you a SMS to verify it."
            />
          </p>
        </ReturnToAndTitle>
        <InputComponent
          type="tel"
          id="contraseña"
          placeholder="+54 11 2458 2578"
        />
        <ContinueButton
          continueFunction={travelFunction}
          whereTo={1.5}
          text={"Continuar"}
        />
    </>
  )
}
