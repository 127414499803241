import ProgressBar from "./ProgressBar";

export default function GroupTaskImg({ completed }) {
  return (
    <>
      {completed ? (
        <div className="px-3">
          <div className="my-1">
            <ProgressBar incompleted={false} completed={100} />
          </div>
          <div className="d-flex">
            <ProgressBar incompleted={false} completed={100} />
            <ProgressBar incompleted={false} completed={100} />
            <ProgressBar incompleted={false} completed={100} />
            <ProgressBar incompleted={false} completed={100} />
          </div>
          <div className="my-1">
            <ProgressBar incompleted={false} completed={100} />
          </div>
        </div>
      ) : (
        <div className="px-3">
          <div className="my-1">
            <ProgressBar incompleted={false} completed={100} />
          </div>
          <div className="d-flex">
            <ProgressBar incompleted={false} completed={100} />
            <ProgressBar incompleted={false} completed={100} />
            <ProgressBar incompleted={false} completed={100} />
            <ProgressBar incompleted={false} completed={100} />
          </div>
          <div className="my-1">
            <ProgressBar incompleted={true} completed={50} />
          </div>
        </div>
      )}
    </>
  );
}
