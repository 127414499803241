import axios from "axios";
import swal from "sweetalert2";
import { multiLangJSONParse } from "../Share/MultiLangJSONParse";
import NetSrvRefresh from "./NetSrvRefresh";


const customNetServAxios = (contentType, ignoreToken, arrayStatus) => {
  const NetSrv = axios.create({
    baseURL: ignoreToken ? process.env.REACT_APP_API_URL_CMS : process.env.REACT_APP_API_URL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      "Content-Type": contentType
        ? contentType
        : "application/x-www-form-urlencoded",
    },
  });

  let isRefreshing = false;
let refreshSubscribers = [];
const failedRequests = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onErrorHandler = () => {
  if (window.location.pathname == "/dashboard" || window.location.pathname == "/directora/logging"  ) {
    return false;
  } else {
    return true;
  }
};

  const onRefresh = (token) => {
    NetSrv.defaults.headers.common.Authorization = token;
    refreshSubscribers.forEach((cb) => cb(token));
    failedRequests.forEach((request) =>
      request.resolve(NetSrv(request.originalRequest))
    );
    failedRequests.splice(0, failedRequests.length);
  };

  const foundStatusError = (status) => {
    if (!arrayStatus) {
      return false;
    }
    let found = arrayStatus.find(item => item === status);
    if (found) {
      return true;
    }
    return false;
  };

  NetSrv.interceptors.request.use(
    async (config) => {
      if (ignoreToken) {
        return config;
      }

      if (!localStorage.getItem("token")) {
        return config;
      }
      const token = localStorage.getItem("token");
      config.headers.Authorization = token;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  NetSrv.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (response.info) {
        response = undefined;
      } else {
        response = response.data;
      }
      return response;
    },
    async (error) => {
      if (!localStorage.getItem("token")) {
        return error.response.data.code;
      }
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (!ignoreToken) {
        if (error.response.data?.code == "505") {
          return "error";
        };

        if(foundStatusError(status)){
            swal
                .fire({
                  title: multiLangJSONParse()["netserver.error.title"],
                  icon: "error",
                  text: error?.response.data.message,
                  allowOutsideClick: false,
                  showCancelButton: true,
                  cancelButtonText: multiLangJSONParse()["buttons.continue"],

                  confirmButtonText: multiLangJSONParse()["buttons.back"],
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.history.back();
                  } else {
                    // window.location.reload();
                  }
                });
                return;
        };

        if (status === 401 && localStorage.getItem("refresh")) {
          if (error.response.data.code == "102") {
            return;
          }

          if (!isRefreshing) {
            isRefreshing = true;

            let urlencoded = new URLSearchParams();
            const location = localStorage.getItem("country");
            urlencoded.append("type", "refresh");
            urlencoded.append("notificationId", "ddffgg");
            try {
              const response = await NetSrvRefresh.post(
                `${location}/security/auth/token`,
                urlencoded
              );

              localStorage.setItem("token", `Bearer ${response.accessToken}`);
              localStorage.setItem(
                "refresh",
                `Bearer ${response.refreshToken}`
              );
              localStorage.setItem(
                "token_time",
                new Date().getTime() + response.accessTokenExpires + 3000
              );

              const { access_token } = response.accessToken;
              NetSrv.defaults.headers.common.Authorization = `Bearer ${access_token}`;
              onRefresh(access_token);
              isRefreshing = false;
            } catch (error) {}
          }

          const retryOriginalRequest = new Promise((resolve) => {
            subscribeTokenRefresh((access_token) => {
              originalRequest.headers.Authorization = `Bearer ${access_token}`;
              resolve(NetSrv(originalRequest));
            });
            failedRequests.push({ resolve, originalRequest });
          });

          const retryTimer = setInterval(() => {
            if (!isRefreshing) {
              clearInterval(retryTimer);
              return;
            }

            subscribeTokenRefresh((access_token) => {
              originalRequest.headers.Authorization = `Bearer ${access_token}`;
            });
          }, 1000);

          return retryOriginalRequest;
        };

        if (error.isAxiosError) {
          if (
            error.message === "timeout of 10000ms exceeded" ||
            error.message === "Network Error" ||
            error.response ===
              undefined /*|| error.response.status !== 499 || error.response.status !== 444*/
          ) {
            if (onErrorHandler() && !ignoreToken) {
              swal
                .fire({
                  title: multiLangJSONParse()["netserver.time.title"],
                  text: multiLangJSONParse()["netserver.26&19.errMsg"],
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: multiLangJSONParse()["buttons.restart.net"],

                  confirmButtonText: multiLangJSONParse()["buttons.back"],
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.history.back();
                  } else {
                    window.location.reload();
                  }
                });
            }
          } else {
            let errMsg;
            if (
              error.response &&
              error.response.data.code != 909 &&
              error.response.data.code != 910
            ) {
              if (
                error.response.status === 500 ||
                error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 404 ||
                error.response.status === 408 ||
                error.response.status === 26 ||
                error.response.status === 19
              ) {
                if (error.response.status === 0) {
                  errMsg = multiLangJSONParse()["netserver.0&403.errMsg"];
                }
                if (
                  error.response.status === 26 ||
                  error.response.status === 19
                ) {
                  errMsg = `${multiLangJSONParse()["netserver.26&19.errMsg"]}`;
                }
                if (error.response.status === 401) {
                  errMsg = `${multiLangJSONParse()["netserver.401.errMsg"]}`;
                }
                if (error.response.status === 404) {
                  errMsg = `${multiLangJSONParse()["netserver.404.errMsg"]}`;
                }
                if (error.response.status === 408) {
                  errMsg = `${multiLangJSONParse()["netserver.408.errMsg"]} `;
                }
                if (error.response.status === 400) {
                  errMsg = `${multiLangJSONParse()["netserver.400.errMsg"]} `;
                }
                if (error.response.status === 500) {
                  errMsg = `${multiLangJSONParse()["netserver.0&403.errMsg"]} `;
                }
              } else {
                errMsg = `${multiLangJSONParse()["netserver.0&403.errMsg"]} `;
              }
            }

            if (onErrorHandler()) {
              swal
                .fire({
                  title: multiLangJSONParse()["netserver.error.title"],
                  icon: "error",
                  text: multiLangJSONParse()["netserver.26&19.errMsg"],
                  allowOutsideClick: false,
                  showCancelButton: true,
                  cancelButtonText: multiLangJSONParse()["buttons.restart.net"],

                  confirmButtonText: multiLangJSONParse()["buttons.back"],
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.history.back();
                  } else {
                    window.location.reload();
                  }
                });
            }
          }
        };
      }

      return Promise.reject(error);
    }
  );

  return NetSrv;
};

export default customNetServAxios;
