import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import onBoardingImage from '../../../../../common/img/onboarding-0.png'
import Loader from '../../../../../common/Share/Loader'
import RegisterAnalytics from '../../../../../Login/Framework/ga/RegisterAnalytics'
import { defineStepNumber } from '../../../../Framework/redux/dashboardAction'

export default function WelcomeModal() {
    const dispatch = useDispatch()
    const userData = useSelector(state => state.userInfo.userInfo);
    const loading = useSelector(state => state.userInfo.loader);

    const startWelcome = () => {
        dispatch(defineStepNumber(1));
        RegisterAnalytics("welcome-start")
    }

  return (
    <div className="modal fade" id="welcomeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered justify-content-center" /*style={{height: "100vh", marginTop: "0", marginBottom: "0"}}*/ style={{maxWidth: "unset"}}>
            <div className="modal-content overflow-hidden" style={{width: "448px", height: "551px", borderRadius: "16px", paddingRight: "24px", paddingLeft: "24px"}}>
                <div className="modal-body d-flex flex-column p-0 align-items-center" /*style={{width: "448px", height: "551px", borderRadius: "16px"}}*/>{/*remover padding */}
                    <div className='d-flex flex-column justify-content-center align-items-center w-100' /*style={{height: "85%"}}*/>
                        <div className='modal-image position-relative' style={{width: "318px", height: "340px"}}>
                            <img src={onBoardingImage} style={{top: -25, left: 15, width: "295px", height: "392px"}} alt="Welcome" className=' position-absolute'/>
                        </div>
                        
                        <div className='d-flex flex-column justify-content-center align-items-center text-center s14 '>
                            <h3 className=' museo-sans-700 s24' >{`¡Bienvenid@, ${userData.firstName ? userData.firstName : ""}!`}</h3>
                            <div>
                                <p className=' performance-toltip-inline-container museo-sans-500 blackColor'>
                                    <FormattedMessage id='on.boarding.welcome.your.title' />
                                </p>
                                <p className='performance-toltip-inline-container museo-sans-700 blackColor'>{`${userData.roleDescription ? userData.roleDescription : ""}.`}</p>
                            </div>
                            <div style={{width: "285px"}}>
                                <p className='performance-toltip-inline-container museo-sans-500 blackColor'>
                                    <FormattedMessage id='on.boarding.welcome.p.one' />
                                </p>
                                <p className='performance-toltip-inline-container museo-sans-700 blackColor'>
                                <FormattedMessage id='on.boarding.welcome.p.two' />
                                </p>
                            </div>
                            
                        </div>
                    </div>
                    <div style={{marginTop: "24px"}}>
                        {
                            loading ?
                                <button type="button" className=" position-relative museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-bootstrap-radius button-padding" style={{width: "288px", height: "40px"}}>
                                    <Loader loading={loading} />
                                    <FormattedMessage id='buttons.start' />
                                </button>
                            :
                                <button onClick={() => dispatch(defineStepNumber(1))} type="button" className=" position-relative museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-bootstrap-radius button-padding" data-bs-target="#firstStepModal" data-bs-toggle="modal" style={{width: "288px", height: "40px"}}>
                                    <Loader loading={loading} />
                                    <FormattedMessage id='buttons.start' />
                                </button>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
