import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const OpenEDBMeetings  = createAsyncThunk(
    'OpenEDBMeetings',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=0&limit=10&edb_state=open`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const DatedEDBMeetings  = createAsyncThunk(
    'DatedEDBMeetings',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=0&limit=10&edb_state=dated`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const ClosedEDBMeetings  = createAsyncThunk(
    'ClosedEDBMeetings',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=0&limit=${number}&edb_state=closed`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

/*********Encuentros Extra*********/

const ClosedExtraEDBMeetings  = createAsyncThunk(
    'ClosedExtraEDBMeetings',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=${data.offset}&limit=${data.limit}&edb_state=closed${data.sortBy ? `&sort_by=${data.sortBy}` : "&sort_by=date.desc"}${data.edbType ? `&edb_type=${data.edbType}` : ""}${data.hostAward ? `&host_award=${data.hostAward}` : ""}${data.date.dateStart ? `&date_start=${data.date.dateStart}` : ""}${data.date.dateEnd ? `&date_end=${data.date.dateEnd}` : ""}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const OpenExtraEDBMeetings  = createAsyncThunk(
    'OpenExtraEDBMeetings',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=${data.offset}&limit=${data.limit}&edb_state=open${data.sortBy ? `&sort_by=${data.sortBy}` : "&sort_by=date.desc"}${data.edbType ? `&edb_type=${data.edbType}` : ""}${data.hostAward ? `&host_award=${data.hostAward}` : ""}${data.date.dateStart ? `&date_start=${data.date.dateStart}` : ""}${data.date.dateEnd ? `&date_end=${data.date.dateEnd}` : ""}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const DatedExtraEDBMeetings  = createAsyncThunk(
    'DatedExtraEDBMeetings',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=${data.offset}&limit=${data.limit}&edb_state=dated${data.sortBy ? `&sort_by=${data.sortBy}` : "&sort_by=date.desc"}${data.edbType ? `&edb_type=${data.edbType}` : ""}${data.hostAward ? `&host_award=${data.hostAward}` : ""}${data.date.dateStart ? `&date_start=${data.date.dateStart}` : ""}${data.date.dateEnd ? `&date_end=${data.date.dateEnd}` : ""}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

/*******Encuentros filtrados********/

const FilteredClosedEDBMeetings  = createAsyncThunk(
    'FilteredClosedEDBMeetings',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=${data.offset}&limit=${data.limit}&edb_state=closed${data.sortBy ? `&sort_by=${data.sortBy}` : "&sort_by=date.desc"}${data.edbType ? `&edb_type=${data.edbType}` : ""}${data.hostAward ? `&host_award=${data.hostAward}` : ""}${data.date.dateStart ? `&date_start=${data.date.dateStart}` : ""}${data.date.dateEnd ? `&date_end=${data.date.dateEnd}` : ""}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const FilteredOpenEDBMeetings  = createAsyncThunk(
    'FilteredOpenEDBMeetings',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=${data.offset}&limit=${data.limit}&edb_state=open${data.sortBy ? `&sort_by=${data.sortBy}` : "&sort_by=date.desc"}${data.edbType ? `&edb_type=${data.edbType}` : ""}${data.hostAward ? `&host_award=${data.hostAward}` : ""}${data.date.dateStart ? `&date_start=${data.date.dateStart}` : ""}${data.date.dateEnd ? `&date_end=${data.date.dateEnd}` : ""}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const FilteredDatedEDBMeetings  = createAsyncThunk(
    'FilteredDatedEDBMeetings',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/meetings?offset=${data.offset}&limit=${data.limit}&edb_state=dated${data.sortBy ? `&sort_by=${data.sortBy}` : "&sort_by=date.desc"}${data.edbType ? `&edb_type=${data.edbType}` : ""}${data.hostAward ? `&host_award=${data.hostAward}` : ""}${data.date.dateStart ? `&date_start=${data.date.dateStart}` : ""}${data.date.dateEnd ? `&date_end=${data.date.dateEnd}` : ""}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)



/*export { fetchEDBMeetingsOpen, fetchEDBMeetingsDated, fetchEDBMeetingsClosed }*/
export { OpenEDBMeetings, DatedEDBMeetings, ClosedEDBMeetings, ClosedExtraEDBMeetings, OpenExtraEDBMeetings, DatedExtraEDBMeetings, FilteredClosedEDBMeetings, FilteredOpenEDBMeetings, FilteredDatedEDBMeetings }


