import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
  isValidNumber,
} from "libphonenumber-js";

export default function SharePhoneLibrary(phone) {

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52",
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const callbackLibrary = async () => {
    let country = localStorage.getItem("country");
    if (phone.length == 0) {
      return true;
    }
    if (phone.length < 2) {
      return true;
    }
    if (!onlyNumbers(numbers[country]) || !onlyNumbers(phone)) {
      return true;
    }
    let associate = numbers[country].concat(phone);
    localStorage.setItem("tel", associate);
    if (associate.length < 4) {
      return true;
    };

    const phoneNumber = parsePhoneNumber(associate);
    if (
      validatePhoneNumberLength(phoneNumber.number, phoneNumber.country) ==
      "TOO_SHORT"
    ) {
      return true;
    };

    if (!isPossiblePhoneNumber(phoneNumber.number, phoneNumber.country)) {
      return true;
    };

    if (!isValidPhoneNumber(phoneNumber.number, phoneNumber.country)) {
      return true;
    };

    if (!isValidNumber(phoneNumber.number)) {
      return true;
    };

    return false;
  };

  const onlyNumbers = (string) => {
    return /^[0-9+]+$/.test(string);
  };

  return callbackLibrary();
}
