import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SVGShare } from "../../../common/svgs/ic-share-sm.svg";
import { ReactComponent as SVGChevronDown } from "../../../common/svgs/ic-chevron-down-sm.svg";
import { ReactComponent as SVGChevronUp } from "../../../common/svgs/ic-chevron-up-sm.svg";
import DashboardGraphPrevPeriods from "../components/Graph/DashboardGraphPrevPeriods";
import {
  fetchHistoryEarning,
  fetchHistoryEarningByPeriod,
  fetchHistoryPeriods,
} from "../../../Earning/redux/earningSideEffects";
import { patchPeriodId } from "../../../Earning/redux/earningActions";
import DashboardPrevInfoModal from "../components/Modals/DashboardPrevInfoModal";
import ModalClipboard from "../../../common/Share/ModalClipboard";
import DashboardPrevModalShare from "../components/Modals/DashboardPrevModalShare";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import { useRef } from "react";
import ShareNameShortMonth from "../../../common/Share/ShareNameShortMonth";

export default function DashboardPrevPeriods() {
  const periodGap = useSelector(
    (state) => state.headerData.period.periodProcessGap
  );
  const [toggle, setToggle] = useState(false);
  
  const headerPeriod = useSelector((state) => state.headerData.period);
  const profileData = useSelector((state) => state.userInfo);
  const historyPeriods = useSelector((state) => state.earning.historyPeriods);
  const historyEarning = useSelector((state) => state.earning.historyEarning);
  const periodId = useSelector((state) => state.earning.periodActual);
  const loading = useSelector((state) => state.earning.isLoading);
  
  const [copyToggler, setCopyToggler] = useState(false);
  const divRef = useRef(null);
  const dispatch = useDispatch();

  

  const onOpenCopyModal = () => {
    setCopyToggler(true);
  };

  const onClick = () => {
    setToggle(!toggle);
  };

  const onCloseCopyModal = () => {
    setCopyToggler(false);
  };

  const scrollTo = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  const startUp = async (currentYearPeriod) => {
      dispatch(fetchHistoryPeriods({ currentYearPeriod: currentYearPeriod }));
      scrollTo();
  };
 

  useEffect(() => {
    startUp(headerPeriod?.currentYearPeriod);
  }, []);

  useEffect(() => {
    historyPeriods &&
      dispatch(
        fetchHistoryEarning({
          currentYearPeriod: historyPeriods[0]?.yearPeriod,
        })
      );
  }, [historyPeriods]);


  const directorId = localStorage.getItem("directorId");

  return (
    <div
      style={{ height: "1000px" }}
      className=" test-hidden-scroll position-relative bg-white container--padding"
    >
      <div
        style={{ top: "-100px" }}
        ref={divRef}
        className=" position-absolute"
      ></div>
      <DashboardPrevModalShare
        dataEarning={historyEarning}
        dataPeriod={periodId}
        open={onOpenCopyModal}
        socialArray={profileData.userInfo.socialNetwork}
        user={profileData.userInfo}
      />
      <ModalClipboard show={copyToggler} close={onCloseCopyModal} />
      <DashboardPrevInfoModal />
      <div className=" container--padding--card-holder">
        <div>
          <div className=" w-100 position-relative my-4 py-3 d-flex  justify-content-end gap-3">
            {loading || loading == null ? (
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#modalShareExample"
                  className=" LinkOpacityHover align-items-center d-flex py-2 px-5 btnCustom brightGrayBg brightGrayColor  museo-sans-700"
                >
                  <p className=" s14 px-1">
                    <FormattedMessage
                      id="dashboard.share.button.short"
                      defaultMessage={"Compartir"}
                    />
                  </p>
                </button>
            ) : (
              <>
                {!directorId && (
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#modalShareExample"
                    className=" LinkOpacityHover align-items-center d-flex py-2 px-5 btnCustom bg-white skyBorder skyColor museo-sans-700"
                  >
                    <SVGShare />
                    <p className=" s14 px-1">
                      <FormattedMessage
                        id="dashboard.share.button.short"
                        defaultMessage={"Compartir"}
                      />
                    </p>
                  </button>
                )}
              </>
            )}
            {loading || loading == null ? (
                <button className=" LinkOpacityHover align-items-center d-flex py-2 px-5 btnCustom  brightGrayBg brightGrayColor museo-sans-700">
                  <p className=" s14 px-1">
                    {" "}
                    <FormattedMessage
                      id="dashboard.prev.earning.period"
                      defaultMessage={"Periodo"}
                    />{" "}
                    {periodId}
                  </p>
                </button>
            ) : (
                <button
                  onClick={onClick}
                  className=" LinkOpacityHover align-items-center d-flex py-2 px-5 btnCustom bg-white skyBorder skyColor museo-sans-700"
                >
                  <p className=" s14 px-1">
                    {" "}
                    <FormattedMessage
                      id="dashboard.prev.earning.period"
                      defaultMessage={"Periodo"}
                    />{" "}
                    {periodId}
                  </p>
                  {toggle ? <SVGChevronDown /> : <SVGChevronUp />}
                </button>
            )}
            {toggle && (
              <div
                style={{
                  top: "70px",
                  width: "392px",
                  height: "400px",
                  zIndex: "5",
                }}
                className=" hidden-y-scroll bg-white shadow position-absolute px-3 rounded-3 "
              >
                {historyPeriods ? (
                  <PeriodListRow
                    onClick={onClick}
                    historyPeriods={historyPeriods}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div className=" w-100" style={{ minHeight: "80vh" }}>
            <DashboardGraphPrevPeriods
              loading={loading}
              gap={periodGap}
              historyEarning={historyEarning}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function PeriodListRow({ historyPeriods, onClick }) {
  const dispatch = useDispatch();
  const periodId = useSelector((state) => state.earning.periodActual);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const fetchDate = (period) => {
    const formatDate = (dateString) => {
      const [year, month, day] = dateString.split("-").map(Number);
      return `${day} ${ShareNameShortMonth(month)}`;
    };

    const startDate = formatDate(period.periodStartDate.split("T")[0]);
    const endDate = formatDate(period.periodEndDate.split("T")[0]);

    return `${startDate} / ${endDate} ${period.periodEndDate.split("-")[0]}`;
  };

  const fetchEarningPeriod = async (period) => {
    onClick();
    dispatch(patchPeriodId(period.period));
    await dispatch(
      fetchHistoryEarningByPeriod({ yearPeriod: period.yearPeriod })
    );
  };

  return (
    <>
      {historyPeriods.map((period) => {
        return (
          <div
            onClick={() => fetchEarningPeriod(period)}
            style={{ height: "63px" }}
            className=" pointer d-flex flex-column w-100 my-3"
          >
            <>
              <div className="px-3 grayColor museo-sans-500 s12 text-uppercase">
                {fetchDate(period)}
              </div>
              <div className="px-3 align-items-end d-flex h-100 justify-content-between">
                <div
                  className={` ${
                    period.period === periodId ? "skyColor" : ""
                  }  museo-sans-${
                    period.period === periodId ? "700" : "500"
                  } s16`}
                >
                  <FormattedMessage id="dashboard.prev.earning.period" />{" "}
                  {period.period}
                </div>
                <div
                  className={`dm-sans-${
                    period.period === periodId ? "bold" : "medium"
                  } s16`}
                >
                  {formatAmount(
                    period.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </div>
              </div>
            </>
            <hr className="m-0 mt-3" />
          </div>
        );
      })}
    </>
  );
}
