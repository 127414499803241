import { useSelector, useDispatch } from "react-redux";
import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as CloseIcon } from "../../../../../common/svgs/ic-x-sm.svg";
import { ReactComponent as ArrowRight } from "../../../../../common/svgs/ic-arrow-right-sm.svg";
import { defineStepNumber } from "../../../../Framework/redux/dashboardAction";

import { ReactComponent as Edbs } from "../../../../../common/svgs/ic-pedido-edb-black-sm.svg";
import { ReactComponent as AddPerson } from "../../../../../common/svgs/ic-add-person.svg";
import { ReactComponent as NewOrder } from "../../../../../common/svgs/ic-new-order.svg";
import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGCross } from "../../../../../common/svgs/ic-x-sm.svg";

export default function SecondStepModal({ removeBlur }) {
  const biggerThan1920 = useMediaPredicate("(min-width: 1800px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const dispatch = useDispatch();
  const groupData = useSelector((state) => state.scoreData.groups);
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const status = useSelector((state) => state.scoreData.consultantStatus);

  const onCancelBlur = () => {
    removeBlur();

    localStorage.removeItem("firstLogin");
    dispatch(defineStepNumber(20));
  };

  const onCancelOnlyBlur = () => {
    removeBlur();
    dispatch(defineStepNumber(20));
  };

  const delayedActivate = () => {
    const activateModalButton = document.getElementById("activateDelay");
    if (profileData.level < 2) {
      dispatch(defineStepNumber(4));
      setTimeout(() => {
        activateModalButton.click();
      }, 800);
    } else {
      dispatch(defineStepNumber(3));
    }
  };

  const s3Styles = {
    right: "28%",
    top: "120px",
    width: "355px",
    height: "205px",
  };
  const s2Styles = {
    right: "465px",
    top: "75px",
    width: "355px",
    height: "205px",
  };
  const s1Styles = {
    right: "450px",
    top: "75px",
    width: "355px",
    height: "205px",
  };
  return (
    <div
      className="modal fade vh-100 vw-100"
      id="secondStepModal"
      data-bs-backdrop="static"
      aria-hidden="true"
      aria-labelledby="secondStepModal"
      tabindex="-1"
    >
      <DoppelgangerBtnNew />
      <div
        className="modal-dialog m-0 position-fixed"
        style={biggerThan1920 ? s3Styles : biggerThan1280 ? s2Styles : s1Styles}
      >
        <div
          className="modal-content h-100"
          style={{ padding: "25px", borderRadius: "15px" }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="museo-sans-500 s12 grayColor">
                {profileData.level < 2 ? "PASO 2 DE 4" : "PASO 2 DE 5"}
              </p>
              <div
                className=" pointer "
                data-bs-dismiss="modal"
                onClick={onCancelOnlyBlur}
                aria-label="Close"
              >
                <CloseIcon />
              </div>
            </div>
            <p className="museo-sans-700 s20 blackColor">
              <FormattedMessage id="on.boarding.second.title.one" />
            </p>
            <div className="mt-2" style={{ lineHeight: "18px" }}>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ display: "inline", verticalAlign: "top" }}
              >
                <FormattedMessage id="on.boarding.second.p.one" />
              </p>
              <p
                className="s14 museo-sans-700 blackColor"
                style={{ display: "inline", verticalAlign: "top" }}
              >
                <FormattedMessage id="on.boarding.second.p.two" />
              </p>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ display: "inline", verticalAlign: "top" }}
              >
                <FormattedMessage id="on.boarding.second.p.three" />.
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-auto">
            <a
              onClick={onCancelBlur}
              data-bs-dismiss="modal"
              aria-label="Close"
              role="button"
              className="museo-sans-700 s14 skyColor button-hover-light-text"
            >
              <FormattedMessage id="buttons.not.show.ever" />
            </a>

            {profileData.level < 2 ? (
              <div
                onClick={delayedActivate}
                className="d-flex hover-pointer align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
              >
                <p className=" text-capitalize museo-sans-700 s14 skyColor button-hover-light-text">
                  <FormattedMessage id="buttons.follow" />
                </p>
                <ArrowRight />
              </div>
            ) : (
              <div
                onClick={() => dispatch(defineStepNumber(3))}
                className="d-flex hover-pointer align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
                data-bs-target="#thirdStepModal"
                data-bs-toggle="modal"
              >
                <p className=" text-capitalize museo-sans-700 s14 skyColor button-hover-light-text">
                  <FormattedMessage id="buttons.follow" />
                </p>
                <ArrowRight />
              </div>
            )}
          </div>
        </div>
        <div className="modal-arrow modal-arrow-rotate"></div>
      </div>
      <div
        id="activateDelay"
        data-bs-target="#extraStepModal"
        data-bs-toggle="modal"
        className="invisible"
      ></div>
    </div>
  );
}

export function DoppelgangerBtnNew() {
  const biggerThan1920 = useMediaPredicate("(min-width: 1920px)");
  const biggerThan1800 = useMediaPredicate("(min-width: 1799px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");

  return (
    <div className=" h-100 w-100 position-absolute container--padding ">
      <div className=" position-relative">
        {biggerThan1920 && (
          <div
            style={{
              top: "15px",
              right: "100px",
              width: "190px",
              height: "50px",
            }}
            className=" position-absolute whiteBg rounded-3 p-1  "
          >
            <div
              style={{
                width: "120px",
                height: "40px",
                top: "0px",
                left: "0px",
              }}
              className=" position-absolute w-100 h-100 whiteBg pulsing  "
            ></div>
            <div
              style={{
                width: "180px",
                height: "40px",
                top: "5px",
                left: "5px",
              }}
              className=" position-absolute rounded-3 gap-2  blueBg d-flex justify-content-center align-items-center  "
            >
              <div
                style={{ fill: "white" }}
                className=" svg-force-path-stroke whiteColor "
              >
                <SVGCross />
              </div>
              <p className=" s14  whiteColor museo-sans-500">
                <FormattedMessage id="new" />
              </p>
            </div>
          </div>
        )}
        {biggerThan1800 && !biggerThan1920 && (
          <div
            style={{
              top: "15px",
              right: "45px",
              width: "190px",
              height: "50px",
            }}
            className=" position-absolute whiteBg rounded-3 p-1  "
          >
            <div
              style={{
                width: "120px",
                height: "40px",
                top: "0px",
                left: "0px",
              }}
              className=" position-absolute w-100 h-100 whiteBg pulsing  "
            ></div>
            <div
              style={{
                width: "180px",
                height: "40px",
                top: "5px",
                left: "5px",
              }}
              className=" position-absolute rounded-3 gap-2  blueBg d-flex justify-content-center align-items-center  "
            >
              <div
                style={{ fill: "white" }}
                className=" svg-force-path-stroke whiteColor "
              >
                <SVGCross />
              </div>
              <p className=" s14  whiteColor museo-sans-500">
                <FormattedMessage id="new" />
              </p>
            </div>
          </div>
        )}
        {biggerThan1280 && !biggerThan1800 && (
          <div
            style={{
              top: "15px",
              right: "70px",
              width: "190px",
              height: "50px",
            }}
            className=" position-absolute whiteBg rounded-3 p-1  "
          >
            <div
              style={{
                width: "120px",
                height: "40px",
                top: "0px",
                left: "0px",
              }}
              className=" position-absolute w-100 h-100 whiteBg pulsing  "
            ></div>
            <div
              style={{
                width: "180px",
                height: "40px",
                top: "5px",
                left: "5px",
              }}
              className=" position-absolute rounded-3 gap-2  blueBg d-flex justify-content-center align-items-center  "
            >
              <div
                style={{ fill: "white" }}
                className=" svg-force-path-stroke whiteColor "
              >
                <SVGCross />
              </div>
              <p className=" s14  whiteColor museo-sans-500">
                <FormattedMessage id="new" />
              </p>
            </div>
          </div>
        )}
        {!biggerThan1920 && !biggerThan1280 && (
          <div
            style={{
              top: "15px",
              right: "70px",
              width: "140px",
              height: "50px",
            }}
            className=" position-absolute whiteBg rounded-3 p-1  "
          >
            <div
              style={{ top: "0px", left: "0px" }}
              className=" position-absolute w-100 h-100 whiteBg pulsing  "
            ></div>
            <div
              style={{
                width: "130px",
                height: "40px",
                top: "5px",
                left: "5px",
              }}
              className=" position-absolute rounded-3 gap-2  blueBg d-flex justify-content-center align-items-center  "
            >
              <div
                style={{ fill: "white" }}
                className=" svg-force-path-stroke whiteColor "
              >
                <SVGCross />
              </div>
              <p className=" s14  whiteColor museo-sans-500">
                <FormattedMessage id="new" />
              </p>
            </div>
          </div>
        )}
      </div>
      <div className=" position-relative">
        <div
          style={
            biggerThan1920
              ? { right: "105px", width: "288px", top: "85px" }
              : biggerThan1800
              ? { right: "50px", width: "288px", top: "85px" }
              : { right: "75px", width: "288px", top: "85px" }
          }
          className=" bg-white position-absolute rounded-3 "
        >
          <ul className=" museo-sans-500 p-3 m-0 list-unstyled">
            <li>
              <div className="p-0 model-plus--row">
                <div className=" blackColor svg-stroke-gray s16">
                  <Edbs />
                </div>
                <p className=" s14 blackColor mb-0 ">
                  <FormattedMessage
                    id="header.button.new.one"
                    defaultMessage="Create WM"
                  />
                </p>
                <div
                  style={{ justifySelf: "center" }}
                  className=" skyColor bi bi-box-arrow-up-right"
                ></div>
              </div>
            </li>
            <hr></hr>
            <li>
              <div className="p-0 model-plus--row">
                <div className="  s16">
                  <AddPerson />
                </div>
                <p className="s14 blackColor mb-0 ">
                  <FormattedMessage
                    id="header.button.new.two"
                    defaultMessage="New incorporation"
                  />
                </p>
                <div
                  style={{ justifySelf: "center" }}
                  className=" skyColor bi bi-box-arrow-up-right"
                ></div>
              </div>
            </li>
            <hr></hr>
            <li>
              <div className="p-0 model-plus--row">
                <div className=" svg-force-color blackColor  s16">
                  <NewOrder />
                </div>
                <p className="s14 blackColor mb-0 ">
                  <FormattedMessage
                    id="header.button.new.four"
                    defaultMessage="Load purchase order"
                  />
                </p>
                <div
                  style={{ justifySelf: "center" }}
                  className=" skyColor bi bi-box-arrow-up-right"
                ></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
