import React from 'react'
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import EDBDatedRow from './EDBDatedRow'

import { FormattedMessage } from 'react-intl';

import Loader from '../../../common/Share/Loader'
import EDBEmpty from './EDBEmpty';

import { DatedExtraEDBMeetings } from '../../Framework/redux/EDBsSideEffects';
import SkeletonEDBRowList from '../../../common/Share/SkeletonEDBRowList';
import ShareKeyGenerator from '../../../common/Share/ShareKeyGenerator';



export default function EDBDatedList({dataWasFiltered, order, edbType, datedFilterOffsetState, setDatedFilterOffsetState, prizeLevel, date}) {
 
    const dispatch = useDispatch() 

    const [isVisible, setIsVisible] = useState()

    const extraDatedLoaderRef = useRef()
    const datedElementsOffset = useRef(10)
    
    const filterInformation = useSelector((state) => state.edbData.filters)

    const datedEDBs = useSelector((state) => state.edbData.datedEDBs)
    const loadingDatedEDBs = useSelector((state) => state.edbData.loadingDatedEDBs)

    const datedExtraEDBs = useSelector((state) => state.edbData.datedExtraEDBs)
    const loadingExtraDatedEDBs = useSelector((state) => state.edbData.loadingExtraDatedEDBs)

    function defineSortBy(orderObject){
        if(orderObject.highLow && orderObject.type.sale){
            return "sales.desc"
        } else if(!orderObject.highLow && orderObject.type.sale){
            return "sales.asc"
        } else if(orderObject.highLow && orderObject.type.guest){
            return "assistants.desc"
        } else if(!orderObject.highLow && orderObject.type.guest){
            return "assistants.asc"
        } else if(orderObject.highLow && orderObject.type.level){
            return "hostLevel.desc"
        } else if(!orderObject.highLow && orderObject.type.level){
            return "hostLevel.asc"
        }
    }
    
      function defineEDBType(edbTypeObject){
        if(edbTypeObject.virtual){
            return "virtual"
        } else if(edbTypeObject.onSite){
            return "presencial"
        }
        return
      }
    
      function definePrizeLevel(awardObject){
        if(awardObject.noAward){
            return "noPrize"
        } else if(awardObject.oneAward){
            return "firstPrize"
        } else if(awardObject.twoAward){
            return "secondPrize"
        }
        return
      }
    
      function defineDate(dateObject){ 
        const dateOriginArray = dateObject.origin.split("-")
        const dateOriginFixedString = dateOriginArray.join("")
        const dateTargetArray = dateObject.target.split("-")
        const dateTargetFixedString = dateTargetArray.join("")
        return {dateStart: dateOriginFixedString, dateEnd: dateTargetFixedString }
      }


    async function loadNewElements(thereIsAFilter) {
        if(thereIsAFilter){
            await dispatch(DatedExtraEDBMeetings({offset: datedFilterOffsetState, limit:10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
            setDatedFilterOffsetState(datedFilterOffsetState + 10)
        } else if (filterInformation.wasFiltered){
            await dispatch(DatedExtraEDBMeetings({offset: datedFilterOffsetState, limit:10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
            setDatedFilterOffsetState(datedFilterOffsetState + 10)
        } else {
            await dispatch(DatedExtraEDBMeetings({offset: datedFilterOffsetState, limit:10}))
            setDatedFilterOffsetState(datedFilterOffsetState + 10)
        }
    }

    

    useEffect(() => {
        if(extraDatedLoaderRef.current){
            const observer = new IntersectionObserver((entries) => {
                const entry = entries[0]
                setIsVisible(entry.isIntersecting)
            })
            observer.observe(extraDatedLoaderRef.current)
        }
    
        if(isVisible){
            loadNewElements(dataWasFiltered)
        }
    }, [isVisible, extraDatedLoaderRef.current, loadingDatedEDBs])

    function displayRows() {
        return datedEDBs.map((element) => {
            return (
                <EDBDatedRow 
                    key={ShareKeyGenerator()}
                    id={element.id}
                    date={element.date}
                    title={element.title}
                    hostName={element.hostName}
                    edbType={element.edbType}
                    edbState={element.edbState}
                    daysToOpen={element.daysToOpen}
                    edbStatus={element.edbStatus}
                    totalRegistered={element.totalRegistered}
                    registeredStatus={element.registeredStatus}
                />
            )
        })
    }

    /*Array para probar el caso en que no haya Encuentros */
    let testArray = []

  return (
    <div className=' w-100' >
    {
      // loadingDatedEDBs

      /** SKELETON 
       * <div className="edb-empty-height position-relative">
          <SkeletonEDBRowList />
          <div>
            <SkeletonEDBRowList noWidth={true} />
            <hr></hr>
            <SkeletonEDBRowList noWidth={true} />
            <hr></hr>
            <SkeletonEDBRowList noWidth={true} />
            <hr></hr>
          </div>
        </div>
       * 
       */
    }
      {loadingDatedEDBs ? (
        <div className="edb-empty-height position-relative">
        <SkeletonEDBRowList />
        <div>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
          <SkeletonEDBRowList noWidth={true} />
          <hr></hr>
        </div>
      </div>
      ) : (
        <>
          <div className="d-flex ps-3 pe-2 s12 museo-sans-500 grayColor text-uppercase justify-content-between align-items-center row-height">
            <div className="" style={{ width: "14%" }}>
              <FormattedMessage id="edb.list.table.time" defaultMessage="" />
            </div>
            <div className="" style={{ width: "21%" }}>
              <FormattedMessage id="edb.list.table.theme" defaultMessage="" />
            </div>
            <div className="" style={{ width: "20%" }}>
              <FormattedMessage id="edb.list.table.host" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.type" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.status" defaultMessage="" />
            </div>
            <div className="" style={{ width: "10%" }}>
              <FormattedMessage id="edb.list.table.guest" defaultMessage="" />
            </div>
            <div className="" style={{ width: "5%" }}></div>
          </div>
          {datedEDBs.length < 1 ? (
            <div className="d-flex justify-content-center align-items-center edb-empty-height">
              <EDBEmpty
                title={
                  <FormattedMessage
                    id="edb.no.dated.edbs"
                    defaultMessage="No hay encuentros Fechados"
                  />
                }
                description={
                  <FormattedMessage
                    id="edb.no.dated.edbs.description"
                    defaultMessage="No hay encuentros Fechados"
                  />
                }
              ></EDBEmpty>
            </div>
          ) : (
            <>
              {displayRows()}
              {!loadingDatedEDBs && datedEDBs.length >= 10 ? (
                <div
                  on
                  ref={extraDatedLoaderRef}
                  className="mt-4"
                  style={{ height: "30px" }}
                >
                  {isVisible ? (
                    loadingExtraDatedEDBs ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="spinner-border blueColor" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="museo-sans-500 s14 mt-2">
                        <FormattedMessage id='edb.list.loading.more' />
                        </p>
                        
                      </div>
                    ) : datedExtraEDBs.length < 1 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="museo-sans-500 s14">
                        <FormattedMessage id='edb.list.loading.end' />
                        </p>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  );
}
