import React from "react";
import DashboardFirstStepsRow from "../../../Dashboard/Presentation/components/DashboardFirstStepsRow";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

const DashboardFirstSteepsRestOfGroup = () => {
  const stepsData = useSelector(
    (state) => state?.firstStepsAllReducer?.firstStepsIncorp
  );
  let ConsultantsGroup = 0;
  let varRestGroup = 0;
  const datoGuardado = localStorage.getItem("rest");
  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {}, [windowWidth]);

  const findLeastOne = (array, boolean) => {
    if (!array) {
      return undefined
    }
    let aux = array.find((elm) => elm.isPersonalIncorporated == boolean);

    return aux;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  for (
    let index = 0;
    index < stepsData?.personalGroup?.consultantsGroup.length;
    index++
  ) {
    if (
      stepsData?.personalGroup?.consultantsGroup[index]
        ?.isPersonalIncorporated == true
    ) {
      ConsultantsGroup++;
    } else {
      varRestGroup++;
    }
  }

  return (
    <>
      <div className="container--padding--card-holder">
        <div className="w-100">
          <div
            className=" bg-white w-100 position-relative h-100"
            style={{ zIndex: 1 }}
          >
            <div className="table--header--1eraGen pt-4 px-0 museo-sans-500 s12 grayColor">
              <p className=" text-uppercase">
                <FormattedMessage id="edb.details.list.name" />
              </p>
              <p className=" text-uppercase">
                <FormattedMessage id="first.steps.page.table.header.advance" />
              </p>
              <p className=" text-uppercase">
                <FormattedMessage id="first.steps.page.table.header.progress" />
              </p>
              <p></p>
            </div>
            <>
              {findLeastOne(
                stepsData?.personalGroup?.consultantsGroup,
                false
              ) ? (
                <>
                  <div
                    style={{
                      paddingBottom: "73px",
                      maxHeight: "827px",
                      minHeight: biggerThan1000
                        ? "980px"
                        : !windowWidth == 1280
                        ? "350px"
                        : "390px",
                    }}
                    className="hidden-y-scroll"
                  >
                    {stepsData?.personalGroup?.consultantsGroup?.map((elm) => {
                      return (
                        <>
                          {elm.isPersonalIncorporated == false && (
                            <>
                              <DashboardFirstStepsRow
                                elm={elm}
                                short={false}
                                incorp={"false"}
                                key={ShareKeyGenerator()}
                              />

                              <hr
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "0px",
                                }}
                              ></hr>
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <>
                    {datoGuardado == true ? (
                      <></>
                    ) : (
                      <div
                        style={{
                          paddingBottom: "73px",
                          maxHeight: "827px",
                          minHeight: biggerThan1000
                            ? "250px"
                            : !windowWidth == 1280
                            ? "350px"
                            : "390px",
                        }}
                        className="hidden-y-scroll d-flex flex-column w-100 justify-content-center align-items-center"
                      >
                        <p className="museo-sans-500 s24">
                          <FormattedMessage id="ffss.rest.no.info" />
                        </p>
                        <div
                          style={{ marginLeft: "90px", marginRight: "90px" }}
                        >
                          <p className="museo-sans-500 s14 grayColor text-center">
                            <FormattedMessage id="ffss.rest.no.info.desc" />
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardFirstSteepsRestOfGroup;
