import { useSelector } from "react-redux";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import { multiLangJSONParse } from "../../../../common/Share/MultiLangJSONParse";
import ShareModalShareBody from "../../../../common/Share/ShareModalShareBody";

export default function PersonalSalesModalShare({ open, socialArray, data }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const handleSwissDate = (date) => {
    let longStringArray = date.split("T");
    let longStringDate = longStringArray[0];
    let shotStringArray = longStringDate.split("-");
    let year = shotStringArray[0];
    let month = shotStringArray[1];
    let day = shotStringArray[2];

    return `${day}-${month}-${year.slice(2, 4)}`;
  };

  const sharedDataPhoneText =
    data &&
    `${
      multiLangJSONParse()[
        "share.message.personal.sale.request.phone.parse.one"
      ]
    }${data.id}${
      multiLangJSONParse()[
        "share.message.personal.sale.request.phone.parse.two"
      ]
    }${data.status}%0A${handleSwissDate(data.date)}${
      multiLangJSONParse()[
        "share.message.personal.sale.request.phone.parse.three"
      ]
    }${data.invoiceNumber}${
      multiLangJSONParse()[
        "share.message.personal.sale.request.phone.parse.four"
      ]
    }${data.products.length}${
      multiLangJSONParse()[
        "share.message.personal.sale.request.phone.parse.five"
      ]
    }${data.totalAmount.toLocaleString()}`;

  const sharedData =
    data &&
    `${
      multiLangJSONParse()["share.message.personal.sale.request.web.parse.one"]
    }${data.id}\n${
      multiLangJSONParse()["share.message.personal.sale.request.web.parse.two"]
    }${data.status}\n${handleSwissDate(data.date)}${
      multiLangJSONParse()[
        "share.message.personal.sale.request.web.parse.three"
      ]
    }${data.invoiceNumber}\n${
      multiLangJSONParse()["share.message.personal.sale.request.web.parse.four"]
    }${data.products.length}\n${
      multiLangJSONParse()["share.message.personal.sale.request.web.parse.five"]
    }${formatAmount(
      data?.totalAmount,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    )}`;

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(sharedData);
      open();
    } catch (error) {
      unsecuredCopyToClipboard(sharedData);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <>
      {socialArray && (
        <div
          className="modal fade"
          id="modalShareExample"
          aria-hidden="true"
          aria-labelledby="modalShareExample"
          tabIndex="-1"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "556px" }}
          >
            <div className="modal-content modal-radius">
              <div className="modal-body p-0">
                <ShareModalShareBody
                  sharedDataPhoneText={sharedDataPhoneText}
                  sharedData={sharedData}
                  socialArray={socialArray}
                  configExist={configExist}
                  doThis={doThis}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
