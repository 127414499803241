import React from 'react'
import ReturnToAndTitle from '../../../Share/ReturnToAndTitle'

export default function ConfigChangeSuccessful({travelFunction, title, description}) {
  return (
    <ReturnToAndTitle
        travelFunction={travelFunction}
        returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
        title={title}
    >
        <p>{description}</p>
    </ReturnToAndTitle>
  )
}
