import "./dashboardGraph.css";
import GraphData from "./GraphData";
import GraphList from "./GraphList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEarningsCard } from "../../../../Earning/redux/earningSideEffects";
import { ReactComponent as SVGTriangle } from "../../../../common/svgs/ic-triangle-warning-lg.svg";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as SVGChevArrowDown } from "../../../../common/svgs/ic-chevron-down-md.svg";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import ReformatAmountsForGraph from "../../../../common/Share/ReformatAmountsForGraph";

export default function DashboardGraphLeader({ gap, toggler }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");

  const earningData = useSelector((state) => state.earning.data);
  const earningDataPL = useSelector(
    (state) => state.earning.dataPotentialLeader
  );
  const scoreData = useSelector((state) => state.scoreData);
  const profileData = useSelector((state) => state.userInfo);

  let wholeData = {};

  const gapActiveOrEmptyBg = () => {
    return ["rgba(221, 221, 221, 0.8)"];
  };

  const gapDeactiveBg = () => {
    return wholeData.colors;
  };

  const valuesArray = (string) => {
    const nonArray = [
      ReformatAmountsForGraph({
        amount: earningData?.byPersonalSales?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningData?.byPersonalGroup?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningData?.byDescendant?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningData?.bondExecutiveDirector?.amount,
        profileData: profileData,
      }),
    ];

    const nonPLArray = [
      ReformatAmountsForGraph({
        amount: earningDataPL?.byPersonalSales?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningDataPL?.byPersonalGroup?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningDataPL?.byDescendant?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningDataPL?.bondExecutiveDirector?.amount,
        profileData: profileData,
      }),
    ];

    if (profileData?.userInfo?.level < 2) {
      wholeData.array = nonPLArray;
    } else {
      wholeData.array = nonArray;
    }

    wholeData.colors = [" #8889DB", "#D18DEF", "#456ECE", "#CED2FF"];
    wholeData.labels = [
      "Venta personal",
      "Grupo personal",
      "Descendencia",
      "Bono",
    ];

    //wholeData = ordenarManualmenteConColores(wholeData.array, wholeData.colors, wholeData.labels );

    if (string == "array") {
      return wholeData.array;
    }
    if (string == "colors") {
      return wholeData.colors;
    }
    if (string == "labels") {
      return wholeData.labels;
    }

    return wholeData;
  };

  useEffect(() => {
    setLoader(true);
    onStartUp();
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, [dispatch]);

  const onStartUp = async () => {
    await dispatch(fetchEarningsCard());
    const result = await valuesArray();
  };

  const data =
    profileData?.userInfo?.level == 1
      ? {
          labels: gap ? [" "] : valuesArray("labels"),
          datasets: [
            {
              label: gap ? "" : "# of Votes",
              data:
                gap == true
                  ? [1]
                  : earningDataPL?.potentialEarnings == 0
                  ? [1]
                  : valuesArray("array"),
              backgroundColor: gap
                ? gapActiveOrEmptyBg
                : earningDataPL?.potentialEarnings == 0
                ? gapActiveOrEmptyBg
                : gapDeactiveBg,
              borderColor: [
                // We don't use them at all.
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              spacing: gap || earningDataPL?.potentialEarnings == 0 ? 0 : 15,
              outerWidth: 20,
              borderWidth: 0,
              borderRadius: gap
                ? 0
                : earningDataPL?.byPersonalSales?.amount === 0 &&
                  earningDataPL?.byPersonalGroup?.amount === 0 &&
                  (earningDataPL?.byDescendant?.amount === 0 ||
                    earningDataPL?.byDescendant === null) &&
                  (earningDataPL?.bondExecutiveDirector?.amount === 0 ||
                    earningDataPL?.bondExecutiveDirector === null)
                ? 0
                : 40,
              cutout: "80%",
            },
          ],
          tooltip: {
            enabled: false,
          },
        }
      : {
          labels: gap ? [" "] : valuesArray("labels"),
          datasets: [
            {
              label: gap ? "" : "# of Votes",
              data:
                gap == true
                  ? [1]
                  : earningData?.potentialEarnings == 0
                  ? [1]
                  : valuesArray("array"),
              backgroundColor: gap
                ? gapActiveOrEmptyBg
                : earningData?.potentialEarnings == 0
                ? gapActiveOrEmptyBg
                : gapDeactiveBg,
              borderColor: [
                // We don't use them at all.
                "rgba(255, 206, 86, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 99, 132, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              spacing: gap || earningData?.potentialEarnings == 0 ? 0 : 15,
              outerWidth: 20,
              borderWidth: 0,
              borderRadius: gap
                ? 0
                : earningData?.byPersonalSales?.amount === 0 &&
                  earningData?.byPersonalGroup?.amount === 0 &&
                  (earningData?.byDescendant?.amount === 0 ||
                    earningData?.byDescendant === null) &&
                  (earningData?.bondExecutiveDirector?.amount === 0 ||
                    earningData?.bondExecutiveDirector === null)
                ? 0
                : 40,
              cutout: "80%",
            },
          ],
          tooltip: {
            enabled: false,
          },
        };

  const loadingData = {
    labels: gap
      ? [" "]
      : ["Venta personal", "Grupo personal", "Descendencia", "Bono"],
    datasets: [
      {
        label: gap ? "" : "# of Votes",
        data: [1200, 600, 400, 100],
        backgroundColor: ["rgba(221, 221, 221, 0.8)"],
        borderColor: [
          // We don't use them at all.
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        spacing: 15,
        outerWidth: 20,
        borderWidth: 0,
        borderRadius: 40,
        cutout: "80%",
      },
    ],
  };

  const loadingPlugins = [
    {
      beforeDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        let fontSize = (height / 150).toFixed(2);
        ctx.font = gap
          ? fontSize + "em MuseoSans700"
          : fontSize + "em DMSansBold";
        ctx.textBaseline = "center";

        let text = "", // Edit this.
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.restore();
      },
    },
  ];

  const plugins =
    profileData?.userInfo?.level == 1
      ? [
          {
            beforeDraw: function (chart) {
              let width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
              ctx.restore();
              let fontSize = (height / 150).toFixed(2);
              ctx.font = gap
                ? fontSize + "em MuseoSans700"
                : fontSize + "em DMSansBold";
              ctx.textBaseline = "center";

              let text = gap
                  ? ``
                  : `${formatAmount(
                      earningDataPL?.potentialEarnings,
                      profileData?.userInfo?.cultureInfo?.languageCode,
                      profileData?.userInfo?.cultureInfo?.defaultCurrencyCode
                    )}`, // Edit this.
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
              ctx.fillText(text, textX, textY);
              ctx.restore();
            },
            tooltip: {
              enabled: false,
            },
          },
        ]
      : [
          {
            beforeDraw: function (chart) {
              let width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
              ctx.restore();
              let fontSize = (height / 150).toFixed(2);
              ctx.font = gap
                ? fontSize + "em MuseoSans700"
                : fontSize + "em DMSansBold";
              ctx.textBaseline = "center";

              let text = gap
                  ? ``
                  : `${formatAmount(
                      earningData.potentialEarnings,
                      profileData.userInfo?.cultureInfo?.languageCode,
                      profileData.userInfo?.cultureInfo?.defaultCurrencyCode
                    )}`, // Edit this.
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
              ctx.fillText(text, textX, textY);
              ctx.restore();
            },
            tooltip: {
              enabled: false,
            },
          },
        ];

  return (
    <>
      {loader ? (
          <div style={{ minHeight: "443px" }} className=" py-3 d-flex ">
              <div
                style={
                  biggerThan1000 ? { maxWidth: "364px" } : { maxWidth: "291px" }
                }
                id=""
                className=" d-flex flex-column align-items-center ps-4  container--dognut py-3 museo-sans-500"
              >
                <div
                  style={
                    biggerThan1000
                      ? { width: "344px", height: "45px", borderRadius: "16px" }
                      : { width: "274px", height: "45px", borderRadius: "16px" }
                  }
                  className=" position-relative brightGrayBgOpacity20 museo-sans-500 s14 p-2 rounded-1 d-flex text-center align-items-center  mb-3"
                >
                  <div
                    style={{ height: "12px", borderRadius: "16px" }}
                    className=" brightGrayBg position-relative"
                  >
                    <p className=" invisible">
                      <FormattedMessage id="dashboard.graph.upper.label" />
                    </p>
                  </div>
                </div>

                <div
                  id="graph-media"
                  style={
                    biggerThan1000 ? { width: "304px" } : { width: "257px" }
                  }
                  className="position-relative"
                >
                  {biggerThan1000 ? (
                    <>
                      <div
                        style={{
                          width: "103px",
                          height: "24px",
                          top: "40%",
                          left: "100px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={
                          biggerThan1000
                            ? {
                                width: "141px",
                                height: "8px",
                                top: "55%",
                                left: "80px",
                                zIndex: 10,
                              }
                            : {
                                width: "130px",
                                height: "32px",
                                top: "41%",
                                left: "80px",
                              }
                        }
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={
                          biggerThan1000
                            ? {
                                width: "141px",
                                height: "8px",
                                top: "60%",
                                left: "80px",
                                zIndex: 10,
                              }
                            : {
                                width: "130px",
                                height: "32px",
                                top: "41%",
                                left: "80px",
                              }
                        }
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "103px",
                          height: "24px",
                          top: "40%",
                          left: "80px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={{
                          width: "112px",
                          height: "8px",
                          top: "55%",
                          left: "75px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={{
                          width: "112px",
                          height: "8px",
                          top: "60%",
                          left: "75px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                    </>
                  )}
                  <GraphData
                    biggerThan1000={biggerThan1000}
                    score={scoreData}
                    data={loadingData}
                    plugins={loadingPlugins}
                  />
                </div>
              </div>
            <div
              style={{ width: "1px", margin: "12px 32px 12px 32px" }}
              className=" brightGrayBg "
            >
              <p className=" invisible">.</p>
            </div>
            <div className=" py-3 w-100">
              <SkeletonDashboardRowData />
            </div>
          </div>
      ) : (
        <div style={{ minHeight: "443px" }} className=" py-3 d-flex ">
          {gap ? (
              <div
                style={{ maxWidth: "364px" }}
                id="dashboard--dognut--gap"
                className=" d-flex flex-column align-items-center ps-4  container--dognut  py-3 museo-sans-500"
              >
                <div
                  style={
                    biggerThan1000
                      ? { maxWidth: "364px" }
                      : { maxWidth: "291px" }
                  }
                  className=" dashboard--dognut-gap position-absolute"
                ></div>

                <div
                  style={
                    biggerThan1000
                      ? { width: "344px", background: "#FEFBEC" }
                      : { width: "274px", background: "#FEFBEC" }
                  }
                  className=" align-items-center gap-3 p-3 d-flex museo-sans-500 s14 rounded-3 p-2 goldBoldColor w-100"
                >
                  <div>
                    <SVGTriangle />
                  </div>
                  <p style={{ color: "#DCB632" }}>
                    <FormattedMessage id="dashboard.graph.upper.label.gap" />
                  </p>
                </div>
                <div
                  style={
                    biggerThan1000 ? { width: "304px" } : { width: "257px" }
                  }
                  className=" position-relative grayColor"
                >
                  <GraphData score={scoreData} data={data} plugins={plugins} />
                </div>
              </div>
          ) : (
              <div
                style={
                  biggerThan1000 ? { maxWidth: "364px" } : { maxWidth: "291px" }
                }
                id="dashboard--dognut"
                className=" d-flex flex-column align-items-center ps-4  container--dognut py-3 museo-sans-500"
              >
                <div
                  style={
                    biggerThan1000 ? { width: "344px" } : { width: "274px" }
                  }
                  className=" museo-sans-500 s14 p-2 blueColor rounded-1 d-flex text-center donutHeaderBg mb-3"
                >
                  <FormattedMessage id="dashboard.graph.upper.label" />
                </div>

                {wholeData?.labels && (
                  <div
                    id="graph-media"
                    style={
                      biggerThan1000 ? { width: "304px" } : { width: "257px" }
                    }
                  >
                    <GraphData
                      biggerThan1000={biggerThan1000}
                      score={scoreData}
                      data={data}
                      plugins={plugins}
                    />
                  </div>
                )}
              </div>
          )}
          <div
            style={{ width: "1px", margin: "12px 32px 12px 32px" }}
            className=" brightGrayBg "
          >
            <p className=" invisible">.</p>
          </div>
          <div className=" py-3 w-100">
            {
              // earningDataPL
              profileData?.userInfo?.level < 2 ? (
                  <GraphList
                    gap={gap}
                    toggler={toggler}
                    earningData={earningDataPL}
                    profileData={profileData.userInfo}
                    score={scoreData}
                    plugins={plugins}
                    data={data}
                  />
              ) : (
                  <GraphList
                    gap={gap}
                    toggler={toggler}
                    earningData={earningData}
                    profileData={profileData.userInfo}
                    score={scoreData}
                    plugins={plugins}
                    data={data}
                  />
              )
            }
          </div>
        </div>
      )}
    </>
  );
}

export function SkeletonDashboardRowData(params) {
  return (
      <div className=" mt-4 pt-3 d-flex flex-column">
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <hr></hr>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
      </div>
  );
}
