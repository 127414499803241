import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";

import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGTrash } from "../../../common/svgs/ic-trash-sm.svg";
import { ReactComponent as SVGExternal } from "../../../common/svgs/ic-external-link-sm.svg";
import { ReactComponent as VirtualStoreIcon } from "../../../common/svgs/ic-tienda-virtual-blue-md.svg";
import { ReactComponent as SVGEDB } from "../../../common/svgs/clients/edb.svg";
import { ReactComponent as SVGAnf } from "../../../common/svgs/clients/anfitriona.svg";
import { ReactComponent as SVGOrden } from "../../../common/svgs/clients/ordenes.svg";

import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { useMediaPredicate } from "react-media-hook";

export default function ClientListDetailsHeader({
  buttonData,
  loading,
  data,
  socialArray,
}) {
  const smallerThan1024 = useMediaPredicate("(min-width: 1100px)");
  const [toggleMoreActions, setToggleMoreActions] = useState(false);

  const toggleData = () => {
    setToggleMoreActions(!toggleMoreActions);
  };

  return (
    <div className=" w-100 d-flex  justify-content-between ">
      <div className="d-flex position-relative">
        <ClientListDetailsHeaderData
          loading={loading}
          data={data}
          socialArray={socialArray}
          s1={smallerThan1024}
        />
        <div className=" position-absolute invisible">
          {buttonData?.data?.links.map((elm) => {
            return (
              <a
                id={`${elm.id}`}
                target="_blank"
                href={elm.link}
                className=" invisible"
              ></a>
            );
          })}
        </div>
      </div>
      <div className=" position-relative d-flex gap-4">
        <div
          onClick={toggleData}
          style={{ width: "184px", height: "40px", borderRadius: "8px" }}
          className="d-flex border-1 gap-2 pointer museo-sans-700 s14 button-hover-light-text skyColor skyBorder align-items-center justify-content-center "
        >
          <p>
            <FormattedMessage id="invitation.details.more.actions" />
          </p>
          <div
            style={{ height: "16px", width: "16px", top: -3 }}
            className=" position-relative svg-force-size"
          >
            <SVGChevArrowDown />
          </div>
        </div>
        {toggleMoreActions && (
          <div
            style={{ top: 60, right: 2, width: "288px" }}
            className=" p-3 rounded-16 shadow bg-white position-absolute"
          >
            <ClientAddMore index={1} data={buttonData.data} />
            <hr />
            <ClientAddMore index={3} data={buttonData.data} />
            {data?.contactOriginType != "PERSONAL_SITE" && (
              <>
                <hr />
                <ClientAddMore index={4} data={buttonData.data} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function ClientAddMore({ index, buttonData }) {
  const onClick = (index, buttonData) => {
    if (index != 1 || buttonData?.length > 1) {
      return "";
    }

    const clickMe = document.getElementById("create_order");
    clickMe.click();
  };

  const data = {
    text: {
      3: "client.details.modal.host.title",
      2: "buttons.invite.edb",
      1: "buttons.post.order",
      4: "notification.modal.delete.title.one",
    },
    icon: { 1: <SVGOrden />, 2: <SVGEDB />, 3: <SVGAnf />, 4: <SVGTrash /> },
    externalLink: { 1: true, 2: true, 3: true, 4: false },
    modalActive: { 1: false, 2: false, 3: true, 4: true },
    modalData: {
      id: { 1: "", 2: "", 3: "#modalHosting", 4: "#modalDelete" },
    },
    onClickActive: { 1: true, 2: true, 3: false, 4: false },
    onClick: () => onClick(index, buttonData),
  };

  return (
    <div
      style={{ height: "40px" }}
      onClick={data.onClickActive[index] ? data.onClick : ""}
      className=" museo-sans-500 position-relative pointer d-flex justify-content-between align-items-center"
    >
      {data.modalActive[index] && (
        <div
          data-bs-toggle="modal"
          data-bs-target={data.modalData.id[index]}
          className=" position-absolute h-100 w-100"
        ></div>
      )}
      <div className="d-flex gap-2 justify-content-center align-items-center">
        <div
          style={{ width: "24px", height: "24px" }}
          className=" svg-force-size"
        >
          {data.icon[index]}
        </div>
        <p className=" s14">
          <FormattedMessage id={data.text[index]} />
        </p>
      </div>
      <div
        style={{ width: "16px", height: "16px" }}
        className=" svg-force-size"
      >
        {data.externalLink[index] ? <SVGExternal /> : ""}
      </div>
    </div>
  );
}

export function ClientListDetailsHeaderData({ loading, data, s1 }) {
  return (
    <>
      <div style={{ top: -80 }} className=" position-absolute">
        <div
          style={{
            width: "160px",
            height: "160px",
            borderRadius: "50%",
            border: "1.5px white solid",
          }}
          className=" d-flex justify-content-center align-items-center "
        >
          {loading ? (
            <ImageCircleHandler
              size={"42px"}
              width={"148px"}
              height={"148px"}
              top={2}
            />
          ) : (
            <ImageCircleHandler
              size={"42px"}
              width={"148px"}
              height={"148px"}
              firstName={data?.firstName}
              lastName={data?.lastName}
              top={2}
            />
          )}
        </div>
      </div>
      <div style={{ width: "160px" }} className=" position-relative"></div>
      <div style={{ marginLeft: "20px" }} className=" d-flex flex-column gap-2">
        {loading ? (
          <p
            style={{ width: "200px" }}
            className=" rounded-5 lightui1-shimmer s30 museo-sans-700 text-captalize "
          >
            <span className="invisible">aaaaaa</span>
          </p>
        ) : (
          <div
            style={{ top: 0, width: !s1 ? "470px" : "680px" }}
            className=" position-absolute"
          >
            <p className=" d-flex flex-wrap s30 museo-sans-700 text-captalize ">
              {data?.firstName} {data?.lastName}
            </p>
            {data?.contactOriginType == "PERSONAL_SITE" && (
              <div
                style={{ width: "251px", height: "32px" }}
                className="d-flex justify-content-center align-items-center rounded-5 blueColor backgroundShallowBlue gap-1  "
              >
                <p className=" s14 museo-sans-500">
                  <FormattedMessage id="client.details.header.data.no.edit.message" />
                </p>
                <div
                  style={{ width: "16px", height: "16px", top: -6 }}
                  className=" position-relative svg-force-size svg-force-color blueColor "
                >
                  <VirtualStoreIcon />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
