import { useEffect, useState } from 'react';
import Select,{ components, createFilter } from 'react-select';
import { ReactComponent as SVGChevArrowDown } from "../svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGChevArrowUp } from "../svgs/ic-chevron-up-md.svg";
import {clientSelectAddressStyles} from './CustomSelectStyles'
import { multiLangJSONParse } from './MultiLangJSONParse';

const DropdownIndicator = ({ selectProps,...props }) => { 
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen?<SVGChevArrowUp /> : <SVGChevArrowDown />}
    </components.DropdownIndicator>
  );
};

export default function ShareInputSelect({customStyles,width,height,options, value,...field}) {
  const globalStyles = customStyles || clientSelectAddressStyles;
  const [selectedOption, setSelectedOption] = useState(null);
  const [toggler, setToggler] = useState(false);

  const defaultOption = { value: field?.name, label: `${multiLangJSONParse()[`selected.${field.name}`]}` };
  const filterConfig = {
    ignoreCase:true,
    ignoreAccents:true,
    trim:true,
    matchFrom: 'start'
  };

  const operateOnChange = (value) => {
    if (field.onChange) {
      field.onChange(value, field.name);
    }
  };

  const onSelectedFatherChange = (value) => {
    if (value) {
      field.onChangeFather(field.level, value, field?.name);
    }
  };

  const combinedRequest = () => {
    onSelectedFatherChange(selectedOption)
    operateOnChange(selectedOption)
  };

  const isValueEmpty = (value, options) => {
    if (value) {
      return false
    }
    if (options?.length < 1) {
        return true
    };
    return false;
  };

  useEffect(() => {
    field?.level ? combinedRequest() :  operateOnChange(selectedOption)
  }, [selectedOption])


  useEffect(() => {
    setSelectedOption(null)
    console.log("Change of Options on level: ", field?.level);
  }, [options])


  return (
    <div onClick={() => setToggler(true)} className="position-relative pointer ">
      {
        ( !toggler && !isValueEmpty(value, options) ) &&
        <div style={{top: 10,zIndex: 1, left: 15}} className="position-absolute w-75" >
          <p className={` position-relative bg-white s14`} >
            {value}
          </p>
        </div>

      }
      <Select
        unstyled
        isDisabled={isValueEmpty(value, options)}
        isOptionDisabled={(option) => option.length < 1}
        filterOption={createFilter(filterConfig)}
        defaultValue={ defaultOption}
        onChange={setSelectedOption}
        components={{ DropdownIndicator }}
        options={[defaultOption, ...options]}
        noOptionsMessage={() => `${multiLangJSONParse()[`selected.${field.name}`] || "Seleccionar" }`}
        styles={{...globalStyles,
            container: (style) => ({
              ...style,
              width: width || 'max-content',
              height: height || '40px'
            }),
            indicatorSeparator:(styles) => ({ ...styles,
               borderLeft: field.errorToggler ? "1px solid #FD0E69" : "1px solid #CED2FF",
           }),
          }}
        id={`select-${value}`}
        className={`s14 rounded-2 ${field.errorToggler ? "input-error-border" : "input-border"} bg-white`}
        classNames={{
          control: (state) =>
            state.isDisabled && 'brightGrayBgOpacity20 custom-select-placeholder s14',
          option: (state) => 
            state.isFocused && 'text-onhover-bold museo-sans-700',
          dropdownIndicator:(state) => 
              state.isDisabled && 'svg-force-path-stroke-grey s14',
          menuList:() => 'remove-scrollbar'
        }}
        placeholder={`${multiLangJSONParse()[`selected.${field.name}`] || "Seleccionar" }`}
      />
    </div>
  )    
}
