import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";

import { ReactComponent as CloseIcon } from "../../../../../common/svgs/ic-x-sm.svg";
import { ReactComponent as Edbs } from "../../../../../common/svgs/ic-edbs.svg";
import { ReactComponent as ArrowRight } from "../../../../../common/svgs/ic-arrow-right-sm.svg";
import { defineStepNumber } from "../../../../Framework/redux/dashboardAction";
import { ReactComponent as Red } from "../../../../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as Yellow } from "../../../../../common/svgs/ic-alert-circle-yellow.svg";
import { ReactComponent as Green } from "../../../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as Purple } from "../../../../../common/svgs/ic-req-red.svg";
import { ReactComponent as Oferts } from "../../../../../common/svgs/ic-offer-web.svg";

import {
  faUser,
  faAward,
  faCalendarDays,
  faMedal,
  faChartPie,
  faRightFromBracket,
  faSitemap,
  faGear,
  faCircleInfo,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../pages/dashboard.css";
import ReformatLevelByGender from "../../../../../common/Share/ReformatLevelByGender";

export default function FirstStepModal({removeBlur}) {
  const biggerThan1920 = useMediaPredicate("(min-width: 1800px)");
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.userInfo.userInfo);

  const onCancelBlur = () => {
    removeBlur()
    
    dispatch(defineStepNumber(20));
    localStorage.removeItem("firstLogin")
  };

  const onCancelOnlyBlur = () => {
    removeBlur()
    dispatch(defineStepNumber(20));
  };

  const getModalStyles = () => {
    return biggerThan1920 ? s3Styles : s1Styles;
  };
  
  const s3Styles = {
    left: "350px",
    top: "75px",
    width: "355px",
    height: "205px",
  };
  
  const s1Styles = {
    left: "350px",
    top: "75px",
    width: "355px",
    height: "205px",
  };

  return (
    <div
      className="modal fade vh-100 vw-100"
      id="firstStepModal"
      data-bs-backdrop="static"
      aria-hidden="true"
      aria-labelledby="stepOneModal"
      tabindex="-1"
    >
      <DoppelgangerNav />
      <div
        className="modal-dialog m-0 position-fixed"
        style={getModalStyles()}
      >
        <div
          className="modal-content h-100"
          style={{ padding: "25px", borderRadius: "15px" }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="museo-sans-500 s12 grayColor">
                {
                profileData.level < 2
                  ? "PASO 1 DE 4"
                  : "PASO 1 DE 5"}
              </p>
              <div
                className=" pointer "
                data-bs-dismiss="modal"
                onClick={() => onCancelOnlyBlur()}
                aria-label="Close"
              >
                <CloseIcon />
              </div>
            </div>
            <p className="museo-sans-700 s20 blackColor">
              <FormattedMessage id="on.boarding.first.title.one" />
            </p>
            <div className="mt-2" style={{ lineHeight: "18px" }}>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ display: "inline", verticalAlign: "top" }}
              >
                <FormattedMessage id="on.boarding.first.p.one" />
                {" "}
              </p>
              <p
                className="s14 museo-sans-700 blackColor"
                style={{ display: "inline", verticalAlign: "top" }}
              >
                <FormattedMessage id="on.boarding.first.p.two" />
                {" "}
              </p>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ display: "inline", verticalAlign: "top" }}
              >
                <FormattedMessage id="on.boarding.first.p.three" />
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-auto">
            <a
              onClick={() => onCancelBlur()}
              data-bs-dismiss="modal"
              aria-label="Close"
              role="button"
              className="museo-sans-700 s14 skyColor button-hover-light-text"
            >
              <FormattedMessage id="buttons.not.show.ever" />
            </a>
            <div
              onClick={() => dispatch(defineStepNumber(2))}
              className="d-flex hover-pointer align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
            >
              <a
                data-bs-target="#secondStepModal"
                data-bs-toggle="modal"
                role="button"
                className=" text-capitalize museo-sans-700 s14 skyColor button-hover-light-text"
              >
                <FormattedMessage id="buttons.follow" />
              </a>
              <ArrowRight />
            </div>
          </div>
        </div>
        <div className="modal-arrow-2"></div>
      </div>
    </div>
  );
}

export function DoppelgangerNav() {
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const status = useSelector((state) => state.scoreData.consultantStatus);
  const blurToggle = useSelector((state) => state.onBoardingData.blurred);

  const navStyles = {
    left: "0px",
    top: "0px",
    height: "100%",
    width: "300px",
  };

  const focusStyle = {
    zIndex: 1000,
    width: "284px",
    top: "22px",
    left: "6px",
  };

  return (
      <div style={navStyles} className=" position-absolute">
        <div style={navStyles} className={blurToggle && "blurred"}>
          <div className=" w-100 h-100 position-absolute opacity-50  blackBg"></div>
          <div className=" w-100 h-100 navbarBg"></div>
        </div>
        <div
          style={focusStyle}
          className=" position-absolute navbarBg rounded-3 "
        >
          <div className=" position-relative">
            <div className=" rounded-3 position-absolute h-100 whiteBg opacity-25 w-100 pulsing "></div>
            <div style={{ zIndex: 1001 }} className=" p-2 d-flex gap-3 ">
              <div>
                {status.checkMarkStatus === "normal" ||
                status.checkMarkStatus === "gap" ||
                !status.checkMarkStatus ? (
                  <></>
                ) : (
                  <div
                    id="profile--status"
                    style={{
                      top: "40px",
                      left: "40px",
                      borderRadius: "50%",
                      width: "18px",
                      height: "18px",
                    }}
                    className=" bg-white position-absolute"
                  >
                    {status.checkMarkStatus === "reduced" && <Purple />}
                    {status.checkMarkStatus === "risk" && <Red />}
                    {status.checkMarkStatus === "completed" && <Green />}
                    {status.checkMarkStatus === "warning" &&<Yellow />}
                  </div>
                )}
                <img
                  src={
                    profileData.image
                      ? profileData.image
                      : "https://via.placeholder.com/60"
                  }
                  alt="profile"
                  width="45"
                  height="45"
                  className="rounded-circle"
                />
              </div>
              <div className=" museo-sans-500 whiteColor d-flex flex-column">
                <h5 className="s16">
                  {profileData?.name ? profileData?.name : "Name"}
                </h5>
                <p className="s12">
                <ReformatLevelByGender level={profileData.level} gender={profileData.gender} roleDescriptionDefault={profileData?.roleDescription}  />
                </p>
              </div>
            </div>
            <hr></hr>
            <div
              style={{ zIndex: 1001 }}
              className=" p-2 gap-2 d-flex whiteColor flex-column s12"
            >
              <div className="museo-sans-700">
              <DoppelgangerNavItem index={4} />
              </div>
              <div className="museo-sans-700">
              <DoppelgangerNavItem index={5} />
              </div>
              <div className="museo-sans-700">
              <DoppelgangerNavItem index={6} />
              </div>
              <div className="museo-sans-700">
              <DoppelgangerNavItem index={7} />
              </div>
              <hr></hr>
              <DoppelgangerNavItem index={1} />
              <DoppelgangerNavItem index={2} />
              <DoppelgangerNavItem index={3} />
              <DoppelgangerNavItem index={12} />
              <hr></hr>
              <DoppelgangerNavItem index={8} />
              {
                // <DoppelgangerNavItem index={11} />
              }
              <DoppelgangerNavItem index={10} />
              <DoppelgangerNavItem index={9} />
              <hr></hr>
            </div>
          </div>
        </div>
      </div>
  );
}

export function DoppelgangerNavItem({ index }) {
  const profileData = useSelector((state) => state.userInfo.userInfo);

  const iconMap = {
    1: faUser,
    2: faMedal,
    3: faCalendarDays,
    4: faChartPie,
    5: faAward,
    7: faSitemap,
    8: faGear,
    9: faRightFromBracket,
    10: faCircleInfo,
    11: faShare,
  };
  
  const switchAwesomeIcon = (index) => iconMap[index];
  
  const textMap = {
    1: "nav.label.five.index",
    2: "nav.label.six.index",
    3: "nav.label.seven.index",
    4: "nav.label.one.index",
    5: "nav.label.two.index",
    6: "nav.label.three.index",
    7: profileData.level > 1 ? "nav.label.four.index" : "descendants.header.title.two",
    8: "nav.label.eight.index",
    9: "nav.label.ten.index",
    10: "nav.label.twelve.index",
    11: "nav.label.eleven.index",
    12: "nav.label.unknown.indexed",
  };
  
  const switchTextContent = (index) => textMap[index];

  return (
    <div className=" d-flex px-2 mx-1 gap-4 ">
      <i className="s14">
        {index === 6 && (
          <div style={{ width: "16px" }}>
            <Edbs />
          </div>
        )}
        {index !== 6 && (
          <div
            style={{ width: "16px", height: "16px" }}
            className="d-flex justify-content-center"
          >
            {
              index != 12 ?
                <FontAwesomeIcon icon={switchAwesomeIcon(index)} />
              :
                <Oferts />
            }
          </div>
        )}
      </i>
      <div>
        <p>
          <FormattedMessage
            id={switchTextContent(index)}
            defaultMessage="My Profile"
          />
        </p>
      </div>
      { index == 1 || index == 4 || index == 6 && (
        <i className="bi bi-chevron-down ms-auto"></i>
      )}  
    </div>
  );
}
