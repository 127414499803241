import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "../../../Dashboard/Presentation/pages/dashboard.css";
import { getOffersData } from "../../../Dashboard/Framework/redux/dashboardSideEffect";
import { OfertCardDates } from "../components/dates/OfertsCardDates";

import OfertsModalImageCard from "../components/modals/OfertsModalImageCard";
import OfertsDashboardTwo from "../components/OfertsDashboardTwo";
import ModalClipboard from "../../../common/Share/ModalClipboard";

export default function DashboardOffers() {
  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");
  const is1920 = useMediaPredicate("(min-width: 1800px)");
  const dispatch = useDispatch();
  const offerData = useSelector((state) => state?.firstStepsData?.offer);
  const [infoForModal, setInfoForModal] = useState();
  const [value, setValue] = useState(false);
  useEffect(() => {
    dispatch(getOffersData());
  }, []);

  const getData = (data) => {
    setInfoForModal(data);
  };

  const onCloseCopyModal = () => {
    setCopyToggler(false);
  };

  const onOpenCopyModal = () => {
    setCopyToggler(true);
  };

  const [copyToggler, setCopyToggler] = useState(false);

  const togglerChange = () => {
    setValue(!value);
  };

  return (
    <div className=" mt-5 ">
      <div className=" container--padding--card-holder ">
        <div>
          <div className=" w-100 ">
            <ModalClipboard show={copyToggler} close={onCloseCopyModal} />
            <div
              style={{ paddingLeft: "0px" }}
              className={`w-100  container--no-padding d-flex align-items-end mt-3`}
            >
              <button
                onClick={value == true ? togglerChange : null}
                className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button gap-1 museo-sans-500 s14 ${
                  value === true
                    ? "lightGreyBg  grayColor "
                    : "bronceBg blackColor"
                }`}
              >
                <spam>
                  <FormattedMessage id="for" />
                </spam>
                <spam className=" text-lowercase">
                  <FormattedMessage id="clients" />
                </spam>
              </button>
              <button
                onClick={value == false ? togglerChange : null}
                className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button gap-1 museo-sans-500 s14 ${
                  value === true
                    ? "bronceBg blackColor"
                    : "lightGreyBg  grayColor"
                }`}
              >
                <spam>
                  <FormattedMessage id="for" />
                </spam>
                <spam className=" text-lowercase">
                  {" "}
                  <FormattedMessage id="consultant" />{" "}
                </spam>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-white container--padding--card-holder">
        <div>
          <div className=" w-100 container--padding--card-holder">
            {value == true ? (
              <>
                <div className="w-100">
                  <div className="w-100 pt-3 pb-4 position-relative bronceBg flap-content-container">
                    {biggerThan1000 ? (
                      <>
                        {is1920 ? (
                          <>
                            <div className="py-4 d-flex flex-wrap gap-3 justify-content-start w-100">
                              {offerData?.data?.map((element) => {
                                return (
                                  <>
                                    {element?.applyTo != "cliente" && (
                                      <>
                                        {OfertCardDates(
                                          element?.dateStartOfPublication,
                                          element?.dateEndOfPublication,
                                          true
                                        ) && (
                                          <>
                                            {element.status == "published" && (
                                              <OfertsDashboardTwo
                                                onOpenCopyModal={
                                                  onOpenCopyModal
                                                }
                                                getData={getData}
                                                element={element}
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="py-4 d-flex flex-wrap gap-4 justify-content-start w-100">
                              {offerData?.data?.map((element) => {
                                return (
                                  <>
                                    {element?.applyTo != "cliente" && (
                                      <>
                                        {OfertCardDates(
                                          element?.dateStartOfPublication,
                                          element?.dateEndOfPublication,
                                          true
                                        ) && (
                                          <>
                                            {element.status == "published" && (
                                              <OfertsDashboardTwo
                                                onOpenCopyModal={
                                                  onOpenCopyModal
                                                }
                                                getData={getData}
                                                element={element}
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div
                        style={{ gap: "16px" }}
                        className="py-4 d-flex flex-wrap justify-content-start w-100"
                      >
                        {offerData?.data?.map((element) => {
                          return (
                            <>
                              {element?.applyTo != "cliente" && (
                                <>
                                  {OfertCardDates(
                                    element?.dateStartOfPublication,
                                    element?.dateEndOfPublication,
                                    true
                                  ) && (
                                    <>
                                      {element.status == "published" && (
                                        <OfertsDashboardTwo
                                          onOpenCopyModal={onOpenCopyModal}
                                          getData={getData}
                                          element={element}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-100">
                  <div className="w-100 pt-3 pb-4 position-relative bronceBg flap-content-container">
                    {biggerThan1000 ? (
                      <>
                        {is1920 ? (
                          <>
                            <div className="py-4 d-flex flex-wrap gap-3 justify-content-start w-100">
                              {offerData?.data?.map((element) => {
                                return (
                                  <>
                                    {element?.applyTo == "cliente" && (
                                      <>
                                        {OfertCardDates(
                                          element?.dateStartOfPublication,
                                          element?.dateEndOfPublication,
                                          true
                                        ) && (
                                          <>
                                            {element.status == "published" && (
                                              <OfertsDashboardTwo
                                                onOpenCopyModal={
                                                  onOpenCopyModal
                                                }
                                                getData={getData}
                                                element={element}
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="py-4 d-flex flex-wrap gap-4 justify-content-start w-100">
                              {offerData?.data?.map((element) => {
                                return (
                                  <>
                                    {element?.applyTo == "cliente" && (
                                      <>
                                        {OfertCardDates(
                                          element?.dateStartOfPublication,
                                          element?.dateEndOfPublication,
                                          true
                                        ) && (
                                          <>
                                            {element.status == "published" && (
                                              <OfertsDashboardTwo
                                                onOpenCopyModal={
                                                  onOpenCopyModal
                                                }
                                                getData={getData}
                                                element={element}
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div
                        style={{ gap: "16px" }}
                        className="py-4 d-flex flex-wrap justify-content-start w-100"
                      >
                        {offerData?.data?.map((element) => {
                          return (
                            <>
                              {element?.applyTo == "cliente" && (
                                <>
                                  {OfertCardDates(
                                    element?.dateStartOfPublication,
                                    element?.dateEndOfPublication,
                                    true
                                  ) && (
                                    <>
                                      {element.status == "published" && (
                                        <OfertsDashboardTwo
                                          onOpenCopyModal={onOpenCopyModal}
                                          getData={getData}
                                          element={element}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <OfertsModalImageCard
        element={infoForModal}
        onOpenCopyModal={onOpenCopyModal}
      />
    </div>
  );
}
